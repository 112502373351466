import FishingPage from '../pages/FishingPage';
import HelpPage from '../pages/HelpPage';
import TasksPage from '../pages/TasksPage';
import dataPages from './dataPages';

const pages = {
  ...dataPages,
  [FishingPage.id]: FishingPage,
  [HelpPage.id]: HelpPage,
  [TasksPage.id]: TasksPage,
};

export default pages;
