export const getUserLocation = (callback) => navigator.geolocation.getCurrentPosition(callback);

export const getLocationIfPermissible = async (callback) => {
  let state = false;
  try {
    state = await navigator.permissions.query({ name: 'geolocation' }).state;
  // eslint-disable-next-line no-empty
  } catch {}
  if (state === 'granted') {
    getUserLocation(callback);
  }
};
