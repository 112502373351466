import moment from 'moment';
import {
  MARCH, APRIL, MAY, JUNE, JULY, AUGUST, SEPTEMBER, OCTOBER, NOVEMBER,
} from '../constants/cropCycles';
import { RAT, PLANT, PLANT_15 } from '../constants/cropTypes';

const plantLabel = `Plant ${moment().format('YYYY')}`;
const plant15Label = `Plant ${moment().subtract(1, 'year').format('YYYY')}`;

const commonMonths = {
  [AUGUST]: {
    info: {
      long: 'A late crop cycle is planted/harvested on the 15th August. All cropping cycles are 12 months. Crops are simulated with a 1.8 m row configuration. All crops are irrigated to refill the profile if the stress index drops below 0.6.',
      short: 'A plant/ratooning cycle starting mid August (15th August)',
    },
    label: 'August',
    value: AUGUST,
  },
  [JULY]: {
    info: {
      long: 'A mid crop cycle is planted/harvested on the 15th July. All cropping cycles are 12 months. Crops are simulated with a 1.8 m row configuration. All crops are irrigated to refill the profile if the stress index drops below 0.6.',
      short: 'A plant/ratooning cycle starting mid July (15th July)',
    },
    label: 'July',
    value: JULY,
  },
  [JUNE]: {
    info: {
      long: 'An early crop cycle is planted/harvested on the 15th June. All cropping cycles are 12 months. Crops are simulated with a 1.8 m row configuration. All crops are irrigated to refill the profile if the stress index drops below 0.6.',
      short: 'A plant/ratooning cycle starting mid June (15th June)',
    },
    label: 'June',
    value: JUNE,
  },
  [SEPTEMBER]: {
    info: {
      long: 'An early crop cycle is planted/harvested on the 15th September. All cropping cycles are 12 months. Crops are simulated with a 1.8 m row configuration. All crops are irrigated to refill the profile if the stress index drops below 0.6.',
      short: 'A plant/ratooning cycle starting mid September (15th September)',
    },
    label: 'September',
    value: SEPTEMBER,
  },
};

const cropFilters = [
  {
    cropCycle: [
      {
        info: {
          long: 'An early crop cycle is planted/harvested on the 15th March. All cropping cycles are 12 months. Crops are simulated with a 1.5 m row configuration . All crops are irrigated to refill the profile if the stress index drops below 0.6.',
          short: 'A plant cycle starting mid March (15th March)',
        },
        label: 'March',
        value: MARCH,
      },
      {
        info: {
          long: 'A mid crop cycle is planted/harvested on the 15th April. All cropping cycles are 12 months. Crops are simulated with a 1.5 m row configuration . All crops are irrigated to refill the profile if the stress index drops below 0.6.',
          short: 'A plant cycle starting mid April (15th April)',
        },
        label: 'April',
        value: APRIL,
      },
      {
        info: {
          long: 'A late crop cycle is planted/harvested on the 15th May. All cropping cycles are 12 months. Crops are simulated with a 1.5 m row configuration . All crops are irrigated to refill the profile if the stress index drops below 0.6.',
          short: 'A plant cycle starting mid May (15th May)',
        },
        label: 'May',
        value: MAY,
      },
      commonMonths[JUNE],
      commonMonths[JULY],
      commonMonths[AUGUST],
      commonMonths[SEPTEMBER],
    ],
    info: {
      long: 'A plant crop is grown from a single sett rather than a previously harvested stool. In crop simulations a plant crop tends to develop slowly but, also tends to have a higher final yield than ratoon crops.',
      short: 'A first plant crop',
    },
    label: plantLabel,
    value: PLANT,
  },
  {
    cropCycle: [
      {
        info: {
          long: 'An early crop cycle is planted/harvested on the 15th March. All cropping cycles are 12 months. Crops are simulated with a 1.5 m row configuration . All crops are irrigated to refill the profile if the stress index drops below 0.6.',
          short: 'A plant cycle starting mid March (15th March)',
        },
        label: 'March',
        value: MARCH,
      },
      {
        info: {
          long: 'A mid crop cycle is planted/harvested on the 15th April. All cropping cycles are 12 months. Crops are simulated with a 1.5 m row configuration . All crops are irrigated to refill the profile if the stress index drops below 0.6.',
          short: 'A plant cycle starting mid April (15th April)',
        },
        label: 'April',
        value: APRIL,
      },
      {
        info: {
          long: 'A late crop cycle is planted/harvested on the 15th May. All cropping cycles are 12 months. Crops are simulated with a 1.5 m row configuration . All crops are irrigated to refill the profile if the stress index drops below 0.6.',
          short: 'A plant cycle starting mid May (15th May)',
        },
        label: 'May',
        value: MAY,
      },
      commonMonths[JUNE],
      commonMonths[JULY],
      commonMonths[AUGUST],
      commonMonths[SEPTEMBER],
    ],
    info: {
      long: 'A plant crop is grown from a single sett rather than a previously harvested stool. In crop simulations a plant crop tends to develop slowly but, also tends to have a higher final yield than ratoon crops.',
      short: 'A first plant crop',
    },
    label: plant15Label,
    value: PLANT_15,
  },
  {
    cropCycle: [
      commonMonths[JUNE],
      commonMonths[JULY],
      commonMonths[AUGUST],
      commonMonths[SEPTEMBER],
      {
        info: {
          long: 'A mid crop cycle is planted/harvested on the 15th October. All cropping cycles are 12 months. Crops are simulated with a 1.8 m row configuration. All crops are irrigated to refill the profile if the stress index drops below 0.6.',
          short: 'A ratooning cycle starting mid October (15th October)',
        },
        label: 'October',
        value: OCTOBER,
      },
      {
        info: {
          long: 'A late crop cycle is planted/harvested on the 15th November. All cropping cycles are 12 months. Crops are simulated with a 1.8 m row configuration. All crops are irrigated to refill the profile if the stress index drops below 0.6.',
          short: 'A ratooning cycle starting mid November (15th November)',
        },
        label: 'November',
        value: NOVEMBER,
      },
    ],
    info: {
      long: 'A ratoon crop has been harvested and the stool allowed to regrow. In crop simulations a ratoon crop tends to develop faster than a plant crop, but also tends to have a lower final yield. While a second or third ratoon can be simulated, there are no differences between ratooning levels within the crop model.',
      short: 'A first ratoon crop',
    },
    label: 'Ratoon',
    value: RAT,
  },
];

export default cropFilters;
