import ReactGA from 'react-ga';
import * as types from '../constants/actionTypes';

const analyticsMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case types.WIDGET_ADDED:
      ReactGA.event({
        action: 'Add widget',
        category: 'Dashboard',
      });
      break;

    case types.WIDGET_EDITED:
      ReactGA.event({
        action: 'Edit widget',
        category: 'Dashboard',
      });
      break;

    case types.WIDGET_REMOVED:
      ReactGA.event({
        action: 'Remove widget',
        category: 'Dashboard',
      });
      break;

    case types.CURRENT_LOCATION_SET:
      ReactGA.event({
        action: 'Set location',
        category: 'Map',
        ...action.event,
      });
      break;

    case types.DATA_PAGE_VIEW_DATE_TIME_RANGE_CHANGED:
      ReactGA.event({
        action: action.event.action,
        category: 'Dates',
        ...action.event,
      });
      break;

    case types.IS_PLAYING_CHANGED:
      ReactGA.event({
        action: 'Set is playing',
        category: 'Map',
        label: action.isPlaying ? 'true' : 'false',
      });
      break;

    case types.MAP_DISPLAY_CHANGED:
      ReactGA.event({
        action: 'Set property',
        category: 'Map',
        label: action.propertyId,
      });
      break;

    case types.MAP_STYLE_CHANGED:
      ReactGA.event({
        action: 'Set style',
        category: 'Map',
        label: action.style,
      });
      break;

    case types.DATA_PAGE_DATE_TIME_OFFSET_CHANGED:
      if (action.event) {
        ReactGA.event({
          action: action.event.action,
          category: 'Map',
          ...action.event,
        });
      }
      break;

    case types.USER_SIGNED_OUT:
      ReactGA.event({
        action: 'Sign out',
        category: 'Auth',
      });
      break;

    default:
      break;
  }
  return next(action);
};

export default analyticsMiddleware;
