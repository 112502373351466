import './Legend.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function Legend({
  className, legend, orientation, unit, condensed,
}) {
  const legendEntries = legend
    .filter((item, index) => (!condensed || index === 0 || index === legend.length - 1))
    .reverse()
    .map((item) => (
      <div
        className={classNames('cssip-legend__item position-relative d-flex align-items-center justify-content-end', {
          'flex-column': orientation === 'horizontal',
        })}
        key={item.value}
      >
        <div className="fw-bold cssip-legend__value small mx-1">
          {item.value}
        </div>
        <div className="bg-dark cssip-legend__item-dash" />
      </div>
    ));
  const gradientDirections = {
    horizontal: 'right',
    vertical: 'top',
  };
  return (
    <div className={classNames(className, `cssip-legend--${orientation}`)}>
      <div
        className={classNames(
          'd-flex align-items-center order-1 bg-white',
          {
            'h-100 flex-column': orientation === 'vertical',
            'w-100': orientation === 'horizontal',
          },
        )}
      >
        <div
          className={classNames('d-flex flex-grow-1', {
            'flex-column': orientation === 'horizontal',
          })}
        >
          <div
            className={classNames('w-100 d-flex justify-content-between', {
              'flex-column': orientation === 'vertical',
              'flex-row-reverse': orientation === 'horizontal',
            })}
          >
            {legendEntries}
          </div>
          <div
            className={classNames('cssip-legend__gradient', {
              'mb-1': orientation === 'horizontal',
              'me-1': orientation === 'vertical',
            })}
            style={{ background: `linear-gradient(to ${gradientDirections[orientation]}, ${legend.map((item) => item.color).join(',')})` }}
          />
        </div>
        <div className="fw-bold text-center small me-1">
          {unit}
        </div>
      </div>
    </div>
  );
}

Legend.propTypes = {
  className: PropTypes.string,
  legend: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  orientation: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  condensed: PropTypes.bool,
};

Legend.defaultProps = {
  className: '',
  condensed: false,
};
