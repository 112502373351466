import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';
import analyticsMiddleware from './analyticsMiddleware';
import { replayMiddleware, getReplayActions } from './reduxReplay';
import userLocationInjectorMiddleware from './userLocationInjectorMiddleware';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const storeForInitialisingState = createStore(rootReducer, applyMiddleware(thunkMiddleware));

const replayActions = getReplayActions();
replayActions.forEach((action) => storeForInitialisingState.dispatch(action));

const actionEditors = [];

const actionEditorMiddleware = (store) => (next) => (action) => {
  let editedAction = { ...action };
  actionEditors.forEach((editor) => {
    editedAction = editor(action, { store });
  });
  return next(editedAction);
};

const middleware = [
  thunkMiddleware,
  userLocationInjectorMiddleware,
  analyticsMiddleware,
  replayMiddleware,
  actionEditorMiddleware,
];

const store = createStore(
  rootReducer,
  storeForInitialisingState.getState(),
  composeEnhancer(applyMiddleware(...middleware)),
);

store.addActionEditor = (editor) => actionEditors.push(editor);

export default store;
