import _ from 'lodash';
import * as conditionIds from '../constants/conditionIds';
import * as valueTypes from '../constants/valueTypes';
import Property from './Property';

export default class RainPopProperty extends Property {
  getFormatted(data, options = {}) {
    if (_.every([valueTypes.RAIN_50MM], _.partial(_.has, _.get(data, this.id)))) {
      const optionalUnit = options.unit ? this.unit : '';
      const key = options.key || valueTypes.RAIN_50MM;
      return `${data[this.id][key].toFixed(this.decimalPoints)}${optionalUnit} chance of above ${key}`;
    }
    return super.getFormatted(data, options);
  }

  getConditionIds(data) {
    const isRainPopRange = data.some(
      (value) => _.every(
        [valueTypes.RAIN_50MM],
        _.partial(_.has, this.getValue(value.forecast)),
      ),
    );
    if (isRainPopRange) {
      return [
        conditionIds.FORECAST_GREATER_THAN_0MM,
        conditionIds.FORECAST_GREATER_THAN_10MM,
        conditionIds.FORECAST_GREATER_THAN_15MM,
        conditionIds.FORECAST_GREATER_THAN_25MM,
        conditionIds.FORECAST_GREATER_THAN_50MM,
      ];
    }
    return super.getConditionIds(data);
  }

  getLabelForPropertyKey = (key) => {
    const labels = {
      [valueTypes.RAIN_0MM]: 'above 0mm',
      [valueTypes.RAIN_5MM]: 'above 5mm',
      [valueTypes.RAIN_10MM]: 'above 10mm',
      [valueTypes.RAIN_15MM]: 'above 15mm',
      [valueTypes.RAIN_25MM]: 'above 25mm',
      [valueTypes.RAIN_50MM]: 'above 50mm',
    };
    if (key in labels) {
      return labels[key];
    }
    return key;
  };
}
