import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ResponsiveLegend from '../components/ResponsiveLegend';
import { PROPERTY_VIEW } from '../constants/pageViews';
import pages from '../data/dataPages';
import properties from '../data/properties';

const ResponsiveLegendContainer = () => {
  const { propertyId, pageId } = useParams();
  const Page = pages[pageId];
  const property = properties[propertyId];
  const legend = useSelector((state) => {
    const page = new Page(state);
    return property.getLegend({
      timeIntervalId: page.getSelectedTimeInterval(PROPERTY_VIEW, propertyId).id,
    });
  }, _.isEqual);
  return <ResponsiveLegend legend={legend} unit={property.unit} />;
};

export default ResponsiveLegendContainer;
