import _ from 'lodash';
import { matchPath } from 'react-router-dom';
import { HELP } from '../constants/pageIds';
import * as paths from '../constants/paths';
import helpSections from '../data/helpSections';
import * as text from '../data/text';

export default class HelpPage {
  static id = HELP;

  constructor(state) {
    this.state = state;
  }

  // eslint-disable-next-line class-methods-use-this
  generateBreadcrumbs(pathname) {
    const breadcrumbs = [
      {
        to: paths.HOME,
        name: 'Home',
      },
      {
        to: '/help/',
        name: 'Help',
      },
    ];
    const HelpSectionMatch = matchPath(pathname, { path: paths.HELP_SECTION });
    if (HelpSectionMatch) {
      const helpSectionId = _.get(HelpSectionMatch, 'params.sectionId');
      breadcrumbs.push({
        to: pathname,
        name: helpSections[helpSectionId].name,
      });
    }
    return breadcrumbs;
  }

  generateHead(pathname) {
    const breadcrumbs = this.generateBreadcrumbs(pathname);
    return {
      title: breadcrumbs.slice(1).map((item) => item.name).reverse(),
      meta: [
        { name: 'description', content: text.HELP_PAGE_DESCRIPTION },
      ],
    };
  }
}
