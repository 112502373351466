import _ from 'lodash';
import moment from 'moment';
import { matchPath } from 'react-router-dom';
import { OVERVIEW_VIEW, PROPERTY_VIEW } from '../constants/pageViews';
import * as paths from '../constants/paths';
import dateTimeRanges from '../data/dateTimeRanges';
import endpoints from '../data/endpoints';
import locationTypes from '../data/locationTypes';
import { exclamation, times } from '../data/mapIcons';
import properties from '../data/properties';
import timeIntervals from '../data/timeIntervals';

export default class DataPage {
  constructor(state) {
    this.state = state;
  }

  static showMapDisplaySelector = true;

  static getOverviewLocationPath(locationId) {
    return paths.OVERVIEW_ITEM
      .replace(paths.dataPageIdString, this.id)
      .replace(paths.locationIdString, locationId);
  }

  static getOverviewPath() {
    return paths.DATA.replace(paths.dataPageIdString, this.id);
  }

  static getPath() { return this.getOverviewPath(); }

  static getPropertyLocationPath(propertyId, locationId) {
    return paths.PROPERTY_ITEM
      .replace(paths.propertyIdString, propertyId)
      .replace(paths.dataPageIdString, this.id)
      .replace(paths.locationIdString, locationId);
  }

  static getPropertyPath(propertyId) {
    return paths.PROPERTY
      .replace(paths.propertyIdString, propertyId)
      .replace(paths.dataPageIdString, this.id);
  }

  getPageState() {
    return this.state.pages[this.constructor.id];
  }

  static getLatestForecastEndpointId() {
    return this.initialState.forecastEndpointId;
  }

  static getProperties() {
    return this.propertyIds.map((id) => properties[id]);
  }

  getLocationEndpoint() {
    return endpoints[this.constructor.getLocationType().endpointId];
  }

  static filterLocations(locations) {
    return locations.filter((location) => location.type === this.locationTypeId);
  }

  static getLocationType() {
    return locationTypes[this.locationTypeId];
  }

  getSelectedDateTimeRange(view, propertyId) {
    let dateTimeRange = dateTimeRanges[this.getPageState().dateTimeRangeIds[view]];
    if (propertyId) {
      if (properties[propertyId].dateTimeRangeIds.indexOf(dateTimeRange.id) < 0) {
        dateTimeRange = dateTimeRanges[properties[propertyId].dateTimeRangeIds[0]];
      }
    }
    return dateTimeRange;
  }

  getSelectedDateTimeOffset() {
    return this.getPageState().dateTimeOffset;
  }

  getSources() {
    return this.constructor.sources;
  }

  getSelectedForecastEndpoint() {
    return endpoints[this.getPageState().forecastEndpointId];
  }

  static getObservationEndpoint() {
    return endpoints[this.observationEndpointId];
  }

  static getForecastsDatesEndpoint() {
    return endpoints[this.forecastsDatesEndpointId];
  }

  static getForecastEndpoints() {
    return this.forecastEndpointIds.map((id) => endpoints[id]);
  }

  getSelectedTimeInterval(view, propertyId) {
    return timeIntervals[this.getSelectedDateTimeRange(view, propertyId).timeIntervalId];
  }

  getDefaultLocation() {
    return this.constructor.filterLocations(Object.values(this.state.locations))[0];
  }

  // eslint-disable-next-line class-methods-use-this
  getSelectedEndpointIssuedDate() {
    return null;
  }

  getMapDisplayOptions = (propertyId) => {
    const dateTimeRangeId = this.getSelectedDateTimeRange(PROPERTY_VIEW, propertyId).id;
    const forecastEndpointId = this.getSelectedForecastEndpoint().id;
    const observationEndpointId = this.constructor.getObservationEndpoint().id;
    const startDate = moment(this.state.datetimes[dateTimeRangeId].start);
    const endDate = moment(this.state.datetimes[dateTimeRangeId].end);
    const locations = this.constructor.filterLocations(Object.values(this.state.locations))
      .map((location) => ({
        ...location,
        observations: _.get(this.state, `observations[${observationEndpointId}][${location.id}]`, []),
        forecasts: _.get(this.state, `forecasts[${forecastEndpointId}][${location.id}]`, []),
      }));
    let hasForecasts = false;
    let forecastPropertyObject = false;
    locations.forEach((location) => {
      location.forecasts
        .filter((item) => (
          item.timeInterval === this.getSelectedTimeInterval(PROPERTY_VIEW, propertyId).id
        ))
        .filter((item) => moment(item.datetime).isBetween(startDate, endDate, 'day', '[]'))
        .forEach((forecast) => {
          if (propertyId in forecast.properties) {
            hasForecasts = true;
            if (_.isObject(forecast.properties[propertyId])) {
              forecastPropertyObject = forecast.properties[propertyId];
            }
          }
        });
    });
    const hasObservations = locations.some((location) => location.observations
      .filter((item) => moment(item.datetime).isBetween(startDate, endDate, 'day', '[]'))
      .some((observation) => propertyId in observation.properties));
    const options = [];
    if (forecastPropertyObject) {
      Object.keys(forecastPropertyObject).forEach((key) => options.push({
        label: key,
        value: `forecast.${key}`,
      }));
    } else if (hasForecasts) {
      options.push({ label: 'forecast', value: 'forecast' });
    }
    if (hasObservations) {
      options.push({ label: 'observed', value: 'observation' });
    }
    return options;
  };

  generateMapDisplay = (propertyId) => {
    const { display } = this.state.map;
    const options = this.getMapDisplayOptions(propertyId);
    if (options.some((option) => option.value === display)) {
      return display;
    }
    return options.length ? options[Math.floor(options.length / 2)].value : 'forecast';
  };

  getGeoJson = (propertyId) => {
    const timeIntervalId = this.getSelectedTimeInterval(PROPERTY_VIEW, propertyId).id;
    const dateTimeRange = this.getSelectedDateTimeRange(PROPERTY_VIEW);
    const forecastEndpointId = this.getSelectedForecastEndpoint().id;
    const observationEndpointId = this.constructor.getObservationEndpoint().id;
    const mapDisplay = this.generateMapDisplay(propertyId);
    const date = this.constructor.generateGeoJsonDate(
      this.getSelectedDateTimeOffset(),
      dateTimeRange.unit,
    );
    const { metaFilter } = this.getPageState();
    let theFilterer = (i) => i;
    if (metaFilter) {
      theFilterer = (item) => (
        !_.every(Object.keys(metaFilter), _.partial(_.has, item.meta))
        || _.isMatch(item.meta, metaFilter)
      );
    }
    const locations = this.constructor.filterLocations(Object.values(this.state.locations))
      .map((location) => ({
        ...location,
        observations: _.get(this.state, `observations[${observationEndpointId}][${location.id}]`, [])
          .filter(theFilterer),
        forecasts: _.get(this.state, `forecasts[${forecastEndpointId}][${location.id}]`, [])
          .filter(theFilterer),
      }));
    return this.constructor.generateGeoJsonItems(
      this.constructor,
      locations,
      propertyId,
      mapDisplay,
      date,
      timeIntervalId,
    );
  };

  static generateGeoJsonDate(offset, timeIntevalId) {
    return moment().add(offset, timeIntevalId);
  }

  static generateGeoJsonItems(
    Page,
    unfilteredLocations,
    propertyId,
    mapDisplay,
    date,
    timeIntervalId,
  ) {
    const locations = Page.filterLocations(unfilteredLocations);
    return locations.filter((location) => location.geometry).map((location) => {
      const value = Page.getMapValue(
        location,
        propertyId,
        mapDisplay,
        date,
        timeIntervalId,
      );
      const property = properties[propertyId];
      const color = property.getColor(value, { timeIntervalId });
      const to = this.getPropertyLocationPath(property.id, location.id);
      const formattedValue = property.getFormatted(value, { unit: true });
      let icon = property.mapIcon;
      // @TODO: WTF?
      const hasValues = !_.isUndefined(value);
      const hasValue = !_.isUndefined(value);
      if (hasValues && !hasValue) {
        icon = times;
      } else if (!hasValues) {
        icon = exclamation;
      }
      const items = {
        name: location.name,
        icon,
        color: color.rgb(),
        rotation: property.getAngle(value),
        speed: property.getSpeed(value),
        to,
        tooltip: {
          name: location.name,
          value: _.isUndefined(formattedValue) ? 'No current value' : formattedValue,
        },
        value: property.getValue(value),
      };
      return {
        geometry: location.geometry,
        id: location.id,
        properties: items,
        type: 'Feature',
      };
    });
  }

  generateOverviewLocationListItems() {
    const locations = _.orderBy(this.constructor.filterLocations(Object.values(this.state.locations)), ['distance'], ['asc']);
    return locations.map((location) => {
      const items = [];
      if (location.distance) {
        items.push({
          name: 'Distance from current location',
          value: `${parseInt(location.distance, 10)} km`,
        });
      }
      return {
        geoJson: {
          features: locations.map((item) => ({
            geometry: item.geometry,
            properties: {
              selected: item === location,
            },
            type: 'Feature',
          })),
          type: 'FeatureCollection',
        },
        name: location.name,
        properties: items,
        id: location.id,
        to: this.constructor.getOverviewLocationPath(location.id),
      };
    });
  }

  generateBreadcrumbs(pathname) {
    const breadcrumbs = [
      {
        to: paths.HOME,
        name: 'Home',
      },
      {
        to: this.constructor.getOverviewPath(),
        name: this.constructor.label,
      },
    ];
    const propertyPageMatch = matchPath(pathname, { path: paths.PROPERTY_ITEM_OPTIONAL });
    if (propertyPageMatch) {
      const propertyId = _.get(propertyPageMatch, 'params.propertyId');
      const locationId = _.get(propertyPageMatch, 'params.locationId');
      if (locationId) {
        breadcrumbs.push({
          to: this.constructor.getOverviewLocationPath(locationId),
          name: _.get(this.state, `locations[${locationId}].name`, 'location'),
        });
      }
      breadcrumbs.push({
        name: properties[propertyId].name,
      });
    } else {
      const overviewPageMatch = matchPath(pathname, {
        path: paths.OVERVIEW_ITEM_OPTIONAL,
        exact: true,
        strict: false,
      });
      if (overviewPageMatch) {
        const locationId = _.get(overviewPageMatch, 'params.locationId');
        if (locationId) {
          breadcrumbs.push({
            to: this.constructor.getOverviewLocationPath(locationId),
            name: _.get(this.state, `locations[${locationId}].name`, 'location'),
          });
        }
      }
    }
    return breadcrumbs;
  }

  generateHead(pathname) {
    const breadcrumbs = this.generateBreadcrumbs(pathname);
    return {
      title: breadcrumbs.slice(1).map((item) => item.name).reverse(),
      meta: [
        { name: 'description', content: this.constructor.description },
      ],
    };
  }

  getOverviewProperties() {
    const dateTimeRange = this.getSelectedDateTimeRange(OVERVIEW_VIEW);
    return this.constructor.getProperties()
      .filter((property) => property.dateTimeRangeIds.indexOf(dateTimeRange.id) >= 0)
      .map((property) => ({ id: property.id }));
  }

  getMetaFilter() {
    return this.getPageState().metaFilter;
  }

  getSelectedEndpointSourceInfo() {
    return false;
  }
}
