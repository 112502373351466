import {
  faClipboard,
  faTint,
  faFaucet,
  faPercentage,
  faCloudRain,
  faTemperatureHigh,
  faTemperatureLow,
  faRadiation,
  faSun,
  faWind,
  faThermometerHalf,
  faBurn,
  faLeaf,
} from '@fortawesome/free-solid-svg-icons';
import * as chartIds from '../constants/chartIds';
import { TOTAL } from '../constants/combiningTypes';
import {
  LAST_48_HOURS,
  LAST_7_DAYS,
  NEXT_48_HOURS,
  NEXT_7_DAYS,
} from '../constants/dateTimeRangeIds';
import * as propertyIds from '../constants/propertyIds';
import { WEEK } from '../constants/timeIntervalIds';
import CropWaterUseProperty from '../properties/CropWaterUseProperty';
import DeltaTProperty from '../properties/DeltaTProperty';
import IrrigationProperty from '../properties/IrrigationProperty';
import MaxTemperatureProperty from '../properties/MaxTemperatureProperty';
import MinTemperatureProperty from '../properties/MinTemperatureProperty';
import Property from '../properties/Property';
import RainPopProperty from '../properties/RainPopProperty';
import RainProperty from '../properties/RainProperty';
import WindProperty from '../properties/WindProperty';
import * as legends from './legends';
import { arrowUp } from './mapIcons';

const properties = {
  [propertyIds.AIR_TEMPERATURE]: new Property({
    icon: faSun,
    id: propertyIds.AIR_TEMPERATURE,
    info: {
      long: 'The recorded air temperature. This is generally recorded for available real-time weather stations.',
      short: 'Temperature',
    },
    legend: legends.temperatureLegend,
    name: 'Air temperature',
    unit: '°C',
  }),
  [propertyIds.RELATIVE_HUMIDITY]: new Property({
    domain: [0, 100],
    icon: faTint,
    id: propertyIds.RELATIVE_HUMIDITY,
    info: {
      long: 'The recorded relative humidity as a percentage. This is generally recorded for available real-time weather stations.',
      short: 'Relative Humidity',
    },
    legend: legends.probabilityLegend,
    name: 'Relative humidity',
    unit: '%',
  }),
  [propertyIds.WIND_SPEED]: new WindProperty({
    chartId: chartIds.WIND_CHART,
    dateTimeRangeIds: [NEXT_48_HOURS, NEXT_7_DAYS, LAST_48_HOURS, LAST_7_DAYS],
    domain: [0, 'dataMax'],
    hasparticles: true,
    icon: faWind,
    id: propertyIds.WIND_SPEED,
    info: {
      long: 'Wind speed is shown in km/hr. For real-time weather station data and hourly forecasts Wind direction is also provided as a compass direction (e.g. N, NNE, NE,...)',
      short: 'Wind Speed',
    },
    legend: legends.windLegend,
    mapIcon: arrowUp,
    name: 'Wind',
    showMapIconOverPolygon: true,
    unit: 'km/h',
  }),
  [propertyIds.RAIN_24_HOUR_TOTAL]: new Property({
    combining: TOTAL,
    icon: faCloudRain,
    id: propertyIds.RAIN_24_HOUR_TOTAL,
    info: {
      long: 'The total rainfall in mm over the last 24 hours. This is calculated for available real-time weather stations.',
      short: '24hr Rain Trace',
    },
    legend: legends.waterLegend,
    name: 'Rain in last 24 hours',
    unit: 'mm',
  }),
  [propertyIds.RAIN]: new RainProperty({
    combining: TOTAL,
    chartId: chartIds.RAIN_CHART,
    domain: [0, (dataMax) => (dataMax > 50 ? dataMax : 50)],
    icon: faCloudRain,
    id: propertyIds.RAIN,
    info: {
      long: 'Precipitation in mm.',
      short: 'Rainfall',
    },
    legend: legends.waterLegend,
    name: 'Rainfall',
    unit: 'mm',
  }),
  [propertyIds.RAIN_POP]: new RainPopProperty({
    chartId: chartIds.COMBINED_RAIN_POP_CHART,
    domain: [0, 100],
    icon: faPercentage,
    id: propertyIds.RAIN_POP,
    info: {
      long: 'The probability of recieving rainfall above a certain level (> 0 mm).',
      short: 'Probability of Percipitation',
    },
    legend: legends.popLegend,
    name: 'Rainfall Prob',
    unit: '%',
  }),
  [propertyIds.MIN_TEMPERATURE]: new MinTemperatureProperty({
    domain: [(dataMin) => dataMin - 1, 'auto'],
    icon: faTemperatureLow,
    id: propertyIds.MIN_TEMPERATURE,
    info: {
      long: 'The daily minimum temperature. Generally minimum temperatures occur around 5am.',
      short: 'Minimum Temperature',
    },
    legend: legends.temperatureLegend,
    name: 'Min Temp',
    unit: '°C',
  }),
  [propertyIds.MAX_TEMPERATURE]: new MaxTemperatureProperty({
    domain: [(dataMin) => dataMin - 1, 'auto'],
    icon: faTemperatureHigh,
    id: propertyIds.MAX_TEMPERATURE,
    info: {
      long: 'The daily maximum temperature. Generally maximum temperatures occur around 2pm.',
      short: 'Maximum Temperature',
    },
    legend: legends.temperatureLegend,
    name: 'Max Temp',
    unit: '°C',
  }),
  [propertyIds.RADIATION]: new Property({
    domain: [(dataMin) => dataMin - 1, 'auto'],
    icon: faRadiation,
    id: propertyIds.RADIATION,
    info: {
      long: 'The short wave radiation at the surface in MJ/m². This is used in calculating evapotranspiration and crop simulations.',
      short: 'Short wave Radiation',
    },
    legend: legends.radiationLegendCreator([0, 40]),
    name: 'Solar Radiation',
    timeIntervalLegends: {
      [WEEK]: legends.radiationLegendCreator([0, 40 * 7]),
    },
    unit: 'MJ/m²',
  }),
  [propertyIds.REAL_TIME_RADIATION]: new Property({
    domain: [0, 1200],
    icon: faRadiation,
    id: propertyIds.REAL_TIME_RADIATION,
    info: {
      long: 'The short wave radiation at the surface in W/m².',
      short: 'Short wave Radiation',
    },
    legend: legends.radiationLegendCreator([1200, 0]),
    name: 'Solar Radiation',
    unit: 'W/m²',
  }),
  [propertyIds.MIN_RH]: new Property({
    domain: [0, 100],
    icon: faTint,
    id: propertyIds.MIN_RH,
    info: {
      long: 'The minimum relative humidity.',
      short: 'Min Relative Humidity',
    },
    legend: legends.probabilityLegend,
    name: 'Min RH',
    unit: '%',
  }),
  [propertyIds.MAX_RH]: new Property({
    domain: [0, 100],
    icon: faTint,
    id: propertyIds.MAX_RH,
    info: {
      long: 'The maximum relative humidity.',
      short: 'Max Relative Humidity',
    },
    legend: legends.probabilityLegend,
    name: 'Max RH',
    unit: '%',
  }),
  [propertyIds.ET]: new CropWaterUseProperty({
    combining: TOTAL,
    chartId: chartIds.MID_CHART,
    decimalPoints: 1,
    domain: [0, (dataMax) => (dataMax > 5 ? dataMax : 5)],
    icon: faFaucet,
    id: propertyIds.ET,
    info: {
      long: 'This is the sum of soil evaporation and transpiration calculated by irrigweb for the growing crop',
      short: 'Crop Water Use',
    },
    legend: legends.etLegendCreator([0, 18]),
    timeIntervalLegends: {
      [WEEK]: legends.radiationLegendCreator([0, 18 * 7]),
    },
    name: 'CWU',
    unit: 'mm',
  }),
  [propertyIds.FIRST_IRRIG_DATE_SUMMARY]: new IrrigationProperty({
    combining: TOTAL,
    domain: [0, (dataMax) => (dataMax > 5 ? dataMax : 5)],
    icon: faTint,
    id: propertyIds.FIRST_IRRIG_DATE_SUMMARY,
    info: {
      long: 'The likely window for the next simulated irrigation event. Forecasts of irrigation events are currently under development.',
      short: 'Irrigation',
    },
    legend: legends.irrigationLegend,
    name: 'Irrigation',
    unit: ' days away',
  }),
  [propertyIds.WB]: new Property({
    combining: TOTAL,
    domain: [(dataMin) => (dataMin < -5 ? dataMin : -5), 0],
    icon: faClipboard,
    id: propertyIds.WB,
    info: {
      long: 'The soil water deficit is a measure of the depth of water needed to bring the soil up to field capacity. SWD is displayed as negative values. Positive values represent irrigation above field capacity',
      short: 'Soil water deficit',
    },
    legend: legends.swdLegendCreator([-100, 0]),
    name: 'SWD',
    timeIntervalLegends: {
      [WEEK]: legends.swdLegendCreator([-100 * 7, 0]),
    },
    unit: 'mm',
  }),
  [propertyIds.DELTA_T]: new DeltaTProperty({
    dateTimeRangeIds: [NEXT_48_HOURS, LAST_48_HOURS],
    domain: [0, (dataMax) => (dataMax > 8 ? dataMax : 8)],
    icon: faThermometerHalf,
    id: propertyIds.DELTA_T,
    info: {
      long: 'Delta T is one of the standard indicators for acceptable spray conditions. It is calculated by subtracting the wet bulb temperature from the dry bulb temperature. A Delta T between 2 & 8 is usually considered acceptable for spraying.',
      short: 'Delta T',
    },
    legend: legends.deltaTLegend,
    name: 'Delta T',
    unit: '°C',
  }),
  [propertyIds.ETC]: new Property({
    combining: TOTAL,
    chartId: chartIds.MID_CHART,
    domain: [0, (dataMax) => dataMax],
    decimalPoints: 1,
    icon: faBurn,
    id: propertyIds.ETC,
    info: {
      long: 'ETC Is sugarcane evapotranspiration. It is calculated from the Penmann-Monteith reference ETo with a crop factor of 1.25. ETC = 1.25 * ET',
      short: 'Sugarcane crop evapotranspiration',
    },
    legend: legends.etLegendCreator([0, 18]),
    timeIntervalLegends: {
      [WEEK]: legends.radiationLegendCreator([0, 18 * 7]),
    },
    name: 'ETC',
    unit: 'mm',
  }),
  [propertyIds.FIRST_IRRIG_POP]: new Property({
    domain: [0, 100],
    icon: faTint,
    id: propertyIds.FIRST_IRRIG_POP,
    info: {
      long: 'The percent of models that predict the next irrigation occuring for a given date.',
      short: 'Models predicting irrigation',
    },
    legend: legends.probabilityLegend,
    name: 'Models predicting irrigation',
    unit: '% of models',
  }),
  [propertyIds.SOIL_TEMPERATURE]: new Property({
    icon: faLeaf,
    id: propertyIds.SOIL_TEMPERATURE,
    info: {
      long: 'The recorded soil temperature. This is generally recorded for available real-time weather stations.',
      short: 'Temperature',
    },
    legend: legends.temperatureLegend,
    name: 'Soil temperature',
    unit: '°C',
  }),
};

export default properties;
