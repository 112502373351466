import PropTypes from 'prop-types';
import React from 'react';

const Row = ({ children }) => (
  <div className="row px-2">
    {children}
  </div>
);
Row.propTypes = {
  children: PropTypes.node.isRequired,
};

const Column = ({ children, className }) => (
  <div className={`${className} px-2 mb-3`}>
    {children}
  </div>
);
Column.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

const Content = ({
  children, title, className, bg,
}) => (
  <div className={`${className} h-100 bg-${bg} border px-3 mb-3 position-relative d-flex flex-column`}>
    {!!title && (
      <h2 className="h3 border-bottom py-2 mb-3 text-center flex-grow-0 flex-shrink-1">{title}</h2>
    )}
    {children}
  </div>
);
Content.propTypes = {
  bg: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.bool]),
};
Content.defaultProps = {
  bg: 'light',
  className: '',
  title: false,
};

export default { Column, Content, Row };
