import PropTypes from 'prop-types';
import React from 'react';

export default function OverviewItemStats({ stats }) {
  if (stats.length === 0) {
    return null;
  }
  return (
    <ul className="small border-top pt-1 pl-3 my-1 text-danger">
      {stats.map((stat) => (<li key={stat}>{stat}</li>))}
    </ul>
  );
}

OverviewItemStats.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.string).isRequired,
};
