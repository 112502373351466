import React from 'react';
import { useSelector } from 'react-redux';
import Navbar from '../components/Navbar';

const NavbarContainer = () => {
  const isAuthed = useSelector((state) => state.auth.state === 'signedIn');
  return (
    <Navbar isAuthed={isAuthed} />
  );
};

export default NavbarContainer;
