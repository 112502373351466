import * as types from '../constants/actionTypes';

const userLocationInjectorMiddleware = (store) => (next) => (action) => {
  const actionToPassOn = action;
  switch (action.type) {
    case types.REAL_TIME_ADD_NEW_OBSERVATIONS:
    case types.ENDPOINT_RECEIVED: {
      if (action.locations) {
        actionToPassOn.currentLocation = store.getState().map.currentLocation;
      }
      break;
    }
    default:
      break;
  }
  return next(actionToPassOn);
};

export default userLocationInjectorMiddleware;
