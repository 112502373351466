import * as types from '../constants/actionTypes';
import * as mapStyleConstants from '../constants/mapStyles';
import * as valueTypes from '../constants/valueTypes';
import dateTimeRanges from '../data/dateTimeRanges';

const initialState = {
  currentLocation: false,
  display: `forecast.${valueTypes.MID}`,
  showParticles: false,
  style: mapStyleConstants.STREET,
};

export default function map(state = initialState, action = {}) {
  switch (action.type) {
    case types.DATA_PAGE_VIEW_DATE_TIME_RANGE_CHANGED: {
      if (!(action.dateTimeRangeId in dateTimeRanges)) {
        return state;
      }
      let { display } = state;
      if (dateTimeRanges[action.dateTimeRangeId].startOffset < 0) {
        display = 'observation';
      } else if (display === 'observation') {
        display = initialState.display;
      }
      return { ...state, display };
    }

    case types.MAP_DISPLAY_CHANGED: {
      return { ...state, display: action.display };
    }

    case types.CURRENT_LOCATION_SET: {
      return {
        ...state,
        currentLocation: action.location,
      };
    }

    case types.MAP_STYLE_CHANGED: {
      return { ...state, style: action.style };
    }

    case types.MAP_IS_SHOWING_PARTICLES_CHANGED: {
      return {
        ...state,
        showParticles: action.showParticles,
      };
    }

    default: {
      return state;
    }
  }
}
