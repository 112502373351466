export const REAL_TIME_DATE_TIME_RANGE = 'REAL_TIME_DATE_TIME_RANGE';
export const NEXT_24_HOURS = 'Next 24 hours';
export const NEXT_48_HOURS = 'Next 48 hours';
export const NEXT_3_DAYS = 'Next 3 days';
export const NEXT_7_DAYS = 'Next 7 days';
export const NEXT_14_WEEKS = 'Next 14 weeks';
export const LAST_48_HOURS = 'Last 48 hours';
export const LAST_3_DAYS = 'Last 3 days';
export const LAST_7_DAYS = 'Last 7 days';
export const LAST_14_WEEKS = 'Last 14 weeks';
export const LAST_24_WEEKS = 'Last 24 weeks';
export const NEXT_24_WEEKS = 'Next 24 weeks';
export const LAST_30_DAYS = 'Last 30 days';
