import _ from 'lodash';
import moment from 'moment';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Placeholder from '../components/Placeholder';
import { FORECAST_MY_FARM, OBSERVATION_MY_FARM } from '../constants/endpointIds';
import * as propertyIds from '../constants/propertyIds';
import pages from '../data/dataPages';
import dateTimeRanges from '../data/dateTimeRanges';
import { useLoader } from '../lib/hooks';

const FarmItemOverviewInfo = React.lazy(() => import('../components/FarmItemOverviewInfo'));

const FarmItemOverviewInfoContainer = () => {
  const { pageId, locationId } = useParams();
  const Page = pages[pageId];
  const info = useSelector((state) => {
    const page = new Page(state);
    return page.generateLocationItems(locationId);
  }, _.isEqual);
  const timeIntervalId = useSelector((state) => dateTimeRanges[
    state.pages[pageId].dateTimeRangeIds.OVERVIEW_VIEW
  ].timeIntervalId);
  const data = useSelector((state) => {
    const observations = _.get(state.observations, `${OBSERVATION_MY_FARM}.${locationId}`, []);
    const result = [];
    _.takeRight(observations.filter((item) => item.timeInterval === timeIntervalId), 4)
      .forEach((item) => {
        result.push({
          datetime: item.datetime,
          [propertyIds.RAIN]: item.properties[propertyIds.RAIN],
          [propertyIds.IRRIGATION]: item.properties[propertyIds.IRRIGATION],
          [propertyIds.WB]: item.properties[propertyIds.WB],
        });
      });
    if (result.length > 0) {
      _.last(result).deficitForecast = _.last(result)[propertyIds.WB];
    }
    let deficit = _.get(_.last(result), propertyIds.WB, 0);
    const datetime = _.get(_.last(result), 'datetime', moment().startOf('day'));
    const forecasts = _.get(state.forecasts, `${FORECAST_MY_FARM}.${locationId}`, []);
    _.take(forecasts.filter(
      (item) => item.timeInterval === timeIntervalId && moment(item.datetime).isAfter(datetime),
    ), 4)
      .forEach((item) => {
        deficit -= item.properties[propertyIds.ET].mid;
        result.push({
          datetime: item.datetime,
          deficitForecast: parseFloat(deficit.toFixed(2)),
        });
      });
    return result;
  });
  return (
    <FarmItemOverviewInfo
      info={info}
      data={data}
    />
  );
};

const FarmItemOverviewInfoLoader = () => {
  const { pageId } = useParams();
  const { sources, isWaitingForUserLoad } = useSelector((state) => {
    const Page = pages[pageId];
    const page = new Page(state);
    return {
      sources: page.getSources(),
      isWaitingForUserLoad: page.requiresAuth && !state.auth.user,
    };
  }, _.isEqual);
  const isLoading = useLoader(sources) || isWaitingForUserLoad;
  if (isLoading) {
    return <Placeholder />;
  }
  return (
    <Suspense fallback={<Placeholder />}>
      <FarmItemOverviewInfoContainer />
    </Suspense>
  );
};

export default FarmItemOverviewInfoLoader;
