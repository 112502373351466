export const ABOUT = 'about';
export const ADMIN = 'admin';
export const ALARMS = 'alarms';
export const AUTH = 'auth';
export const CONTACT = 'contact';
export const CWU = 'crop-water-use';
export const DASHBOARD = 'dashboard';
export const DEMO_FARM = 'demo-farm';
export const ENSO = 'enso';
export const ERROR = 'error';
export const FISHING = 'fishing';
export const FORECASTS = 'forecasts';
export const HELP = 'help';
export const HOME = 'home';
export const INSTALLING = 'installing';
export const IRRIGATION = 'irrigation';
export const MY_FARM = 'my-farm';
export const REAL_TIME = 'current';
export const REMOTE_SENSING = 'remote-sensing';
export const SEASONAL = 'seasonal';
export const SETTINGS = 'account';
export const TASKS = 'tasks';
export const TERMS_OF_USE = 'terms-of-use';

export const dataPageIds = [
  FORECASTS,
  MY_FARM,
  REAL_TIME,
  DEMO_FARM,
  CWU,
];

export const breadcrumbPageIds = [
  ...dataPageIds,
  FISHING,
  HELP,
  TASKS,
];
