import { faBellSlash } from '@fortawesome/free-regular-svg-icons';
import { faBell, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Box from './Box';
import CustomToggle from './CustomToggle';

export default function Alarm({
  alarm, property, condition, isActive, timeInterval, remove, setIsModalShowing, location,
}) {
  return (
    <Box.Content title={alarm.title}>
      <div className="position-absolute" style={{ right: 0, top: 0 }}>
        <Dropdown align="end">
          <Dropdown.Toggle variant="success" as={CustomToggle} className="mt-2">
            <FontAwesomeIcon icon={faEllipsisV} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setIsModalShowing(true)}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item onClick={() => remove(alarm.id)}>
              Remove
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="d-flex align-items-center h-100 text-start">
        <FontAwesomeIcon
          icon={alarm.notifications ? faBell : faBellSlash}
          fixedWidth
          className={classNames('mx-3 mb-3', {
            'text-danger': isActive,
            'text-muted': !isActive,
          })}
          size="5x"
        />
        <p>
          <strong>Location </strong>
          {`${location.name}`}
          <br />
          <strong>SMS notification </strong>
          {alarm.notifications ? 'enabled' : 'disabled'}
          <br />
          {!isActive && (
            <>
              <strong>Last triggered </strong>
              {alarm.triggeredAt ? moment(alarm.triggeredAt).format('Do MMM YYYY') : 'never'}
              <br />
            </>
          )}
          <strong>Trigger condition </strong>
          {`${timeInterval.prefix} ${property.name} ${condition.name} ${alarm.value}${property.unit}`}
        </p>
      </div>
    </Box.Content>
  );
}

Alarm.propTypes = {
  isActive: PropTypes.bool.isRequired,
  alarm: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    notifications: PropTypes.bool.isRequired,
    triggeredAt: PropTypes.string,
  }).isRequired,
  property: PropTypes.shape({
    name: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
  }).isRequired,
  condition: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  timeInterval: PropTypes.shape({
    prefix: PropTypes.string.isRequired,
  }).isRequired,
  remove: PropTypes.func.isRequired,
  setIsModalShowing: PropTypes.func.isRequired,
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};
