import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import OverviewMap from '../components/OverviewMap';
import pages from '../data/dataPages';
import { useLoader } from '../lib/hooks';

const OverviewMapContainer = () => {
  const { pageId, locationId } = useParams();
  const locations = useSelector((state) => state.locations);
  const Page = pages[pageId];
  const locationIds = Page.filterLocations(Object.values(locations)).map((location) => location.id);
  return (
    <OverviewMap
      pageId={pageId}
      locationId={locationId}
      locationIds={locationIds}
    />
  );
};

const OverviewMapLoader = () => {
  const { pageId } = useParams();
  const { sources, isWaitingForUserLoad } = useSelector((state) => {
    const Page = pages[pageId];
    const page = new Page(state);
    return {
      sources: page.getSources(),
      isWaitingForUserLoad: page.requiresAuth && !state.auth.user,
    };
  }, _.isEqual);
  const isLoading = useLoader(sources) || isWaitingForUserLoad;
  if (isLoading) {
    return null;
  }
  return <OverviewMapContainer />;
};

export default OverviewMapLoader;
