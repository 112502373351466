import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EndpointDescription from '../components/EndpointDescription';
import pages from '../data/dataPages';

const EndpointDescriptionContainer = () => {
  const { pageId } = useParams();
  const { sourceInfo, description, issuedDate } = useSelector((state) => {
    const page = new pages[pageId](state);
    return {
      sourceInfo: page.getSelectedEndpointSourceInfo(),
      description: page.getSelectedForecastEndpoint().description,
      issuedDate: page.getSelectedEndpointIssuedDate(),
    };
  }, _.isEqual);
  return (
    <EndpointDescription
      sourceInfo={sourceInfo}
      description={description}
      issuedDate={issuedDate}
    />
  );
};

export default EndpointDescriptionContainer;
