import _ from 'lodash';
import moment from 'moment';
import * as rowNames from '../constants/irrigationTableRows';
import * as propertyIds from '../constants/propertyIds';
import { LOWER, MID, UPPER } from '../constants/valueTypes';
import { TABLE, IRRIGATION_SUMMARY } from '../constants/widgetIds';
import Property from './Property';

export default class IrrigationProperty extends Property {
  getPropertyFormatted = (data, propertyId, unit) => {
    if (_.isUndefined(data) || _.isUndefined(data[propertyId])) {
      return undefined;
    }
    const item = data[propertyId];
    if (_.every([LOWER, MID, UPPER], _.partial(_.has, item))) {
      return {
        main: `${item[MID].toFixed(this.decimalPoints)}${unit}`,
        sub: `(${item[LOWER].toFixed(this.decimalPoints)}${unit} - ${item[UPPER].toFixed(this.decimalPoints)}${unit})`,
      };
    }
    if (_.isNumber(item)) {
      return `${item.toFixed(this.decimalPoints)}${unit}`;
    }
    return undefined;
  };

  getNextIrrigDateFormatted = (data) => {
    const start = _.get(data, `forecast.${propertyIds.FIRST_IRRIG_DATE_SUMMARY}.${LOWER}`);
    const end = _.get(data, `forecast.${propertyIds.FIRST_IRRIG_DATE_SUMMARY}.${UPPER}`);
    if (start && end) {
      const isBetween = moment(data.datetime).isBetween(start, end, 'day', '[]');
      return isBetween;
    }
    return false;
  };

  getTableData = (data, timeInterval) => {
    const dates = data.map((item) => ({
      datetime: item.datetime,
      value: timeInterval.formatLong(item.datetime),
    }));
    const tableData = {
      body: [],
      head: { items: dates, name: timeInterval.heading },
    };
    tableData.body.push({
      items: [
        {
          items: data.map((item) => {
            const value = this.getNextIrrigDateFormatted(item);
            if (!_.isUndefined(value)) {
              return {
                color: value ? '#77DD77' : '#BBBBBB',
                datetime: item.datetime,
                value: '',
              };
            }
            return { datetime: item.datetime };
          }),
          name: rowNames.NEXT_IRRIGATION_DATE,
        },
      ],
      name: 'Forecast',
    });
    return tableData;
  };

  getWidgets = (params, title) => {
    const widgets = [
      {
        key: 'IRRIGATION_SUMMARY',
        type: IRRIGATION_SUMMARY,
        params,
        title,
      },
      {
        key: 'TABLE',
        type: TABLE,
        params,
      },
    ];
    return widgets;
  };

  getValue = (data) => {
    if (_.isUndefined(data)) {
      return undefined;
    }
    const dates = _.get(data, propertyIds.FIRST_IRRIG_DATE_SUMMARY);
    if (_.isNumber(dates)) {
      return dates;
    }
    if (_.isUndefined(dates)) {
      return -1;
    }
    return _.mapValues(
      dates,
      (value) => Math.ceil(moment(value).diff(moment(), 'day', true)),
    );
  };

  getFormatted(data, options = {}) {
    if (_.isUndefined(data)) {
      return undefined;
    }
    let value = this.getValue(data);
    if (_.isObject(value)) {
      value = value[Object.keys(value)[parseInt(Object.keys(value).length / 2, 10)]];
    }
    if (value < 0 || _.isUndefined(value)) {
      return 'no date';
    }
    if (options.unit) {
      value = `${value} ${this.unit}`;
    }
    return value;
  }

  generateStats = (forecast) => {
    const stats = [];
    const isPossibleNextIrrigationDate = this.getNextIrrigDateFormatted({
      forecast: forecast.properties,
      datetime: forecast.datetime,
    });
    if (isPossibleNextIrrigationDate) {
      stats.push('Possible next irrigation date');
    }
    return stats;
  };
}
