import './HomePartners.scss';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import partners from '../data/partners';

export default function HomePartners() {
  return (
    <div className="bg-light text-dark p-3 py-md-5 w-100">
      <div className="row h-100 mb-md-5 justify-content-between">
        <h2 className="col-sm-6">Partners</h2>
        <div className="col-sm-6">
          <blockquote className="blockquote text-md-end">
            <p className="mb-0 small">
              Proudly brought to you by the following industry partners.
            </p>
          </blockquote>
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-center text-center">
        {partners.map((item) => (
          <a
            key={item.name}
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            className="m-3 d-inline-block text-dark cssip-home-partners__link"
          >
            <img
              alt={item.name}
              className={classNames(
                'cssip-home-partners__logo',
                `bg-${item.logo.background}`,
              )}
              src={`${process.env.PUBLIC_URL}/logos/${item.logo.file}`}
              title={item.name}
            />
            <strong className="d-block my-2 text-uppercase">
              <u>{item.name}</u>
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                className="ms-2 cssip-home-partners__icon"
              />
            </strong>
          </a>
        ))}
      </div>
    </div>
  );
}
