import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import * as valueTypes from '../constants/valueTypes';
import irrigationTypes from './irrigationTypes';
import mapStyles from './mapStyles';

export const DATE_TIME_RANGE_SELECTOR_LABEL = 'Time period selector';

export const DATE_TIME_RANGE_SELECTOR_HELP = (
  <p>
    Use this to select between short and long range forecasts as well as to view past
    forecasts. The past forecasts have a date selector for selecting what issue date you
    would like to view. This is useful for seeing how the forecasts change over time as
    well evaluating how well they performed.
    <br />
    Short term forecasts are shown at the daily time interval where as long term
    forecasts are displayed at the weekly interval. The weeks align with the current
    day.
  </p>
);

export const DATE_TIME_OFFSET_SELECTOR_LABEL = 'Date/time selector';

export const DATE_TIME_OFFSET_SELECTOR_HELP = (
  <p>
    To see the values for a property on the map for a specific time period there is the
    map date slider. By default it shows the earliest date in the range. There is also a
    play/pause button which can be used to cycle through the dates automatically.
  </p>
);

export const IRRIGATION_TYPE_SELECTOR_LABEL = 'Irrigation type selector';

export const IRRIGATION_TYPE_SELECTOR_HELP = (
  <>
    <p>
      The irrigation type selector is used on farm pages. The properties displayed in the
      overview view for the farm pages will be determined by what irrigation type is
      selected.
      <br />
      The avilable irrigation types are:
    </p>
    <ul>
      {Object.values(irrigationTypes).map((type) => (
        <li key={type.id}>{type.name}</li>
      ))}
    </ul>
  </>
);

export const MAP_STYLE_SELECTOR_LABEL = 'Map style';

export const MAP_STYLE_SELECTOR_HELP = (
  <>
    <p>
      Use this dropdown to change the map style. The options are as follows:
    </p>
    <ul>
      {mapStyles.map((style) => (
        <li key={style.name}>{style.name}</li>
      ))}
    </ul>
  </>
);

export const MAP_PROPERTY_SELECTOR_LABEL = 'Map property selector';

export const MAP_PROPERTY_SELECTOR_HELP = (
  <>
    <p>
      Some forecasts have multiple values, for example an upper, mid and lower, changing
      what value the map is displaying can be achieved through the map property
      selector. By default it will show the middle value. Also, when viewing past
      weather and there is an observed value, it will be displayed by default. Possible
      forecast options are:
    </p>
    <ul>
      {_.uniq(Object.values(valueTypes)).map((type) => (
        <li key={type}>{type}</li>
      ))}
    </ul>
  </>
);

export const LOCATION_BUTTON_LABEL = 'Location button';

export const LOCATION_BUTTON_HELP = (
  <p>
    To show your current location on the map press the location button.
    <br />
    Location will be determined using GPS if available or else it will be derived from
    either wifi network, cell phone towers or your IP address.
    <br />
    When using location your location, the following icon will appear on the map:
    <br />
    <span className="d-block text-center">
      <FontAwesomeIcon icon={faMapMarkerAlt} className="text-danger" size="2x" />
    </span>
  </p>
);

export const MAP_PARTICLES_BUTTON_LABEL = 'Map particles button';

export const MAP_PARTICLES_BUTTON_HELP = (
  <p>
    <strong>Experimental feature: </strong>
    this button is available when viewing wind on the map.
    <br />
    Clicking this button will toggle the rendering of wind particles.
    <br />
    These particles move in the direction of the wind and also their speed is
    proportional to the wind speed. The slower the wind speed the more transparent
    the particles become.
  </p>
);

export const ABOUT_PAGE_DESCRIPTION = 'The CSSIP project aims to help farmers improve their sugarcane irrigation management.';
export const ADMIN_PAGE_DESCRIPTION = 'View users of Opticane and their data.';
export const ALARMS_PAGE_DESCRIPTION = 'Alarms can be used to receive SMS notifications when certain conditions are met. They can be added and removed from within the app.';
export const CONTACT_PAGE_DESCRIPTION = 'Get in contact with us..';
export const TERMS_OF_USE_PAGE_DESCRIPTION = 'This page sets out the terms of use of this application.';
export const TASKS_PAGE_DESCRIPTION = 'Recent conditions and short range forecasts for common managment decisions. Find recent outlooks on relevent conditions in one place.';
export const SETTINGS_PAGE_DESCRIPTION = 'To get the most out of this app it is recommended to create an account. Upon creating an account your IrrigWeb fields will automatically be added into the app.';
export const INSTALLING_PAGE_DESCRIPTION = 'Install Opticane as a shortcut on your device.';
export const HOME_PAGE_DESCRIPTION = process.env.REACT_APP_DESCRIPTION;
export const HELP_PAGE_DESCRIPTION = 'Help on how to use Opticane.';
export const ERROR_PAGE_DESCRIPTION = 'Something went wrong.';
export const ENSO_PAGE_DESCRIPTION = 'Phases of the EL Nino Southern Oscillation (ENSO) and upcoming rainfall';
export const DASHBOARD_PAGE_DESCRIPTION = 'A personalised dashboard is available to users who have an account. Widgets are able to be added to the dashboard, this makes it a convenient place to view everything you are interested in in the one place.';
export const AUTH_PAGE_DESCRIPTION = 'Sign in or create an account to get the most out of Opticane.';
export const FARM_PAGE_DESCRIPTION = 'The my farm page can be used to view forecasts and observations that have been generated specifically from and for you IrrigWeb fields.';
export const FORECAST_PAGE_DESCRIPTION = 'The forecasts page can be used to view forecasts and observations for the areas shown in the map.';
export const CURRENT_CONDITIONS_PAGE_DESCRIPTION = 'Recent weather conditions for the lower Burdekin region. A network of BPS and JCU stations providing up-to-date conditions near you.';
export const REMOTE_SENSING_PAGE_DESCRIPTION = 'View recent and historical satellite images showing NDVI, canopy closure, ETC and RGB.';
export const IRRIGATION_PAGE_DESCRIPTION = 'Irrigation page for Opticane.';
