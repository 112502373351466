import { SEASONAL } from '../constants/pageIds';

export default class SeasonalPage {
  static id = SEASONAL;

  static label = 'Seasonal';

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: 'Compare seasonal weather observations for anywhere in Australia.' },
      ],
    };
  }
}
