import './MapWrapper.scss';
import PropTypes from 'prop-types';
import React from 'react';

const MapWrapper = React.forwardRef(({ children }, ref) => (
  <div ref={ref} className="cssip-map-wrapper">
    {children}
  </div>
));

MapWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MapWrapper;
