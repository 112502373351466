import PropTypes from 'prop-types';
import React from 'react';

export default function PageTitle({ children }) {
  return (
    <div className="text-white p-3 bg-dark">
      <h1 className="mb-0 h3">{children}</h1>
    </div>
  );
}

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
};
