import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import * as actions from '../actions';
import Alarm from '../components/Alarm';
import NotFoundBox from '../components/NotFoundBox';
import Spinner from '../components/Spinner';
import conditions from '../data/conditions';
import dateTimeRanges from '../data/dateTimeRanges';
import properties from '../data/properties';
import timeIntervals from '../data/timeIntervals';
import { useAction, useLoader, useLocationData } from '../lib/hooks';

const AlarmContainer = ({ alarm }) => {
  const location = useSelector((state) => state.locations[alarm.locationId]);
  const edit = useAction(actions.editAlarm);
  const remove = useAction(actions.removeAlarm);
  const { timeIntervalId } = dateTimeRanges[alarm.dateTimeRangeId];
  const timeInterval = timeIntervals[timeIntervalId];
  const data = useLocationData({
    dateTimeRangeId: alarm.dateTimeRangeId,
    forecastEndpointId: alarm.forecastEndpointId,
    locationId: alarm.locationId,
    observationEndpointId: alarm.observationEndpointId,
    metaFilter: alarm.metaFilter,
  });
  const property = properties[alarm.propertyId];
  if (!data.length || !property) {
    return <NotFoundBox message="Could not find data" />;
  }
  const condition = conditions[alarm.conditionId];
  return (
    <Alarm
      alarm={alarm}
      isActive={condition.calculate(data, property, alarm.value)}
      property={property}
      condition={condition}
      timeInterval={timeInterval}
      location={location}
      edit={edit}
      remove={remove}
    />
  );
};

AlarmContainer.propTypes = {
  alarm: PropTypes.shape({
    locationId: PropTypes.string.isRequired,
    dateTimeRangeId: PropTypes.string.isRequired,
    propertyId: PropTypes.string.isRequired,
    forecastEndpointId: PropTypes.string.isRequired,
    observationEndpointId: PropTypes.string.isRequired,
    conditionId: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    metaFilter: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  }).isRequired,
};

const AlarmLoader = ({ alarm }) => {
  const isLoading = useLoader(alarm.sources);
  if (isLoading) {
    return <Spinner />;
  }
  return <AlarmContainer alarm={alarm} />;
};

AlarmLoader.propTypes = {
  alarm: PropTypes.shape({
    sources: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};
export default AlarmLoader;
