import { ENSO } from '../constants/pageIds';
import * as text from '../data/text';

export default class EnsoPage {
  static id = ENSO;

  static label = 'ENSO';

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: text.ENSO_PAGE_DESCRIPTION },
      ],
    };
  }
}
