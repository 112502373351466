import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import properties from '../data/properties';
import Legend from './Legend';

export default function HelpProperties({ propertyIds }) {
  return (
    <div className="print-break-inside-avoid">
      <h4><u>Properties</u></h4>
      <div className="row d-print-block">
        {propertyIds.map((id) => properties[id]).map((property, index) => (
          <React.Fragment key={property.id}>
            <div className="col-md-4 col-lg-3 mb-3">
              <div className="card h-100 cssip-help__property">
                <div className="card-body d-flex flex-column">
                  <div className="position-relative">
                    <FontAwesomeIcon icon={property.icon} className="float-end text-primary" />
                    <h5 className="card-title">{property.name}</h5>
                  </div>
                  <hr className="w-100" />
                  <div className="card-text flex-grow-1 d-flex flex-column justify-content-between">
                    <div>
                      <p>
                        <strong>Unit: </strong>
                        {property.unit}
                      </p>
                      {property.hasInfo() && (
                        <p className="small">
                          <strong>
                            {property.info.short}
                          </strong>
                          <br />
                          {property.info.long}
                        </p>
                      )}
                    </div>
                    <Legend
                      className="border"
                      condensed
                      legend={property.getLegend()}
                      orientation="horizontal"
                      unit={property.unit}
                      withMargins={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            {index % 3 === 2 && (
              <div
                className="d-none d-print-block"
                style={{
                  breakAfter: index % 6 === 5 ? 'page' : '',
                  clear: 'both',
                  position: 'relative',
                }}
              />
            )}
          </React.Fragment>
        ))}
        <div
          style={{
            breakAfter: 'page',
            clear: 'both',
            position: 'relative',
          }}
        />
      </div>
    </div>
  );
}

HelpProperties.propTypes = {
  propertyIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};
