import PropTypes from 'prop-types';
import React from 'react';
import Box from './Box';

export default function NotFoundBox({ message }) {
  return (
    <div>
      <Box.Content className="py-3">
        <h1>Not found</h1>
        <p>{message}</p>
      </Box.Content>
    </div>
  );
}

NotFoundBox.propTypes = {
  message: PropTypes.string.isRequired,
};
