import React from 'react';
import { useSelector } from 'react-redux';
import Home from '../components/Home';

const HomeContainer = () => {
  const isAuthed = useSelector((state) => state.auth.state === 'signedIn');
  return <Home isAuthed={isAuthed} />;
};

export default HomeContainer;
