import * as endpointIds from '../constants/endpointIds';
import * as locationTypeIds from '../constants/locationTypeIds';

export const defaultLocationType = {
  id: locationTypeIds.DEFAULT,
  endpointId: endpointIds.LOCATIONS,
};

const locationTypes = {
  [locationTypeIds.DEFAULT]: defaultLocationType,
  [locationTypeIds.MY_FARM]: {
    id: locationTypeIds.MY_FARM,
    endpointId: endpointIds.LOCATIONS_MY_FARM,
  },
  [locationTypeIds.DEMO_FARM]: {
    id: locationTypeIds.DEMO_FARM,
    endpointId: endpointIds.DEMO_FARM,
  },
  [locationTypeIds.REAL_TIME]: {
    id: locationTypeIds.REAL_TIME,
    endpointId: endpointIds.REAL_TIME,
  },
};

export default locationTypes;
