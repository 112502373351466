import { faTractor } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment';
import FarmOverviewItem from '../components/FarmOverviewItem';
import { NEXT_7_DAYS, LAST_7_DAYS } from '../constants/dateTimeRangeIds';
import { OVERVIEW_VIEW } from '../constants/pageViews';
import * as propertyIds from '../constants/propertyIds';
import { NET_APP, WB, ET } from '../constants/propertyIds';
import { DAY, WEEK } from '../constants/timeIntervalIds';
import { RAIN_10MM, RAIN_50MM } from '../constants/valueTypes';
import dateTimeRanges from '../data/dateTimeRanges';
import irrigationTypes from '../data/irrigationTypes';
import properties from '../data/properties';
import * as text from '../data/text';
import timeIntervals from '../data/timeIntervals';
import { getZoneForField } from '../lib/utils';
import DataPage from './DataPage';

const emptyList = [];

export default class FarmPage extends DataPage {
  static icon = faTractor;

  static propertyIds = [
    propertyIds.ET,
    propertyIds.FIRST_IRRIG_DATE_SUMMARY,
    propertyIds.FIRST_IRRIG_POP,
    propertyIds.RAIN,
    propertyIds.RAIN_POP,
  ];

  static dateTimeRangeIds = [
    NEXT_7_DAYS,
    LAST_7_DAYS,
  ];

  static description = text.FARM_PAGE_DESCRIPTION;

  static getMapValue = (location, propertyId, mapDisplay, date, timeIntervalId) => {
    const timeInterval = timeIntervals[timeIntervalId];
    const data = {
      forecast: location.forecasts
        .filter((item) => item.timeInterval === timeIntervalId)
        .filter((item) => date.isSame(item.datetime, timeInterval.alignment))
        .map((item) => item.properties)
        .pop(),
      observation: location.observations
        .filter((item) => item.timeInterval === timeIntervalId)
        .filter((item) => date.isSame(item.datetime, timeInterval.alignment))
        .map((item) => item.properties)
        .pop(),
    };
    const property = properties[propertyId];
    const index = mapDisplay.indexOf('.');
    let value;
    const source = index > 0 ? mapDisplay.slice(0, index) : mapDisplay;
    if (index > 0) {
      value = _.get(property.getValue(data[source]), mapDisplay.slice(index + 1));
    } else {
      value = property.getValue(data[source]);
    }
    if (!_.isUndefined(value)) {
      return { ...data[source], [propertyId]: value };
    }
    return undefined;
  };

  getSelectedEndpointIssuedDate() {
    const all = [];
    if (this.state.forecasts[this.getPageState().forecastEndpointId]) {
      Object.values(this.state.forecasts[this.getPageState().forecastEndpointId])
        .forEach((location) => {
          const locationForecasts = location.filter((item) => _.isString(item.issued));
          locationForecasts.forEach((forecast) => all.push(forecast));
        });
    }
    const filtered = all.map((item) => moment(item.issued));
    return filtered.length ? moment.max(filtered) : false;
  }

  getIrrigationSummary = (state, props) => {
    const locationForecasts = _.get(state, `forecasts[${props.forecastEndpointId}][${props.locationId}]`, emptyList);
    const locationObservations = _.get(state, `observations[${props.observationEndpointId}][${props.locationId}]`, emptyList);
    const dateTimeRange = dateTimeRanges[props.dateTimeRangeId];
    const { timeIntervalId } = dateTimeRange;
    const forecasts = locationForecasts
      .filter((item) => item.timeInterval === timeIntervalId);
    const observations = locationObservations;
    const startOfToday = moment().startOf('day');
    const lastIrrigationDay = [...forecasts]
      .reverse()
      .find(
        (item) => item.properties[NET_APP] > 0 && moment(item.datetime).isBefore(startOfToday),
      );
    const nextIrrigationDay = [...forecasts]
      .reverse()
      .find((item) => (
        item.properties[NET_APP] > 0 && moment(item.datetime).isSameOrAfter(startOfToday)
      ));
    const rows = [];
    const previousDay = observations
      .filter((item) => item.timeInterval === DAY)
      .reverse()
      .find((item) => moment(item.datetime).isBefore(startOfToday));
    const lastObservedSwd = _.get(previousDay, `properties.${WB}`);
    if (lastObservedSwd) {
      rows.push({
        key: 'Last observed SWD',
        value: `${lastObservedSwd}mm`,
      });
    }
    const lastObservedEt = _.get(previousDay, `properties.${ET}`);
    if (lastObservedEt) {
      rows.push({
        key: 'Last observed ET',
        value: `${lastObservedEt}mm`,
      });
    }
    rows.push({
      key: 'Last Irrigation',
      value: !lastIrrigationDay ? (
        'no irrigation last week'
      ) : (
        `${lastIrrigationDay.properties[NET_APP].toFixed(2)} mm on ${moment(lastIrrigationDay.datetime).format('Do MMMM')}`
      ),
    });
    rows.push({
      key: 'Next scheduled Irrigation',
      value: !nextIrrigationDay ? (
        'no irrigation scheduled'
      ) : (
        `${nextIrrigationDay.properties[NET_APP].toFixed(2)} mm on ${moment(nextIrrigationDay.datetime).format('Do MMMM')}`
      ),
    });
    return rows;
  };

  getOverviewItemComponent = () => FarmOverviewItem;

  getOverviewItems = (state, props) => {
    const { timeIntervalId } = dateTimeRanges[props.dateTimeRangeId];
    const { forecasts } = state;
    const location = state.locations[props.locationId];
    const { now } = props;
    const farmForecasts = (forecasts[this.getSelectedForecastEndpoint().id][location.id] || [])
      .filter((forecast) => forecast.timeInterval === timeIntervalId)
      .filter((forecast) => now.isSameOrBefore(forecast.datetime, 'day'));
    const items = farmForecasts.map((forecast) => {
      let title;
      if (timeIntervalId === DAY) {
        title = moment(forecast.datetime).format('dddd Do MMMM');
      } else if (timeIntervalId === WEEK) {
        const start = moment(forecast.datetime).format('Do MMMM');
        const end = moment(forecast.datetime).add(6, 'days').format('Do MMMM');
        title = `${start} - ${end}`;
      }
      const stats = properties[propertyIds.FIRST_IRRIG_DATE_SUMMARY].generateStats(forecast);
      return {
        ...forecast,
        title,
        stats,
      };
    });
    return items;
  };

  getLocationOverviewColor(locationId) {
    const startOfToday = moment().startOf('day');
    const props = {
      forecastEndpointId: this.getSelectedForecastEndpoint().id,
      observationEndpointId: this.constructor.getObservationEndpoint().id,
    };
    const observations = _.get(this.state, `observations[${props.observationEndpointId}][${locationId}]`, emptyList);
    const previousDay = observations
      .filter((item) => item.timeInterval === DAY)
      .reverse()
      .find((item) => moment(item.datetime).isBefore(startOfToday));
    const color = properties[propertyIds.WB].getColor(_.get(previousDay, 'properties'));
    return color.rgb();
  }

  generateOverviewLocationListItems() {
    const groupedByZone = {};
    const startOfToday = moment().startOf('day');
    const forecastEndpointId = this.getSelectedForecastEndpoint().id;
    const observationEndpointId = this.constructor.getObservationEndpoint().id;
    const locations = this.constructor.filterLocations(Object.values(this.state.locations))
      .map((location) => ({
        ...location,
        observations: _.get(this.state, `observations[${observationEndpointId}][${location.id}]`, []),
        forecasts: _.get(this.state, `forecasts[${forecastEndpointId}][${location.id}]`, []),
      }));
    locations.forEach((location) => {
      const items = this.generateLocationItems(location.id);
      const previousDay = location.observations
        .filter((item) => item.timeInterval === DAY)
        .reverse()
        .find((item) => moment(item.datetime).isBefore(startOfToday));
      const lastObservedSwd = _.get(previousDay, `properties.${WB}`);
      const zoneId = _.get(getZoneForField(location, this.state.locations), 'id', '1');
      let group = groupedByZone[zoneId];
      if (!group) {
        groupedByZone[zoneId] = [];
        group = groupedByZone[zoneId];
      }
      const color = properties[propertyIds.WB].getColor(_.get(previousDay, 'properties'));
      group.push({
        id: location.id,
        hasLocation: 'geometry' in location,
        name: location.fieldName,
        farmName: location.farmName,
        properties: items,
        to: this.constructor.getOverviewLocationPath(location.id),
        sort: lastObservedSwd,
        color: color.css(),
        geoJson: {
          features: locations.filter((item) => item.geometry).map((item) => ({
            geometry: item.geometry,
            properties: {
              selected: item === location,
            },
            type: 'Feature',
          })),
          type: 'FeatureCollection',
        },
      });
    });
    const zonesWithSortedFields = Object.keys(groupedByZone).map((id) => ({
      name: this.state.locations[id].name,
      locationId: id,
      fields: _.sortBy(groupedByZone[id], (item) => _.get(item, 'sort')),
    }));
    return _.sortBy(zonesWithSortedFields, (item) => _.get(item, 'fields[0].sort'));
  }

  generateLocationItems(locationId) {
    const startOfToday = moment().startOf('day');
    const forecastEndpointId = this.getSelectedForecastEndpoint().id;
    const observationEndpointId = this.constructor.getObservationEndpoint().id;
    const timeInterval = this.getSelectedTimeInterval(OVERVIEW_VIEW);
    const location = {
      ..._.get(this.state, `locations[${locationId}]`),
      observations: _.get(this.state, `observations[${observationEndpointId}][${locationId}]`, []),
      forecasts: _.get(this.state, `forecasts[${forecastEndpointId}][${locationId}]`, []),
    };
    const items = [];
    const irrigationDateProperty = properties[propertyIds.FIRST_IRRIG_DATE_SUMMARY];
    const forecast = location.forecasts.find((item) => {
      const isRigtTimeInterval = item.timeInterval === timeInterval.id;
      const isAfterStartOfToday = startOfToday.isSameOrBefore(item.datetime);
      return isRigtTimeInterval && isAfterStartOfToday;
    });
    const previousDay = location.observations
      .filter((item) => item.timeInterval === DAY)
      .reverse()
      .find((item) => moment(item.datetime).isBefore(startOfToday));
    const lastObservedSwd = _.get(previousDay, `properties.${WB}`);
    if (!_.isUndefined(lastObservedSwd)) {
      items.push({
        name: 'Last observed SWD',
        value: `${lastObservedSwd}mm`,
      });
    }
    const irrigationDate = irrigationDateProperty.getFormatted(
      _.get(forecast, 'properties'),
      { unit: true },
    );
    if (location.irrigationAmount) {
      items.push({
        name: 'Last irrigation',
        value: moment(location.irrigationDate).isSame(moment(), 'day') ? 'today' : moment(location.irrigationDate).fromNow(),
      });
      items.push({
        name: 'Last irrigation amount',
        value: `${parseInt(location.irrigationAmount, 10)}mm`,
      });
    }
    if (irrigationDate) {
      items.push({
        name: irrigationDateProperty.name,
        value: irrigationDate,
      });
    }
    // @TODO: DRY, this is also in parent class along with in selectors (same sort of thing).
    if (location.distance) {
      items.push({
        name: 'Distance from current location',
        value: `${parseInt(location.distance, 10)} km`,
      });
    }
    return items;
  }

  getOverviewProperties() {
    const dateTimeRange = this.getSelectedDateTimeRange(OVERVIEW_VIEW);
    const propertiesToShow = [
      { id: propertyIds.ET },
      { id: propertyIds.RAIN },
    ];
    const irrigationTypeId = this.getIrrigationTypeId();
    irrigationTypes[irrigationTypeId].toAdd(dateTimeRange.timeIntervalId)
      .forEach((propertyToShow) => propertiesToShow.push(propertyToShow));
    if (dateTimeRange.timeIntervalId === DAY) {
      propertiesToShow.push({ id: propertyIds.RAIN_POP, options: { key: RAIN_10MM } });
    } else {
      propertiesToShow.push({ id: propertyIds.RAIN_POP, options: { key: RAIN_50MM } });
    }
    return propertiesToShow;
  }
}
