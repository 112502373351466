import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AlarmModalContent from './AlarmModalContent';

export default function AlarmModal({
  fields, isShowing, onClose, onChange, conditions, property, userHasPhone, timeInterval, location,
}) {
  const [alarm, setFields] = useState(fields);
  useEffect(() => {
    setFields(fields);
  }, [fields, isShowing]);
  const isValid = alarm.title && _.isNumber(alarm.value) && alarm.conditionId;
  return (
    <Modal show={isShowing} onHide={onClose}>
      {isShowing && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Alarm</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AlarmModalContent
              conditions={conditions}
              property={property}
              setFields={setFields}
              alarm={alarm}
              userHasPhone={userHasPhone}
              timeInterval={timeInterval}
              location={location}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={() => onChange(alarm)}
              disabled={!isValid}
            >
              Ok
            </Button>
            <Button
              variant="outline-secondary"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}

AlarmModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
  conditions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  property: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  timeInterval: PropTypes.shape({
    prefix: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  userHasPhone: PropTypes.bool.isRequired,
};
