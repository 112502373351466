import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as dateTimeRangeIds from '../constants/dateTimeRangeIds';
import * as endpointIds from '../constants/endpointIds';
import * as propertyIds from '../constants/propertyIds';
import * as taskIds from '../constants/taskIds';
import * as valueTypes from '../constants/valueTypes';
import * as widgetIds from '../constants/widgetIds';
import properties from '../data/properties';

const spraying = {
  // https://pixabay.com/photos/spraying-sugar-cane-sugar-cane-2746350/.
  image: `${process.env.PUBLIC_URL}/tasks/spraying.jpg`,
  getWidgetsForLocations: (locations) => [
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.WIND_SPEED,
        sources: [endpointIds.REAL_TIME],
      },
      title: `Wind real time (${locations.realTime.name})`,
      type: widgetIds.REAL_TIME_VALUES,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.RAIN_24_HOUR_TOTAL,
        sources: [endpointIds.REAL_TIME],
      },
      title: `Rain real time (${locations.realTime.name})`,
      type: widgetIds.REAL_TIME_VALUES,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.DELTA_T,
        sources: [endpointIds.REAL_TIME],
      },
      title: `Delta T real time (${locations.realTime.name})`,
      type: widgetIds.REAL_TIME_VALUES,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.WIND_SPEED,
        sources: [endpointIds.REAL_TIME],
        referenceLines: [5, 15],
      },
      title: `Wind real time (${locations.realTime.name})`,
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.RAIN_24_HOUR_TOTAL,
        sources: [endpointIds.REAL_TIME],
      },
      title: `Rain real time (${locations.realTime.name})`,
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.DELTA_T,
        sources: [endpointIds.REAL_TIME],
        referenceLines: [2, 8],
      },
      title: `Delta T real time (${locations.realTime.name})`,
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_24_HOURS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.WIND_SPEED,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
        referenceLines: [5, 15],
      },
      title: 'Wind forecast for next 24 hours',
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_24_HOURS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.DELTA_T,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
        referenceLines: [2, 8],
      },
      title: 'Delta T forecast for next 24 hours',
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_24_HOURS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.RELATIVE_HUMIDITY,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Humidity forecast for next 24 hours',
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.WIND_SPEED,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
        referenceLines: [5, 15],
      },
      title: 'Wind forecast for next 3 days',
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.RAIN,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Rain forecast for next 3 days',
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_24_HOURS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.WIND_SPEED,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Wind forecast for next 24 hours',
      type: widgetIds.TABLE,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_24_HOURS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.DELTA_T,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Delta T forecast for next 24 hours',
      type: widgetIds.TABLE,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_24_HOURS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.RELATIVE_HUMIDITY,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Humidity forecast for next 24 hours',
      type: widgetIds.TABLE,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.RAIN,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Rain forecast for next 3 days',
      type: widgetIds.TABLE,
    },
  ],
  id: taskIds.SPRAYING,
  name: 'Spraying',
  statuses: [
    {
      getStatus: (current) => {
        const deltaT = properties[propertyIds.DELTA_T].getValue(current.properties);
        if (deltaT < 2 || (deltaT > 8 && deltaT <= 10)) {
          return {
            icon: 'minus',
            message: 'Delta T conditions are currently marginal',
          };
        }
        if (deltaT >= 2 && deltaT <= 8) {
          return {
            icon: 'check',
            message: 'Delta T conditions are currently suitable for spraying',
          };
        }
        if (deltaT >= 10 && deltaT < 12) {
          return {
            icon: 'minus',
            message: 'Delta T conditions are currently marginal for \'COARSE\' or greater spray quality and unsuitable for \'MEDIUM\' or finer spray quality',
          };
        }
        return {
          icon: 'times',
          message: 'Delta T conditions are currently unsuitable for spraying',
        };
      },
      info: {
        long: 'Between 0 and 2 or 8 and 10 the Delta T conditions are marginal. For coarse or greater spray marginal conditions can include Delta T values up to 12.',
        short: 'Suitable Delta T conditions are between 2 and 8',
      },
      name: 'Delta T',
    },
    {
      getStatus: (current) => {
        const windSpeed = properties[propertyIds.WIND_SPEED].getValue(current.properties);
        if (windSpeed >= 5 && windSpeed <= 15) {
          return {
            icon: 'check',
            message: 'Wind speed conditions are currently suitable for spraying',
          };
        }
        if (windSpeed >= 3 && windSpeed <= 5) {
          return {
            icon: 'minus',
            message: 'Wind speed conditions are currently acceptable for spraying',
          };
        }
        return {
          icon: 'times',
          message: 'Wind speed conditions are currently unsuitable for spraying',
        };
      },
      info: {
        long: 'Above this range is unsuitable. Between 3 and 5 km/h is acceptable. Anything below 3 km/h is unsuitable.',
        short: 'Suitable wind speed conditions are between 5 and 15 km/h.',
      },
      name: 'Wind speed',
    },
    {
      getStatus: (current) => {
        const airTemperature = properties[propertyIds.AIR_TEMPERATURE].getValue(current.properties);
        const deltaT = properties[propertyIds.DELTA_T].getValue(current.properties);
        if (airTemperature <= 30) {
          return {
            icon: 'check',
            message: 'Air temperature conditions are currently suitable for spraying',
          };
        }
        if (airTemperature > 30 && deltaT >= 2 && deltaT <= 8) {
          return {
            icon: 'minus',
            message: 'Air temperature conditions are currently acceptable for spraying',
          };
        }
        return {
          icon: 'times',
          message: 'Air temperature conditions are currently unsuitable for spraying',
        };
      },
      info: {
        long: 'It is acceptable conditions above 30 if the DeltaT value is between 2-8. Anything outside these conditions is unsuitable.',
        short: 'Suitable air temperature is below 30.',
      },
      name: 'Air temperature',
    },
    {
      getStatus: (current, weatherForecasts) => {
        const start = moment().startOf('day');
        const end = start.add(3, 'days').startOf('day');
        const forecasts = weatherForecasts
          .filter((item) => item.timeInterval === 'day')
          .filter((item) => moment(item.datetime).isBetween(start, end));
        const isBad = forecasts.some((item) => _.get(item.properties, `${propertyIds.RAIN_POP}.${valueTypes.RAIN_0MM}`) > 50);
        if (isBad) {
          return {
            icon: 'times',
            message: 'There is a medium chance of some rain in the next three days',
          };
        }
        return {
          icon: 'check',
          message: 'There is a low to no chance of rain in the next three days',
        };
      },
      info: {
        long: 'A medium chance of rain is defined by any day in the next 3 days having 50% chance of any rain (above 0mm).',
        short: 'Low to no chance of rain is suitable for spraying',
      },
      name: 'Rain Probability',
    },
  ],
};

export default spraying;
