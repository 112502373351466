import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Head from '../components/Head';
import pages from '../data/pages';

export const HeadContainer = () => {
  const { pageId } = useParams();
  const location = useLocation();
  const head = useSelector((state) => {
    const Page = _.get(pages, pageId);
    if (Page) {
      // @TODO: Turn into a static method which has locations passed in.
      const page = new Page(state);
      return page.generateHead(location.pathname);
    }
    return { title: ['Not found'] };
  }, _.isEqual);
  return (
    <Head head={head} />
  );
};

export default HeadContainer;
