import moment from 'moment';
import * as constants from '../constants/endpointIds';
import { DAY, WEEK } from '../constants/timeIntervalIds';
import * as urls from '../constants/urls';
import axios, { axiosAuthed } from '../lib/axios';
import { dateShiftMockData } from '../lib/utils';
import locations from './locations';

const fetchForecasts = async (url) => {
  const { data } = await axios(url);
  return { forecasts: data };
};

const fetchObservations = async (url) => {
  const { data } = await axios(url);
  return { observations: data };
};

const endpoints = {
  [constants.REAL_TIME]: {
    description: 'Weather station readings.',
    fetch: async () => {
      const { data } = await axios(urls.REAL_TIME_URL);
      return data;
    },
    id: constants.REAL_TIME,
  },
  [constants.LAST_30_DAYS]: {
    description: 'Weather station readings.',
    fetch: async () => {
      const { data } = await axios(urls.LAST_30_DAYS_URL);
      return data;
    },
    id: constants.LAST_30_DAYS,
  },
  [constants.OBSERVATION_WEATHER]: {
    fetch: async () => fetchObservations(urls.WEATHER_OBSERVATION_URL),
    id: constants.OBSERVATION_WEATHER,
  },
  [constants.OBSERVATION_CROP]: {
    fetch: async () => fetchObservations(urls.CROP_OBSERVATION_URL),
    id: constants.OBSERVATION_CROP,
  },
  [constants.LOCATIONS]: {
    fetch: async () => ({ locations }),
    id: constants.LOCATIONS,
  },
  [constants.WIDGETS]: {
    fetch: async () => {
      const { data } = await axiosAuthed(urls.WIDGETS_URL);
      return { widgets: data };
    },
    id: constants.WIDGETS,
    isUserData: true,
  },
  [constants.ALARMS]: {
    fetch: async () => {
      const { data } = await axiosAuthed(urls.ALARMS_URL);
      return { alarms: data };
    },
    id: constants.ALARMS,
    isUserData: true,
  },
  [constants.LOCATIONS_MY_FARM]: {
    fetch: async () => {
      const { data } = await axiosAuthed(urls.MY_FARM_LOCATION_URL);
      return data;
    },
    id: constants.LOCATIONS_MY_FARM,
    isUserData: true,
  },
  [constants.OBSERVATION_MY_FARM]: {
    fetch: async () => {
      const { data } = await axiosAuthed(urls.MY_FARM_OBSERVATION_URL);
      return data;
    },
    id: constants.OBSERVATION_MY_FARM,
    isUserData: true,
  },
  [constants.CROP_FORECAST_ENDPOINT_DATES]: {
    fetch: async () => {
      const { data } = await axios(urls.CROP_FORECAST_ENDPOINT_DATES_URL);
      return data;
    },
    id: constants.CROP_FORECAST_ENDPOINT_DATES,
  },
  [constants.MY_FARM_FORECAST_ENDPOINT_DATES]: {
    fetch: async () => {
      const { data } = await axiosAuthed(urls.MY_FARM_FORECAST_ENDPOINT_DATES_URL);
      return data;
    },
    id: constants.MY_FARM_FORECAST_ENDPOINT_DATES,
    isUserData: true,
  },
  [constants.USERS]: {
    fetch: async () => {
      const { data } = await axiosAuthed(urls.ADMIN_USERS_URL);
      return data;
    },
    id: constants.USERS,
  },
  [constants.WEATHER_FORECAST_ENDPOINT_DATES]: {
    fetch: async () => {
      const { data } = await axios(urls.WEATHER_FORECAST_ENDPOINT_DATES_URL);
      return data;
    },
    id: constants.WEATHER_FORECAST_ENDPOINT_DATES,
  },
  [constants.DEMO_FARM_FORECAST_ENDPOINT_DATES]: {
    fetch: async () => ({ endpointsDates: [] }),
    id: constants.DEMO_FARM_FORECAST_ENDPOINT_DATES,
  },
  [constants.ENSO_PHASE]: {
    fetch: async () => {
      const { data } = await axios(urls.ENSO_PHASE);
      return { ensoPhase: data };
    },
    id: constants.ENSO_PHASE,
  },
};

export const weatherForecastEndpoints = {
  [constants.FORECAST_WEATHER]: {
    description: 'Latest forecasts shown.',
    fetch: () => fetchForecasts(urls.WEATHER_FORECAST_URL),
    id: constants.FORECAST_WEATHER,
    label: 'Today',
  },
};

export const cropForecastEndpoints = {
  [constants.FORECAST_CROP]: {
    description: 'Crop forecasts are sourced from IrrigWeb.',
    fetch: () => fetchForecasts(urls.CROP_FORECAST_URL),
    id: constants.FORECAST_CROP,
    label: 'Today',
  },
};

export const myFarmForecastEndpoints = {
  [constants.FORECAST_MY_FARM]: {
    description: 'My farm forecasts.',
    fetch: async () => {
      const { data } = await axiosAuthed(urls.MY_FARM_FORECAST_URL);
      return data;
    },
    id: constants.FORECAST_MY_FARM,
    isUserData: true,
    label: 'Today',
  },
};

export const demoFarmForecastEndpoints = {
  [constants.DEMO_FARM]: {
    description: 'Demonstration of My farm forecasts.',
    fetch: async () => {
      const { data } = await axios(urls.DEMO_FARM);
      return dateShiftMockData(data, { date: '20210317' });
    },
    id: constants.DEMO_FARM,
    label: 'Today',
  },
};

const date = moment();

for (let i = 0; i < 7; i += 1) {
  date.subtract(1, 'day');
  const dateString = date.format('YYYY-MM-DD');
  const common = {
    description: `${date.format('ddd Do MMMM')} forecasts shown.`,
    issued: date.format('YYYY-MM-DD'),
    label: date.format('ddd Do MMM'),
    timeIntervalId: DAY,
  };
  const weatherId = `PAST_WEATHER_FORECAST_-${i}_DAYS`;
  weatherForecastEndpoints[weatherId] = {
    ...common,
    fetch: () => fetchForecasts(urls.WEATHER_FORECAST_PAST_ISSUED_URL.replace(':date', dateString)),
    id: weatherId,
  };
  const cropId = `PAST_CROP_FORECAST_-${i}_DAYS`;
  cropForecastEndpoints[cropId] = {
    ...common,
    fetch: () => fetchForecasts(urls.CROP_FORECAST_PAST_ISSUED_URL.replace(':date', dateString)),
    id: cropId,
  };
  const myFarmId = `PAST_MY_FARM_PROPERTY_-${i}_DAYS`;
  myFarmForecastEndpoints[myFarmId] = {
    ...common,
    fetch: async () => {
      const { data } = await axiosAuthed(urls.MY_FARM_PAST_ISSUED_URL.replace(':date', dateString));
      return data;
    },
    id: myFarmId,
    isUserData: true,
  };
}

for (let i = 0; i < 14; i += 1) {
  date.subtract(1, 'week');
  const dateString = date.format('YYYY-MM-DD');
  const common = {
    description: `${date.format('ddd Do MMMM')} forecasts shown.`,
    issued: date.format('YYYY-MM-DD'),
    label: date.format('ddd Do MMM'),
    timeIntervalId: WEEK,
  };
  const weatherId = `PAST_WEATHER_FORECAST_-${i}_WEEKS`;
  weatherForecastEndpoints[weatherId] = {
    ...common,
    fetch: () => fetchForecasts(urls.WEATHER_FORECAST_PAST_ISSUED_URL.replace(':date', dateString)),
    id: weatherId,
  };
  const cropId = `PAST_CROP_FORECAST_-${i}_WEEKS`;
  cropForecastEndpoints[cropId] = {
    ...common,
    fetch: () => fetchForecasts(urls.CROP_FORECAST_PAST_ISSUED_URL.replace(':date', dateString)),
    id: cropId,
  };
  const myFarmId = `PAST_MY_FARM_PROPERTY_-${i}_WEEKS`;
  myFarmForecastEndpoints[myFarmId] = {
    ...common,
    fetch: async () => {
      const { data } = await axiosAuthed(urls.MY_FARM_PAST_ISSUED_URL.replace(':date', dateString));
      return data;
    },
    id: myFarmId,
    isUserData: true,
  };
}

const allEndpoints = {
  ...endpoints,
  ...weatherForecastEndpoints,
  ...cropForecastEndpoints,
  ...myFarmForecastEndpoints,
  ...demoFarmForecastEndpoints,
};

export const authedEndpointIds = Object.values(allEndpoints)
  .filter((endpoint) => endpoint.isUserData)
  .map((endpoint) => endpoint.id);

export default allEndpoints;
