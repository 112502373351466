import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

export default function TimeFromNow({ updateFrequency, moment }) {
  const [fromNow, setFromNow] = useState(moment.fromNow());
  useEffect(() => {
    setFromNow(moment.fromNow());
    const interval = setInterval(() => setFromNow(moment.fromNow()), updateFrequency);
    return () => clearInterval(interval);
  }, [updateFrequency, moment]);
  return fromNow;
}

TimeFromNow.propTypes = {
  moment: PropTypes.shape({
    fromNow: PropTypes.func.isRequired,
  }).isRequired,
  updateFrequency: PropTypes.number.isRequired,
};
