import { HOME } from '../constants/pageIds';
import * as text from '../data/text';

export default class HomePage {
  static id = HOME;

  static label = 'Home';

  // eslint-disable-next-line class-methods-use-this
  generateHead() {
    return {
      title: [],
      meta: [
        { name: 'description', content: text.HOME_PAGE_DESCRIPTION },
      ],
    };
  }
}
