import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

export default function TableDownload({ data, getLabelForPropertyKey }) {
  const headings = [data.head.name];
  const columns = [data.head.items.map((item) => item.value)];
  data.body.forEach((row) => {
    const isMulti = row.items.find((item) => item.items);
    if (isMulti) {
      row.items.forEach(({ name }) => {
        headings.push(`${row.name} ${getLabelForPropertyKey(name)}`);
        columns.push(row.items.find((sub) => sub.name === name).items.map(({ value }) => value));
      });
    } else {
      headings.push(row.name);
      columns.push(row.items.map(({ value }) => value));
    }
  });
  let downloadText = `${headings.join(',')}\n`;
  const rows = columns[0].map((_, colIndex) => columns.map((row) => row[colIndex]));
  rows.forEach((row) => {
    downloadText += `${row.join(',')}\n`;
  });
  return (
    <a
      className="btn-sm text-decoration-none btn btn-link"
      href={`data:text/plain:charset=utf-8,${encodeURIComponent(downloadText)}`}
      download="opticane-table-export.csv"
    >
      Download
      <FontAwesomeIcon icon={faArrowAltCircleDown} className="ms-1" />
    </a>
  );
}

TableDownload.propTypes = {
  data: PropTypes.shape({
    body: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    head: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  getLabelForPropertyKey: PropTypes.func.isRequired,
};
