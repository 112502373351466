import { faAtom } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { isWebGL2Supported } from '../constants/browser';

export default function MapParticlesToggle({ hasParticles, setShowParticles, showParticles }) {
  if (!hasParticles) {
    return null;
  }
  if (!isWebGL2Supported) {
    return null;
  }
  return (
    <button
      className={classNames('btn btn-sm mx-1', {
        'btn-light text-primary': !showParticles,
        'btn-primary': showParticles,
      })}
      onClick={() => setShowParticles(!showParticles)}
      title="Toggle particles (experimental)"
      type="button"
    >
      <FontAwesomeIcon icon={faAtom} />
    </button>
  );
}

MapParticlesToggle.propTypes = {
  hasParticles: PropTypes.bool.isRequired,
  setShowParticles: PropTypes.func.isRequired,
  showParticles: PropTypes.bool.isRequired,
};
