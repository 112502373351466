import { faCog } from '@fortawesome/free-solid-svg-icons';
import { SETTINGS } from '../constants/pageIds';
import * as paths from '../constants/paths';
import * as text from '../data/text';

export default class SettingsPage {
  static id = SETTINGS;

  static label = 'Settings';

  static path = paths.ACCOUNT;

  static icon = faCog;

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: text.SETTINGS_PAGE_DESCRIPTION },
        { name: 'robots', content: 'noindex' },
      ],
    };
  }
}
