import React from 'react';
import RealTimePage from '../pages/RealTimePage';
import HelpProperties from './HelpProperties';
import HelpSection from './HelpSection';

export default function HelpCurrentConditions() {
  return (
    <HelpSection title="Current conditions">
      <p className="lead">
        The current conditions page can be used to view recent observations from weather stations
        within the area. Clicking on the current conditions menu item will take you to the page.
      </p>
      <div className="print-break-inside-avoid">
        <h4><u>Overview view</u></h4>
        <p>
          This view contains a map for selecting what weather station to see information for.
          Also there is a list showing all the weather stations. Clicking on either a station in the
          map or in the list will show you the most recent readings for all the properties available
          at that station. Clicking on one of these properties will then take you to the property
          view explained below.
        </p>
      </div>
      <div className="print-break-inside-avoid">
        <h4><u>Property view</u></h4>
        <p>
          The property view goes into a more detailed look at a particular property. Charts and
          tables show the observations over the past 24 hour time period.
          <br />
          Clicking on a station in the map will show the property data view specific for that
          station. The same can be achieved by clicking on the a station in the list if no station
          is selected.
          <br />
          If you want to go back to viewing all stations there is a few different ways you can do
          this. Using your browsers back button or clicking outside of the areas in the map.
          Clicking on the link in the menu for the page that you are currently on will take you to
          the overview. Finally the breadcrumbs can also be used to achieve the same navigation.
        </p>
      </div>
      <HelpProperties propertyIds={RealTimePage.propertyIds} />
    </HelpSection>
  );
}
