import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import AlarmAdd from '../containers/AlarmAdd';
import cropFilters from '../data/cropFilters';
import Info from './Info';

export default function Summary({
  alarmFields, dates, metaFilter, property, timeInterval, stats,
}) {
  let cropType;
  let cropCycle;
  const hasFilter = !!metaFilter;
  if (hasFilter) {
    cropType = cropFilters.find((item) => item.value === metaFilter.cropType);
    cropCycle = cropType.cropCycle.find((item) => item.value === metaFilter.cropCycle);
  }
  const infoData = {
    long: property.info.long,
    short: `${property.info.short}`,
  };
  if (property.unit) {
    infoData.short += ` (${property.unit})`;
  }
  return (
    <div className="w-100">
      <p className="lead mb-4">
        <FontAwesomeIcon className="text-primary me-2" icon={property.icon} />
        {`${timeInterval.prefix} `}
        <Info info={infoData}>
          {property.name}
        </Info>
        {' between '}
        {dates.startDate.format('Do MMMM YYYY')}
        {' and '}
        {dates.endDate.format('Do MMMM YYYY')}
        {'. '}
        {hasFilter && (
          <>
            Data represent a
            <Info className="mx-1" info={cropType.info}>{cropType.label}</Info>
            crop planted in
            <Info className="mx-1" info={cropCycle.info}>{cropCycle.label}</Info>
          </>
        )}
      </p>
      {!!alarmFields && (
        <p>
          <AlarmAdd fields={alarmFields} />
        </p>
      )}
      <ul className="pl-3">
        {stats.map((stat) => (
          <li key={stat}>{stat}</li>
        ))}
      </ul>
    </div>
  );
}

Summary.propTypes = {
  alarmFields: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.bool,
  ]),
  dates: PropTypes.shape({
    endDate: PropTypes.shape({
      format: PropTypes.func.isRequired,
    }).isRequired,
    startDate: PropTypes.shape({
      format: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  metaFilter: PropTypes.oneOfType([
    PropTypes.shape({
      cropCycle: PropTypes.string.isRequired,
      cropType: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
  property: PropTypes.shape({
    icon: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    info: PropTypes.shape().isRequired,
    name: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
  }).isRequired,
  stats: PropTypes.arrayOf(PropTypes.string).isRequired,
  timeInterval: PropTypes.shape({
    prefix: PropTypes.string.isRequired,
  }).isRequired,
};

Summary.defaultProps = {
  metaFilter: false,
  alarmFields: false,
};
