import PropTypes from 'prop-types';
import React from 'react';
import { FORECASTS } from '../constants/pageIds';
import OverviewProperty from '../containers/OverviewProperty';
import Box from './Box';
import OverviewItemStats from './OverviewItemStats';

export default function ForecastOverviewItem({
  item, location, dateTimeRange, properties,
}) {
  return (
    <Box.Content className="py-1">
      <h2 className="h4 text-center text-black-50">{item.title}</h2>
      <hr className="m-0 mb-1" />
      <div className="row align-items-stretch">
        {properties.map(({ id, options }) => (
          <OverviewProperty
            key={`${id}${options.key}`}
            locationId={location.id}
            propertyId={id}
            propertyOptions={options}
            data={item}
            dateTimeRange={dateTimeRange}
            pageId={FORECASTS}
          />
        ))}
      </div>
      <OverviewItemStats stats={item.stats} />
    </Box.Content>
  );
}

ForecastOverviewItem.propTypes = {
  item: PropTypes.shape({
    datetime: PropTypes.string.isRequired,
    properties: PropTypes.shape({}).isRequired,
    stats: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
    geometry: PropTypes.shape({}).isRequired,
  }).isRequired,
  dateTimeRange: PropTypes.shape({}).isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({}),
  }).isRequired).isRequired,
};
