import { TERMS_OF_USE } from '../constants/pageIds';
import * as text from '../data/text';

export default class TermsOfUsePage {
  static id = TERMS_OF_USE;

  static label = 'Terms Of Use';

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: text.TERMS_OF_USE_PAGE_DESCRIPTION },
      ],
    };
  }
}
