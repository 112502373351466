import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { NEXT_7_DAYS, NEXT_14_WEEKS, NEXT_24_WEEKS } from '../constants/dateTimeRangeIds';
import { OVERVIEW_VIEW } from '../constants/pageViews';
import dateTimeRanges from '../data/dateTimeRanges';
import DateTimeRangeSelector from './DateTimeRangeSelector';

export default function ForecastOverviewNav({ dateTimeRangeId, setPageViewDateTimeRange, Page }) {
  const onDateTimeRangeChange = (value) => setPageViewDateTimeRange(Page.id, OVERVIEW_VIEW, value);
  return (
    <div className="my-2 d-flex">
      <DateTimeRangeSelector
        options={[
          dateTimeRanges[NEXT_7_DAYS],
          dateTimeRanges[NEXT_14_WEEKS],
          dateTimeRanges[NEXT_24_WEEKS],
        ].filter(({ id }) => _.includes(Page.dateTimeRangeIds, id))}
        value={dateTimeRangeId}
        set={onDateTimeRangeChange}
      />
    </div>
  );
}

ForecastOverviewNav.propTypes = {
  dateTimeRangeId: PropTypes.string.isRequired,
  setPageViewDateTimeRange: PropTypes.func.isRequired,
  Page: PropTypes.func.isRequired,
};
