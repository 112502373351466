import PropTypes from 'prop-types';
import React from 'react';

export default function MapDisplaySelector({
  options, value, set, getLabelForPropertyKey,
}) {
  if (options.length === 0) {
    return null;
  }
  return (
    <select
      className="form-select form-select-sm mb-1 w-auto"
      disabled={options.length === 1}
      value={value}
      onChange={(event) => set(event.target.value)}
    >
      {options.map((item) => (
        <option key={item.value} value={item.value}>
          {getLabelForPropertyKey(item.label)}
        </option>
      ))}
    </select>
  );
}

MapDisplaySelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  set: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  getLabelForPropertyKey: PropTypes.func,
};

MapDisplaySelector.defaultProps = {
  getLabelForPropertyKey: (key) => key,
};
