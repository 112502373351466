import _ from 'lodash';
import * as ids from '../constants/conditionIds';
import * as valueTypes from '../constants/valueTypes';

export default {
  [ids.UPPER_FORECAST_GREATER_THAN]: {
    id: ids.UPPER_FORECAST_GREATER_THAN,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.UPPER);
      return forecast > value;
    }),
    name: 'upper greater than',
  },
  [ids.UPPER_FORECAST_LESS_THAN]: {
    id: ids.UPPER_FORECAST_LESS_THAN,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.UPPER);
      return forecast < value;
    }),
    name: 'upper less than',
  },
  [ids.MID_FORECAST_GREATER_THAN]: {
    id: ids.MID_FORECAST_GREATER_THAN,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.MID);
      return forecast > value;
    }),
    name: 'mid greater than',
  },
  [ids.MID_FORECAST_LESS_THAN]: {
    id: ids.MID_FORECAST_LESS_THAN,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.MID);
      return forecast < value;
    }),
    name: 'mid less than',
  },
  [ids.LOWER_FORECAST_GREATER_THAN]: {
    id: ids.LOWER_FORECAST_GREATER_THAN,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.LOWER);
      return forecast > value;
    }),
    name: 'lower greater than',
  },
  [ids.LOWER_FORECAST_LESS_THAN]: {
    id: ids.LOWER_FORECAST_LESS_THAN,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.LOWER);
      return forecast < value;
    }),
    name: 'lower less than',
  },
  [ids.FORECAST_GREATER_THAN]: {
    id: ids.FORECAST_GREATER_THAN,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = property.getValue(item.forecast);
      return forecast > value;
    }),
    name: 'greater than',
  },
  [ids.FORECAST_LESS_THAN]: {
    id: ids.FORECAST_LESS_THAN,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = property.getValue(item.forecast);
      return forecast < value;
    }),
    name: 'less than',
  },
  [ids.FORECAST_GREATER_THAN_0MM]: {
    id: ids.FORECAST_GREATER_THAN_0MM,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.RAIN_0MM);
      return forecast > value;
    }),
    name: '% greater than 0mm',
  },
  [ids.FORECAST_GREATER_THAN_5MM]: {
    id: ids.FORECAST_GREATER_THAN_5MM,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.RAIN_5MM);
      return forecast > value;
    }),
    name: '% greater than 5mm',
  },
  [ids.FORECAST_GREATER_THAN_10MM]: {
    id: ids.FORECAST_GREATER_THAN_10MM,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.RAIN_10MM);
      return forecast > value;
    }),
    name: '% greater than 10mm',
  },
  [ids.FORECAST_GREATER_THAN_15MM]: {
    id: ids.FORECAST_GREATER_THAN_15MM,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.RAIN_15MM);
      return forecast > value;
    }),
    name: '% greater than 15mm',
  },
  [ids.FORECAST_GREATER_THAN_25MM]: {
    id: ids.FORECAST_GREATER_THAN_25MM,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.RAIN_25MM);
      return forecast > value;
    }),
    name: '% greater than 25mm',
  },
  [ids.FORECAST_GREATER_THAN_50MM]: {
    id: ids.FORECAST_GREATER_THAN_50MM,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.RAIN_50MM);
      return forecast > value;
    }),
    name: '% greater than 50mm',
  },
  [ids.FORECAST_LESS_THAN_0MM]: {
    id: ids.FORECAST_LESS_THAN_0MM,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.SWD_0);
      return forecast > value;
    }),
    name: '% less than 0mm',
  },
  [ids.FORECAST_LESS_THAN_N10MM]: {
    id: ids.FORECAST_LESS_THAN_N10MM,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.SWD_N10);
      return forecast > value;
    }),
    name: '% less than -10mm',
  },
  [ids.FORECAST_LESS_THAN_N20MM]: {
    id: ids.FORECAST_LESS_THAN_N20MM,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.SWD_N20);
      return forecast > value;
    }),
    name: '% less than -20mm',
  },
  [ids.FORECAST_LESS_THAN_N30MM]: {
    id: ids.FORECAST_LESS_THAN_N30MM,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.SWD_N30);
      return forecast > value;
    }),
    name: '% less than -30mm',
  },
  [ids.FORECAST_LESS_THAN_N40MM]: {
    id: ids.FORECAST_LESS_THAN_N40MM,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.SWD_N40);
      return forecast > value;
    }),
    name: '% less than -40mm',
  },
  [ids.FORECAST_LESS_THAN_N50MM]: {
    id: ids.FORECAST_LESS_THAN_N50MM,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.SWD_N50);
      return forecast > value;
    }),
    name: '% less than -50mm',
  },
  [ids.FORECAST_LESS_THAN_N60MM]: {
    id: ids.FORECAST_LESS_THAN_N60MM,
    calculate: (data, property, value) => data.some((item) => {
      const forecast = _.get(property.getValue(item.forecast), valueTypes.SWD_N60);
      return forecast > value;
    }),
    name: '% less than -60mm',
  },
};
