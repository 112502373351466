import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import * as actions from '../actions';
import AlarmAdd from '../components/AlarmAdd';
import { useAction } from '../lib/hooks';

const AlarmAddContainer = ({ fields }) => {
  const isAuthenticated = useSelector((state) => state.auth.state === 'signedIn');
  const add = useAction(actions.addAlarm);
  return (
    <AlarmAdd
      fields={fields}
      isAuthenticated={isAuthenticated}
      add={add}
    />
  );
};

AlarmAddContainer.propTypes = {
  fields: PropTypes.shape({}).isRequired,
};

export default AlarmAddContainer;
