import './Overview.scss';
import { faDirections } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import { Route } from 'react-router-dom';
import * as paths from '../constants/paths';
import EndpointDescription from '../containers/EndpointDescription';
import FarmItemOverviewInfo from '../containers/FarmItemOverviewInfo';
import { isBelowMedium, scrollToElement } from '../lib/utils';
import Box from './Box';
import TourStarterButton from './TourStarterButton';

export default function Overview({
  location, items, dateTimeRange, ItemComponent, properties,
}) {
  const element = useRef();
  const locationRef = useRef(location);
  useEffect(() => {
    const options = { behavior: 'instant' };
    if (locationRef.current !== location) {
      options.behavior = 'smooth';
    }
    if (isBelowMedium()) {
      scrollToElement(element.current, options);
    }
  }, [location]);
  useEffect(() => {
    if (!isBelowMedium()) {
      window.scrollTo({ behavior: 'instant', top: 0 });
    }
  }, []);
  const rows = items.map((item) => ((
    <ItemComponent
      key={item.datetime}
      item={item}
      location={location}
      dateTimeRange={dateTimeRange}
      properties={properties}
    />
  )));
  return (
    <div ref={element}>
      <Box.Content className="py-3 cssip-tour-location-name">
        <div>
          <Route path={paths.DEMO_FARM}>
            <TourStarterButton
              className="mb-3 btn btn-outline-primary cssip-overview__start-tour"
            >
              <FontAwesomeIcon icon={faDirections} className="me-1" />
              Start tour
            </TourStarterButton>
          </Route>
          <div>
            <h1 className="text-black-50 text-uppercase mb-0 h3">
              {location.name}
            </h1>
            <hr className="my-2" />
            <div className="p-md-0 pt-2">
              <EndpointDescription />
            </div>
          </div>
          <Route path={paths.FARM_OVERVIEW_ITEM} component={FarmItemOverviewInfo} />
        </div>
      </Box.Content>
      {rows.length === 0 ? (
        <div className="mx-3">
          <h3 className="text-center">No items currently available</h3>
        </div>
      ) : (
        rows
      )}
    </div>
  );
}

Overview.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    geometry: PropTypes.shape({}),
  }).isRequired,
  dateTimeRange: PropTypes.shape({}).isRequired,
  ItemComponent: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.shape({}).isRequired,
  ]).isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({}),
  }).isRequired).isRequired,
};
