import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import ListItem from './ListItem';
import MapItems from './MapItems';
import MapWrapper from './MapWrapper';

// @TODO: Combine items and geoJson props.
export default function RealTime({ items, currentLocation }) {
  const [selectedLocation, setSelectedLocation] = useState();
  useEffect(() => { window.scrollTo({ behavior: 'instant', top: 0 }); }, []);
  const geoJson = items.filter((item) => item.geometry).map((item) => ({
    geometry: item.geometry,
    id: item.id,
    properties: {
      tooltip: {
        name: item.name,
      },
    },
    type: 'Feature',
  }));
  return (
    <Layout.Content className="container-fluid d-flex flex-column flex-grow-1">
      <Layout.Row>
        <Layout.Primary>
          <MapWrapper>
            <MapItems
              geoJson={geoJson}
              currentLocation={currentLocation}
              onChange={(item) => {
                if (item) {
                  setSelectedLocation(item.id);
                } else {
                  setSelectedLocation(null);
                }
              }}
              locationId={selectedLocation}
            />
          </MapWrapper>
        </Layout.Primary>
        <Layout.Secondary expanded={false}>
          <ul className="list-unstyled">
            {items.map((item) => ((
              <ListItem key={item.id} item={item} selected={item.id === selectedLocation} />
            )))}
          </ul>
        </Layout.Secondary>
      </Layout.Row>
    </Layout.Content>
  );
}

RealTime.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    geometry: PropTypes.shape({}),
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  currentLocation: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.bool,
  ]).isRequired,
};
