import './Layout.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Container = ({ children }) => (
  <div
    className="cssip-layout d-flex flex-column"
  >
    {children}
  </div>
);
Container.propTypes = {
  children: PropTypes.node.isRequired,
};

const Nav = ({ children }) => (
  <section className="cssip-layout__nav">
    {children}
  </section>
);
Nav.propTypes = {
  children: PropTypes.node.isRequired,
};

const SubNav = ({ children }) => (
  <section className="w-100">
    <div className="d-flex justify-content-start bg-dark">
      {children}
    </div>
  </section>
);
SubNav.propTypes = {
  children: PropTypes.node.isRequired,
};

const Content = ({ children, className }) => (
  <section className={className}>
    {children}
  </section>
);
Content.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
Content.defaultProps = {
  className: '',
};

const Row = ({ children }) => (
  <div className="row flex-grow-1">
    {children}
  </div>
);
Row.propTypes = {
  children: PropTypes.node.isRequired,
};

const Primary = ({ className, children }) => (
  <section
    className={classNames(
      'cssip-layout__primary',
      className,
      'flex-grow-1 col-12 col-md py-3 p-md-0 order-0',
    )}
  >
    <div className="position-relative h-100 bg-dark overflow-hidden">
      {children}
    </div>
  </section>
);
Primary.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
Primary.defaultProps = {
  className: '',
};

const Secondary = ({ children, expanded }) => (
  <section
    className={classNames(
      'col-12 col-md-6 pt-3 order-2',
      {
        'col-lg-4': !expanded,
        'col-lg-8': expanded,
      },
    )}
  >
    {children}
  </section>
);
Secondary.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired,
};

const Footer = ({ children }) => (
  <section className="container-fluid flex-shrink-0 bg-dark p-0">
    {children}
  </section>
);
Footer.propTypes = {
  children: PropTypes.node.isRequired,
};

const Layout = {
  Container, Content, Footer, Nav, Primary, Row, Secondary, SubNav,
};

export default Layout;
