import './RealTimeValues.scss';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { AVERAGE } from '../constants/combiningTypes';
import FormattedValue from './FormattedValue';
import Icon from './Icon';

export default function RealTimeValues({ data, property }) {
  const current = _.last(data);
  const sorted = _.sortBy(data, (item) => property.getValue(item.observation));
  const max = _.last(sorted);
  const min = sorted[0];
  const scale = property.getScale();
  return (
    <div className="pb-3">
      <div className="d-flex justify-content-center align-items-center">
        <Icon
          icon={property.icon}
          shadow
          size="5x"
          style={{ color: scale(property.getValue(current.observation)) }}
        />
        <div className="ms-2">
          <div>
            <span className="cssip-real-time-values__number">
              <FormattedValue
                value={property.getFormatted(current.observation)}
              />
            </span>
            <small>{property.unit}</small>
          </div>
          <div className="mt-n2 small">
            {`${property.name} (at ${moment(current.datetime).format('H:mm A')})`}
          </div>
        </div>
      </div>
      {property.combining === AVERAGE && (
        <div className="pt-3 small">
          <span>
            {'Min '}
            <strong>{property.getFormatted(min.observation, { unit: true })}</strong>
            {' '}
            {moment(min.datetime).calendar()}
          </span>
          <br />
          <span>
            {'Max '}
            <strong>{property.getFormatted(max.observation, { unit: true })}</strong>
            {' '}
            {moment(max.datetime).calendar()}
          </span>
        </div>
      )}
    </div>
  );
}

RealTimeValues.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    datetime: PropTypes.string.isRequired,
    observation: PropTypes.shape({}).isRequired,
  })).isRequired,
  property: PropTypes.shape({
    combining: PropTypes.string.isRequired,
    getFormatted: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired,
    icon: PropTypes.shape({}).isRequired,
    getScale: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
  }).isRequired,
};
