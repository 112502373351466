import * as types from '../constants/actionTypes';
import { LOCATIONS } from '../constants/endpointIds';
import { authedEndpointIds } from '../data/endpoints';

const initialState = {
  [LOCATIONS]: 'received',
};

export default function endpoints(state = initialState, action = {}) {
  switch (action.type) {
    case types.ENDPOINT_REQUESTED: {
      return {
        ...state,
        [action.endpointId]: state[action.endpointId] || 'requested',
      };
    }

    case types.ENDPOINT_RECEIVED: {
      return {
        ...state,
        [action.endpointId]: 'received',
      };
    }

    case types.ADMIN_DATA_SOURCE_USERNAME_CHANGED:
    case types.USER_SIGNED_OUT: {
      const newState = { ...state };
      authedEndpointIds.forEach((id) => {
        delete newState[id];
      });
      return newState;
    }

    default: {
      return state;
    }
  }
}
