import PropTypes from 'prop-types';
import React from 'react';
import cropFilters from '../data/cropFilters';

export default function CropFilterSelector({ value, set }) {
  const cropTypeOptions = cropFilters.map((cropType) => (
    <option key={cropType.value} value={cropType.value}>
      {cropType.label}
    </option>
  ));
  const currentCropType = cropFilters.find((item) => item.value === value.cropType);
  const cropCycleOptions = currentCropType.cropCycle.map((cropCycle) => (
    <option key={cropCycle.value} value={cropCycle.value}>
      {cropCycle.label}
    </option>
  ));
  const currentCropCycle = currentCropType.cropCycle.find((item) => item.value === value.cropCycle);
  return (
    <>
      <select
        className="form-select form-select-sm w-auto flex-fill mx-1"
        value={currentCropType.value}
        onChange={(event) => {
          const cropType = cropFilters.find((item) => item.value === event.target.value);
          const selected = {
            cropCycle: cropType.cropCycle[0].value,
            cropType: cropType.value,
          };
          set(selected);
        }}
        title="Crop type"
      >
        {cropTypeOptions}
      </select>
      <select
        className="form-select form-select-sm w-auto flex-fill mx-1"
        value={currentCropCycle.value}
        onChange={(event) => {
          const selected = { ...value, cropCycle: event.target.value };
          set(selected);
        }}
        title="Crop cycle"
      >
        {cropCycleOptions}
      </select>
    </>
  );
}

CropFilterSelector.propTypes = {
  set: PropTypes.func.isRequired,
  value: PropTypes.shape({
    cropCycle: PropTypes.string.isRequired,
    cropType: PropTypes.string.isRequired,
  }).isRequired,
};
