import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styles from '../data/mapStyles';

export default function MapStyleSelector({ styleName, setStyle }) {
  const style = styles.find((item) => item.name === styleName);
  return (
    <Dropdown className="cssip-tour-map-style-selector">
      <Dropdown.Toggle size="sm" variant="primary" aria-label="Map style">
        <FontAwesomeIcon icon={faLayerGroup} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {styles.map((item) => (
          <Dropdown.Item
            key={item.name}
            active={item === style}
            onClick={() => setStyle(item.name)}
          >
            {item.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

MapStyleSelector.propTypes = {
  setStyle: PropTypes.func.isRequired,
  styleName: PropTypes.string.isRequired,
};
