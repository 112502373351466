import _ from 'lodash';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as actions from '../actions';
import DateTimeRangeSelector from '../components/DateTimeRangeSelector';
import { PROPERTY_VIEW } from '../constants/pageViews';
import pages from '../data/dataPages';
import dateTimeRanges from '../data/dateTimeRanges';
import properties from '../data/properties';

const DateTimeRangeSelectorContainer = () => {
  const { pageId, propertyId } = useParams();
  const Page = pages[pageId];
  const value = useSelector((state) => {
    const page = new Page(state);
    return page.getSelectedDateTimeRange(PROPERTY_VIEW, propertyId).id;
  });
  const property = properties[propertyId];
  const options = property.dateTimeRangeIds
    .filter((id) => _.includes(Page.dateTimeRangeIds, id))
    .map((id) => ({
      id,
      label: dateTimeRanges[id].label,
    }));
  const dispatch = useDispatch();
  const set = useCallback((id, event) => {
    dispatch(actions.setPageViewDateTimeRange(pageId, PROPERTY_VIEW, id, event));
  }, [dispatch, pageId]);
  return (
    <DateTimeRangeSelector
      set={set}
      value={value}
      options={options}
    />
  );
};

export default DateTimeRangeSelectorContainer;
