import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as dateTimeRangeIds from '../constants/dateTimeRangeIds';
import * as endpointIds from '../constants/endpointIds';
import * as propertyIds from '../constants/propertyIds';
import * as taskIds from '../constants/taskIds';
import * as valueTypes from '../constants/valueTypes';
import * as widgetIds from '../constants/widgetIds';
import properties from '../data/properties';

const burning = {
  // https://pixabay.com/photos/match-flame-smoke-matchstick-359970/
  image: `${process.env.PUBLIC_URL}/tasks/burning.jpg`,
  getWidgetsForLocations: (locations) => [
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.WIND_SPEED,
        sources: [endpointIds.REAL_TIME],
      },
      title: `Wind real time (${locations.realTime.name})`,
      type: widgetIds.REAL_TIME_VALUES,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.WIND_SPEED,
        sources: [endpointIds.REAL_TIME],
        referenceLines: [15],
      },
      title: `Wind real time (${locations.realTime.name})`,
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_24_HOURS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.WIND_SPEED,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
        referenceLines: [15],
      },
      title: 'Wind forecast for next 24 hours',
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_24_HOURS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.WIND_SPEED,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Wind forecast for next 24 hours',
      type: widgetIds.TABLE,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.RAIN_24_HOUR_TOTAL,
        sources: [endpointIds.REAL_TIME],
      },
      title: `Rain real time (${locations.realTime.name})`,
      type: widgetIds.REAL_TIME_VALUES,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.RAIN_24_HOUR_TOTAL,
        sources: [endpointIds.REAL_TIME],
      },
      title: `Rain real time (${locations.realTime.name})`,
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.RAIN_POP,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Rain probability forecast for next 3 days',
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.RAIN,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Rain forecast for next 3 days',
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.RAIN_POP,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Rain probability forecast for next 3 days',
      type: widgetIds.TABLE,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.RAIN,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Rain forecast for next 3 days',
      type: widgetIds.TABLE,
    },
  ],
  id: taskIds.BURNING,
  name: 'Burning',
  statuses: [
    {
      getStatus: (current) => {
        const windSpeed = properties[propertyIds.WIND_SPEED].getValue(current.properties);
        if (windSpeed < 15) {
          return {
            icon: 'check',
            message: 'Wind speed conditions are currently sufficient for burning',
          };
        }
        return {
          icon: 'times',
          message: 'Wind speed conditions are high, consider holding off burning',
        };
      },
      info: {
        long: 'If the wind speed is greater than 15km/h it is recommended that you consider holding off burning.',
        short: 'Wind speed is sufficient for burning when less than 15km/h.',
      },
      name: 'Wind speed',
    },
    {
      getStatus: (current, weatherForecasts) => {
        const start = moment().startOf('day');
        const end = start.add(3, 'days').startOf('day');
        const forecasts = weatherForecasts
          .filter((item) => item.timeInterval === 'day')
          .filter((item) => moment(item.datetime).isBetween(start, end));
        const isBad = forecasts.some((item) => _.get(item.properties, `${propertyIds.RAIN_POP}.${valueTypes.RAIN_25MM}`) > 50);
        if (isBad) {
          return {
            icon: 'times',
            message: 'There is a medium chance of at least one above 25mm day in the next three days',
          };
        }
        const isOk = forecasts.some((item) => _.get(item.properties, `${propertyIds.RAIN_POP}.${valueTypes.RAIN_0MM}`) > 50);
        if (isOk) {
          return {
            icon: 'minus',
            message: 'There is a medium chance of at least one above 25mm day in the next three days',
          };
        }
        return {
          icon: 'check',
          message: 'There is a low to no chance of rain in the next three days',
        };
      },
      info: {
        long: 'A medium chance of rain is defined by any day in the next 3 days having 50% chance of any rain (above 0mm). Likewise a medium chance of above 25mm is any day having a 50% chance of at least 25mm occuring.',
        short: 'Low to no chance of rain is suitable for burning',
      },
      name: 'Rain Probability',
    },
    {
      getStatus: () => ({
        icon: 'question',
        message: (
          <>
            {'Don\'t forget to check current storm activity on the '}
            <a
              href="http://www.bom.gov.au/products/IDR732.loop.shtml#skip"
              target="popup"
              onClick={() => {
                window.open('http://www.bom.gov.au/products/IDR732.loop.shtml#skip', 'popup', 'width=600,height=800');
                return false;
              }}
              rel="noreferrer noopener"
            >
              BOM RADAR
            </a>
          </>
        ),
      }),
      info: {
        long: '',
        short: 'Link to BOM RADAR',
      },
      name: 'BOM RADAR',
    },
  ],
};

export default burning;
