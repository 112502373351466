import PropTypes from 'prop-types';
import React from 'react';

export default function StructuredData({ data }) {
  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data, null, 4) }}
    />
  );
}

StructuredData.propTypes = {
  data: PropTypes.shape().isRequired,
};
