import './Icon.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function Icon({ shadow, className, ...props }) {
  return (
    <FontAwesomeIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={classNames(className, {
        'cssip-icon--shadow': shadow,
      })}
    />
  );
}

Icon.propTypes = {
  shadow: PropTypes.bool,
  className: PropTypes.string,
};

Icon.defaultProps = {
  shadow: false,
  className: '',
};
