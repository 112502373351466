import React from 'react';
import tasks from '../data/tasks';
import HelpComponent from './HelpComponent';
import HelpSection from './HelpSection';
import HelpSubSection from './HelpSubSection';
import LocationSelector from './LocationSelector';

export default function HelpTasksPage() {
  return (
    <HelpSection title="Tasks page">
      <p className="lead">
        The tasks page is designed to make it easy to see the relevant data used in decision
        making for specific tasks. Each task provides insight into what the current statuses
        are for a given region.
      </p>
      <HelpSubSection
        title="Zone selector"
        example={(
          <HelpComponent>
            <div className="bg-white p-2">
              <LocationSelector value="" locations={[]} id="zone-location-selector" />
            </div>
          </HelpComponent>
        )}
        text={(
          <p>
            This zone selector will set what forecasts and observations are shown in the
            widgets. By default the zone will be set the users default zone.
          </p>
        )}
      />
      <HelpSubSection
        title="Weather station selector"
        example={(
          <HelpComponent>
            <div className="bg-white p-2">
              <LocationSelector
                value=""
                locations={[{ id: 'stationId', name: 'stationName' }]}
                id="station-location-selector"
              />
            </div>
          </HelpComponent>
        )}
        text={(
          <p>
            Real time weather station selector is used in determining what real time data to
            display in the pages widgets and statuses. By default it will be set to the users
            default station if the selected zone is the users default zone.
          </p>
        )}
      />
      <ul className="list-unstyled">
        {Object.values(tasks).map((task) => (
          <li key={task.name}>
            <h4><u>{task.name}</u></h4>
            <ul>
              {task.statuses.map((status) => (
                <li key={status.name}>
                  <strong>{status.name}</strong>
                  <br />
                  {status.info.short}
                  <br />
                  {status.info.long}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </HelpSection>
  );
}
