import chroma from 'chroma-js';
import _ from 'lodash';
import moment from 'moment';
import { matchPath } from 'react-router-dom';
import * as paths from '../constants/paths';
import * as valueTypes from '../constants/valueTypes';
import sassVariables from '../custom.module.scss';
import history from './history';
import turf from './turf';

export const scrollToElement = (element, args = {}) => {
  const options = { position: 'top', behavior: 'smooth', ...args };
  const navbarHeight = _.toNumber(sassVariables.navbarHeight.replace('px', ''));
  const rectangle = element.getBoundingClientRect();
  let y = rectangle.top + window.pageYOffset - navbarHeight;
  if (options.position === 'center') {
    const extra = window.innerHeight / 2 - rectangle.height / 2;
    y -= extra;
  }
  window.scrollTo({ behavior: options.behavior, top: y });
};

export const generateLegend = (colors, domain, steps = 10) => {
  const max = Math.max(...domain);
  const min = Math.min(...domain);
  const scale = chroma.scale(colors).domain(domain);
  const legend = [];
  const increment = (max - min) / (steps - 1);
  for (let i = 0; i < steps; i += 1) {
    const value = parseFloat((min + i * increment).toFixed(1));
    legend.push({
      color: scale(value).hex(),
      value,
    });
  }
  return legend;
};

export const sortProperty = (property) => {
  if (_.isObject(property)) {
    const sorted = {};
    Object.values(valueTypes).forEach((item) => {
      if (item in property) {
        sorted[item] = property[item];
      }
    });
    return sorted;
  }
  return property;
};

export const getLineEquationFrom2Points = (points) => {
  const m = (points[1].y - points[0].y) / (points[1].x - points[0].x);
  return (x) => m * (x - points[1].x) + points[1].y;
};

export const degreesToCompass = (degrees) => {
  const index = Math.floor(degrees / 22.5 + 0.5) % 16;
  const directions = [
    'N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW',
  ];
  return directions[index];
};

export const groupMergeAndSortByDatetime = (input, alignment) => {
  const grouped = _.groupBy(input, (item) => moment(item.datetime)
    .startOf(alignment).format());
  const merged = Object.values(grouped).map((items) => _.merge({}, ...items));
  return _.sortBy(merged, 'datetime');
};

export const getClosestStationForZone = (zone, locations) => {
  const source = turf.center(zone.geometry);
  const stations = Object.values(locations).filter((item) => item.type === 'REAL_TIME');
  const features = stations.map((item) => turf.feature(item.geometry, { station: item }));
  return turf.nearestPoint(source, turf.featureCollection(features)).properties.station;
};

export const getZoneForField = (field, locations) => {
  if (!field.geometry) {
    return false;
  }
  const point = turf.center(field.geometry);
  const zones = Object.values(locations).filter((item) => item.type === 'DEFAULT');
  const zone = zones.find((item) => {
    const polygonFeature = turf.feature(item.geometry);
    return turf.booleanPointInPolygon(point, polygonFeature);
  });
  return zone;
};

export const dateShiftMockData = (data, shift) => {
  const amount = moment().diff(shift.date, 'days');
  /* eslint-disable no-param-reassign */
  Object.values(data.forecasts).forEach((locations) => {
    Object.values(locations).forEach((item) => {
      item.datetime = moment(item.datetime).add(amount, 'days').format('YYYY-MM-DD');
      item.issued = moment(item.issued).add(amount, 'days').format('YYYY-MM-DD');
      if ((Object.keys(item.properties).includes('irrigFirstDateSummary'))) {
        if (!(item.properties.irrigFirstDateSummary === null)) {
          Object.keys(item.properties.irrigFirstDateSummary).forEach((key) => {
            item.properties.irrigFirstDateSummary[key] = moment(
              item.properties.irrigFirstDateSummary[key],
            ).add(amount, 'days').format('YYYY-MM-DD');
            item.properties.irrigFirstDateSummary[key] += 'T00:00:00+10:00';
          });
        }
      }
      item.datetime += 'T00:00:00+10:00';
      item.issued += 'T00:00:00+10:00';
    });
  });
  Object.values(data.observations).forEach((locations) => {
    Object.values(locations).forEach((item) => {
      item.datetime = moment(item.datetime).add(amount, 'days').format('YYYY-MM-DD');
      item.issued = moment(item.issued).add(amount, 'days').format('YYYY-MM-DD');
      item.datetime += 'T00:00:00+10:00';
      item.issued += 'T00:00:00+10:00';
    });
  });
  /* eslint-enable no-param-reassign */
  return data;
};

export const getCurrentPageId = () => {
  const pageId = _.get(
    matchPath(history.location.pathname, paths.DATA),
    'params.pageId',
  );
  return pageId;
};

export const generateTitle = (parts) => process.env.REACT_APP_TITLE_PREFIX + [
  ...parts,
  'Opticane',
].join(' | ');

export const isBelowMedium = () => window.innerWidth < _.toNumber(sassVariables.md.replace('px', ''));
