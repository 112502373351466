import React from 'react';
import { Route } from 'react-router-dom';
import * as paths from '../constants/paths';
import CropFilterSelector from '../containers/CropFilterSelector';
import DateTimeOffsetSelector from '../containers/DateTimeOffsetSelector';
import DateTimeRangeSelector from '../containers/DateTimeRangeSelector';

export default function CwuNav() {
  return (
    <Route path={paths.FORECAST_PROPERTY}>
      <div className="d-flex my-md-0 my-2 w-100 flex-md-row flex-column">
        <div className="my-md-2 my-0 d-flex">
          <DateTimeRangeSelector />
          <DateTimeOffsetSelector />
        </div>
        <div className="my-md-2 my-0 mt-2 d-flex">
          <CropFilterSelector />
        </div>
      </div>
    </Route>
  );
}
