import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as paths from '../constants/paths';
import MapLocationSelector from '../containers/MapLocationSelector';
import MapWrapper from './MapWrapper';

export default function OverviewMap({ pageId, locationId, locationIds }) {
  const history = useHistory();
  return (
    <MapWrapper>
      <MapLocationSelector
        options={locationIds}
        value={locationId}
        onChange={(item) => {
          if (item) {
            const url = paths.OVERVIEW_ITEM
              .replace(paths.dataPageIdString, pageId)
              .replace(paths.locationIdString, item.id);
            history.push(url);
          }
        }}
      />
    </MapWrapper>
  );
}

OverviewMap.propTypes = {
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  locationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  pageId: PropTypes.string.isRequired,
};

OverviewMap.defaultProps = {
  locationId: false,
};
