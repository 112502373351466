import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { getUserLocation } from '../lib/location';

export default function Location({ className, setCurrentLocation, currentLocation }) {
  if (!navigator) {
    return null;
  }
  const isActive = !!currentLocation;
  return (
    <button
      className={classNames(
        className,
        'cssip-tour-location',
        'btn btn-sm',
        {
          'btn-light text-primary': !isActive,
          'btn-primary': isActive,
        },
      )}
      type="button"
      title="Center map on current location"
      onClick={() => {
        getUserLocation(({ coords }) => setCurrentLocation([coords.longitude, coords.latitude]));
      }}
    >
      <FontAwesomeIcon icon={faCrosshairs} />
    </button>
  );
}

Location.propTypes = {
  className: PropTypes.string,
  currentLocation: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.bool,
  ]).isRequired,
  setCurrentLocation: PropTypes.func.isRequired,
};

Location.defaultProps = {
  className: '',
};
