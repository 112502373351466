import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Route, useParams, useHistory } from 'react-router-dom';
import MapItems from '../components/MapItems';
import * as paths from '../constants/paths';
import pages from '../data/dataPages';
import properties from '../data/properties';
import { useLoader } from '../lib/hooks';
import MapDisplaySelector from './MapDisplaySelector';
import MapParticlesToggle from './MapParticlesToggle';

const emptyGeoJson = [];

const MapItemsTopLeft = React.memo(({ propertyId }) => (
  <MapParticlesToggle propertyId={propertyId} />
));

MapItemsTopLeft.propTypes = {
  propertyId: PropTypes.string.isRequired,
};

const MapItemsTopRight = React.memo(() => (
  <div className="cssip-map__overlay position-absolute m-1 text-end d-flex flex-column">
    <div>
      <Route path={paths.PROPERTY_ITEM_OPTIONAL} component={MapDisplaySelector} />
    </div>
  </div>
));

const MapItemsContainer = () => {
  const { pageId, locationId, propertyId } = useParams();
  const history = useHistory();
  const Page = pages[pageId];
  const property = properties[propertyId];
  const sources = useSelector((state) => {
    const page = new Page(state);
    return page.getSources();
  }, _.isEqual);
  const isLoading = useLoader(sources);
  const geoJson = useSelector((state) => {
    const page = new Page(state);
    return isLoading ? emptyGeoJson : page.getGeoJson(propertyId);
    // @TODO: Remove as much as possible here. eg paths.LOCATION_OPTIONAL, getPropertyPath etc.
    // Follow the rabbit.
    // basePath: Page.getPropertyPath(property.id),
  }, _.isEqual);
  const currentLocation = useSelector((state) => state.map.currentLocation);
  const showParticles = useSelector((state) => state.map.showParticles);
  const { showMapDisplaySelector } = Page;
  const onChange = useCallback((item) => {
    if (item) {
      history.push(item.properties.to);
    }
  }, [history]);
  return (
    <MapItems
      topLeft={<MapItemsTopLeft propertyId={propertyId} />}
      topRight={showMapDisplaySelector && <MapItemsTopRight />}
      locationId={locationId}
      propertyId={propertyId}
      currentLocation={currentLocation}
      showParticles={showParticles && property.hasParticles}
      geoJson={geoJson}
      showMapIconOverPolygon={property.showMapIconOverPolygon}
      particleDataFilterer={property.particleDataFilterer}
      onChange={onChange}
    />
  );
};

export default MapItemsContainer;
