import './Banner.scss';
import PropTypes from 'prop-types';
import React from 'react';

export default function Banner({ image, main, sub }) {
  return (
    <div className="container-fluid">
      <div
        className="cssip-banner row"
        // Image is from https://pixabay.com/photos/water-resources-irrigation-2251633/
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="align-self-center col-12 col-md">
          <div className="p-3">{main}</div>
        </div>
        {!!sub && (
          <div className="align-self-end col-12 col-md">
            <div className="p-3">{sub}</div>
          </div>
        )}
      </div>
    </div>
  );
}

Banner.propTypes = {
  image: PropTypes.string.isRequired,
  main: PropTypes.node.isRequired,
  sub: PropTypes.node,
};

Banner.defaultProps = {
  sub: null,
};
