import React from 'react';
import Layout from './Layout';
import Spinner from './Spinner';

const Placeholder = () => (
  <Layout.Content className="container-fluid d-flex flex-column flex-grow-1">
    <Spinner />
  </Layout.Content>
);

export default Placeholder;
