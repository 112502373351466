import { AUTH } from '../constants/pageIds';
import * as text from '../data/text';

export default class AuthPage {
  static id = AUTH;

  static label = 'Auth';

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: text.AUTH_PAGE_DESCRIPTION },
      ],
    };
  }
}
