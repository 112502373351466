import _ from 'lodash';
import moment from 'moment';
import React, { Suspense } from 'react';
import * as propertyIds from '../constants/propertyIds';
import { BURNING } from '../constants/taskIds';
import { DAY } from '../constants/timeIntervalIds';
import * as valueTypes from '../constants/valueTypes';
import properties from '../data/properties';
import tasks from '../data/tasks';
import timeIntervals from '../data/timeIntervals';
import HelpComponent from './HelpComponent';
import HelpSection from './HelpSection';
import HelpSubSection from './HelpSubSection';
import RealTimeValues from './RealTimeValues';
import Summary from './Summary';
import Table from './Table';
import TaskStatuses from './TaskStatuses';
import WidgetWrapper from './WidgetWrapper';

const ChartWrapper = React.lazy(() => import('./ChartWrapper'));
const LocationsMap = React.lazy(() => import('./LocationsMap'));

export default function HelpDataRepresentation() {
  return (
    <HelpSection title="Data representation">
      <p className="lead">
        Data is represented using widgets. Listed below are the widgets used throughout the app.
      </p>
      <HelpSubSection
        cols={8}
        title="Chart widget"
        example={(
          <HelpComponent display="d-block">
            <div className="bg-white mb-n3">
              <WidgetWrapper
                title="An example chart"
                move={_.noop}
                setIsModalShowing={_.noop}
                remove={_.noop}
              >
                <Suspense fallback={null}>
                  <ChartWrapper
                    data={[
                      {
                        datetime: '2020-11-24T00:00:00+10:00',
                        forecast: { et: 5.5 },
                        issued: '2020-11-23T00:00:00+10:00',
                        observation: { et: 4.5 },
                      },
                      {
                        datetime: '2020-11-25T00:00:00+10:00',
                        forecast: { et: 5.4 },
                        issued: '2020-11-25T00:00:00+10:00',
                        observation: { et: 4.2 },
                      },
                      {
                        datetime: '2020-11-26T00:00:00+10:00',
                        forecast: { et: 6.4 },
                        issued: '2020-11-26T00:00:00+10:00',
                        observation: { et: 3.7 },
                      },
                      {
                        datetime: '2020-11-27T00:00:00+10:00',
                        forecast: { et: 4.5 },
                        issued: '2020-11-26T00:00:00+10:00',
                        observation: { et: 3.5 },
                      },
                    ]}
                    property={properties[propertyIds.ET]}
                    issued="2020-11-26"
                    formatDate={timeIntervals[DAY].formatLong}
                    timeIntervalId={DAY}
                  />
                </Suspense>
              </WidgetWrapper>
            </div>
          </HelpComponent>
        )}
        text={(
          <p>
            This example chart is showing past forecasts that were issued on the 26th of
            November.
            <br />
            The black line represents the actual observed value.
            <br />
            The coloured bars are showing the forecast value. The colour matches the legend for
            the property being forecast.
            <br />
            The greyed out area shows the most recent forecasts that were issued prior to the
            26th of November. As the forecast for the 26th would obviously not include days that
            have already occurred.
          </p>
        )}
      />
      <HelpSubSection
        cols={8}
        title="Table widget"
        example={(
          <HelpComponent display="d-block">
            <div className="bg-white mb-n3">
              <WidgetWrapper
                title="An example table"
                move={_.noop}
                setIsModalShowing={_.noop}
                remove={_.noop}
              >
                <Table
                  {...{
                    data: {
                      body: [
                        {
                          items: [
                            {
                              color: properties[propertyIds.RAIN]
                                .getScale()(5.1).alpha(0.25).css(),
                              datetime: '2020-10-24T00:00:00+10:00',
                              value: '50.1mm',
                            },
                            {
                              color: 'rgba(216,240,197,0.25)',
                              datetime: '2020-10-25T00:00:00+10:00',
                              value: '9.3mm',
                            },
                            {
                              color: 'rgba(242,250,235,0.25)',
                              datetime: '2020-10-26T00:00:00+10:00',
                              value: '3.2mm',
                            },
                          ],
                          name: 'Observed',
                        },
                        {
                          items: [
                            {
                              items: [
                                {
                                  color: properties[propertyIds.RAIN]
                                    .getScale()(30).alpha(0.25).css(),
                                  datetime: '2020-10-24T00:00:00+10:00',
                                  value: '30.0mm',
                                },
                                {
                                  color: 'rgba(255,255,255,0.25)',
                                  datetime: '2020-10-25T00:00:00+10:00',
                                  value: '0.0mm',
                                },
                                {
                                  color: 'rgba(255,255,255,0.25)',
                                  datetime: '2020-10-26T00:00:00+10:00',
                                  value: '0.0mm',
                                },
                              ],
                              name: valueTypes.LOWER,
                            },
                            {
                              items: [
                                {
                                  color: properties[propertyIds.RAIN]
                                    .getScale()(40).alpha(0.25).css(),
                                  datetime: '2020-10-24T00:00:00+10:00',
                                  value: '40.0mm',
                                },
                                {
                                  color: 'rgba(251,253,249,0.25)',
                                  datetime: '2020-10-25T00:00:00+10:00',
                                  value: '1.0mm',
                                },
                                {
                                  color: 'rgba(253,254,252,0.25)',
                                  datetime: '2020-10-26T00:00:00+10:00',
                                  value: '0.5mm',
                                },
                              ],
                              name: valueTypes.MID,
                            },
                            {
                              items: [
                                {
                                  color: properties[propertyIds.RAIN]
                                    .getScale()(60).alpha(0.25).css(),
                                  datetime: '2020-10-24T00:00:00+10:00',
                                  value: '60.0mm',
                                },
                                {
                                  color: 'rgba(241,250,234,0.25)',
                                  datetime: '2020-10-25T00:00:00+10:00',
                                  value: '3.4mm',
                                },
                                {
                                  color: 'rgba(244,251,238,0.25)',
                                  datetime: '2020-10-26T00:00:00+10:00',
                                  value: '2.7mm',
                                },
                              ],
                              name: valueTypes.UPPER,
                            },
                          ],
                          name: 'Forecast',
                        },
                      ],
                      head: {
                        items: [
                          {
                            datetime: '2020-10-24T00:00:00+10:00',
                            value: '24 Oct 2020',
                          },
                          {
                            datetime: '2020-10-25T00:00:00+10:00',
                            value: '25 Oct 2020',
                          },
                          {
                            datetime: '2020-10-26T00:00:00+10:00',
                            value: '26 Oct 2020',
                          },
                        ],
                        name: 'Date',
                      },
                    },
                  }}
                />
              </WidgetWrapper>
            </div>
          </HelpComponent>
        )}
        text={(
          <p>
            An example table showing past forecasts next to observed values. The table cell
            background colour matches the legend for the property being shown.
            <br />
            The blue question mark circles can be clicked on to reveal more details about
            specific terms.
          </p>
        )}
      />
      <HelpSubSection
        cols={8}
        title="Summary widget"
        example={(
          <HelpComponent display="d-block">
            <div className="bg-white mb-n3">
              <WidgetWrapper
                title="An example summary"
                move={_.noop}
                setIsModalShowing={_.noop}
                remove={_.noop}
              >
                <Summary
                  {...{
                    dates: {
                      endDate: moment('2021-02-25T01:41:18.996Z'),
                      startDate: moment('2020-10-29T01:41:18.996Z'),
                    },
                    property: properties[propertyIds.RAIN],
                    stats: [
                      'There is a high chance of at least 2 wet days this week',
                      'There is a medium chance of at least 1 very wet day this week',
                    ],
                    timeInterval: {
                      label: 'Week',
                      prefix: 'Weekly',
                      value: 'week',
                    },
                  }}
                />
              </WidgetWrapper>
            </div>
          </HelpComponent>
        )}
        text={(
          <p>
            An example summary showing stats. The list of stats shown will appear when there is
            a significant event forecast for the selected property and time period.
          </p>
        )}
      />
      <HelpSubSection
        cols={8}
        title="Real time widget"
        example={(
          <HelpComponent display="d-block">
            <div className="bg-white mb-n3">
              <WidgetWrapper
                title="An example real time"
                move={_.noop}
                setIsModalShowing={_.noop}
                remove={_.noop}
              >
                <RealTimeValues
                  {...{
                    data: [
                      {
                        datetime: '2020-10-28T15:00:16+10:00',
                        observation: { [propertyIds.AIR_TEMPERATURE]: 35.23 },
                      },
                      {
                        datetime: '2020-10-29T00:03:36+10:00',
                        observation: { [propertyIds.AIR_TEMPERATURE]: 21.05 },
                      },
                      {
                        datetime: '2020-10-29T11:20:32+10:00',
                        observation: { [propertyIds.AIR_TEMPERATURE]: 33.52 },
                      },
                    ],
                    property: properties[propertyIds.AIR_TEMPERATURE],
                  }}
                />
              </WidgetWrapper>
            </div>
          </HelpComponent>
        )}
        text={(
          <p>
            An example real time value. Shows the min/max and current values for the past 24
            hours. The exception to showing min/max values is when rain is shown as it is an
            accumulative value over the past 24 hours.
          </p>
        )}
      />
      <HelpSubSection
        cols={8}
        title="Locations widget"
        example={(
          <HelpComponent display="d-block">
            <div className="bg-white mb-n3">
              <WidgetWrapper
                title="Example locations"
                move={_.noop}
                setIsModalShowing={_.noop}
                remove={_.noop}
              >
                <Suspense fallback={null}>
                  <LocationsMap
                    {...{
                      locationIds: {
                        realTime: 'bps:13745',
                        weather: '1',
                      },
                      locations: {
                        1: {
                          id: '1',
                          name: 'Giru/Barratta',
                          geometry: {
                            type: 'MultiPolygon',
                            coordinates: [
                              [
                                [
                                  [147.225, -19.525],
                                  [147.275, -19.525],
                                  [147.275, -19.475],
                                  [147.325, -19.475],
                                  [147.325, -19.525],
                                  [147.325, -19.575],
                                  [147.275, -19.575],
                                  [147.275, -19.625],
                                  [147.225, -19.625],
                                  [147.175, -19.625],
                                  [147.175, -19.675],
                                  [147.125, -19.675],
                                  [147.075, -19.675],
                                  [147.025, -19.675],
                                  [147.025, -19.625],
                                  [147.025, -19.575],
                                  [147.025, -19.525],
                                  [147.025, -19.475],
                                  [147.075, -19.475],
                                  [147.125, -19.475],
                                  [147.175, -19.475],
                                  [147.225, -19.475],
                                  [147.225, -19.525],
                                ],
                              ],
                            ],
                          },
                          type: 'DEFAULT',
                        },
                        2: {
                          id: '2',
                          name: 'Upper Haughton/Clare',
                          geometry: {
                            type: 'MultiPolygon',
                            coordinates: [
                              [
                                [
                                  [147.125, -19.925],
                                  [147.075, -19.925],
                                  [147.075, -19.875],
                                  [147.075, -19.825],
                                  [147.075, -19.775],
                                  [147.025, -19.775],
                                  [147.025, -19.725],
                                  [
                                    146.975,
                                    -19.725,
                                  ],
                                  [
                                    146.975,
                                    -19.675,
                                  ],
                                  [147.025, -19.675],
                                  [147.075, -19.675],
                                  [147.125, -19.675],
                                  [147.175, -19.675],
                                  [147.175, -19.725],
                                  [147.225, -19.725],
                                  [147.225, -19.775],
                                  [147.275, -19.775],
                                  [147.325, -19.775],
                                  [147.325, -19.825],
                                  [147.325, -19.875],
                                  [147.275, -19.875],
                                  [147.225, -19.875],
                                  [147.225, -19.925],
                                  [147.275, -19.925],
                                  [147.275, -19.975],
                                  [147.225, -19.975],
                                  [147.175, -19.975],
                                  [147.175, -19.925],
                                  [147.125, -19.925],
                                ],
                              ],
                            ],
                          },
                          type: 'DEFAULT',
                        },
                        4: {
                          id: '4',
                          name: 'Home Hill/Mona Park',
                          geometry: {
                            type: 'MultiPolygon',
                            coordinates: [
                              [
                                [
                                  [147.525, -19.775],
                                  [147.525, -19.825],
                                  [147.475, -19.825],
                                  [147.475, -19.775],
                                  [147.425, -19.775],
                                  [147.425, -19.825],
                                  [147.375, -19.825],
                                  [147.325, -19.825],
                                  [147.325, -19.775],
                                  [147.275, -19.775],
                                  [147.225, -19.775],
                                  [147.225, -19.725],
                                  [147.175, -19.725],
                                  [147.175, -19.675],
                                  [147.175, -19.625],
                                  [147.225, -19.625],
                                  [147.275, -19.625],
                                  [147.275, -19.575],
                                  [147.325, -19.575],
                                  [147.325, -19.625],
                                  [147.375, -19.625],
                                  [147.425, -19.625],
                                  [147.475, -19.625],
                                  [147.525, -19.625],
                                  [147.575, -19.625],
                                  [147.575, -19.675],
                                  [147.575, -19.725],
                                  [147.575, -19.775],
                                  [147.525, -19.775],
                                ],
                              ],
                            ],
                          },
                          type: 'DEFAULT',
                        },
                        5: {
                          id: '5',
                          name: 'Ayr/Brandon',
                          geometry: {
                            type: 'MultiPolygon',
                            coordinates: [
                              [
                                [
                                  [147.525, -19.575],
                                  [147.575, -19.575],
                                  [147.575, -19.625],
                                  [147.525, -19.625],
                                  [147.475, -19.625],
                                  [147.425, -19.625],
                                  [147.375, -19.625],
                                  [147.325, -19.625],
                                  [147.325, -19.575],
                                  [147.325, -19.525],
                                  [147.325, -19.475],
                                  [147.375, -19.475],
                                  [147.425, -19.475],
                                  [147.475, -19.475],
                                  [147.525, -19.475],
                                  [147.525, -19.525],
                                  [147.525, -19.575],
                                ],
                              ],
                            ],
                          },
                          type: 'DEFAULT',
                        },
                        'bps:13731': {
                          id: 'bps:13731',
                          name: 'BPS Shirbourne Weather Station',
                          geometry: {
                            type: 'Point',
                            coordinates: [
                              147.0961379,
                              -19.5570123,
                            ],
                          },
                          type: 'REAL_TIME',
                          source: 'BPS',
                        },
                        'bps:13733': {
                          id: 'bps:13733',
                          name: 'BPS Upper Haughton Weather Station',
                          geometry: {
                            type: 'Point',
                            coordinates: [
                              147.1631435,
                              -19.651098,
                            ],
                          },
                          type: 'REAL_TIME',
                          source: 'BPS',
                        },
                        'bps:13734': {
                          id: 'bps:13734',
                          name: 'BPS Stockham Road Weather Station',
                          geometry: {
                            type: 'Point',
                            coordinates: [
                              147.1065857,
                              -19.7386551,
                            ],
                          },
                          type: 'REAL_TIME',
                          source: 'BPS',
                        },
                        'bps:13735': {
                          id: 'bps:13735',
                          name: 'BPS Mona Park Weather Station',
                          geometry: {
                            type: 'Point',
                            coordinates: [
                              147.212953,
                              -19.7204274,
                            ],
                          },
                          type: 'REAL_TIME',
                          source: 'BPS',
                        },
                        'bps:13743': {
                          id: 'bps:13743',
                          name: 'BPS Millaroo Weather Station',
                          geometry: {
                            type: 'Point',
                            coordinates: [
                              147.2779504,
                              -20.0447029,
                            ],
                          },
                          type: 'REAL_TIME',
                          source: 'BPS',
                        },
                        'bps:13744': {
                          id: 'bps:13744',
                          name: 'BPS Jardine Weather Station',
                          geometry: {
                            type: 'Point',
                            coordinates: [
                              147.1748088,
                              -19.6178835,
                            ],
                          },
                          type: 'REAL_TIME',
                          source: 'BPS',
                        },
                        'bps:13745': {
                          id: 'bps:13745',
                          name: 'BPS Clare Weather Station',
                          geometry: {
                            type: 'Point',
                            coordinates: [
                              147.1723384,
                              -19.8020163,
                            ],
                          },
                          type: 'REAL_TIME',
                          source: 'BPS',
                        },
                      },
                    }}
                  />
                </Suspense>
              </WidgetWrapper>
            </div>
          </HelpComponent>
        )}
        text={(
          <p>
            An example locations map. This can be used to identify the selected region and station.
            The selection is shown in red whereas unselected shows in white.
          </p>
        )}
      />
      <HelpSubSection
        cols={8}
        title="Task statuses widget"
        example={(
          <HelpComponent display="d-block">
            <div className="bg-white mb-n3">
              <WidgetWrapper
                title="Example task statuses"
                move={_.noop}
                setIsModalShowing={_.noop}
                remove={_.noop}
              >
                <TaskStatuses
                  {...{
                    current: {
                      datetime: '2021-02-08T00:49:36+00:00',
                      properties: {
                        [propertyIds.AIR_TEMPERATURE]: 35.32,
                        [propertyIds.WIND_SPEED]: 5.34,
                        [propertyIds.WIND_DIRECTION]: 3,
                        [propertyIds.RELATIVE_HUMIDITY]: 63,
                        rainTrace: 0,
                        [propertyIds.RAIN_24_HOUR_TOTAL]: 0,
                      },
                    },
                    locationIds: { realTime: 'bps:13745', weather: '1' },
                    statuses: tasks[BURNING].statuses,
                    weatherForecasts: [
                      {
                        issued: moment().format(),
                        datetime: moment().add(1, 'day').format(),
                        timeInterval: 'day',
                        lead: 0,
                        properties: {
                          [propertyIds.MAX_TEMPERATURE]: 32.1,
                          cwu: 5,
                          rain: {
                            [valueTypes.AVERAGE]: 2.07,
                            [valueTypes.MID]: 5,
                            [valueTypes.UPPER]: 6.92,
                            [valueTypes.LOWER]: 5,
                          },
                          [propertyIds.AIR_TEMPERATURE]: 27.7,
                          [propertyIds.MIN_TEMPERATURE]: 23.6,
                          [propertyIds.RADIATION]: 19.1,
                          maxRh: 95.9,
                          [propertyIds.RELATIVE_HUMIDITY]: 75.1,
                          minRh: 53.7,
                          [propertyIds.RAIN_POP]: {
                            [valueTypes.RAIN_0MM]: 60,
                            [valueTypes.RAIN_5MM]: 50,
                            [valueTypes.RAIN_10MM]: 50,
                            [valueTypes.RAIN_15MM]: 30,
                            [valueTypes.RAIN_25MM]: 20,
                            [valueTypes.RAIN_50MM]: 10,
                          },
                          [propertyIds.WIND_DIRECTION]: 58.9,
                          [propertyIds.WIND_SPEED]: 14.7,
                        },
                        meta: null,
                      },
                    ],
                  }}
                />
              </WidgetWrapper>
            </div>
          </HelpComponent>
        )}
        text={(
          <p>
            Example burning task statuses. The different coloured icons have associated meanings.
            Green represents the condition is suitable, orange is acceptable, red is unsuitable and
            blue is info.
          </p>
        )}
      />
    </HelpSection>
  );
}
