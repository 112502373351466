import { faCloudSunRain } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment';
import ForecastOverviewItem from '../components/ForecastOverviewItem';
import * as dateTimeRangeIds from '../constants/dateTimeRangeIds';
import * as endpointIds from '../constants/endpointIds';
import { DEFAULT } from '../constants/locationTypeIds';
import * as constants from '../constants/pageIds';
import { PROPERTY_VIEW, OVERVIEW_VIEW } from '../constants/pageViews';
import * as propertyIds from '../constants/propertyIds';
import { DAY } from '../constants/timeIntervalIds';
import * as valueTypes from '../constants/valueTypes';
import dateTimeRanges from '../data/dateTimeRanges';
import endpoints, { weatherForecastEndpoints } from '../data/endpoints';
import phases from '../data/ensoPhases';
import properties from '../data/properties';
import * as text from '../data/text';
import timeIntervals from '../data/timeIntervals';
import DataPage from './DataPage';

export default class ForecastPage extends DataPage {
  static icon = faCloudSunRain;

  static id = constants.FORECASTS;

  static locationTypeId = DEFAULT;

  static initialState = {
    dateTimeRangeIds: {
      [PROPERTY_VIEW]: dateTimeRangeIds.NEXT_7_DAYS,
      [OVERVIEW_VIEW]: dateTimeRangeIds.NEXT_7_DAYS,
    },
    forecastEndpointId: endpointIds.FORECAST_WEATHER,
    dateTimeOffset: 0,
  };

  static forecastEndpointIds = Object.keys(weatherForecastEndpoints);

  static observationEndpointId = endpointIds.OBSERVATION_WEATHER;

  static locationsEndpointId = endpointIds.LOCATIONS;

  static label = 'Forecasts';

  static propertyIds = [
    propertyIds.RAIN,
    propertyIds.RAIN_POP,
    propertyIds.MIN_TEMPERATURE,
    propertyIds.MAX_TEMPERATURE,
    propertyIds.RADIATION,
    propertyIds.MIN_RH,
    propertyIds.MAX_RH,
    propertyIds.WIND_SPEED,
    propertyIds.DELTA_T,
    propertyIds.ETC,
  ];

  static dateTimeRangeIds = [
    dateTimeRangeIds.LAST_24_WEEKS,
    dateTimeRangeIds.NEXT_24_WEEKS,
    dateTimeRangeIds.NEXT_7_DAYS,
    dateTimeRangeIds.LAST_7_DAYS,
    dateTimeRangeIds.NEXT_48_HOURS,
    dateTimeRangeIds.LAST_48_HOURS,
  ];

  static sources = [
    endpointIds.LOCATIONS,
    endpointIds.OBSERVATION_WEATHER,
    endpointIds.FORECAST_WEATHER,
    endpointIds.ENSO_PHASE,
  ];

  static description = text.FORECAST_PAGE_DESCRIPTION;

  static getMapValue = (location, propertyId, mapDisplay, date, timeIntervalId) => {
    const timeInterval = timeIntervals[timeIntervalId];
    const data = {
      forecast: location.forecasts
        .filter((item) => item.timeInterval === timeIntervalId)
        .filter((item) => date.isSame(item.datetime, timeInterval.alignment))
        .map((item) => item.properties)
        .pop(),
      observation: location.observations
        .filter((item) => item.timeInterval === timeIntervalId)
        .filter((item) => date.isSame(item.datetime, timeInterval.alignment))
        .map((item) => item.properties)
        .pop(),
    };
    const property = properties[propertyId];
    const index = mapDisplay.indexOf('.');
    let value;
    const source = index > 0 ? mapDisplay.slice(0, index) : mapDisplay;
    if (index > 0) {
      value = _.get(property.getValue(data[source]), mapDisplay.slice(index + 1));
    } else {
      value = property.getValue(data[source]);
    }
    if (!_.isUndefined(value)) {
      return { ...data[source], [propertyId]: value };
    }
    return undefined;
  };

  getSelectedEndpointIssuedDate() {
    const all = [];
    if (this.state.forecasts[this.getPageState().forecastEndpointId]) {
      Object.values(this.state.forecasts[this.getPageState().forecastEndpointId])
        .forEach((location) => {
          const locationForecasts = location.filter((item) => _.isString(item.issued));
          locationForecasts.forEach((forecast) => all.push(forecast));
        });
    }
    const filtered = all.map((item) => moment(item.issued));
    return filtered.length ? moment.max(filtered) : false;
  }

  static getForecastEndpointIds() {
    return Object.keys(weatherForecastEndpoints);
  }

  getSelectedForecastEndpoint() {
    const selected = this.getPageState().forecastEndpointId;
    const selectedEndpointIds = this.constructor.getForecastEndpointIds();
    const selectedInEndpoints = selectedEndpointIds.indexOf(selected) >= 0;
    if (selectedInEndpoints) {
      return endpoints[selected];
    }
    return endpoints[endpointIds.FORECAST_WEATHER];
  }

  getDefaultLocation() {
    const locationId = _.get(this.state, 'auth.user.zone');
    if (!locationId) {
      return this.constructor.filterLocations(Object.values(this.state.locations))[0];
    }
    return this.state.locations[locationId];
  }

  getOverviewItemComponent = () => ForecastOverviewItem;

  getOverviewItems = (state, props) => {
    const { timeIntervalId } = dateTimeRanges[props.dateTimeRangeId];
    const timeInterval = timeIntervals[timeIntervalId];
    const { forecasts } = state;
    const location = state.locations[props.locationId];
    const { now } = props;
    const weatherForecasts = (forecasts[endpointIds.FORECAST_WEATHER][location.id] || [])
      .filter((forecast) => forecast.timeInterval === timeIntervalId)
      .filter((forecast) => now.isSameOrBefore(forecast.datetime, timeInterval.alignment));
    const items = weatherForecasts.map((forecast) => {
      const title = timeInterval.formatLong(forecast.datetime);
      const stats = [];
      if (timeInterval.showStats) {
        this.constructor.getProperties().forEach((property) => {
          property.generateStats(forecast).forEach((stat) => stats.push(stat));
        });
      }
      return {
        ...forecast,
        title,
        stats,
      };
    });
    return items;
  };

  static getForecastsDatesEndpoint() {
    return endpoints[endpointIds.WEATHER_FORECAST_ENDPOINT_DATES];
  }

  getOverviewProperties() {
    const dateTimeRange = this.getSelectedDateTimeRange(OVERVIEW_VIEW);
    const overviewProperties = [
      { id: propertyIds.RAIN, options: {} },
      { id: propertyIds.RAIN_POP, options: { key: valueTypes.RAIN_10MM } },
      { id: propertyIds.RAIN_POP, options: { key: valueTypes.RAIN_50MM } },
      { id: propertyIds.MIN_TEMPERATURE, options: {} },
      { id: propertyIds.MAX_TEMPERATURE, options: {} },
      { id: propertyIds.RADIATION, options: {} },
      { id: propertyIds.MIN_RH, options: {} },
      { id: propertyIds.MAX_RH, options: {} },
      { id: propertyIds.WIND_SPEED, options: {} },
      { id: propertyIds.DELTA_T, options: {} },
      { id: propertyIds.ETC, options: {} },
    ];
    return overviewProperties.filter(
      ({ id }) => properties[id].dateTimeRangeIds.indexOf(dateTimeRange.id) >= 0,
    );
  }

  getSelectedEndpointSourceInfo() {
    if (this.getSelectedDateTimeRange(OVERVIEW_VIEW).timeIntervalId === DAY) {
      return 'Daily forecasts of rainfall and temperature data are derived from the ACCESS-G ASP3 NWP model. Data are post processed for the Burdekin region using a custom downscaling technique.';
    }
    const phase = phases.find((item) => item.id === this.state.app.ensoPhase);
    return `Outlooks are derived from historical SILO data. The data are post processed for the Burdekin climate zones by selecting years from the last 40 year period that have the same ENSO phase for the previous 3 month period (currently ${phase.name}).`;
  }
}
