import PropTypes from 'prop-types';
import React from 'react';

export default function DateTimeRangeSelector({ options, value, set }) {
  return (
    <div className="flex-fill mx-1">
      <select
        className="form-select form-select-sm cssip-tour-date-time-range-selector"
        value={value}
        onChange={(event) => set(event.target.value, {
          action: 'Range', label: event.target.value,
        })}
        title="Time period"
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

DateTimeRangeSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  set: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
