import _ from 'lodash';
import React from 'react';
import { NEXT_14_WEEKS, NEXT_7_DAYS } from '../constants/dateTimeRangeIds';
import { FORECASTS } from '../constants/pageIds';
import * as valueTypes from '../constants/valueTypes';
import dateTimeRanges from '../data/dateTimeRanges';
import mapStyles from '../data/mapStyles';
import * as text from '../data/text';
import DateTimeOffsetSelector from './DateTimeOffsetSelector';
import DateTimeRangeSelector from './DateTimeRangeSelector';
import HelpComponent from './HelpComponent';
import HelpSection from './HelpSection';
import HelpSubSection from './HelpSubSection';
import Location from './Location';
import MapDisplaySelector from './MapDisplaySelector';
import MapParticlesToggle from './MapParticlesToggle';
import MapStyleSelector from './MapStyleSelector';

export default function HelpControls() {
  return (
    <HelpSection title="Controls">
      <p className="lead mb-4">
        Determining what is displayed on each data page can be achieved using the controls on
        those pages. There are options of what property to show as well as what period to look
        at.
      </p>
      <HelpSubSection
        title={text.DATE_TIME_RANGE_SELECTOR_LABEL}
        example={(
          <HelpComponent>
            <DateTimeRangeSelector
              options={[dateTimeRanges[NEXT_7_DAYS], dateTimeRanges[NEXT_14_WEEKS]]}
              value={NEXT_7_DAYS}
              set={_.noop}
            />
          </HelpComponent>
        )}
        text={text.DATE_TIME_RANGE_SELECTOR_HELP}
      />
      <HelpSubSection
        title={text.DATE_TIME_OFFSET_SELECTOR_LABEL}
        example={(
          <>
            <HelpComponent>
              <div style={{ width: 200 }}>
                <DateTimeOffsetSelector
                  isPlaying={false}
                  dateTimeRange={dateTimeRanges[NEXT_7_DAYS]}
                  value={4}
                  setIsPlaying={_.noop}
                  setDateTime={_.noop}
                />
              </div>
            </HelpComponent>
            <br />
            <HelpComponent>
              <div style={{ width: 200 }}>
                <DateTimeOffsetSelector
                  isPlaying
                  dateTimeRange={dateTimeRanges[NEXT_14_WEEKS]}
                  value={15}
                  setIsPlaying={_.noop}
                  setDateTime={_.noop}
                />
              </div>
            </HelpComponent>
          </>
        )}
        text={text.DATE_TIME_OFFSET_SELECTOR_HELP}
      />
      <HelpSubSection
        title={text.MAP_STYLE_SELECTOR_LABEL}
        example={(
          <HelpComponent>
            <MapStyleSelector setStyle={_.noop} styleName={mapStyles[0].name} />
          </HelpComponent>
        )}
        text={text.MAP_STYLE_SELECTOR_HELP}
      />
      <HelpSubSection
        title={text.MAP_PROPERTY_SELECTOR_LABEL}
        example={(
          <HelpComponent>
            <div style={{ width: 80 }}>
              <MapDisplaySelector
                pageId={FORECASTS}
                value={`forecast.${valueTypes.MID}`}
                options={[
                  { label: valueTypes.UPPER, value: `forecast.${valueTypes.UPPER}` },
                  { label: valueTypes.MID, value: `forecast.${valueTypes.MID}` },
                  { label: valueTypes.LOWER, value: `forecast.${valueTypes.LOWER}` },
                ]}
                set={_.noop}
              />
            </div>
          </HelpComponent>
        )}
        text={text.MAP_PROPERTY_SELECTOR_HELP}
      />
      <HelpSubSection
        title={text.LOCATION_BUTTON_LABEL}
        example={(
          <HelpComponent>
            <Location
              setCurrentLocation={_.noop}
              currentLocation={false}
            />
          </HelpComponent>
        )}
        text={text.LOCATION_BUTTON_HELP}
      />
      <HelpSubSection
        title={text.MAP_PARTICLES_BUTTON_LABEL}
        example={(
          <HelpComponent>
            <MapParticlesToggle
              hasParticles
              setShowParticles={_.noop}
              showParticles={false}
            />
          </HelpComponent>
        )}
        text={text.MAP_PARTICLES_BUTTON_HELP}
      />
    </HelpSection>
  );
}
