export const UPPER_FORECAST_GREATER_THAN = 'UPPER_FORECAST_GREATER_THAN';
export const MID_FORECAST_GREATER_THAN = 'MID_FORECAST_GREATER_THAN';
export const LOWER_FORECAST_GREATER_THAN = 'LOWER_FORECAST_GREATER_THAN';
export const UPPER_FORECAST_LESS_THAN = 'UPPER_FORECAST_LESS_THAN';
export const MID_FORECAST_LESS_THAN = 'MID_FORECAST_LESS_THAN';
export const LOWER_FORECAST_LESS_THAN = 'LOWER_FORECAST_LESS_THAN';
export const FORECAST_GREATER_THAN = 'FORECAST_GREATER_THAN';
export const FORECAST_LESS_THAN = 'FORECAST_LESS_THAN';
export const FORECAST_GREATER_THAN_0MM = 'FORECAST_GREATER_THAN_0MM';
export const FORECAST_GREATER_THAN_5MM = 'FORECAST_GREATER_THAN_5MM';
export const FORECAST_GREATER_THAN_10MM = 'FORECAST_GREATER_THAN_10MM';
export const FORECAST_GREATER_THAN_15MM = 'FORECAST_GREATER_THAN_15MM';
export const FORECAST_GREATER_THAN_25MM = 'FORECAST_GREATER_THAN_25MM';
export const FORECAST_GREATER_THAN_50MM = 'FORECAST_GREATER_THAN_50MM';
export const FORECAST_LESS_THAN_0MM = 'FORECAST_LESS_THAN_0MM';
export const FORECAST_LESS_THAN_N10MM = 'FORECAST_LESS_THAN_N10MM';
export const FORECAST_LESS_THAN_N20MM = 'FORECAST_LESS_THAN_N20MM';
export const FORECAST_LESS_THAN_N30MM = 'FORECAST_LESS_THAN_N30MM';
export const FORECAST_LESS_THAN_N40MM = 'FORECAST_LESS_THAN_N40MM';
export const FORECAST_LESS_THAN_N50MM = 'FORECAST_LESS_THAN_N50MM';
export const FORECAST_LESS_THAN_N60MM = 'FORECAST_LESS_THAN_N60MM';
