import { icon } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamation, faTimes, faArrowUp, faCircle, faMapMarkerAlt, faCrosshairs, faMapMarker,
} from '@fortawesome/free-solid-svg-icons';

function createSVGIcon(faIcon) {
  const svg = icon(faIcon).html[0];
  return svg.replace('<svg', '<svg width="128" height="128"');
}

function svgToDataURL(svg) {
  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

export const exclamation = svgToDataURL(createSVGIcon(faExclamation));
export const times = svgToDataURL(createSVGIcon(faTimes));
export const arrowUp = svgToDataURL(createSVGIcon(faArrowUp));
export const circle = svgToDataURL(createSVGIcon(faCircle));
export const mapMarker = svgToDataURL(createSVGIcon(faMapMarker));
export const mapMarkerAlt = svgToDataURL(createSVGIcon(faMapMarkerAlt));
export const crosshairs = svgToDataURL(createSVGIcon(faCrosshairs));
