import burning from '../tasks/burning';
import fertilising from '../tasks/fertilising';
import harvesting from '../tasks/harvesting';
import planting from '../tasks/planting';
import spraying from '../tasks/spraying';

const tasks = {
  [spraying.id]: spraying,
  [burning.id]: burning,
  [planting.id]: planting,
  [fertilising.id]: fertilising,
  [harvesting.id]: harvesting,
};

export default tasks;
