import * as types from '../constants/actionTypes';
import { NO_IRRIGATION } from '../constants/irrigationTypeIds';
import irrigationTypes from '../data/irrigationTypes';

const initialState = {
  isPlaying: false,
  irrigationTypeId: NO_IRRIGATION, // @TODO: This could be in a 'demo-farm' state.
  ensoPhase: '',
};

export default function app(state = initialState, action = {}) {
  switch (action.type) {
    case types.IS_PLAYING_CHANGED: {
      return { ...state, isPlaying: action.isPlaying };
    }

    case types.APP_IRRIGATION_TYPE_CHANGED: {
      const { irrigationTypeId } = action;
      if (!(irrigationTypeId in irrigationTypes)) {
        return state;
      }
      return {
        ...state,
        irrigationTypeId,
      };
    }

    case types.ENDPOINT_RECEIVED: {
      if (action.ensoPhase) {
        return {
          ...state,
          ensoPhase: action.ensoPhase,
        };
      }
      return state;
    }

    default: {
      return state;
    }
  }
}
