import {
  faCheckCircle, faMinusCircle, faQuestionCircle, faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Info from './Info';

const icons = {
  check: { color: 'success', name: faCheckCircle },
  minus: { color: 'warning', name: faMinusCircle },
  question: { color: 'info', name: faQuestionCircle },
  times: { color: 'danger', name: faTimesCircle },
};

export default function TaskStatuses({ current, statuses, weatherForecasts }) {
  return (
    <div className="d-flex flex-column align-items-center">
      <ul className="list-unstyled">
        {statuses.map((item) => {
          const status = item.getStatus(current, weatherForecasts);
          const icon = icons[status.icon];
          return (
            <li key={item.name}>
              <FontAwesomeIcon className={`text-${icon.color} me-2`} icon={icon.name} />
              {status.message}
              <Info info={item.info} />
            </li>
          );
        })}
      </ul>
      <p className="small px-md-5">
        <i>
          We cannot guarantee the accuracy, currency or completeness of the information available on
          Opticane.
          The information available on Opticane
          is not a substitute for independent professional advice, and only applies to the indicated
          location as atmospheric conditions may vary across short distances. You should obtain
          specific professional advice or conduct your own inquiries relevant to your particular
          circumstances.
        </i>
      </p>
    </div>
  );
}

TaskStatuses.propTypes = {
  current: PropTypes.shape().isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({
    getStatus: PropTypes.func.isRequired,
    info: PropTypes.shape().isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  weatherForecasts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
