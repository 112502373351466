import { generateLegend } from '../lib/utils';

const waterLegendPairs = [
  { color: '#ffffff', value: 0 },
  { color: '#ffffcc', value: 2 },
  { color: '#a1dab4', value: 20 },
  { color: '#41b6c4', value: 45 },
  { color: '#2c7fb8', value: 70 },
  { color: '#253494', value: 95 },
  { color: '#010a42', value: 120 },
];
export const waterLegend = generateLegend(
  waterLegendPairs.map((item) => item.color),
  waterLegendPairs.map((item) => item.value),
  13,
);

export const probabilityLegend = generateLegend('Spectral', [0, 100], 11);

export const popLegend = generateLegend(['#ffffff', '#253494'], [0, 100], 11);

export const temperatureLegend = generateLegend('Spectral', [40, 0], 11);

export const windLegend = generateLegend('Spectral', [0, 90]);

export const deltaTLegend = generateLegend('Spectral', [0, 12]);

export const radiationLegendCreator = (range) => generateLegend('Spectral', range, 11);

export const etLegendCreator = (range) => generateLegend('Spectral', range);

export const swdLegendCreator = (range) => generateLegend('Spectral', range, 11);

export const irrigationLegend = generateLegend(['red', 'yellow', 'green'], [0, 7], 8);
