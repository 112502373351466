import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

export default function AlarmAddButton({ onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="btn btn-outline-primary"
    >
      Add alarm
      {' '}
      <FontAwesomeIcon icon={faBell} />
    </button>
  );
}

AlarmAddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
