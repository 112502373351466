import * as constants from '../constants/mapStyles';

const styles = [
  {
    name: constants.SATELLITE,
    uri: `${process.env.PUBLIC_URL}/map-styles/satellite.json`,
  },
  {
    name: constants.STREET,
    uri: `${process.env.PUBLIC_URL}/map-styles/streets.json`,
  },
  {
    name: constants.DARK,
    uri: `${process.env.PUBLIC_URL}/map-styles/dark.json`,
  },
  {
    name: constants.LIGHT,
    uri: `${process.env.PUBLIC_URL}/map-styles/light.json`,
  },
  {
    name: constants.OUTDOORS,
    uri: `${process.env.PUBLIC_URL}/map-styles/outdoors.json`,
  },
];

export default styles;
