import moment from 'moment';
import { setIrrigationType, setPageViewDateTimeRange } from '../actions';
import { NEXT_7_DAYS } from '../constants/dateTimeRangeIds';
import { DRIP, FURROW, NO_IRRIGATION } from '../constants/irrigationTypeIds';
import { DEMO_FARM } from '../constants/pageIds';
import { OVERVIEW_VIEW } from '../constants/pageViews';
import * as paths from '../constants/paths';
import * as propertyIds from '../constants/propertyIds';
import { DAY } from '../constants/timeIntervalIds';
import store from '../lib/store';
import * as text from './text';

const dailyOverviewStep = {
  targetClass: `cssip-tour-farm-overview-item-${DAY}`,
  content: 'Overview of forecast data for a particular day',
  title: 'Daily farm overview',
};

const farmOverviewView = [
  {
    targetClass: 'cssip-tour-map-style-selector',
    content: text.MAP_STYLE_SELECTOR_HELP,
    title: text.MAP_STYLE_SELECTOR_LABEL,
  },
  {
    targetClass: 'cssip-tour-location',
    content: text.LOCATION_BUTTON_HELP,
    title: text.LOCATION_BUTTON_LABEL,
  },
];

const demoFarmBlock1View = [
  {
    targetClass: 'cssip-tour-location-name',
    content: 'This tutorial covers demo block 1. Demo block 1 represents a young crop that does not require any immediate irrigation. As there is no forecast irrigation we recommend using the Soil Water Deficit view option. For demonstration purposes we show only a partial forecast. ',
    title: 'Tutorial: Demo Block 1',
    onBefore: () => {
      store.dispatch(setPageViewDateTimeRange(DEMO_FARM, OVERVIEW_VIEW, NEXT_7_DAYS));
      store.dispatch(setIrrigationType(NO_IRRIGATION));
    },
  },
  dailyOverviewStep,
  {
    targetClass: `cssip-tour-overview-property-${propertyIds.ET}-${moment().format('YYYY-MM-DD')}-${DAY}`,
    content: 'CWU shows the median forecast CWU for the day. Because Demo Farm 1 is a young crop CWU on a daily time step is quite low.',
    title: 'Todays CWU for Demo Block 1',
  },
  {
    targetClass: `cssip-tour-overview-property-${propertyIds.RAIN_POP}-${moment().add(5, 'days').format('YYYY-MM-DD')}-${DAY}`,
    content: 'The chance of above 10mm of rainfall is shown for each day. These can be useful in deciding to delay an irrigation event if multiple days after the forecast irrigation event have a high chance of recieving more than 10mm.',
    title: 'End of forecast period rainfall probability forecasts for Demo Block 1',
    onBefore: () => store.dispatch(
      setPageViewDateTimeRange(DEMO_FARM, OVERVIEW_VIEW, NEXT_7_DAYS),
    ),
  },
  {
    targetClass: 'cssip-tour-location-name',
    content: 'This demo block was designed to represent a crop that did not require any irrigation. Based on the forecasts the young crop seems unlikely to require an irrigation event over the next four weeks as there is low CWU and the SWD never drops very low. Irrigation event and rainfall probability forecasts were not informative in this demo. To explore how these properties can be used in decision making try the tutorial for block 2 Mature Crop: Late week irrigation',
    title: 'Summary of Demo Block 1',
  },
];

const demoFarmBlock2View = [
  {
    targetClass: 'cssip-tour-location-name',
    content: 'This tutorial covers demo block 2. Demo block 2 represents a mature crop that requires a single irrigation a week. As there is only a single forecast irrigation event we recommend using the Single Irrigation view option. For demonstration purposes we show only a partial forecast. ',
    title: 'Tutorial: Demo block 2',
    onBefore: () => {
      store.dispatch(setPageViewDateTimeRange(DEMO_FARM, OVERVIEW_VIEW, NEXT_7_DAYS));
      store.dispatch(setIrrigationType(FURROW));
    },
  },
  dailyOverviewStep,
  {
    targetClass: `cssip-tour-overview-property-${propertyIds.ET}-${moment().format('YYYY-MM-DD')}-${DAY}`,
    content: 'CWU shows the median forecast CWU for the day. Because Demo Block is a mature unstressed crop, CWU remains consistant at around 5mm (median forecast) per day.',
    title: 'Todays CWU for Demo Block 2',
  },
  {
    targetClass: `cssip-tour-overview-property-${propertyIds.FIRST_IRRIG_POP}-${moment().add(4, 'days').format('YYYY-MM-DD')}-${DAY}`,
    content: `The probability of the next irrigation shows the chance that the next irrigation will occur on the given date. In this example there is a 25% chance of needing to irrigate on ${moment().add(4, 'days').format('dddd')} and a 4% chance on ${moment().add(5, 'days').format('dddd')}. This suggests that if an irrigation is required, it is more likely to be required by ${moment().add(4, 'days').format('dddd')} than later in the week. For the short range forecast these results suggest there is only around a 1-in-4 chance of needing to irrigate at all.`,
    title: 'Forecast irrigation event for Demo Block 2',
  },
  {
    targetClass: `cssip-tour-overview-property-${propertyIds.RAIN_POP}-${moment().add(4, 'days').format('YYYY-MM-DD')}-${DAY}`,
    content: `The irrigation event was most likely to occur on ${moment().add(4, 'days').format('dddd')}. These simulations make use of the forecast weather data but do not consider weather it will rain after the irrigation is applied. By considering the probability of rainfall on and after the irrigation date we may decide that the irrigation isn't necessary. In this example there is a less than 12% chance of more than 10mm on each day after the irrigation event. This isn't a strong indicator that we'll be over irrigating if the irrigation goes ahead.`,
    title: 'Forecast rainfall probability for Demo Block 2',
    onBefore: () => store.dispatch(
      setPageViewDateTimeRange(DEMO_FARM, OVERVIEW_VIEW, NEXT_7_DAYS),
    ),
  },
  {
    targetClass: 'cssip-tour-location-name',
    content: 'This demo block was designed to represent a crop that had a chance of requiring an irrigation event in the coming week. Based on the forecasts there is a forecast irrigation event late in the week. However, the probability of the irrigation being required is still relatively low (less than 50%). As the forecast irrigation date is not until nearer the end of the week, it would be a good idea to monitor the situation and see how conditions progress into the week. As forecasts are updated daily, updates closer to the forecast irrigation date may change.',
    title: 'Summary of Demo Block 2',
  },
];

const demoFarmBlock3View = [
  {
    targetClass: 'cssip-tour-location-name',
    content: 'This tutorial covers demo block 3. Demo block 3 represents a mature crop that requires multiple irrigations a week. Specifically, this block is irrigated at 10mm per day when SWD drops below -30mm. We recommend using the Multiple Irrigations view option for this block. For demonstration purposes we show only a partial forecast.',
    title: 'Tutorial: Demo Block 3',
    onBefore: () => {
      store.dispatch(setPageViewDateTimeRange(DEMO_FARM, OVERVIEW_VIEW, NEXT_7_DAYS));
      store.dispatch(setIrrigationType(DRIP));
    },
  },
  dailyOverviewStep,
  {
    targetClass: `cssip-tour-overview-property-${propertyIds.ET}-${moment().format('YYYY-MM-DD')}-${DAY}`,
    content: 'CWU shows the median forecast CWU for the day. Because Demo Farm 3 is a young crop CWU is a mature crop the CWU each day is reasonable at just under 5mm per day.',
    title: 'Todays CWU for Demo Block 3',
  },
  {
    targetClass: `cssip-tour-overview-property-${propertyIds.FIRST_IRRIG_POP}-${moment().add(2, 'days').format('YYYY-MM-DD')}-${DAY}`,
    content: `The chance of next irrigation gives the probability that the next irrigation event will be required on each day. The probability of the next irrigation event is highest for ${moment().add(2, 'days').format('dddd')}. At 45% there is a relatively good chance that this irrigation event will be needed.`,
    title: 'Irrigation event forecast for Demo Block 3',
  },
  {
    targetClass: `cssip-tour-overview-property-${propertyIds.RAIN_POP}-${moment().add(4, 'days').format('YYYY-MM-DD')}-${DAY}`,
    content: `The irrigation event was most likely to occur on ${moment().add(2, 'days').format('dddd')}. These simulations make use of the forecast weather data but do not consider weather it will rain after the irrigation is applied. By considering the probability of rainfall on and after the irrigation date we may decide that the irrigation isn't necessary. In this example there is a less than 12% chance of more than 10mm on each day after the irrigation event. This isn't a strong indicator that we'll be over irrigating if the irrigation goes ahead.`,
    title: 'Forecast rainfall probability for Demo Block 3',
    onBefore: () => store.dispatch(
      setPageViewDateTimeRange(DEMO_FARM, OVERVIEW_VIEW, NEXT_7_DAYS),
    ),
  },
  {
    targetClass: 'cssip-tour-location-name',
    content: 'This demo block was designed to represent a crop that applied irrigation across multiple days. Based on the forecasts there is a forecast requirement for irrigation each week and a very high chance of needing to irrigate within the next week. Given the high probability of needing to irrigate, the need to set up irrigation across multiple days and the next irrigation event forecast for within 2 to 3 days, we would probably consider scheduling this irrigation in.',
    title: 'Summary of  Demo Block 3',
  },
];

const tour = {
  [paths.DEMO_FARM_OVERVIEW]: farmOverviewView,
  [`${paths.DEMO_FARM_OVERVIEW}demoFarm-01/`]: demoFarmBlock1View,
  [`${paths.DEMO_FARM_OVERVIEW}demoFarm-02/`]: demoFarmBlock2View,
  [`${paths.DEMO_FARM_OVERVIEW}demoFarm-03/`]: demoFarmBlock3View,
};

export default tour;
