export const LOWER = 'lower';
export const MID = 'mid';
export const UPPER = 'upper';
export const RAIN_0MM = '0mm';
export const RAIN_5MM = '5mm';
export const RAIN_10MM = '10mm';
export const RAIN_15MM = '15mm';
export const RAIN_25MM = '25mm';
export const RAIN_50MM = '50mm';
export const SWD_0 = '0mm';
export const SWD_N10 = '-10mm';
export const SWD_N20 = '-20mm';
export const SWD_N30 = '-30mm';
export const SWD_N40 = '-40mm';
export const SWD_N50 = '-50mm';
export const SWD_N60 = '-60mm';
export const AVERAGE = 'average';
