import HelpAccount from '../components/HelpAccount';
import HelpAlarms from '../components/HelpAlarms';
import HelpControls from '../components/HelpControls';
import HelpCurrentConditions from '../components/HelpCurrentConditions';
import HelpDashboard from '../components/HelpDashboard';
import HelpDataRepresentation from '../components/HelpDataRepresentation';
import HelpForecasts from '../components/HelpForecasts';
import HelpMyFarm from '../components/HelpMyFarm';
import HelpTasksPage from '../components/HelpTasksPage';
import HelpTools from '../components/HelpTools';
import * as ids from '../constants/helpSectionIds';

const sections = {
  [ids.CURRENT_CONDITIONS]: {
    name: 'Current conditions page',
    id: ids.CURRENT_CONDITIONS,
    component: HelpCurrentConditions,
  },
  [ids.FORECASTS]: {
    name: 'Forecasts page',
    id: ids.FORECASTS,
    component: HelpForecasts,
  },
  [ids.TASKS_PAGE]: {
    name: 'Tasks page',
    id: ids.TASKS_PAGE,
    component: HelpTasksPage,
  },
  [ids.TOOLS]: {
    name: 'Tools page',
    id: ids.TOOLS,
    component: HelpTools,
  },
  [ids.CONTROLS]: {
    name: 'Controls',
    id: ids.CONTROLS,
    component: HelpControls,
  },
  [ids.DATA_REPRESENTATION]: {
    name: 'Data representation',
    id: ids.DATA_REPRESENTATION,
    component: HelpDataRepresentation,
  },
  [ids.DASHBOARD]: {
    name: 'Dashboard',
    id: ids.DASHBOARD,
    component: HelpDashboard,
  },
  [ids.MY_FARM]: {
    name: 'My farm page',
    id: ids.MY_FARM,
    component: HelpMyFarm,
  },
  [ids.ALARMS]: {
    name: 'Alarms',
    id: ids.ALARMS,
    component: HelpAlarms,
  },
  [ids.ACCOUNT]: {
    name: 'Account settings',
    id: ids.ACCOUNT,
    component: HelpAccount,
  },
};

export default sections;
