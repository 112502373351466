import _ from 'lodash';
import * as dateTimeRangeIds from '../constants/dateTimeRangeIds';
import * as endpointIds from '../constants/endpointIds';
import { MY_FARM } from '../constants/locationTypeIds';
import * as constants from '../constants/pageIds';
import { PROPERTY_VIEW, OVERVIEW_VIEW } from '../constants/pageViews';
import { myFarmForecastEndpoints } from '../data/endpoints';
import FarmPage from './FarmPage';

export default class MyFarmPage extends FarmPage {
  static id = constants.MY_FARM;

  static locationTypeId = MY_FARM;

  static initialState = {
    dateTimeRangeIds: {
      [PROPERTY_VIEW]: dateTimeRangeIds.NEXT_7_DAYS,
      [OVERVIEW_VIEW]: dateTimeRangeIds.NEXT_7_DAYS,
    },
    forecastEndpointId: endpointIds.FORECAST_MY_FARM,
    dateTimeOffset: 0,
  };

  static forecastEndpointIds = Object.keys(myFarmForecastEndpoints);

  static observationEndpointId = endpointIds.OBSERVATION_MY_FARM;

  static forecastsDatesEndpointId = endpointIds.MY_FARM_FORECAST_ENDPOINT_DATES;

  static locationsEndpointId = endpointIds.LOCATIONS_MY_FARM;

  static label = 'My Farm';

  static requiresAuth = true;

  static sources = [
    endpointIds.LOCATIONS_MY_FARM, endpointIds.OBSERVATION_MY_FARM, endpointIds.FORECAST_MY_FARM,
  ];

  getIrrigationTypeId = () => _.get(this.state, 'auth.user.irrigationType');

  generateHead(pathname) {
    const breadcrumbs = this.generateBreadcrumbs(pathname);
    return {
      title: breadcrumbs.slice(1).map((item) => item.name).reverse(),
      meta: [
        { name: 'description', content: this.constructor.description },
        { name: 'robots', content: 'noindex' },
      ],
    };
  }
}
