import * as helpSectionIds from './helpSectionIds';
import * as pageIds from './pageIds';
import { dataPageIds, breadcrumbPageIds } from './pageIds';
import * as propertyIds from './propertyIds';
import * as taskIds from './taskIds';
import * as urlIds from './urls';

export const ROOT = '/';
export const PAGE = '/:pageId/';
export const ABOUT = `/${pageIds.ABOUT}/`;
export const ADMIN = `/${pageIds.ADMIN}/`;
export const AUTH = `/${pageIds.AUTH}/`;
export const ACCOUNT = `/${pageIds.SETTINGS}/`;
export const ALARMS = `/${pageIds.ALARMS}/`;
export const CONTACT = `/${pageIds.CONTACT}/`;
export const DASHBOARD = `/${pageIds.DASHBOARD}/`;
export const FISHING = `/${pageIds.FISHING}/`;
export const HELP = `/${pageIds.HELP}/`;
export const HOME = `/${pageIds.HOME}/`;
export const ERROR = `/${pageIds.ERROR}/`;
export const ENSO = `/${pageIds.ENSO}/`;
export const INSTALLING = `/${pageIds.INSTALLING}/`;
export const REMOTE_SENSING = `/${pageIds.REMOTE_SENSING}/`;
export const IRRIGATION = `/${pageIds.IRRIGATION}/`;
export const SEASONAL = `/${pageIds.SEASONAL}/`;

export const termsOfUseSectionIdString = ':sectionId(data-attribution)?';
export const TERMS_OF_USE = `/${pageIds.TERMS_OF_USE}/`;
export const TERMS_OF_USE_SECTION_OPTIONAL = `${TERMS_OF_USE}${termsOfUseSectionIdString}/`;

export const helpSectionIdString = `:sectionId(${Object.values(helpSectionIds).join('|')})`;
export const HELP_SECTION = `${HELP}${helpSectionIdString}/`;

export const farmPageIdString = `:pageId(${[pageIds.MY_FARM, pageIds.DEMO_FARM].join('|')})`;
export const forecastPageIdString = `:pageId(${[pageIds.FORECASTS, pageIds.MY_FARM, pageIds.DEMO_FARM, pageIds.CWU].join('|')})`;
export const locationIdString = ':locationId';
const locationIdOptionalString = `${locationIdString}?`;
export const taskIdString = `:taskId(${Object.values(taskIds).join('|')})`;
export const pageIdString = ':pageId';
export const dataPageIdString = `:pageId(${Object.values(dataPageIds).join('|')})`;
export const propertyIdString = `:propertyId(${Object.values(propertyIds).join('|')})`;

export const TASKS = '/tasks/';
export const TASK_PARAMS_OPTIONAL = `/tasks/${taskIdString}/:weatherLocationId?/:realTimeLocationId?/`;
export const TASKS_PARAMS_NO_REAL_TIME_LOCATION = `/tasks/${taskIdString}/:weatherLocationId?/`;
export const DATA = `/${dataPageIdString}/`;
export const FORECASTS = `/:pageId(${pageIds.FORECASTS})/`;
export const FORECASTS_OVERVIEW_ITEM = `${FORECASTS}${locationIdString}/`;
export const CWU = `/:pageId(${pageIds.CWU})/`;
export const CWU_PROPERTY_ITEM = `${CWU}${locationIdString}/${propertyIdString}/`;
export const FARM = `/${farmPageIdString}/`;
export const FARM_OVERVIEW_ITEM = `/${farmPageIdString}/${locationIdString}/`;
export const MY_FARM = `/:pageId(${pageIds.MY_FARM})/`;
export const DEMO_FARM = `/:pageId(${pageIds.DEMO_FARM})/`;
export const DEMO_FARM_OVERVIEW = `/:pageId(${pageIds.DEMO_FARM})/`;
export const PROPERTY = `${DATA}${propertyIdString}/`;
export const PROPERTY_ITEM = `${DATA}${locationIdString}/${propertyIdString}/`;
export const PROPERTY_ITEM_OPTIONAL = `${DATA}${locationIdOptionalString}/${propertyIdString}/`;
export const OVERVIEW_ITEM = `${DATA}${locationIdString}/`;
export const OVERVIEW_ITEM_OPTIONAL = `${DATA}${locationIdOptionalString}/`;
export const FORECAST_PROPERTY = `/${forecastPageIdString}/${locationIdOptionalString}/${propertyIdString}/`;
export const REAL_TIME = `/:pageId(${pageIds.REAL_TIME})/`;
export const REAL_TIME_PROPERTY_ITEM = `/${pageIds.REAL_TIME}/${locationIdString}/${propertyIdString}/`;

export const IRRIGWEB_HOME = `${urlIds.IRRIGWEB_URL}/Home`;
export const IRRIGWEB_LOGIN = `${urlIds.IRRIGWEB_URL}/User/Login`;

export const breadcrumbPaths = `/:pageId(${Object.values(breadcrumbPageIds).join('|')})`;
