import './Navbar.scss';
import { faBars, faInfo, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import BootstrapNavbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {
  Link, NavLink, Route, useRouteMatch,
} from 'react-router-dom';
import * as paths from '../constants/paths';
import NavbarAccountDropdown from '../containers/NavbarAccountDropdown';
import tools from '../data/tools';
import { faOpticane } from '../lib/customIcons';
import DemoFarmPage from '../pages/DemoFarmPage';
import ForecastPage from '../pages/ForecastPage';
import IrrigationPage from '../pages/IrrigationPage';
import RealTimePage from '../pages/RealTimePage';
import TasksPage from '../pages/TasksPage';
import NavbarSignIn from './NavbarSignIn';

export default function Navbar({ isAuthed }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const collapse = () => setIsExpanded(false);
  const [isShowingInfo, setIsShowingInfo] = useState(false);
  const [isShowingAccount, setIsShowingAccount] = useState(false);
  const [isShowingTools, setIsShowingTools] = useState(false);
  const onInfoLinkClick = () => {
    setIsShowingInfo(false);
    collapse();
  };
  const onAccountLinkClick = () => {
    setIsShowingAccount(false);
    collapse();
  };
  const onToolsLinkClick = () => {
    setIsShowingTools(false);
    collapse();
  };
  const pageId = _.get(useRouteMatch(paths.DATA), 'params.pageId');
  const toolsPaths = tools.filter((tool) => (
    tool.includeInUnauthed || isAuthed
  )).map((tool) => tool.path);
  return (
    <BootstrapNavbar
      expanded={isExpanded}
      className="py-1 d-print-none bg-primary"
      variant="dark"
      expand="md"
      onToggle={() => setIsExpanded(!isExpanded)}
      fixed="top"
    >
      <div className="cssip-navbar__container container-fluid">
        <BootstrapNavbar.Brand className="cssip-navbar__brand me-0">
          <Link to={paths.HOME} onClick={collapse} className="text-white ps-2">
            <FontAwesomeIcon icon={faOpticane} />
            <span className="sr-only">Home</span>
          </Link>
        </BootstrapNavbar.Brand>
        <div className="d-md-none d-flex flex-grow-1 justify-content-evenly">
          <NavLink
            onClick={collapse}
            to={IrrigationPage.getPath({ isAuthed })}
            className="nav-link text-white"
            activeClassName="border-bottom border-secondary"
          >
            <FontAwesomeIcon icon={IrrigationPage.icon} size="sm" />
          </NavLink>
          <NavLink
            onClick={collapse}
            to={RealTimePage.getOverviewPath()}
            className="nav-link text-white"
            activeClassName="border-bottom border-secondary"
            isActive={() => pageId === RealTimePage.id}
          >
            <FontAwesomeIcon icon={RealTimePage.icon} size="sm" />
          </NavLink>
          <NavLink
            onClick={collapse}
            to={ForecastPage.getOverviewPath()}
            className="nav-link text-white"
            activeClassName="border-bottom border-secondary"
            isActive={() => pageId === ForecastPage.id}
          >
            <FontAwesomeIcon icon={ForecastPage.icon} size="sm" />
          </NavLink>
        </div>
        <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav">
          MENU
          <FontAwesomeIcon icon={faBars} className="ms-2" />
        </BootstrapNavbar.Toggle>
        <BootstrapNavbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavLink
              onClick={collapse}
              to={IrrigationPage.getPath({ isAuthed })}
              className="nav-link"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={IrrigationPage.icon} className="me-2" />
              {IrrigationPage.label}
            </NavLink>
            <NavLink
              onClick={collapse}
              to={RealTimePage.getOverviewPath()}
              className="nav-link"
              activeClassName="active"
              isActive={() => pageId === RealTimePage.id}
            >
              <FontAwesomeIcon icon={RealTimePage.icon} className="me-2" />
              <span className="d-md-none d-lg-inline">
                {RealTimePage.label}
              </span>
              <span className="d-none d-md-inline d-lg-none">
                Current
              </span>
            </NavLink>
            <NavLink
              onClick={collapse}
              to={ForecastPage.getOverviewPath()}
              className="nav-link"
              activeClassName="active"
              isActive={() => pageId === ForecastPage.id}
            >
              <FontAwesomeIcon icon={ForecastPage.icon} className="me-2" />
              {ForecastPage.label}
            </NavLink>
            <NavLink
              onClick={collapse}
              to={TasksPage.path}
              className="nav-link"
              activeClassName="active"
              isActive={() => pageId === TasksPage.id}
            >
              <FontAwesomeIcon icon={TasksPage.icon} className="me-2" />
              {TasksPage.label}
            </NavLink>
            <Route
              path={toolsPaths}
              // eslint-disable-next-line react/no-children-prop
              children={({ match }) => (
                <NavDropdown
                  id="extensions-navbar-dropdown"
                  show={isShowingTools}
                  onToggle={() => setIsShowingTools(!isShowingTools)}
                  active={!!match}
                  title={(
                    <>
                      <FontAwesomeIcon icon={faTools} className="me-2" />
                      Tools
                    </>
                  )}
                >
                  {tools.map((tool) => (
                    <NavLink
                      key={tool.path}
                      onClick={onToolsLinkClick}
                      to={tool.path}
                      className="dropdown-item"
                      activeClassName="active"
                    >
                      {tool.name}
                    </NavLink>
                  ))}
                </NavDropdown>
              )}
            />
          </Nav>
          <Nav>
            <a
              onClick={collapse}
              href={paths.IRRIGWEB_LOGIN}
              className="nav-link"
            >
              <b>
                Irrig
              </b>
              Web
            </a>
            <Route
              path={[
                paths.ABOUT,
                paths.HELP,
                paths.TERMS_OF_USE,
                paths.INSTALLING,
                paths.CONTACT,
                DemoFarmPage.getOverviewPath(),
              ]}
              // eslint-disable-next-line react/no-children-prop
              children={({ match }) => (
                <NavDropdown
                  id="info-navbar-dropdown"
                  show={isShowingInfo}
                  align="end"
                  onToggle={() => setIsShowingInfo(!isShowingInfo)}
                  active={!!match}
                  title={(
                    <>
                      <FontAwesomeIcon icon={faInfo} className="me-2" />
                      <span className="d-md-none d-lg-inline">Info</span>
                    </>
                  )}
                >
                  <NavLink
                    onClick={onInfoLinkClick}
                    to={paths.HELP}
                    exact
                    className="dropdown-item"
                    activeClassName="active"
                  >
                    Help
                  </NavLink>
                  <NavLink
                    onClick={onInfoLinkClick}
                    to={paths.ABOUT}
                    exact
                    className="dropdown-item"
                    activeClassName="active"
                  >
                    About
                  </NavLink>
                  <NavLink
                    onClick={onInfoLinkClick}
                    to={paths.INSTALLING}
                    exact
                    className="dropdown-item"
                    activeClassName="active"
                  >
                    Installing
                  </NavLink>
                  <NavLink
                    onClick={onInfoLinkClick}
                    to={DemoFarmPage.getOverviewPath()}
                    exact
                    className="dropdown-item"
                    activeClassName="active"
                  >
                    Demo farm
                  </NavLink>
                  <NavLink
                    onClick={onInfoLinkClick}
                    to={paths.TERMS_OF_USE}
                    exact
                    className="dropdown-item"
                    activeClassName="active"
                  >
                    Terms of use
                  </NavLink>
                  <NavLink
                    onClick={onInfoLinkClick}
                    to={paths.CONTACT}
                    exact
                    className="dropdown-item"
                    activeClassName="active"
                  >
                    Contact
                  </NavLink>
                </NavDropdown>
              )}
            />
            {isAuthed ? (
              <NavbarAccountDropdown
                onClick={onAccountLinkClick}
                isShowing={isShowingAccount}
                setIsShowing={setIsShowingAccount}
              />
            ) : (
              <NavbarSignIn onClick={onAccountLinkClick} />
            )}
          </Nav>
        </BootstrapNavbar.Collapse>
      </div>
    </BootstrapNavbar>
  );
}

Navbar.propTypes = {
  isAuthed: PropTypes.bool.isRequired,
};
