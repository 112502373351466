import './Tour.scss';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';

const TourEventListener = () => {
  const activeClassName = 'cssip-tour-is-active';
  const tour = useContext(ShepherdTourContext);
  const history = useHistory();
  history.listen(() => {
    if (tour) {
      document.body.classList.remove(activeClassName);
      tour.cancel();
    }
  });
  useEffect(() => {
    if (tour) {
      const onStart = () => document.body.classList.add(activeClassName);
      tour.on('active', onStart);
      const onFinish = () => document.body.classList.remove(activeClassName);
      tour.on('inactive', onFinish);
      return () => {
        tour.off('active', onStart);
        tour.off('inactive', onFinish);
      };
    }
    return _.noop;
  }, [tour]);
  return null;
};

export default function Tour({ children, steps, keyProp }) {
  if (steps.length === 0) {
    return children;
  }
  return (
    <ShepherdTour
      key={keyProp}
      steps={steps.map((step, index) => {
        const buttons = [];
        if (index > 0) {
          buttons.push({
            classes: 'mx-1 btn-sm btn btn-outline-primary',
            text: 'Back',
            type: 'back',
          });
        }
        if (index < steps.length - 1) {
          buttons.push({
            classes: 'mx-1 btn-sm btn btn-outline-primary',
            text: 'Next',
            type: 'next',
          });
        } else {
          buttons.push({
            classes: 'mx-1 btn-sm btn btn-outline-primary',
            text: 'Exit',
            type: 'cancel',
          });
        }
        return {
          buttons,
          attachTo: {
            element: `.${step.targetClass}`,
            on: 'bottom',
          },
          title: step.title,
          text: () => {
            const appContainer = document.createElement('div');
            // eslint-disable-next-line react/no-render-return-value
            return ReactDOM.render(
              <div className="text-start">
                {step.content}
              </div>,
              appContainer,
            );
          },
          beforeShowPromise: () => new Promise((resolve) => {
            ReactGA.event({
              action: 'Step',
              category: 'Tour',
              label: step.title,
            });
            const onBefore = _.get(step, 'onBefore', _.noop);
            onBefore();
            const interval = setInterval(() => {
              if (document.getElementsByClassName(step.targetClass).length) {
                clearInterval(interval);
                document.getElementsByClassName(step.targetClass)[0].scrollIntoView();
                resolve();
              }
            }, 100);
          }),
        };
      })}
      tourOptions={{
        defaultStepOptions: {
          cancelIcon: {
            enabled: true,
          },
          scrollTo: true,
          canClickTarget: false,
        },
        useModalOverlay: true,
      }}
    >
      <TourEventListener />
      {children}
    </ShepherdTour>
  );
}

Tour.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  keyProp: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Tour.defaultProps = {
  keyProp: '',
};
