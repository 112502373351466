import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import StructuredData from './StructuredData';

export default function Breadcrumbs({ breadcrumbs }) {
  return (
    <nav aria-label="breadcrumb">
      <ol className="p-2 breadcrumb bg-white small mb-0 d-flex flex-nowrap overflow-auto">
        {breadcrumbs.map((item, index) => {
          const isActive = index === breadcrumbs.length - 1;
          const innerClasses = 'text-nowrap';
          return (
            <li
              key={item.name}
              className={classNames(
                'breadcrumb-item flex-shrink-0',
                { 'pr-3': isActive, [innerClasses]: isActive },
              )}
              aria-current={isActive ? 'page' : undefined}
            >
              {isActive ? (
                item.name
              ) : (
                <Link
                  to={item.to}
                  className={innerClasses}
                >
                  {item.name}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
      <StructuredData
        data={{
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: breadcrumbs.map((item, index) => {
            const isActive = index === breadcrumbs.length - 1;
            const listItem = {
              '@type': 'ListItem',
              position: index + 1,
              name: item.name,
            };
            if (!isActive) {
              listItem.item = `${window.location.origin}${item.to}`;
            }
            return listItem;
          }),
        }}
      />
    </nav>
  );
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    name: PropTypes.string.isRequired,
  })).isRequired,
};
