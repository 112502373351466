import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import * as paths from '../constants/paths';
import AccountLocationsModal from '../containers/AccountLocationsModal';

import Admin from '../containers/Admin';
import Alarms from '../containers/Alarms';
import Breadcrumbs from '../containers/Breadcrumbs';
import ForecastOverviewNav from '../containers/ForecastOverviewNav';
import Home from '../containers/Home';
import Navbar from '../containers/Navbar';
import RealTime from '../containers/RealTime';
import RequireAuth from '../containers/RequireAuth';
import TaskNav from '../containers/TaskNav';
import Toasts from '../containers/Toasts';
import CwuAlert from './CwuAlert';
import CwuNav from './CwuNav';
import DataPageLocation from './DataPageLocation';
import DemoFarmAlert from './DemoFarmAlert';
import Footer from './Footer';
import ForecastPropertyNav from './ForecastPropertyNav';
import Layout from './Layout';
import NotFound from './NotFound';
import Placeholder from './Placeholder';
import Spinner from './Spinner';

const About = React.lazy(() => import(/* webpackChunkName: "About" */ './About'));
const Auth = React.lazy(() => import(/* webpackChunkName: "Auth" */ '../containers/Auth'));
const Account = React.lazy(() => import(/* webpackChunkName: "Account" */ '../containers/Account'));
const Contact = React.lazy(() => import(/* webpackChunkName: "Contact" */ './Contact'));
const Dashboard = React.lazy(() => import(/* webpackChunkName: "Dashboard" */ '../containers/Dashboard'));
const DataOverviewList = React.lazy(() => import(/* webpackChunkName: "Contact" */ '../containers/DataOverviewList'));
const Enso = React.lazy(() => import(/* webpackChunkName: "Enso" */ '../containers/Enso'));
const Farm = React.lazy(() => import(/* webpackChunkName: "Farm" */ '../containers/Farm'));
const Fishing = React.lazy(() => import(/* webpackChunkName: "Fishing" */ './Fishing'));
const Help = React.lazy(() => import(/* webpackChunkName: "Help" */ './Help'));
const Installing = React.lazy(() => import(/* webpackChunkName: "Installing" */ './Installing'));
const RemoteSensing = React.lazy(() => import(/* webpackChunkName: "RemoteSensing" */ '../containers/RemoteSensing'));
const Seasonal = React.lazy(() => import(/* webpackChunkName: "Seasonal" */ '../containers/Seasonal'));
const Task = React.lazy(() => import(/* webpackChunkName: "Task" */ '../containers/Task'));
const Tasks = React.lazy(() => import(/* webpackChunkName: "TermsOfUse" */ '../containers/Tasks'));
const TermsOfUse = React.lazy(() => import(/* webpackChunkName: "TermsOfUse" */ './TermsOfUse'));

export default function App() {
  return (
    <Layout.Container>
      <AccountLocationsModal />
      <Layout.Nav>
        <Navbar />
      </Layout.Nav>
      <Route path={paths.breadcrumbPaths} component={Breadcrumbs} />
      <Route
        path={[
          paths.FORECAST_PROPERTY,
          paths.FORECASTS_OVERVIEW_ITEM,
          paths.FARM_OVERVIEW_ITEM,
          paths.TASK_PARAMS_OPTIONAL,
          paths.CWU_PROPERTY_ITEM,
        ]}
      >
        <Layout.SubNav>
          <Switch>
            <Route path={paths.CWU_PROPERTY_ITEM} component={CwuNav} />
            <Route path={paths.FORECAST_PROPERTY} component={ForecastPropertyNav} />
            <Route
              path={[
                paths.FORECASTS_OVERVIEW_ITEM,
                paths.FARM_OVERVIEW_ITEM,
              ]}
              component={ForecastOverviewNav}
            />
            <Route path={paths.TASK_PARAMS_OPTIONAL} component={TaskNav} />
          </Switch>
        </Layout.SubNav>
      </Route>
      <Route path={paths.CWU} component={CwuAlert} />
      <Route path={paths.DEMO_FARM} component={DemoFarmAlert} />
      <Suspense fallback={<Placeholder />}>
        <Switch>
          <Route path={paths.SEASONAL} component={Seasonal} />
          <Route path={[paths.CWU, paths.FORECASTS]} exact component={DataOverviewList} />
          <Route path={paths.FARM} exact component={Farm} />
          <Route path={paths.REAL_TIME} exact component={RealTime} />
          <Route
            path={[
              paths.PROPERTY_ITEM,
              paths.FARM_OVERVIEW_ITEM,
              paths.FORECASTS_OVERVIEW_ITEM,
            ]}
            component={DataPageLocation}
          />
          <Route path={paths.REMOTE_SENSING} component={RemoteSensing} />
          <Route path={paths.ROOT}>
            <Layout.Content className="flex-grow-1 p-0 overflow-auto">
              <Suspense fallback={<Spinner />}>
                <Switch>
                  <Route path={paths.CONTACT} component={Contact} />
                  <Route path={paths.ENSO} component={Enso} />
                  <Route path={paths.TERMS_OF_USE_SECTION_OPTIONAL} component={TermsOfUse} />
                  <Route path={paths.ABOUT} component={About} />
                  <Route path={paths.HELP} component={Help} />
                  <Route path={paths.INSTALLING} component={Installing} />
                  <Route path={paths.ACCOUNT}>
                    <RequireAuth redirectToLogin>
                      <Account />
                    </RequireAuth>
                  </Route>
                  <Route path={paths.ALARMS}>
                    <RequireAuth redirectToLogin>
                      <Alarms />
                    </RequireAuth>
                  </Route>
                  <Route path={paths.DASHBOARD}>
                    <RequireAuth redirectToLogin>
                      <Dashboard />
                    </RequireAuth>
                  </Route>
                  <Route path={paths.TASK_PARAMS_OPTIONAL} component={Task} />
                  <Route path={paths.TASKS} component={Tasks} />
                  <Route path={paths.AUTH} component={Auth} />
                  <Route exact path={paths.HOME} component={Home} />
                  <Route path={paths.FISHING} component={Fishing} />
                  <Route path={paths.ADMIN}>
                    <RequireAuth group="admin">
                      <Admin />
                    </RequireAuth>
                  </Route>
                  <Route path={paths.ROOT} component={NotFound} />
                </Switch>
              </Suspense>
            </Layout.Content>
          </Route>
        </Switch>
      </Suspense>
      <Layout.Footer>
        <Footer />
      </Layout.Footer>
      <Toasts />
    </Layout.Container>
  );
}
