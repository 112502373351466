import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AccountStation from './AccountStation';
import AccountZone from './AccountZone';

export default function AccountLocationsModal({ station, zone, onChange }) {
  const [show, setShow] = useState(true);
  const attributesFromProps = {
    'custom:station': station,
    'custom:zone': zone,
  };
  const allAttributesSetInProps = !Object.values(attributesFromProps).some((value) => !value);
  const [attributes, setAttributes] = useState(attributesFromProps);
  const close = () => setShow(false);
  useEffect(() => {
    const allAttributesSetInState = !Object.values(attributes).some((value) => !value);
    if (!allAttributesSetInProps && allAttributesSetInState) {
      onChange(attributes);
      close();
    }
  }, [attributes, allAttributesSetInProps, onChange]);
  return (
    <Modal
      show={show && Object.values(attributes).some((value) => !value)}
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title>Default locations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Select your default zone and station below.
          <br />
          Setting a zone enables forecasts to be generated for IrrigWeb fields that do not have
          location data.
          <br />
          The default station will be used to provide data on the tasks page.
        </p>
        <AccountZone
          value={attributes['custom:zone']}
          onChange={(value) => {
            setAttributes({ ...attributes, 'custom:zone': value });
          }}
        />
        <AccountStation
          value={attributes['custom:station']}
          onChange={(value) => {
            setAttributes({ ...attributes, 'custom:station': value });
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={close}>
          Not now
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AccountLocationsModal.propTypes = {
  onChange: PropTypes.func.isRequired,
  station: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  zone: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

AccountLocationsModal.defaultProps = {
  station: false,
  zone: false,
};
