import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import * as paths from '../constants/paths';
import Info from './Info';

export default function AlarmModalContent({
  alarm, setFields, conditions, property, userHasPhone, timeInterval, location,
}) {
  return (
    <>
      <div className="mb-3">
        <Form.Control
          onFocus={(e) => e.target.select()}
          placeholder="Alarm name"
          type="text"
          value={alarm.title}
          onChange={(e) => setFields({ ...alarm, title: e.target.value })}
        />
      </div>
      <p className="mt-3 mb-1">
        <strong>
          {`${location.name} ${timeInterval.prefix} ${property.name}:`}
        </strong>
      </p>
      <div className="row">
        <div className="col">
          <div className="mb-3">
            <select
              className="form-select"
              value={alarm.conditionId}
              onChange={(e) => setFields({ ...alarm, conditionId: e.target.value })}
            >
              {!alarm.conditionId && (
                <option>Select condition</option>
              )}
              {conditions.map((condition) => (
                <option key={condition.id} value={condition.id}>
                  {condition.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <Form.Control
              onFocus={(e) => e.target.select()}
              placeholder="Condition value"
              type="number"
              value={alarm.value || ''}
              onChange={(e) => setFields({ ...alarm, value: _.toNumber(e.target.value) })}
            />
          </div>
        </div>
      </div>
      <div className="form-check mt-4">
        <input
          type="checkbox"
          className="form-check-input"
          id="notifications"
          checked={alarm.notifications}
          onChange={() => setFields({ ...alarm, notifications: !alarm.notifications })}
          disabled={!userHasPhone}
        />
        <label
          className="form-check-label"
          htmlFor="notifications"
        >
          SMS notifications
        </label>
        <Info
          info={{
            short: 'Requires a phone number set',
            long: 'A phone number can be set and updated through the account settings page',
          }}
        />
      </div>
      {!userHasPhone && (
        <div>
          <FontAwesomeIcon className="text-warning me-2" icon={faExclamationTriangle} />
          <i className="text-danger">
            No phone number has been added to your
            {' '}
            <Link className="text-danger" to={paths.ACCOUNT}><u>account.</u></Link>
          </i>
        </div>
      )}
    </>
  );
}

AlarmModalContent.propTypes = {
  alarm: PropTypes.shape({
    title: PropTypes.string,
    conditionId: PropTypes.string,
    notifications: PropTypes.bool,
    value: PropTypes.number,
  }).isRequired,
  setFields: PropTypes.func.isRequired,
  conditions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  property: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  timeInterval: PropTypes.shape({
    prefix: PropTypes.string.isRequired,
  }).isRequired,
  userHasPhone: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};
