import React from 'react';
import { useSelector } from 'react-redux';
import Alarms from '../components/Alarms';
import Spinner from '../components/Spinner';
import { ALARMS } from '../constants/endpointIds';
import { useLoader } from '../lib/hooks';

const AlarmsContainer = () => {
  const alarms = useSelector((state) => state.alarms);
  return (
    <Alarms alarms={alarms} />
  );
};

const AlarmsLoader = () => {
  const isLoading = useLoader([ALARMS]);
  if (isLoading) {
    return <Spinner />;
  }
  return <AlarmsContainer />;
};

export default AlarmsLoader;
