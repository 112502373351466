import PropTypes from 'prop-types';
import React from 'react';

const CustomToggle = React.forwardRef(({
  children, onClick, className, ...props
}, ref) => (
  <button
    className={`${className.replace('dropdown-toggle', '')} btn btn-link`}
    type="button"
    ref={ref}
    onClick={onClick}
    aria-label={props['aria-label']}
  >
    {children}
  </button>
));

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  'aria-label': PropTypes.string,
};

CustomToggle.defaultProps = {
  className: '',
  'aria-label': '',
};

export default CustomToggle;
