import PropTypes from 'prop-types';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as paths from '../constants/paths';
import OverviewProperty from '../containers/OverviewProperty';
import Box from './Box';
import OverviewItemStats from './OverviewItemStats';

export default function FarmOverviewItem({
  item, location, dateTimeRange, properties,
}) {
  const match = useRouteMatch(paths.DATA);
  return (
    <Box.Content
      className={`py-1 cssip-tour-farm-overview-item-${dateTimeRange.timeIntervalId}`}
    >
      <h2 className="h4 text-center text-black-50">{item.title}</h2>
      <hr className="m-0 mb-1" />
      <div className="row align-items-stretch">
        {properties.map(({ id, options }) => (
          <OverviewProperty
            key={id}
            locationId={location.id}
            propertyId={id}
            propertyOptions={options}
            data={item}
            dateTimeRange={dateTimeRange}
            pageId={match.params.pageId}
          />
        ))}
      </div>
      <OverviewItemStats stats={item.stats} />
    </Box.Content>
  );
}

FarmOverviewItem.propTypes = {
  item: PropTypes.shape({
    datetime: PropTypes.string.isRequired,
    properties: PropTypes.shape({}).isRequired,
    stats: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
    geometry: PropTypes.shape({}).isRequired,
  }).isRequired,
  dateTimeRange: PropTypes.shape({
    timeIntervalId: PropTypes.string.isRequired,
  }).isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({}),
  }).isRequired).isRequired,
};
