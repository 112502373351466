import * as types from '../constants/actionTypes';

const initialState = {
  state: undefined,
};

export default function auth(state = initialState, action = {}) {
  switch (action.type) {
    case types.USER_SET: {
      return {
        state: action.user ? 'signedIn' : 'notSignedIn',
        user: action.user,
      };
    }

    case types.USER_IS_LOADING: {
      return {
        ...state,
        state: 'loading',
      };
    }

    case types.USER_SIGNED_OUT: {
      return {
        state: 'notSignedIn',
        user: null,
      };
    }

    default: {
      return state;
    }
  }
}
