import { secondary, primary } from './theme';

const phases = [
  {
    name: 'El Nino',
    id: 'En',
    color: secondary.css(),
  },
  {
    name: 'La Nina',
    id: 'Ln',
    color: primary.css(),
  },
  {
    name: 'Neutral',
    id: 'N',
    color: '#BBBBBB',
  },
];

export default phases;
