import { faCloudSunRain } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { matchPath } from 'react-router-dom';
import { JUNE } from '../constants/cropCycles';
import { RAT } from '../constants/cropTypes';
import * as dateTimeRangeIds from '../constants/dateTimeRangeIds';
import * as endpointIds from '../constants/endpointIds';
import { DEFAULT } from '../constants/locationTypeIds';
import * as constants from '../constants/pageIds';
import { PROPERTY_VIEW, OVERVIEW_VIEW } from '../constants/pageViews';
import * as paths from '../constants/paths';
import * as propertyIds from '../constants/propertyIds';
import endpoints, { cropForecastEndpoints } from '../data/endpoints';
import ForecastPage from './ForecastPage';

export default class CwuPage extends ForecastPage {
  static icon = faCloudSunRain; // @TODO: Find an icon for CWU.

  static id = constants.CWU;

  static locationTypeId = DEFAULT;

  static initialState = {
    dateTimeRangeIds: {
      [PROPERTY_VIEW]: dateTimeRangeIds.NEXT_7_DAYS,
      [OVERVIEW_VIEW]: dateTimeRangeIds.NEXT_7_DAYS,
    },
    forecastEndpointId: endpointIds.FORECAST_CROP,
    dateTimeOffset: 0,
    metaFilter: { cropCycle: JUNE, cropType: RAT },
  };

  static forecastEndpointIds = Object.keys(cropForecastEndpoints);

  static observationEndpointId = endpointIds.OBSERVATION_CROP;

  static label = 'Generic Crop Water Use';

  static description = 'CWU forecast for 5 zones in the lower Burdekin. Forecasts of crop water requirements at a more targeted spatial scale.';

  static propertyIds = [
    propertyIds.ET,
  ];

  static dateTimeRangeIds = [
    dateTimeRangeIds.NEXT_7_DAYS,
    dateTimeRangeIds.LAST_7_DAYS,
  ];

  static sources = [
    endpointIds.LOCATIONS,
    endpointIds.OBSERVATION_CROP,
    endpointIds.FORECAST_CROP,
  ];

  static getForecastEndpointIds() {
    return Object.keys(cropForecastEndpoints);
  }

  getSelectedForecastEndpoint() {
    const selected = this.getPageState().forecastEndpointId;
    const selectedEndpointIds = this.constructor.getForecastEndpointIds();
    const selectedInEndpoints = selectedEndpointIds.indexOf(selected) >= 0;
    if (selectedInEndpoints) {
      return endpoints[selected];
    }
    return endpoints[endpointIds.FORECAST_CROP];
  }

  static getForecastsDatesEndpoint() {
    return endpoints[endpointIds.CROP_FORECAST_ENDPOINT_DATES];
  }

  generateOverviewLocationListItems() {
    const locations = _.orderBy(this.constructor.filterLocations(Object.values(this.state.locations)), ['distance'], ['asc']);
    return locations.map((location) => {
      const items = [];
      if (location.distance) {
        items.push({
          name: 'Distance from current location',
          value: `${parseInt(location.distance, 10)} km`,
        });
      }
      return {
        geoJson: {
          features: locations.map((item) => ({
            geometry: item.geometry,
            properties: {
              selected: item === location,
            },
            type: 'Feature',
          })),
          type: 'FeatureCollection',
        },
        name: location.name,
        properties: items,
        id: location.id,
        to: this.constructor.getPropertyLocationPath(propertyIds.ET, location.id),
      };
    });
  }

  generateBreadcrumbs(pathname) {
    const breadcrumbs = [
      {
        to: paths.HOME,
        name: 'Home',
      },
      {
        to: this.constructor.getOverviewPath(),
        name: this.constructor.label,
      },
    ];
    const propertyPageMatch = matchPath(pathname, { path: paths.PROPERTY_ITEM_OPTIONAL });
    if (propertyPageMatch) {
      const locationId = _.get(propertyPageMatch, 'params.locationId');
      if (locationId) {
        breadcrumbs.push({
          to: this.constructor.getOverviewPath(),
          name: `${_.get(this.state, `locations[${locationId}].name`, 'location')}`,
        });
      }
    }
    return breadcrumbs;
  }
}
