import * as types from '../constants/actionTypes';

const initialState = {};

export default function endpoints(state = initialState, action = {}) {
  switch (action.type) {
    case types.ENDPOINT_RECEIVED: {
      if (!action.endpointsDates) {
        return state;
      }
      return {
        ...state,
        [action.endpointId]: action.endpointsDates,
      };
    }

    default: {
      return state;
    }
  }
}
