import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import timeIntervals from '../data/timeIntervals';

export default function DateTimeOffsetSelector({
  value, setDateTime, setIsPlaying, isPlaying, dateTimeRange,
}) {
  const timeInterval = timeIntervals[dateTimeRange.timeIntervalId];
  const label = timeInterval.formatShort(
    moment().add(value, dateTimeRange.unit),
  );
  const valueRef = useRef(value);
  useEffect(() => { valueRef.current = value; });
  const min = dateTimeRange.startOffset;
  const max = _.toInteger(dateTimeRange.endOffset / dateTimeRange.step) * dateTimeRange.step;
  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        const offset = valueRef.current + 1 <= max ? valueRef.current + 1 : min;
        setDateTime(offset);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [min, max, isPlaying, setDateTime]);
  return (
    <div className="flex-fill mx-1 bg-white d-flex align-items-center px-2 py-1 cssip-tour-date-time-offset-selector">
      <span className="small flex-shrink-0">
        <label className="mb-0 form-label" htmlFor="date-time-offset-selector">{label}</label>
        <FontAwesomeIcon
          className="mx-1 cursor-pointer"
          icon={isPlaying ? faPause : faPlay}
          onClick={() => setIsPlaying(!isPlaying)}
        />
      </span>
      <input
        className="form-control-range form-range"
        max={max}
        min={min}
        onChange={(e) => {
          setDateTime(parseInt(e.target.value, 10), {
            action: 'Set time',
            label: 'Slider',
          });
        }}
        step={dateTimeRange.step}
        type="range"
        value={value}
        id="date-time-offset-selector"
      />
    </div>
  );
}

DateTimeOffsetSelector.propTypes = {
  dateTimeRange: PropTypes.shape({
    endOffset: PropTypes.number.isRequired,
    startOffset: PropTypes.number.isRequired,
    timeIntervalId: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    step: PropTypes.number.isRequired,
  }).isRequired,
  isPlaying: PropTypes.bool.isRequired,
  setDateTime: PropTypes.func.isRequired,
  setIsPlaying: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};
