import CwuPage from '../pages/CwuPage';
import DemoFarmPage from '../pages/DemoFarmPage';
import ForecastPage from '../pages/ForecastPage';
import MyFarmPage from '../pages/MyFarmPage';
import RealTimePage from '../pages/RealTimePage';

const dataPages = {
  [CwuPage.id]: CwuPage,
  [RealTimePage.id]: RealTimePage,
  [ForecastPage.id]: ForecastPage,
  [MyFarmPage.id]: MyFarmPage,
  [DemoFarmPage.id]: DemoFarmPage,
};

export default dataPages;
