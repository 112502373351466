import * as types from '../constants/actionTypes';

const localStorageKey = 'CSSIP_REDUX_REPLAY_ACTIONS';

const replayableActionTypes = [
  types.DATA_PAGE_VIEW_DATE_TIME_RANGE_CHANGED,
  types.APP_IRRIGATION_TYPE_CHANGED,
  types.MAP_DISPLAY_CHANGED,
  types.MAP_STYLE_CHANGED,
];

const actions = JSON.parse(localStorage.getItem(localStorageKey)) || {};

const setAction = (action) => {
  actions[action.type + action.pageId] = action;
  return new Promise((resolve) => {
    localStorage.setItem(localStorageKey, JSON.stringify(actions));
    resolve();
  });
};

export const replayMiddleware = () => (next) => (action) => {
  if (replayableActionTypes.indexOf(action.type) >= 0) {
    setAction(action);
  }
  return next(action);
};

export const getReplayActions = () => Object.values(actions)
  .filter((action) => replayableActionTypes.indexOf(action.type) >= 0)
  .sort((a, b) => (
    Math.sign(replayableActionTypes.indexOf(a.type) - replayableActionTypes.indexOf(b.type))
  ));
