import _ from 'lodash';
import moment from 'moment';
import * as types from '../constants/actionTypes';

const initialState = {};

export default function observations(state = initialState, action = {}) {
  switch (action.type) {
    case types.ENDPOINT_RECEIVED: {
      if (!action.observations) {
        return state;
      }
      return {
        ...state,
        [action.endpointId]: action.observations,
      };
    }

    case types.REAL_TIME_REMOVE_OLD_OBSERVATIONS: {
      if (!_.has(state, 'REAL_TIME')) {
        return state;
      }
      const oldDate = moment(action.datetime);
      const updatedRealTime = {};
      Object.keys(state.REAL_TIME).forEach((station) => {
        updatedRealTime[station] = state.REAL_TIME[station]
          .filter((item) => oldDate.isBefore(item.datetime));
      });
      return { ...state, REAL_TIME: updatedRealTime };
    }

    case types.REAL_TIME_ADD_NEW_OBSERVATIONS: {
      if (!_.has(state, 'REAL_TIME')) {
        return state;
      }
      return {
        ...state,
        REAL_TIME: {
          ...state.REAL_TIME,
          ..._.mapValues(action.data.observations, (value, key) => [
            ..._.get(state, `REAL_TIME[${key}]`, []),
            ...value,
          ]),
        },
      };
    }

    default: {
      return state;
    }
  }
}
