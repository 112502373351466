import React from 'react';
import * as actions from '../actions';
import OverviewProperty from '../components/OverviewProperty';
import { useAction } from '../lib/hooks';

const OverviewPropertyContainer = (props) => {
  const selectOverviewProperty = useAction(actions.selectOverviewProperty);
  return (
    <OverviewProperty
      selectOverviewProperty={selectOverviewProperty}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default OverviewPropertyContainer;
