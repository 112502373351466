import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { primary } from '../data/theme';
import turf from '../lib/turf';

const polygonPointGenerator = {
  Polygon: (coords) => coords.map((item) => [item[0], -item[1]]),
  MultiPolygon: (coords) => coords.map((outer) => outer.map((item) => [item[0], -item[1]])),
};

export default function Shape({ geoJson }) {
  const box = turf.bbox(geoJson);
  const scale = _.max([box[3] - box[1], box[2] - box[0]]);
  let shapes;
  let padding = 0.01;
  switch (geoJson.type) {
    case 'FeatureCollection': {
      const sorted = _.sortBy(geoJson.features, (feature) => _.get(feature, 'properties.selected'));
      shapes = sorted.map((feature) => {
        switch (feature.geometry.type) {
          case 'Polygon':
          case 'MultiPolygon': {
            return feature.geometry.coordinates
              .map((coordinates, i) => polygonPointGenerator[feature.geometry.type](coordinates)
                .map((points, j) => (
                  <polygon
                    fill={feature.properties.selected ? primary.css() : 'rgb(255, 255, 255)'}
                    strokeWidth={0.01}
                    stroke={primary.css()}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${i}-${j}`}
                    points={points.join(' ')}
                  />
                )));
          }
          case 'Point': {
            padding = scale * 0.25;
            return (
              <circle
                key={feature.geometry.coordinates}
                cx={feature.geometry.coordinates[0]}
                cy={-feature.geometry.coordinates[1]}
                r={scale * (feature.properties.selected ? 0.2 : 0.15)}
                strokeWidth={scale * 0.05}
                stroke={primary.css()}
                fill={feature.properties.selected ? primary.css() : '#FFFFFF'}
              />
            );
          }
          default: {
            return null;
          }
        }
      });
      break;
    }
    case 'Polygon':
    case 'MultiPolygon': {
      shapes = geoJson.coordinates
        .map((coordinates) => polygonPointGenerator[geoJson.type](coordinates))
        .map((points) => (
          <polygon key={points} points={points.join(' ')} fill={primary.css()} />
        ));
      break;
    }
    default: {
      shapes = null;
    }
  }
  return (
    <svg
      className="ms-2"
      viewBox={`${box[0] - padding} ${-box[3] - padding} ${box[2] - box[0] + (2 * padding)} ${box[3] - box[1] + (2 * padding)}`}
      width={50}
      height="100%"
    >
      {shapes}
    </svg>
  );
}

Shape.propTypes = {
  geoJson: PropTypes.shape({
    coordinates: PropTypes.arrayOf(PropTypes.number),
    features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};
