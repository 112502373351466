import * as dateTimeRangeIds from '../constants/dateTimeRangeIds';
import * as endpointIds from '../constants/endpointIds';
import { DEMO_FARM } from '../constants/locationTypeIds';
import * as constants from '../constants/pageIds';
import { PROPERTY_VIEW, OVERVIEW_VIEW } from '../constants/pageViews';
import { demoFarmForecastEndpoints } from '../data/endpoints';
import FarmPage from './FarmPage';

export default class DemoFarmPage extends FarmPage {
  static id = constants.DEMO_FARM;

  static locationTypeId = DEMO_FARM;

  static initialState = {
    dateTimeRangeIds: {
      [PROPERTY_VIEW]: dateTimeRangeIds.NEXT_7_DAYS,
      [OVERVIEW_VIEW]: dateTimeRangeIds.NEXT_7_DAYS,
    },
    forecastEndpointId: endpointIds.DEMO_FARM,
    dateTimeOffset: 0,
  };

  static forecastEndpointIds = Object.keys(demoFarmForecastEndpoints);

  static observationEndpointId = endpointIds.DEMO_FARM;

  static forecastsDatesEndpointId = endpointIds.DEMO_FARM_FORECAST_ENDPOINT_DATES;

  static locationsEndpointId = endpointIds.DEMO_FARM;

  static label = 'Demo Farm';

  static requiresAuth = false;

  static sources = [
    endpointIds.DEMO_FARM,
  ];

  getIrrigationTypeId = () => this.state.app.irrigationTypeId;
}
