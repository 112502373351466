import PropTypes from 'prop-types';
import React from 'react';
import PageTitle from './PageTitle';

export default function Admin({ users, dataSourceUser, setDataSourceUsername }) {
  return (
    <div>
      <PageTitle>Admin</PageTitle>
      <div className="container bg-white p-3">
        <h3>Users</h3>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Email</th>
                <th>Email Verified</th>
                <th>Zone</th>
                <th>Station</th>
                <th>Data source</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.username}>
                  <td>{user.email}</td>
                  <td>{user.isEmailVerified ? 'YES' : 'NO'}</td>
                  <td>{user.zone}</td>
                  <td>{user.station}</td>
                  <td className="text-center">
                    <input
                      name="data-source"
                      type="radio"
                      checked={dataSourceUser === user.username}
                      onChange={() => setDataSourceUsername(user.username)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

Admin.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    zone: PropTypes.string,
    station: PropTypes.string,
    isEmailVerified: PropTypes.bool.isRequired,
  })).isRequired,
  dataSourceUser: PropTypes.string.isRequired,
  setDataSourceUsername: PropTypes.func.isRequired,
};
