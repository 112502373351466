import React from 'react';
import { Route } from 'react-router-dom';
import * as paths from '../constants/paths';
import DateTimeOffsetSelector from '../containers/DateTimeOffsetSelector';
import DateTimeRangeSelector from '../containers/DateTimeRangeSelector';

export default function ForecastPropertyNav() {
  return (
    <>
      <div className="my-2 d-flex">
        <Route path={paths.FORECAST_PROPERTY} component={DateTimeRangeSelector} />
      </div>
      <div className="my-2 d-flex align-items-center">
        <Route path={paths.FORECAST_PROPERTY} component={DateTimeOffsetSelector} />
      </div>
    </>
  );
}
