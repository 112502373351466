import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import * as paths from '../constants/paths';
import * as valueTypes from '../constants/valueTypes';
import properties from '../data/properties';
import FormattedValue from './FormattedValue';
import Icon from './Icon';
import Info from './Info';
import OverviewPropertyBox from './OverviewPropertyBox';

export default function OverviewProperty({
  className, locationId, data, propertyId, propertyOptions, selectOverviewProperty,
  dateTimeRange, pageId,
}) {
  const property = properties[propertyId];
  const scale = property.getScale({ timeIntervalId: dateTimeRange.timeIntervalId });
  // @TODO: Add a method property.getNumericValue which returns a number.
  const value = property.getValue(data.properties);
  let color = scale(value);
  if (_.isObject(value) && valueTypes.MID in value) {
    color = scale(value[valueTypes.MID]);
  } else if (_.isObject(value) && valueTypes.RAIN_50MM in value) {
    color = scale(value[valueTypes.RAIN_50MM]);
  }
  const infoData = {
    long: property.info.long,
    short: `${property.info.short}`,
  };
  if (property.unit) {
    infoData.short += ` (${property.unit})`;
  }
  return (
    <OverviewPropertyBox
      className={`${className} cssip-tour-overview-property-${propertyId}-${data.datetime.substring(0, 10)}-${dateTimeRange.timeIntervalId}`}
      to={paths.PROPERTY_ITEM
        .replace(paths.propertyIdString, propertyId)
        .replace(paths.dataPageIdString, pageId)
        .replace(paths.locationIdString, locationId)}
      onClick={() => {
        const offset = moment(data.datetime)
          .diff(moment().startOf(dateTimeRange.unit), dateTimeRange.unit);
        selectOverviewProperty({
          offset,
          pageId,
          propertyId: property.id,
          dateTimeRangeId: dateTimeRange.id,
        });
      }}
      topRight={<Info info={infoData} />}
    >
      <div className="h-100 d-flex align-items-center justify-content-start">
        <Icon
          className="me-2"
          icon={property.icon}
          shadow
          style={{ color }}
          size="2x"
          fixedWidth
        />
        <div className="d-flex align-items-start flex-column">
          <div className="text-primary">{property.name}</div>
          <div>
            <FormattedValue
              display="inline"
              value={property.getFormatted(data.properties, { unit: true, ...propertyOptions })}
            />
          </div>
        </div>
      </div>
    </OverviewPropertyBox>
  );
}

OverviewProperty.propTypes = {
  data: PropTypes.shape({
    datetime: PropTypes.string.isRequired,
    properties: PropTypes.shape({}).isRequired,
    timeInterval: PropTypes.string.isRequired,
  }).isRequired,
  selectOverviewProperty: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired,
  propertyOptions: PropTypes.shape({}),
  pageId: PropTypes.string.isRequired,
  dateTimeRange: PropTypes.shape({
    id: PropTypes.string.isRequired,
    timeIntervalId: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

OverviewProperty.defaultProps = {
  propertyOptions: {},
  className: '',
};
