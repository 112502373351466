import PropTypes from 'prop-types';
import React from 'react';
import FormattedValue from './FormattedValue';

export default function TableCell({ value, color, className }) {
  return (
    <td
      className={`text-nowrap text-center ${className}`}
      style={{ backgroundColor: color }}
    >
      <FormattedValue value={value} />
    </td>
  );
}

TableCell.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
};

TableCell.defaultProps = {
  className: '',
  color: '',
  value: '',
};
