import { ADMIN } from '../constants/pageIds';
import * as text from '../data/text';

export default class AdminPage {
  static id = ADMIN;

  static label = 'Admin';

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: text.ADMIN_PAGE_DESCRIPTION },
        { name: 'robots', content: 'noindex' },
      ],
    };
  }
}
