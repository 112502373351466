import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ShepherdTourContext } from 'react-shepherd';

export default function TourStarterButton({ children, className }) {
  const tour = useContext(ShepherdTourContext);
  return (
    <button
      onClick={tour.start}
      className={className}
      type="button"
    >
      {children}
    </button>
  );
}

TourStarterButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TourStarterButton.defaultProps = {
  className: '',
};
