import * as types from '../constants/actionTypes';
import { PROPERTY_VIEW } from '../constants/pageViews';
import cropFilters from '../data/cropFilters';
import pages from '../data/dataPages';
import dateTimeRanges from '../data/dateTimeRanges';

const initialState = {};
Object.values(pages).forEach((page) => {
  initialState[page.id] = page.initialState;
});

export default function pagesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.DATA_PAGE_VIEW_DATE_TIME_RANGE_CHANGED: {
      if (!(action.dateTimeRangeId in dateTimeRanges) || !(action.pageId in pages)) {
        return state;
      }
      const Page = pages[action.pageId];
      const forecastEndpointId = Page.getLatestForecastEndpointId();
      return {
        ...state,
        [action.pageId]: {
          ...state[action.pageId],
          dateTimeRangeIds: {
            ...state[action.pageId].dateTimeRangeIds,
            [action.view]: action.dateTimeRangeId,
          },
          forecastEndpointId,
          dateTimeOffset: dateTimeRanges[action.dateTimeRangeId].startOffset,
        },
      };
    }

    case types.DATA_PAGE_FORECAST_ENDPOINT_CHANGED: {
      if (!(action.pageId in pages)) {
        return state;
      }
      const Page = pages[action.pageId];
      const forecastEndpoints = Page.getForecastEndpoints();
      const forecastEndpointIds = forecastEndpoints.map((item) => item.id);
      if (forecastEndpointIds.indexOf(action.forecastEndpointId) < 0) {
        return state;
      }
      return {
        ...state,
        [action.pageId]: { ...state[action.pageId], forecastEndpointId: action.forecastEndpointId },
      };
    }

    case types.DATA_PAGE_DATE_TIME_OFFSET_CHANGED: {
      return {
        ...state,
        [action.pageId]: { ...state[action.pageId], dateTimeOffset: action.offset },
      };
    }

    case types.OVERVIEW_PROPERTY_SELECTED: {
      return {
        ...state,
        [action.pageId]: {
          ...state[action.pageId],
          dateTimeOffset: action.offset,
          dateTimeRangeIds: {
            ...state[action.pageId].dateTimeRangeIds,
            [PROPERTY_VIEW]: action.dateTimeRangeId,
          },
        },
      };
    }

    case types.DATA_PAGE_META_FILTER_CHANGED: {
      const { metaFilter } = action;
      const cropType = cropFilters.find((item) => item.value === metaFilter.cropType);
      if (!cropType) {
        return state;
      }
      const cropCyle = cropType.cropCycle.find((item) => item.value === metaFilter.cropCycle);
      if (!cropCyle) {
        return state;
      }
      return {
        ...state,
        [action.pageId]: {
          ...state[action.pageId],
          metaFilter: action.metaFilter,
        },
      };
    }

    default: {
      return state;
    }
  }
}
