import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as paths from '../constants/paths';
import MyFarmPage from '../pages/MyFarmPage';

export default function Alert({ id, isAuthed, children }) {
  const key = `CSSIP_ALERT_VISIBILITY_${id.toUpperCase()}`;
  let initialState = true;
  const sessionValue = JSON.parse(sessionStorage.getItem(key));
  if (_.isBoolean(sessionValue)) {
    initialState = sessionValue;
  }
  const [show, setShow] = useState(initialState);
  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(show));
  }, [show, key]);
  if (!show) {
    return null;
  }
  return (
    <div className="bg-warning p-3 d-md-flex justify-content-between align-items-center position-relative">
      <button
        type="button"
        className="btn-close position-absolute m-2"
        aria-label="Close"
        style={{ top: 0, right: 0 }}
        onClick={() => setShow(false)}
      />
      <div>
        {children}
        <div className="small">
          {isAuthed ? (
            <p className="my-1">Go the Irrigation page to view your farms.</p>
          ) : (
            <>
              <p className="my-1">Sign in to see your farms.</p>
              Farms are retrieved from IrrigWeb. The same email address used in your
              IrrigWeb account will be required when creating an Opticane account.
            </>
          )}
        </div>
      </div>
      {!isAuthed && (
        <>
          <hr className="d-md-none border-white" />
          <div className="d-flex align-items-center justify-content-center">
            <Link
              className="btn btn-light"
              to={{
                pathname: paths.AUTH,
                state: { referrer: MyFarmPage.getOverviewPath() },
              }}
            >
              Sign in
            </Link>
            <strong className="d-inline-block mx-2">or</strong>
            <Link
              className="btn btn-light"
              to={{
                pathname: paths.AUTH,
                state: { initialState: 'signUp', referrer: MyFarmPage.getOverviewPath() },
              }}
            >
              Create an account
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

Alert.propTypes = {
  id: PropTypes.string.isRequired,
  isAuthed: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
