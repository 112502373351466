import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import * as actions from '../actions';
import dateTimeRanges from '../data/dateTimeRanges';
import timeIntervals from '../data/timeIntervals';
import { groupMergeAndSortByDatetime } from './utils';

export const useAction = (action) => {
  const dispatch = useDispatch();
  return useCallback((...args) => dispatch(action(...args)), [dispatch, action]);
};

export const useLoader = (sources) => {
  const store = useStore();
  const endpoints = useSelector((state) => state.endpoints);
  const fetchEndpoint = useAction(actions.fetchEndpoint);
  useEffect(() => {
    sources.filter((endpoint) => !(endpoint in store.getState().endpoints)).forEach(fetchEndpoint);
  }, [sources, fetchEndpoint, store]);
  const isLoading = sources.find((endpoint) => (
    !(endpoint in endpoints) || endpoints[endpoint] === 'requested'
  ));
  return isLoading;
};

const emptyList = [];

export const useLocationData = ({
  dateTimeRangeId, forecastEndpointId, locationId, observationEndpointId, metaFilter,
}) => {
  let theFilterer = (i) => i;
  if (metaFilter) {
    theFilterer = (item) => (
      !_.every(Object.keys(metaFilter), _.partial(_.has, item.meta))
      || _.isMatch(item.meta, metaFilter)
    );
  }
  const stateForecasts = useSelector(
    (state) => _.get(state, `forecasts[${forecastEndpointId}][${locationId}]`, emptyList),
  );
  const stateObservations = useSelector(
    (state) => _.get(state, `observations[${observationEndpointId}][${locationId}]`, emptyList),
  );
  const { timeIntervalId } = dateTimeRanges[dateTimeRangeId];
  const timeInterval = timeIntervals[timeIntervalId];
  const start = useSelector((state) => state.datetimes[dateTimeRangeId].start);
  const end = useSelector((state) => state.datetimes[dateTimeRangeId].end);
  const startDate = moment(start);
  const endDate = moment(end);
  const forecasts = stateForecasts
    .filter(theFilterer)
    .filter((item) => item.timeInterval === timeIntervalId)
    .filter((item) => moment(item.datetime).isBetween(startDate, endDate, timeInterval.alignment, '[]'))
    // eslint-disable-next-line no-shadow
    .map(({ properties, ...other }) => ({ ...other, forecast: properties }));
  let observations = [];
  if (moment().isAfter(startDate, timeInterval.alignment)) {
    observations = stateObservations
      .filter(theFilterer)
      .filter((item) => _.isUndefined(item.timeInterval) || item.timeInterval === timeIntervalId)
      .filter((item) => moment(item.datetime).isBetween(startDate, endDate, timeInterval.alignment, '[]'))
      // eslint-disable-next-line no-shadow
      .map(({ properties, ...other }) => ({ ...other, observation: properties }));
  }
  const input = [...forecasts, ...observations];
  if (input.some((item) => !_.isUndefined(item.timeInterval))) {
    const current = moment(startDate).startOf(timeInterval.alignment);
    while (current.isSameOrBefore(endDate)) {
      input.push({ datetime: current.format() });
      current.add(timeInterval.quantity, timeInterval.alignment);
    }
  }
  return groupMergeAndSortByDatetime(input, timeInterval.alignment);
};
