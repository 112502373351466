import { fetchEndpoint, addNewRealTimeData } from '../actions';
import * as notifications from '../constants/notifications';
import store from './store';
import { onMessage } from './websocket';

onMessage((data) => {
  switch (data.type) {
    case notifications.ENDPOINT_UPDATED: {
      if (data.endpointId in store.getState().endpoints) {
        store.dispatch(fetchEndpoint(data.endpointId));
      }
      break;
    }

    case notifications.NEW_DATA_RECEIVED: {
      if (data.endpointId in store.getState().endpoints) {
        store.dispatch(addNewRealTimeData(data.data));
      }
      break;
    }

    default:
      break;
  }
});
