import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { generateTitle } from '../lib/utils';

const robotsMetaContent = process.env.REACT_APP_ROBOTS_META_CONTENT;

function Head({ head }) {
  const description = head.meta && _.get(
    head.meta.find((item) => item.name === 'description'),
    'content',
    false,
  );
  const title = generateTitle(head.title);
  return (
    <Helmet>
      <title>{title}</title>
      {!!head.meta && head.meta
        .filter((item) => (item.name !== 'robots' || robotsMetaContent !== 'noindex, nofollow'))
        .map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key, react/jsx-props-no-spreading
          <meta key={index} {...item} />
        ))}
      <meta property="og:url" content={window.location.href} />
      <meta property="twitter:url" content={window.location.href} />
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />
      {!!description && <meta property="og:description" content={description} />}
      {!!description && <meta property="twitter:description" content={description} />}
      {robotsMetaContent === 'noindex, nofollow' && (
        <meta name="robots" content="noindex, nofollow" />
      )}
    </Helmet>
  );
}

Head.propTypes = {
  head: PropTypes.shape({
    title: PropTypes.arrayOf(PropTypes.string).isRequired,
    meta: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default React.memo(Head);
