import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as actions from '../actions';
import CropFilterSelector from '../components/CropFilterSelector';
import pages from '../data/dataPages';
import { useAction } from '../lib/hooks';

const CropFilterSelectorContainer = () => {
  const { pageId } = useParams();
  const Page = pages[pageId];
  const cropFilter = useSelector((state) => {
    const page = new Page(state);
    return page.getMetaFilter();
  });
  const setMetaFilter = useAction(actions.setMetaFilter);
  return (
    <CropFilterSelector
      set={(value) => setMetaFilter(pageId, value)}
      value={cropFilter}
    />
  );
};

export default CropFilterSelectorContainer;
