import React from 'react';
import HelpSection from './HelpSection';

export default function HelpTools() {
  return (
    <HelpSection title="Tools">
      <p className="lead">
        The extensions menu contains links to pages both within Opticane and externally. These links
        provide additional information that can be useful in decision making. Below is a detailed
        listing of each.
      </p>
      <div className="print-break-inside-avoid">
        <h4><u>Crop water use</u></h4>
        <p>
          The crop water use link is a convenient shortcut to the crop water use forecast page that
          is available within the app under the forecasts menu.
        </p>
      </div>
      {process.env.REACT_APP_SHOW_ENSO === 'true' && (
        <div className="print-break-inside-avoid">
          <h4><u>ENSO</u></h4>
          <p>
            This links to a page within Opticane that explores the relationship between ENSO
            (El Nino/La Nina) Phases and rainfall in the Burdekin region throughout the year.
          </p>
        </div>
      )}
      <div className="print-break-inside-avoid">
        <h4><u>Climate explorer</u></h4>
        <p>
          This is an external link to an app that goes into detail the science behind the formation
          of the zones within Opticane.
        </p>
      </div>
    </HelpSection>
  );
}
