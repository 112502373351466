export const CONTROLS = 'controls';
export const TASKS_PAGE = 'tasks-page';
export const DATA_REPRESENTATION = 'data-representation';
export const ACCOUNT = 'account';
export const DASHBOARD = 'dashboard';
export const ALARMS = 'alarms';
export const CURRENT_CONDITIONS = 'current-conditions';
export const FORECASTS = 'forecasts';
export const MY_FARM = 'my-farm';
export const TOOLS = 'tools';
