import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as paths from '../constants/paths';

export default function NavbarSignIn({ onClick }) {
  const location = useLocation();
  return (
    <Link
      onClick={onClick}
      to={{
        pathname: paths.AUTH,
        state: {
          referrer: location.pathname,
        },
      }}
      type="button"
      className="nav-link text-white"
    >
      Sign in
    </Link>
  );
}

NavbarSignIn.propTypes = {
  onClick: PropTypes.func.isRequired,
};
