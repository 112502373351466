import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import * as actions from '../actions';
import NavbarAccountDropdown from '../components/NavbarAccountDropdown';
import { ADMIN } from '../constants/groups';
import { useAction } from '../lib/hooks';

const NavbarAccountDropdownContainer = ({ onClick, isShowing, setIsShowing }) => {
  const user = useSelector((state) => state.auth.user);
  const { email, groups } = user;
  const isAdmin = groups.indexOf(ADMIN) >= 0;
  const signOut = useAction(actions.signOut);
  return (
    <NavbarAccountDropdown
      email={email}
      isAdmin={isAdmin}
      signOut={signOut}
      onClick={onClick}
      isShowing={isShowing}
      setIsShowing={setIsShowing}
    />
  );
};

NavbarAccountDropdownContainer.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  setIsShowing: PropTypes.func.isRequired,
};

export default NavbarAccountDropdownContainer;
