import _ from 'lodash';
import moment from 'moment';
import ReactGA from 'react-ga';
import { v4 as uuidv4 } from 'uuid';
import * as types from '../constants/actionTypes';
import { IRRIGATE } from '../constants/irrigationTypeIds';
import * as paths from '../constants/paths';
import * as urls from '../constants/urls';
import endpoints from '../data/endpoints';
import auth from '../lib/auth';
import { axiosAuthed } from '../lib/axios';
import history from '../lib/history';
import { authWebSocket } from '../lib/websocket';

export const addToast = (fields) => ({
  type: types.TOAST_ADDED,
  toast: { created: moment().toISOString(), id: uuidv4(), ...fields },
});

export const removeToast = (toast) => ({ type: types.TOAST_REMOVED, toast });

export const fetchEndpoint = (endpointId) => async (dispatch) => {
  if (!(endpointId in endpoints)) {
    dispatch({ endpointId, type: types.ENDPOINT_RECEIVED });
    return;
  }
  dispatch({ endpointId, type: types.ENDPOINT_REQUESTED });
  const data = await endpoints[endpointId].fetch();
  if (Object.keys(data).length) {
    dispatch({ endpointId, type: types.ENDPOINT_RECEIVED, ...data });
  }
};

export const setCurrentLocation = (location, event) => ({
  event,
  location,
  type: types.CURRENT_LOCATION_SET,
});

export const setPageViewDateTimeRange = (pageId, view, dateTimeRangeId, event = false) => ({
  dateTimeRangeId,
  event,
  pageId,
  view,
  type: types.DATA_PAGE_VIEW_DATE_TIME_RANGE_CHANGED,
});

export const setDataPageDateTimeOffset = (pageId, offset, event = false) => ({
  event,
  offset,
  pageId,
  type: types.DATA_PAGE_DATE_TIME_OFFSET_CHANGED,
});

export const setMapDisplay = (display) => ({ display, type: types.MAP_DISPLAY_CHANGED });

export const setIrrigationType = (irrigationTypeId) => ({
  irrigationTypeId,
  type: types.APP_IRRIGATION_TYPE_CHANGED,
});

export const setPageForecastEndpoint = (pageId, endpointId) => async (dispatch, getState) => {
  if (!(endpointId in getState().endpoints)) {
    const data = await endpoints[endpointId].fetch();
    if (Object.keys(data).length) {
      dispatch({ endpointId, type: types.ENDPOINT_RECEIVED, ...data });
    }
  }
  dispatch({
    forecastEndpointId: endpointId,
    pageId,
    type: types.DATA_PAGE_FORECAST_ENDPOINT_CHANGED,
  });
};

export const setMapStyle = (style) => ({ style, type: types.MAP_STYLE_CHANGED });

export const setIsPlaying = (isPlaying) => ({ isPlaying, type: types.IS_PLAYING_CHANGED });

export const setUser = (user) => {
  if (user) {
    const idToken = user.signInUserSession.idToken.jwtToken;
    const accessToken = user.signInUserSession.accessToken.jwtToken;
    axiosAuthed.defaults.headers.common.Authorization = `Bearer ${idToken}`;
    authWebSocket.setSession(accessToken);
    return {
      type: types.USER_SET,
      user: {
        id: _.get(user, 'attributes.sub'),
        email: _.get(user, 'attributes.email'),
        emailVerified: _.get(user, 'attributes.email_verified', false),
        phoneNumber: _.get(user, 'attributes.phone_number', ''),
        groups: _.get(user, 'signInUserSession.accessToken.payload["cognito:groups"]', []),
        station: _.get(user, 'attributes[custom:station]', false),
        zone: _.get(user, 'attributes[custom:zone]', false),
        irrigationType: _.get(user, 'attributes[custom:irrigation_type]', IRRIGATE),
      },
    };
  }
  return { type: types.USER_SET, user };
};

export const fetchUser = () => async (dispatch, getState) => {
  const state = getState();
  // @TODO: This could be done before the fetchUser call is made.
  if (state.auth.state === 'loading') {
    return;
  }
  if (!state.auth.user) {
    dispatch({ type: types.USER_IS_LOADING });
  }
  try {
    const user = await auth.getUser();
    dispatch(setUser(user));
  } catch (err) {
    dispatch(setUser(null));
  }
};

export const updateUserAttributes = (attributes) => async (dispatch) => {
  const response = await auth.updateUserAttributes(attributes);
  if (response === 'SUCCESS') {
    ReactGA.event({ action: 'Updated attributes', category: 'Profile' });
    dispatch(fetchUser());
    dispatch(addToast({
      message: 'Attributes successfully updated', type: 'success', autoClose: true,
    }));
  }
};

export const signOut = () => async (dispatch) => {
  authWebSocket.clearSession();
  await auth.signOut();
  dispatch({ type: types.USER_SIGNED_OUT });
  history.push(paths.AUTH);
};

export const addWidget = (widget) => async (dispatch) => {
  dispatch({ type: types.WIDGET_ADDED, widget });
  await axiosAuthed({ data: widget, method: 'POST', url: urls.WIDGETS_URL });
  dispatch(addToast({
    message: 'Successfully added widget to dashboard', type: 'success', autoClose: true,
  }));
};

export const editWidget = (id, fields) => async (dispatch) => {
  dispatch({ fields, id, type: types.WIDGET_EDITED });
  await axiosAuthed({ data: { fields, id }, method: 'PATCH', url: urls.WIDGETS_URL });
};

export const removeWidget = (id) => async (dispatch) => {
  dispatch({ id, type: types.WIDGET_REMOVED });
  await axiosAuthed({ data: { id }, method: 'DELETE', url: urls.WIDGETS_URL });
};

export const moveWidget = (sourceId, targetId) => async (dispatch) => {
  dispatch({
    sourceId, targetId, type: types.WIDGET_MOVED,
  });
  await axiosAuthed({ data: { sourceId, targetId }, method: 'POST', url: urls.WIDGETS_MOVE_URL });
};

export const setShowMapParticles = (showParticles) => ({
  showParticles, type: types.MAP_IS_SHOWING_PARTICLES_CHANGED,
});

export const addAlarm = (alarm) => async (dispatch) => {
  dispatch({ type: types.ALARM_ADDED, alarm });
  await axiosAuthed({ data: alarm, method: 'POST', url: urls.ALARMS_URL });
  dispatch(addToast({
    message: 'Successfully added alarm', type: 'success', autoClose: true,
  }));
};

export const editAlarm = (id, fields) => async (dispatch) => {
  dispatch({ fields, id, type: types.ALARM_EDITED });
  await axiosAuthed({ data: { fields, id }, method: 'PATCH', url: urls.ALARMS_URL });
};

export const removeAlarm = (id) => async (dispatch) => {
  dispatch({ id, type: types.ALARM_REMOVED });
  await axiosAuthed({ data: { id }, method: 'DELETE', url: urls.ALARMS_URL });
};

export const removeOldRealTimeObservations = () => ({
  type: types.REAL_TIME_REMOVE_OLD_OBSERVATIONS,
  datetime: moment().subtract(1, 'day').format(),
});

export const selectOverviewProperty = (params) => ({
  type: types.OVERVIEW_PROPERTY_SELECTED,
  ...params,
});

export const setDataSourceUsername = (username) => {
  axiosAuthed.defaults.headers.common['imitation-username'] = username;
  return {
    type: types.ADMIN_DATA_SOURCE_USERNAME_CHANGED,
    username,
  };
};

export const addNewRealTimeData = (data) => ({
  type: types.REAL_TIME_ADD_NEW_OBSERVATIONS,
  data,
});

export const updateTime = () => ({
  type: types.TIME_CHANGED,
  time: moment().toISOString(),
});

export const setMetaFilter = (pageId, metaFilter) => ({
  type: types.DATA_PAGE_META_FILTER_CHANGED,
  pageId,
  metaFilter,
});
