import * as types from '../constants/actionTypes';

const initialState = [];

export default function widgets(state = initialState, action = {}) {
  switch (action.type) {
    case types.ENDPOINT_RECEIVED: {
      if (action.widgets) {
        return action.widgets;
      }
      return state;
    }

    case types.WIDGET_ADDED: {
      return [...state, action.widget];
    }

    case types.WIDGET_EDITED: {
      return state.map((item) => {
        if (item.id !== action.id) {
          return item;
        }
        return { ...item, ...action.fields };
      });
    }

    case types.WIDGET_REMOVED: {
      return state.filter((item) => item.id !== action.id);
    }

    case types.WIDGET_MOVED: {
      const source = state.find((item) => item.id === action.sourceId);
      const target = state.find((item) => item.id === action.targetId);
      if (!source || !target || source === target) {
        return state;
      }
      const newState = state.filter((item) => item !== source);
      const targetIndex = state.indexOf(target);
      newState.splice(targetIndex, 0, source);
      return newState;
    }

    default: {
      return state;
    }
  }
}
