import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as dateTimeRangeIds from '../constants/dateTimeRangeIds';
import * as endpointIds from '../constants/endpointIds';
import * as propertyIds from '../constants/propertyIds';
import * as taskIds from '../constants/taskIds';
import * as valueTypes from '../constants/valueTypes';
import * as widgetIds from '../constants/widgetIds';

const fertilising = {
  // https://pixabay.com/photos/tractor-field-agriculture-landscape-4543124/
  image: `${process.env.PUBLIC_URL}/tasks/fertilising.jpg`,
  getWidgetsForLocations: (locations) => [
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.RAIN_24_HOUR_TOTAL,
        sources: [endpointIds.REAL_TIME],
      },
      title: `Rain real time (${locations.realTime.name})`,
      type: widgetIds.REAL_TIME_VALUES,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.RAIN_24_HOUR_TOTAL,
        sources: [endpointIds.REAL_TIME],
      },
      title: `Rain real time (${locations.realTime.name})`,
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.RELATIVE_HUMIDITY,
        sources: [endpointIds.REAL_TIME],
      },
      title: `Relative humidity real time (${locations.realTime.name})`,
      type: widgetIds.REAL_TIME_VALUES,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
        forecastEndpointId: endpointIds.REAL_TIME,
        locationId: locations.realTime.id,
        observationEndpointId: endpointIds.REAL_TIME,
        propertyId: propertyIds.RELATIVE_HUMIDITY,
        sources: [endpointIds.REAL_TIME],
      },
      title: `Relative humidity real time (${locations.realTime.name})`,
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.RAIN_POP,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Rain probability forecast for next 3 days',
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.RAIN,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Rain forecast for next 3 days',
      type: widgetIds.CHART,
    },

    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.MIN_RH,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Min RH forecast for next 3 days',
      type: widgetIds.CHART,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.RAIN_POP,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Rain probability forecast for next 3 days',
      type: widgetIds.TABLE,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.RAIN,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Rain forecast for next 3 days',
      type: widgetIds.TABLE,
    },
    {
      key: uuidv4(),
      params: {
        dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
        forecastEndpointId: endpointIds.FORECAST_WEATHER,
        locationId: locations.weather.id,
        observationEndpointId: endpointIds.OBSERVATION_WEATHER,
        propertyId: propertyIds.MIN_RH,
        sources: [
          endpointIds.FORECAST_WEATHER,
          endpointIds.LOCATIONS,
          endpointIds.OBSERVATION_WEATHER,
        ],
      },
      title: 'Min RH forecast for next 3 days',
      type: widgetIds.TABLE,
    },
  ],
  id: taskIds.FERTILISING,
  name: 'Fertilising',
  statuses: [
    {
      getStatus: (current, weatherForecasts) => {
        const start = moment().startOf('day');
        const end = start.add(3, 'days').startOf('day');
        const forecasts = weatherForecasts
          .filter((item) => item.timeInterval === 'day')
          .filter((item) => moment(item.datetime).isBetween(start, end));
        const isBad = forecasts.some((item) => _.get(item.properties, `${propertyIds.RAIN_POP}.${valueTypes.RAIN_25MM}`) > 50);
        if (isBad) {
          return {
            icon: 'minus',
            message: 'There is a medium chance of a heavy (above 25mm) rainfall event on at least one day in the next three days',
          };
        }
        return {
          icon: 'check',
          message: 'There is low to no chance of a heavy (above 25mm) rainfall event in the next three days',
        };
      },
      info: {
        long: 'A medium chance of heavy rain is defined by any day in the next 3 days having 50% chance of rain above 25mm.',
        short: 'Low to no chance of heavy rain is suitable for fertilising',
      },
      name: 'Rain Probability',
    },
    {
      getStatus: () => ({
        icon: 'question',
        message: (
          <>
            {'Don\'t forget to check current storm activity on the '}
            <a
              href="http://www.bom.gov.au/products/IDR732.loop.shtml#skip"
              target="popup"
              onClick={() => {
                window.open('http://www.bom.gov.au/products/IDR732.loop.shtml#skip', 'popup', 'width=600,height=800');
                return false;
              }}
              rel="noreferrer noopener"
            >
              BOM RADAR
            </a>
          </>
        ),
      }),
      info: {
        long: '',
        short: 'Link to BOM RADAR',
      },
      name: 'BOM RADAR',
    },
  ],
};

export default fertilising;
