import { faFaucet } from '@fortawesome/free-solid-svg-icons';
import { IRRIGATION, MY_FARM, CWU } from '../constants/pageIds';
import * as paths from '../constants/paths';
import * as text from '../data/text';

export default class IrrigationPage {
  static id = IRRIGATION;

  static label = 'Irrigation';

  static description = 'View irrigation outlooks for your farm. See when irrigation events are likely to be required.';

  static icon = faFaucet;

  static path = paths.IRRIGATION;

  static getPath = ({ isAuthed }) => {
    if (isAuthed) {
      return paths.FARM.replace(paths.farmPageIdString, MY_FARM);
    }
    return paths.PAGE.replace(paths.pageIdString, CWU);
  };

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: text.IRRIGATION_PAGE_DESCRIPTION },
      ],
    };
  }
}
