import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import * as actions from '../actions';
import MapParticlesToggle from '../components/MapParticlesToggle';
import properties from '../data/properties';
import { useAction } from '../lib/hooks';

const MapParticlesToggleContainer = ({ propertyId }) => {
  const property = properties[propertyId];
  const showParticles = useSelector((state) => state.map.showParticles);
  const setShowMapParticles = useAction(actions.setShowMapParticles);
  return (
    <MapParticlesToggle
      hasParticles={property.hasParticles}
      showParticles={showParticles}
      setShowParticles={setShowMapParticles}
    />
  );
};

MapParticlesToggleContainer.propTypes = {
  propertyId: PropTypes.string.isRequired,
};

export default MapParticlesToggleContainer;
