import _ from 'lodash';
import moment from 'moment';
import * as types from '../constants/actionTypes';
import dateTimeRanges from '../data/dateTimeRanges';

const getDatetimes = (time) => _.mapValues(
  dateTimeRanges,
  ({
    alignTimeIntervalId, unit, startOffset, endOffset,
  }) => ({
    start: moment(time).startOf(alignTimeIntervalId).add(startOffset, unit).toISOString(),
    end: moment(time).startOf(alignTimeIntervalId).add(endOffset, unit).toISOString(),
  }),
);

const initialState = getDatetimes(moment());

export default function datetimes(state = initialState, action = {}) {
  switch (action.type) {
    case types.REAL_TIME_ADD_NEW_OBSERVATIONS:
    case types.TIME_CHANGED: {
      return getDatetimes(action.time);
    }

    default: {
      return state;
    }
  }
}
