import _ from 'lodash';
import { RELATIVE_HUMIDITY, AIR_TEMPERATURE } from '../constants/propertyIds';
import Property from './Property';

export default class DeltaTProperty extends Property {
  wetTemperature = (temperature, relativeHumidity) => {
    const t = temperature;
    const h = relativeHumidity;
    const A = 0.151977;
    const B = 8.313659;
    const C = 1.676331;
    const D = 0.00391838;
    const E = 0.023101;
    const F = 4.686035;
    const { sqrt, atan } = Math;
    // eslint-disable-next-line max-len
    return t * atan(A * sqrt(h + B)) + atan(t + h) - atan(h - C) + D * sqrt(h ** 3) * atan(E * h) - F;
  };

  deltaT = (temperature, relativeHumidity) => (
    temperature - this.wetTemperature(temperature, relativeHumidity)
  );

  getValue = (data) => {
    if (!_.isNumber(_.get(data, AIR_TEMPERATURE)) || !_.isNumber(_.get(data, RELATIVE_HUMIDITY))) {
      return undefined;
    }
    return parseFloat(
      this.deltaT(data[AIR_TEMPERATURE], data[RELATIVE_HUMIDITY]).toFixed(this.decimalPoints),
    );
  };

  getFormatted(data, options = {}) {
    if (_.isUndefined(this.getValue(data))) {
      return undefined;
    }
    const optionalUnit = options.unit ? this.unit : '';
    return `${this.getValue(data).toFixed(this.decimalPoints)}${optionalUnit}`;
  }

  getTableData = (data, timeInterval) => {
    const scale = this.getScale({ timeIntervalId: timeInterval.id });
    const dates = data.map((item) => ({
      datetime: item.datetime,
      value: timeInterval.formatLong(item.datetime),
    }));
    const tableData = {
      body: [],
      head: { items: dates, name: timeInterval.heading },
    };
    [{ key: 'observation', name: 'Observed' }, { key: 'forecast', name: 'Forecast' }].forEach((row) => {
      const hasRow = data.some((item) => !_.isUndefined(this.getValue(item[row.key])));
      if (hasRow) {
        const items = data.map((item) => {
          if (!_.isUndefined(item[row.key])) {
            return {
              color: scale(this.getValue(item[row.key]))
                .alpha(0.25)
                .css(),
              datetime: item.datetime,
              value: this.getFormatted(item[row.key], { unit: true }),
            };
          }
          return { datetime: item.datetime };
        });
        tableData.body.push({
          items,
          name: row.name,
        });
      }
    });
    return tableData;
  };

  isInData = (data) => {
    const isInObservation = data.some(({ observation }) => (
      !_.isUndefined(_.get(observation, AIR_TEMPERATURE))
      && !_.isUndefined(_.get(observation, RELATIVE_HUMIDITY))
    ));
    const isInForecast = data.some(({ forecast }) => (
      !_.isUndefined(_.get(forecast, AIR_TEMPERATURE))
      && !_.isUndefined(_.get(forecast, RELATIVE_HUMIDITY))
    ));
    return isInObservation || isInForecast;
  };
}
