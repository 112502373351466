import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TaskNav from '../components/TaskNav';
import { LOCATIONS, REAL_TIME } from '../constants/endpointIds';
import zones from '../data/locations';
import tasks from '../data/tasks';
import { useLoader } from '../lib/hooks';
import { getClosestStationForZone } from '../lib/utils';

const TaskNavContainer = () => {
  const params = useParams();
  let { realTimeLocationId, weatherLocationId } = params;
  const { taskId } = params;
  const userWeatherLocationId = useSelector((state) => _.get(state, 'auth.user.zone'));
  const userRealTimeLocationId = useSelector((state) => _.get(state, 'auth.user.station'));
  const locations = useSelector((state) => state.locations);
  if (!weatherLocationId) {
    weatherLocationId = userWeatherLocationId;
    if (weatherLocationId && !realTimeLocationId) {
      realTimeLocationId = userRealTimeLocationId;
    }
  }
  if (!weatherLocationId) {
    [weatherLocationId] = Object.keys(zones);
  }
  const task = tasks[taskId];
  const weatherLocation = locations[weatherLocationId];
  let finalRealTimeId = realTimeLocationId;
  if (!finalRealTimeId || !locations[finalRealTimeId]) {
    finalRealTimeId = getClosestStationForZone(weatherLocation, locations).id;
  }
  const realTimeLocation = locations[finalRealTimeId];
  const taskLocations = { realTime: realTimeLocation, weather: weatherLocation };
  return <TaskNav locations={taskLocations} task={task} />;
};

const TaskNavLoader = () => {
  const isLoading = useLoader([LOCATIONS, REAL_TIME]);
  if (isLoading) {
    return null;
  }
  return <TaskNavContainer />;
};

export default TaskNavLoader;
