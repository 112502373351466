import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as actions from '../actions';
import MapDisplaySelector from '../components/MapDisplaySelector';
import pages from '../data/dataPages';
import properties from '../data/properties';
import { useAction } from '../lib/hooks';

const MapDisplaySelectorContainer = () => {
  const { pageId, propertyId } = useParams();
  const { options, value } = useSelector((state) => {
    const page = new pages[pageId](state);
    return {
      options: page.getMapDisplayOptions(propertyId),
      value: page.generateMapDisplay(propertyId),
    };
  }, _.isEqual);
  const setMapDisplay = useAction(actions.setMapDisplay);
  const { getLabelForPropertyKey } = properties[propertyId];
  return (
    <MapDisplaySelector
      set={setMapDisplay}
      options={options}
      value={value}
      getLabelForPropertyKey={getLabelForPropertyKey}
    />
  );
};

export default MapDisplaySelectorContainer;
