const partners = [
  {
    logo: { background: 'white', file: 'agtac_jcu_logo.png' },
    name: 'AGTAC',
    url: 'https://www.jcu.edu.au/agtac/',
  },
  {
    logo: { background: 'white', file: 'bps_logo.png' },
    name: 'BPS',
    url: 'https://bps.net.au/',
  },
  {
    logo: { background: 'white', file: 'sqr_logo.png' },
    name: 'SQR',
    url: 'https://sqrsoftware.com/',
  },
  {
    logo: { background: 'white', file: 'csiro_logo.png' },
    name: 'CSIRO',
    url: 'https://www.csiro.au/',
  },
  {
    logo: { background: 'white', file: 'agritech_logo.png' },
    name: 'AgriTech',
    url: 'https://www.agritechsolutions.com.au/',
  },
];

export default partners;
