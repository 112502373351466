export const SINGLE = 'SINGLE';
export const BOX = 'BOX';
export const OBSERVATION = 'OBSERVATION';
export const BOX_CHART = 'BOX_CHART';
export const RAIN_CHART = 'RAIN_CHART';
export const DEFAULT_CHART = 'DEFAULT_CHART';
export const POP_CHART = 'POP_CHART';
export const COMBINED_RAIN_POP_CHART = 'COMBINED_RAIN_POP_CHART';
export const WIND_CHART = 'WIND_CHART';
export const MID_CHART = 'MID_CHART';
