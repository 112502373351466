import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Alert from '../components/Alert';

const AlertContainer = ({ id, children }) => {
  const isAuthed = useSelector((state) => state.auth.state === 'signedIn');
  return (
    <Alert isAuthed={isAuthed} id={id}>{children}</Alert>
  );
};

AlertContainer.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

export default AlertContainer;
