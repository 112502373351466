import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

export default function WidgetModalContent({
  value, setValue, onChange, onClose, autoFocus,
}) {
  const inputRef = useRef();
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  });
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Dashboard widget</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          ref={inputRef}
          onFocus={(e) => e.target.select()}
          placeholder="Widget name"
          type="text"
          value={value}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && value) {
              onChange(value);
            }
          }}
          onChange={(e) => setValue(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => onChange(value)}
          disabled={!value}
        >
          Ok
        </Button>
        <Button
          variant="outline-secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
}

WidgetModalContent.propTypes = {
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

WidgetModalContent.defaultProps = {
  autoFocus: true,
};
