import PropTypes from 'prop-types';
import React from 'react';

export default function HelpSubSection({
  title, example, text, cols,
}) {
  return (
    <div className="print-break-inside-avoid">
      <div className="row mt-5">
        <div className={`col-md-${cols}`} />
        <div className="col">
          <h4><u>{title}</u></h4>
        </div>
      </div>
      <div className="row mb-5">
        <div className={`col-md-${cols} text-md-end text-center`}>
          {example}
        </div>
        <div className="col">
          {text}
        </div>
      </div>
    </div>
  );
}

HelpSubSection.propTypes = {
  cols: PropTypes.number,
  example: PropTypes.node.isRequired,
  text: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};

HelpSubSection.defaultProps = {
  cols: 4,
};
