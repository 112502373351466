import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import OutsideClickHandler from 'react-outside-click-handler';

export default function Info({
  className, children, placement, color, info,
}) {
  const [show, setShow] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const target = useRef(null);
  if (!info) {
    return children;
  }
  return (
    <span className={`position-relative ${className} text-nowrap`}>
      <span
        role="button"
        ref={target}
        onClick={() => setShow(true)}
        onKeyPress={() => setShow(!show)}
        tabIndex={0}
        aria-label="More info"
      >
        <u>{children}</u>
        <FontAwesomeIcon
          className={`ms-1 align-text-top  text-${color}`}
          size="xs"
          icon={faQuestionCircle}
        />
      </span>
      <Overlay target={target.current} show={show} placement={placement}>
        {(props) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Tooltip {...props}>
            <OutsideClickHandler
              onOutsideClick={() => {
                setShow(false);
                setShowMore(false);
              }}
              display="inline"
            >
              {info.short}
              {!showMore && !!info.long && (
                <button
                  type="button"
                  className="btn-block btn btn-link btn-sm p-0 m-0 text-white"
                  onClick={() => setShowMore(true)}
                >
                  <u>show more</u>
                </button>
              )}
              {showMore && !!info.long && (
                <>
                  <hr className="bg-white my-2" />
                  {info.long}
                </>
              )}
            </OutsideClickHandler>
          </Tooltip>
        )}
      </Overlay>
    </span>
  );
}

Info.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  info: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      long: PropTypes.string,
      short: PropTypes.string,
    }),
  ]),
  placement: PropTypes.string,
};

Info.defaultProps = {
  children: null,
  className: '',
  color: 'info',
  info: false,
  placement: 'bottom',
};
