import _ from 'lodash';
import { RAIN } from '../constants/propertyIds';
import { MID } from '../constants/valueTypes';
import Property from './Property';
import RainProperty from './RainProperty';

// @TODO: Seems a bit wrong to declare rainProperty here as well as in data/properties
// but avoiding circular dependency.
const rainProperty = new RainProperty({ id: RAIN, unit: 'mm' });

export default class CropWaterUseProperty extends Property {
  getFormatted(data, options = {}) {
    if (_.isNumber(_.get(data, `${this.id}.${MID}`))) {
      const optionalUnit = options.unit ? this.unit : '';
      return `${data[this.id][MID].toFixed(this.decimalPoints)}${optionalUnit}`;
    }
    return super.getFormatted(data, options);
  }

  getRainFormatted = (data) => rainProperty.getFormatted(data, { unit: true });

  getTableData = (data, timeInterval) => {
    const scale = this.getScale({ timeIntervalId: timeInterval.id });
    const dates = data.map((item) => ({
      datetime: item.datetime,
      value: timeInterval.formatLong(item.datetime),
    }));
    const tableData = {
      body: [],
      head: { items: dates, name: timeInterval.heading },
    };
    const hasObservation = data.some((item) => !_.isUndefined(this.getValue(item.observation)));
    if (hasObservation) {
      tableData.body.push({
        items: data.map((item) => {
          if (_.isObject(item.observation) && this.id in item.observation) {
            return {
              color: scale(this.getValue(item.observation)).alpha(0.25).css(),
              datetime: item.datetime,
              value: this.getFormatted(item.observation, { unit: true }),
            };
          }
          return { datetime: item.datetime };
        }),
        name: 'Observed',
      });
    }
    tableData.body.push({
      items: [
        {
          items: data.map((item) => {
            if (_.isObject(item.forecast) && this.id in item.forecast) {
              const value = this.getValue(item.forecast);
              return {
                color: scale(_.isObject(value) ? value[MID] : value).alpha(0.25).css(),
                datetime: item.datetime,
                value: this.getFormatted(item.forecast, { unit: true }),
              };
            }
            return { datetime: item.datetime };
          }),
          name: 'CWU',
        },
        {
          items: data.map((item) => {
            const value = this.getRainFormatted(item.forecast);
            if (!_.isUndefined(value)) {
              return {
                datetime: item.datetime,
                value,
              };
            }
            return { datetime: item.datetime };
          }),
          name: 'Rain',
        },
      ],
      name: 'Forecast',
    });
    return tableData;
  };
}
