import _ from 'lodash';
import * as types from '../constants/actionTypes';

const initialState = {
  users: [],
  dataSourceUser: undefined,
};

export default function admin(state = initialState, action = {}) {
  switch (action.type) {
    case types.ENDPOINT_RECEIVED: {
      if (action.users) {
        return {
          ...state,
          users: action.users,
        };
      }
      return state;
    }

    case types.USER_SET: {
      return {
        ...state,
        dataSourceUser: _.get(action, 'user.id'),
      };
    }

    case types.ADMIN_DATA_SOURCE_USERNAME_CHANGED: {
      return {
        ...state,
        dataSourceUser: action.username,
      };
    }

    default: {
      return state;
    }
  }
}
