import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import MapItems from '../components/MapItems';

const MapLocationSelectorContainer = ({ value, options, ...rest }) => {
  const geoJson = useSelector((state) => options.map((id) => state.locations[id])
    .filter((location) => location.geometry)
    .map((location) => ({
      geometry: location.geometry,
      id: location.id,
      properties: {
        tooltip: {
          name: location.name,
        },
      },
      type: 'Feature',
    })), _.isEqual);
  const currentLocation = useSelector((state) => state.map.currentLocation);
  return (
    <MapItems
      geoJson={geoJson}
      locationId={value}
      currentLocation={currentLocation}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

MapLocationSelectorContainer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

MapLocationSelectorContainer.defaultProps = {
  value: false,
};

export default MapLocationSelectorContainer;
