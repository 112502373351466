import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { REAL_TIME } from '../constants/locationTypeIds';
import LocationSelector from '../containers/LocationSelector';

export default function AccountStation({ value, onChange }) {
  return (
    <Form.Group>
      <Form.Label>Station</Form.Label>
      <InputGroup>
        <LocationSelector
          onChange={(e) => {
            const station = e.target.value;
            if (value !== station) {
              onChange(station);
            }
          }}
          value={value}
          locationTypeId={REAL_TIME}
        />
      </InputGroup>
    </Form.Group>
  );
}

AccountStation.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

AccountStation.defaultProps = {
  onChange: _.noop,
  value: false,
};
