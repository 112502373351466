import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import * as actions from '../actions';
import Admin from '../components/Admin';
import Spinner from '../components/Spinner';
import { LOCATIONS, REAL_TIME, USERS } from '../constants/endpointIds';
import { useAction, useLoader } from '../lib/hooks';

const AdminContainer = () => {
  const dataSourceUser = useSelector(
    (state) => state.admin.dataSourceUser || state.auth.user.id,
    _.isEqual,
  );
  const users = useSelector((state) => state.admin.users.map((user) => ({
    ...user,
    station: _.get(state.locations[user.station], 'name'),
    zone: _.get(state.locations[user.zone], 'name'),
  })), _.isEqual);
  const setDataSourceUsername = useAction(actions.setDataSourceUsername);
  return (
    <Admin
      users={users}
      setDataSourceUsername={setDataSourceUsername}
      dataSourceUser={dataSourceUser}
    />
  );
};

const AdminLoader = () => {
  const isLoading = useLoader([USERS, LOCATIONS, REAL_TIME]);
  if (isLoading) {
    return <Spinner />;
  }
  return <AdminContainer />;
};

export default AdminLoader;
