import * as constants from '../constants/propertyIds';
import Property from './Property';

export default class RainProperty extends Property {
  generateStats = (forecast) => {
    const stats = [];
    if (forecast.properties[constants.WET_DAYS]) {
      if (forecast.properties[constants.WET_DAYS]['2days'] >= 75) {
        stats.push('There is a high chance of at least 2 wet days this week');
      }
      if (forecast.properties[constants.VERY_WET_DAYS]['1days'] >= 50) {
        stats.push('There is a medium chance of at least 1 very wet day this week');
      }
    }
    return stats;
  };
}
