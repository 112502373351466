import { ERROR } from '../constants/pageIds';
import * as text from '../data/text';

export default class ErrorPage {
  static id = ERROR;

  static label = 'Error';

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: text.ERROR_PAGE_DESCRIPTION },
        { name: 'robots', content: 'noindex' },
      ],
    };
  }
}
