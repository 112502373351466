import AboutPage from '../pages/AboutPage';
import AdminPage from '../pages/AdminPage';
import AlarmsPage from '../pages/AlarmsPage';
import AuthPage from '../pages/AuthPage';
import ContactPage from '../pages/ContactPage';
import CwuPage from '../pages/CwuPage';
import DashboardPage from '../pages/DashboardPage';
import DemoFarmPage from '../pages/DemoFarmPage';
import EnsoPage from '../pages/EnsoPage';
import ErrorPage from '../pages/ErrorPage';
import FishingPage from '../pages/FishingPage';
import ForecastPage from '../pages/ForecastPage';
import HelpPage from '../pages/HelpPage';
import HomePage from '../pages/HomePage';
import InstallingPage from '../pages/InstallingPage';
import IrrigationPage from '../pages/IrrigationPage';
import MyFarmPage from '../pages/MyFarmPage';
import RealTimePage from '../pages/RealTimePage';
import RemoteSensingPage from '../pages/RemoteSensingPage';
import SeasonalPage from '../pages/SeasonalPage';
import SettingsPage from '../pages/SettingsPage';
import TaskPage from '../pages/TaskPage';
import TermsOfUsePage from '../pages/TermsOfUsePage';

const pages = {
  [AboutPage.id]: AboutPage,
  [AdminPage.id]: AdminPage,
  [AlarmsPage.id]: AlarmsPage,
  [AuthPage.id]: AuthPage,
  [ContactPage.id]: ContactPage,
  [CwuPage.id]: CwuPage,
  [DashboardPage.id]: DashboardPage,
  [DemoFarmPage.id]: DemoFarmPage,
  [EnsoPage.id]: EnsoPage,
  [ErrorPage.id]: ErrorPage,
  [FishingPage.id]: FishingPage,
  [ForecastPage.id]: ForecastPage,
  [HelpPage.id]: HelpPage,
  [HomePage.id]: HomePage,
  [InstallingPage.id]: InstallingPage,
  [IrrigationPage.id]: IrrigationPage,
  [MyFarmPage.id]: MyFarmPage,
  [RealTimePage.id]: RealTimePage,
  [RemoteSensingPage.id]: RemoteSensingPage,
  [SeasonalPage.id]: SeasonalPage,
  [SettingsPage.id]: SettingsPage,
  [TaskPage.id]: TaskPage,
  [TermsOfUsePage.id]: TermsOfUsePage,
};

export default pages;
