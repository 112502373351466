export const AIR_TEMPERATURE = 'airTemperature';
export const COOL_DAYS = 'coolDays';
export const DELTA_T = 'deltaT';
export const ET = 'et';
export const ETC = 'etc';
export const FIRST_IRRIG_DATE_SUMMARY = 'irrigFirstDateSummary';
export const FIRST_IRRIG_POP = 'irrigFirstPop';
export const FROST_DAYS = 'frostDays';
export const HOT_DAYS = 'hotDays';
export const IRRIGATION = 'irrigation';
export const MAX_RH = 'maxRh';
export const MAX_TEMPERATURE = 'maxTemperature';
export const MIN_RH = 'minRh';
export const MIN_TEMPERATURE = 'minTemperature';
export const NET_APP = 'netApp';
export const RADIATION = 'radiation';
export const RAIN = 'rain';
export const RAIN_24_HOUR_TOTAL = 'rain24HourTotal';
export const RAIN_POP = 'rainPop';
export const REAL_TIME_RADIATION = 'realTimeRadiation';
export const RELATIVE_HUMIDITY = 'relativeHumidity';
export const SOIL_TEMPERATURE = 'soilTemperature';
export const VERY_WET_DAYS = 'veryWetDays';
export const WB = 'wb';
export const WET_DAYS = 'wetDays';
export const WIND_DIRECTION = 'windDirection';
export const WIND_SPEED = 'windSpeed';
