import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as actions from '../actions';
import Placeholder from '../components/Placeholder';
import RealTime from '../components/RealTime';
import pages from '../data/dataPages';
import { useLoader, useAction } from '../lib/hooks';

const RealTimeContainer = () => {
  const { pageId } = useParams();
  const Page = pages[pageId];
  const items = useSelector((state) => {
    const page = new Page(state);
    return page.generateOverviewLocationListItems();
  }, _.isEqual);
  const currentLocation = useSelector((state) => state.map.currentLocation);
  const setPageViewDateTimeRange = useAction(actions.setPageViewDateTimeRange);
  return (
    <RealTime
      items={items}
      currentLocation={currentLocation}
      setPageViewDateTimeRange={setPageViewDateTimeRange}
    />
  );
};

const RealTimeLoader = () => {
  const { pageId } = useParams();
  const Page = pages[pageId];
  const sources = useSelector((state) => {
    const page = new Page(state);
    return page.getSources();
  }, _.isEqual);
  const isLoading = useLoader(sources);
  if (isLoading) {
    return <Placeholder />;
  }
  return <RealTimeContainer />;
};

export default RealTimeLoader;
