/* eslint-disable */

const locations = {
  1: {
    id: '1',
    name: 'Giru/Barratta',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [147.225, -19.525],
            [147.275, -19.525],
            [147.275, -19.475],
            [147.325, -19.475],
            [147.325, -19.525],
            [147.325, -19.575],
            [147.275, -19.575],
            [147.275, -19.625],
            [147.225, -19.625],
            [147.175, -19.625],
            [147.175, -19.675],
            [147.125, -19.675],
            [147.075, -19.675],
            [147.025, -19.675],
            [147.025, -19.625],
            [147.025, -19.575],
            [147.025, -19.525],
            [147.025, -19.475],
            [147.075, -19.475],
            [147.125, -19.475],
            [147.175, -19.475],
            [147.225, -19.475],
            [147.225, -19.525],
          ],
        ],
      ],
    },
    type: 'DEFAULT',
  },
  2: {
    id: '2',
    name: 'Upper Haughton/Clare',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [147.125, -19.925],
            [147.075, -19.925],
            [147.075, -19.875],
            [147.075, -19.825],
            [147.075, -19.775],
            [147.025, -19.775],
            [147.025, -19.725],
            [146.975, -19.725],
            [146.975, -19.675],
            [147.025, -19.675],
            [147.075, -19.675],
            [147.125, -19.675],
            [147.175, -19.675],
            [147.175, -19.725],
            [147.225, -19.725],
            [147.225, -19.775],
            [147.275, -19.775],
            [147.325, -19.775],
            [147.325, -19.825],
            [147.325, -19.875],
            [147.275, -19.875],
            [147.225, -19.875],
            [147.225, -19.925],
            [147.275, -19.925],
            [147.275, -19.975],
            [147.225, -19.975],
            [147.175, -19.975],
            [147.175, -19.925],
            [147.125, -19.925],
          ],
        ],
      ],
    },
    type: 'DEFAULT',
  },
  3: {
    id: '3',
    name: 'Millaroo/Dalbeg',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [147.325, -20.275],
            [147.325, -20.325],
            [147.275, -20.325],
            [147.275, -20.275],
            [147.275, -20.225],
            [147.275, -20.175],
            [147.325, -20.175],
            [147.325, -20.225],
            [147.325, -20.275],
          ],
        ],
        [
          [
            [147.275, -20.125],
            [147.275, -20.175],
            [147.225, -20.175],
            [147.225, -20.125],
            [147.225, -20.075],
            [147.225, -20.025],
            [147.225, -19.975],
            [147.275, -19.975],
            [147.275, -20.025],
            [147.325, -20.025],
            [147.325, -20.075],
            [147.275, -20.075],
            [147.275, -20.125],
          ],
        ],
      ],
    },
    type: 'DEFAULT',
  },
  4: {
    id: '4',
    name: 'Home Hill/Mona Park',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [147.525, -19.775],
            [147.525, -19.825],
            [147.475, -19.825],
            [147.475, -19.775],
            [147.425, -19.775],
            [147.425, -19.825],
            [147.375, -19.825],
            [147.325, -19.825],
            [147.325, -19.775],
            [147.275, -19.775],
            [147.225, -19.775],
            [147.225, -19.725],
            [147.175, -19.725],
            [147.175, -19.675],
            [147.175, -19.625],
            [147.225, -19.625],
            [147.275, -19.625],
            [147.275, -19.575],
            [147.325, -19.575],
            [147.325, -19.625],
            [147.375, -19.625],
            [147.425, -19.625],
            [147.475, -19.625],
            [147.525, -19.625],
            [147.575, -19.625],
            [147.575, -19.675],
            [147.575, -19.725],
            [147.575, -19.775],
            [147.525, -19.775],
          ],
        ],
      ],
    },
    type: 'DEFAULT',
  },
  5: {
    id: '5',
    name: 'Ayr/Brandon',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [147.525, -19.575],
            [147.575, -19.575],
            [147.575, -19.625],
            [147.525, -19.625],
            [147.475, -19.625],
            [147.425, -19.625],
            [147.375, -19.625],
            [147.325, -19.625],
            [147.325, -19.575],
            [147.325, -19.525],
            [147.325, -19.475],
            [147.375, -19.475],
            [147.425, -19.475],
            [147.475, -19.475],
            [147.525, -19.475],
            [147.525, -19.525],
            [147.525, -19.575],
          ],
        ],
      ],
    },
    type: 'DEFAULT',
  },
  /*10: {
    id: '10',
    name: 'Wet Tropics',
    geometry: {
      "type": "MultiPolygon", "coordinates": [[[[143.925, -19.525], [143.925, -19.475],
      [143.975, -19.475], [143.975, -19.525],
      [144.025, -19.525], [144.025, -19.475],
      [144.025, -19.425], [144.025, -19.375],
      [144.075, -19.375], [144.125, -19.375],
      [144.175, -19.375], [144.175, -19.425],
      [144.225, -19.425], [144.225, -19.375],
      [144.275, -19.375], [144.275, -19.325],
      [144.325, -19.325], [144.325, -19.375],
      [144.325, -19.425], [144.275, -19.425],
      [144.275, -19.475], [144.325, -19.475],
      [144.375, -19.475], [144.375, -19.525],
      [144.375, -19.575], [144.325, -19.575],
      [144.275, -19.575], [144.275, -19.625],
      [144.225, -19.625], [144.225, -19.575],
      [144.175, -19.575], [144.125, -19.575],
      [144.075, -19.575], [144.025, -19.575],
      [144.025, -19.625], [143.975, -19.625],
      [143.925, -19.625], [143.875, -19.625],
      [143.875, -19.575], [143.925, -19.575],
      [143.925, -19.525]]], [
        [[144.475, -19.425], [144.475, -19.475],
        [144.425, -19.475], [144.425, -19.425],
        [144.475, -19.425]]], [
        [[144.075, -19.175], [144.075, -19.225],
        [144.025, -19.225], [144.025, -19.175],
        [144.075, -19.175]]], [
        [[144.975, -17.625], [144.975, -17.675],
        [144.975, -17.725], [144.975, -17.775],
        [144.925, -17.775], [144.925, -17.825],
        [144.925, -17.875], [144.875, -17.875],
        [144.875, -17.925], [144.875, -17.975],
        [144.875, -18.025], [144.875, -18.075],
        [144.875, -18.125], [144.825, -18.125],
        [144.825, -18.175], [144.825, -18.225],
        [144.775, -18.225], [144.775, -18.275],
        [144.775, -18.325], [144.725, -18.325],
        [144.725, -18.375], [144.675, -18.375],
        [144.675, -18.425], [144.675, -18.475],
        [144.675, -18.525], [144.625, -18.525],
        [144.625, -18.575], [144.575, -18.575],
        [144.575, -18.525], [144.525, -18.525],
        [144.525, -18.475], [144.475, -18.475],
        [144.475, -18.425], [144.475, -18.375],
        [144.475, -18.325], [144.525, -18.325],
        [144.525, -18.275], [144.475, -18.275],
        [144.475, -18.225], [144.475, -18.175],
        [144.475, -18.125], [144.475, -18.075],
        [144.475, -18.025], [144.525, -18.025],
        [144.525, -17.975], [144.575, -17.975],
        [144.575, -17.925], [144.575, -17.875],
        [144.525, -17.875], [144.525, -17.825],
        [144.525, -17.775], [144.575, -17.775],
        [144.575, -17.725], [144.525, -17.725],
        [144.525, -17.675], [144.575, -17.675],
        [144.575, -17.625], [144.575, -17.575],
        [144.575, -17.525], [144.625, -17.525],
        [144.625, -17.475], [144.625, -17.425],
        [144.575, -17.425], [144.575, -17.375],
        [144.525, -17.375], [144.525, -17.325],
        [144.525, -17.275], [144.575, -17.275],
        [144.575, -17.225], [144.625, -17.225],
        [144.625, -17.175], [144.675, -17.175],
        [144.675, -17.125], [144.675, -17.075],
        [144.625, -17.075], [144.625, -17.025],
        [144.575, -17.025], [144.575, -16.975],
        [144.525, -16.975], [144.475, -16.975],
        [144.475, -16.925], [144.425, -16.925],
        [144.425, -16.875], [144.475, -16.875],
        [144.525, -16.875], [144.575, -16.875],
        [144.575, -16.825], [144.625, -16.825],
        [144.675, -16.825], [144.675, -16.775],
        [144.725, -16.775], [144.775, -16.775],
        [144.825, -16.775], [144.875, -16.775],
        [144.925, -16.775], [144.925, -16.725],
        [144.925, -16.675], [144.875, -16.675],
        [144.875, -16.625], [144.925, -16.625],
        [144.925, -16.575], [144.875, -16.575],
        [144.875, -16.525], [144.825, -16.525],
        [144.775, -16.525], [144.725, -16.525],
        [144.675, -16.525], [144.625, -16.525],
        [144.625, -16.475], [144.675, -16.475],
        [144.675, -16.425], [144.625, -16.425],
        [144.625, -16.375], [144.575, -16.375],
        [144.575, -16.425], [144.525, -16.425],
        [144.475, -16.425], [144.475, -16.375],
        [144.525, -16.375], [144.525, -16.325],
        [144.575, -16.325], [144.625, -16.325],
        [144.675, -16.325], [144.675, -16.275],
        [144.725, -16.275], [144.775, -16.275],
        [144.775, -16.325], [144.825, -16.325],
        [144.825, -16.375], [144.875, -16.375],
        [144.875, -16.325], [144.925, -16.325],
        [144.975, -16.325], [144.975, -16.275],
        [144.975, -16.225], [145.025, -16.225],
        [145.075, -16.225], [145.075, -16.275],
        [145.125, -16.275], [145.125, -16.325],
        [145.175, -16.325], [145.175, -16.375],
        [145.225, -16.375], [145.225, -16.425],
        [145.225, -16.475], [145.275, -16.475],
        [145.275, -16.525], [145.325, -16.525],
        [145.325, -16.575], [145.375, -16.575],
        [145.375, -16.625], [145.425, -16.625],
        [145.475, -16.625], [145.475, -16.675],
        [145.475, -16.725], [145.425, -16.725],
        [145.375, -16.725], [145.375, -16.775],
        [145.425, -16.775], [145.425, -16.825],
        [145.375, -16.825], [145.375, -16.875],
        [145.425, -16.875], [145.425, -16.925],
        [145.425, -16.975], [145.375, -16.975],
        [145.375, -17.025], [145.325, -17.025],
        [145.325, -17.075], [145.375, -17.075],
        [145.375, -17.125], [145.375, -17.175],
        [145.425, -17.175], [145.425, -17.225],
        [145.425, -17.275], [145.425, -17.325],
        [145.375, -17.325], [145.375, -17.375],
        [145.325, -17.375], [145.325, -17.425],
        [145.275, -17.425], [145.225, -17.425],
        [145.225, -17.475], [145.225, -17.525],
        [145.175, -17.525], [145.125, -17.525],
        [145.125, -17.575], [145.075, -17.575],
        [145.075, -17.625], [145.025, -17.625],
        [144.975, -17.625]],
        [[144.875, -16.925], [144.925, -16.925],
        [144.925, -16.875], [144.925, -16.825],
        [144.875, -16.825], [144.875, -16.875],
        [144.875, -16.925]]], [
        [[144.625, -19.325], [144.625, -19.375],
        [144.575, -19.375], [144.575, -19.325],
        [144.625, -19.325]]], [
        [[144.675, -19.275], [144.675, -19.325],
        [144.625, -19.325], [144.625, -19.275],
        [144.675, -19.275]]], [
        [[144.425, -16.375], [144.425, -16.325],
        [144.475, -16.325], [144.475, -16.375],
        [144.425, -16.375]]]]
    },
    type: 'DEFAULT',
  },
  9: {
    id: '9',
    name: 'Einsaleigh Uplands',
    geometry: {
      "type": "MultiPolygon", "coordinates": [[[[144.175, -16.475], [144.125, -16.475],
      [144.125, -16.425], [144.125, -16.375],
      [144.125, -16.325], [144.125, -16.275],
      [144.075, -16.275], [144.075, -16.225],
      [144.075, -16.175], [144.075, -16.125],
      [144.125, -16.125], [144.125, -16.175],
      [144.125, -16.225], [144.175, -16.225],
      [144.175, -16.275], [144.225, -16.275],
      [144.225, -16.325], [144.275, -16.325],
      [144.325, -16.325], [144.325, -16.275],
      [144.375, -16.275], [144.375, -16.325],
      [144.425, -16.325], [144.425, -16.375],
      [144.475, -16.375], [144.475, -16.425],
      [144.525, -16.425], [144.575, -16.425],
      [144.575, -16.375], [144.625, -16.375],
      [144.625, -16.425], [144.675, -16.425],
      [144.675, -16.475], [144.625, -16.475],
      [144.625, -16.525], [144.675, -16.525],
      [144.725, -16.525], [144.775, -16.525],
      [144.825, -16.525], [144.875, -16.525],
      [144.875, -16.575], [144.925, -16.575],
      [144.925, -16.625], [144.875, -16.625],
      [144.875, -16.675], [144.925, -16.675],
      [144.925, -16.725], [144.925, -16.775],
      [144.875, -16.775], [144.825, -16.775],
      [144.775, -16.775], [144.725, -16.775],
      [144.675, -16.775], [144.675, -16.825],
      [144.625, -16.825], [144.575, -16.825],
      [144.575, -16.875], [144.525, -16.875],
      [144.475, -16.875], [144.425, -16.875],
      [144.425, -16.925], [144.475, -16.925],
      [144.475, -16.975], [144.525, -16.975],
      [144.575, -16.975], [144.575, -17.025],
      [144.625, -17.025], [144.625, -17.075],
      [144.675, -17.075], [144.675, -17.125],
      [144.675, -17.175], [144.625, -17.175],
      [144.625, -17.225], [144.575, -17.225],
      [144.575, -17.275], [144.525, -17.275],
      [144.525, -17.325], [144.525, -17.375],
      [144.575, -17.375], [144.575, -17.425],
      [144.625, -17.425], [144.625, -17.475],
      [144.625, -17.525], [144.575, -17.525],
      [144.575, -17.575], [144.575, -17.625],
      [144.575, -17.675], [144.525, -17.675],
      [144.525, -17.725], [144.575, -17.725],
      [144.575, -17.775], [144.525, -17.775],
      [144.525, -17.825], [144.525, -17.875],
      [144.575, -17.875], [144.575, -17.925],
      [144.575, -17.975], [144.525, -17.975],
      [144.525, -18.025], [144.475, -18.025],
      [144.475, -18.075], [144.475, -18.125],
      [144.475, -18.175], [144.475, -18.225],
      [144.475, -18.275], [144.525, -18.275],
      [144.525, -18.325], [144.475, -18.325],
      [144.475, -18.375], [144.475, -18.425],
      [144.475, -18.475], [144.525, -18.475],
      [144.525, -18.525], [144.575, -18.525],
      [144.575, -18.575], [144.625, -18.575],
      [144.625, -18.625], [144.625, -18.675],
      [144.625, -18.725], [144.625, -18.775],
      [144.575, -18.775], [144.575, -18.825],
      [144.575, -18.875], [144.625, -18.875],
      [144.625, -18.925], [144.675, -18.925],
      [144.725, -18.925], [144.725, -18.975],
      [144.725, -19.025], [144.725, -19.075],
      [144.725, -19.125], [144.775, -19.125],
      [144.775, -19.175], [144.725, -19.175],
      [144.725, -19.225], [144.675, -19.225],
      [144.675, -19.275], [144.625, -19.275],
      [144.625, -19.325], [144.575, -19.325],
      [144.575, -19.375], [144.525, -19.375],
      [144.525, -19.425], [144.475, -19.425],
      [144.425, -19.425], [144.425, -19.475],
      [144.375, -19.475], [144.325, -19.475],
      [144.275, -19.475], [144.275, -19.425],
      [144.325, -19.425], [144.325, -19.375],
      [144.325, -19.325], [144.275, -19.325],
      [144.275, -19.375], [144.225, -19.375],
      [144.225, -19.425], [144.175, -19.425],
      [144.175, -19.375], [144.125, -19.375],
      [144.075, -19.375], [144.025, -19.375],
      [144.025, -19.425], [144.025, -19.475],
      [144.025, -19.525], [143.975, -19.525],
      [143.975, -19.475], [143.925, -19.475],
      [143.925, -19.525], [143.925, -19.575],
      [143.875, -19.575], [143.875, -19.625],
      [143.825, -19.625], [143.825, -19.575],
      [143.775, -19.575], [143.725, -19.575],
      [143.675, -19.575], [143.625, -19.575],
      [143.575, -19.575], [143.575, -19.525],
      [143.525, -19.525], [143.475, -19.525],
      [143.475, -19.575], [143.425, -19.575],
      [143.375, -19.575], [143.375, -19.625],
      [143.325, -19.625], [143.325, -19.575],
      [143.275, -19.575], [143.275, -19.525],
      [143.225, -19.525], [143.175, -19.525],
      [143.175, -19.475], [143.225, -19.475],
      [143.225, -19.425], [143.225, -19.375],
      [143.275, -19.375], [143.275, -19.325],
      [143.275, -19.275], [143.275, -19.225],
      [143.225, -19.225], [143.225, -19.175],
      [143.175, -19.175], [143.175, -19.125],
      [143.225, -19.125], [143.225, -19.075],
      [143.225, -19.025], [143.175, -19.025],
      [143.175, -18.975], [143.225, -18.975],
      [143.275, -18.975], [143.275, -18.925],
      [143.325, -18.925], [143.325, -18.875],
      [143.325, -18.825], [143.375, -18.825],
      [143.425, -18.825], [143.475, -18.825],
      [143.475, -18.775], [143.475, -18.725],
      [143.475, -18.675], [143.475, -18.625],
      [143.475, -18.575], [143.425, -18.575],
      [143.425, -18.525], [143.475, -18.525],
      [143.475, -18.475], [143.525, -18.475],
      [143.575, -18.475], [143.575, -18.425],
      [143.575, -18.375], [143.625, -18.375],
      [143.625, -18.325], [143.675, -18.325],
      [143.675, -18.275], [143.625, -18.275],
      [143.625, -18.225], [143.625, -18.175],
      [143.625, -18.125], [143.625, -18.075],
      [143.625, -18.025], [143.625, -17.975],
      [143.675, -17.975], [143.675, -17.925],
      [143.725, -17.925], [143.725, -17.875],
      [143.775, -17.875], [143.775, -17.825],
      [143.775, -17.775], [143.775, -17.725],
      [143.725, -17.725], [143.725, -17.675],
      [143.675, -17.675], [143.675, -17.625],
      [143.725, -17.625], [143.725, -17.575],
      [143.775, -17.575], [143.775, -17.525],
      [143.775, -17.475], [143.775, -17.425],
      [143.825, -17.425], [143.825, -17.375],
      [143.825, -17.325], [143.825, -17.275],
      [143.825, -17.225], [143.825, -17.175],
      [143.825, -17.125], [143.875, -17.125],
      [143.875, -17.075], [143.925, -17.075],
      [143.925, -17.025], [143.975, -17.025],
      [143.975, -16.975], [144.025, -16.975],
      [144.025, -16.925], [144.025, -16.875],
      [144.025, -16.825], [144.025, -16.775],
      [144.075, -16.775], [144.075, -16.725],
      [144.075, -16.675], [144.075, -16.625],
      [144.075, -16.575], [144.075, -16.525],
      [144.125, -16.525], [144.175, -16.525],
      [144.175, -16.475]],
      [[144.075, -19.225], [144.075, -19.175],
      [144.025, -19.175], [144.025, -19.225],
      [144.075, -19.225]]], [
        [[144.925, -16.825], [144.925, -16.875],
        [144.925, -16.925], [144.875, -16.925],
        [144.875, -16.875], [144.875, -16.825],
        [144.925, -16.825]]], [
        [[143.275, -19.625], [143.225, -19.625],
        [143.225, -19.575], [143.275, -19.575],
        [143.275, -19.625]]], [
        [[143.125, -18.925], [143.075, -18.925],
        [143.075, -18.875], [143.125, -18.875],
        [143.125, -18.925]]]]
    },
    type: 'DEFAULT',
  },
  8: {
    id: '8',
    name: 'Mitchell River Catchment',
    geometry: {
      "type": "MultiPolygon", "coordinates": [[[[143.225, -17.725], [143.175, -17.725],
      [143.125, -17.725], [143.125, -17.675],
      [143.075, -17.675], [143.075, -17.625],
      [143.075, -17.575], [143.025, -17.575],
      [143.025, -17.525], [142.975, -17.525],
      [142.975, -17.475], [142.925, -17.475],
      [142.925, -17.425], [142.925, -17.375],
      [142.875, -17.375], [142.875, -17.325],
      [142.825, -17.325], [142.825, -17.275],
      [142.775, -17.275], [142.725, -17.275],
      [142.725, -17.225], [142.675, -17.225],
      [142.675, -17.175], [142.625, -17.175],
      [142.575, -17.175], [142.525, -17.175],
      [142.525, -17.125], [142.475, -17.125],
      [142.425, -17.125], [142.375, -17.125],
      [142.325, -17.125], [142.275, -17.125],
      [142.225, -17.125], [142.175, -17.125],
      [142.125, -17.125], [142.075, -17.125],
      [142.025, -17.125], [141.975, -17.125],
      [141.925, -17.125], [141.875, -17.125],
      [141.825, -17.125], [141.775, -17.125],
      [141.725, -17.125], [141.675, -17.125],
      [141.625, -17.125], [141.625, -17.175],
      [141.575, -17.175], [141.525, -17.175],
      [141.475, -17.175], [141.425, -17.175],
      [141.425, -17.225], [141.375, -17.225],
      [141.325, -17.225], [141.275, -17.225],
      [141.225, -17.225], [141.225, -17.275],
      [141.175, -17.275], [141.125, -17.275],
      [141.125, -17.225], [141.075, -17.225],
      [141.025, -17.225], [140.975, -17.225],
      [140.925, -17.225], [140.875, -17.225],
      [140.875, -17.175], [140.925, -17.175],
      [140.925, -17.125], [140.925, -17.075],
      [140.925, -17.025], [140.925, -16.975],
      [140.925, -16.925], [140.975, -16.925],
      [140.975, -16.875], [141.025, -16.875],
      [141.025, -16.825], [141.075, -16.825],
      [141.075, -16.775], [141.125, -16.775],
      [141.125, -16.725], [141.175, -16.725],
      [141.175, -16.675], [141.175, -16.625],
      [141.175, -16.575], [141.225, -16.575],
      [141.225, -16.525], [141.275, -16.525],
      [141.275, -16.475], [141.275, -16.425],
      [141.275, -16.375], [141.275, -16.325],
      [141.325, -16.325], [141.325, -16.275],
      [141.325, -16.225], [141.325, -16.175],
      [141.375, -16.175], [141.375, -16.125],
      [141.375, -16.075], [141.375, -16.025],
      [141.375, -15.975], [141.325, -15.975],
      [141.325, -15.925], [141.325, -15.875],
      [141.375, -15.875], [141.375, -15.925],
      [141.425, -15.925], [141.425, -15.875],
      [141.425, -15.825], [141.475, -15.825],
      [141.475, -15.875], [141.525, -15.875],
      [141.575, -15.875], [141.625, -15.875],
      [141.675, -15.875], [141.725, -15.875],
      [141.725, -15.925], [141.775, -15.925],
      [141.825, -15.925], [141.825, -15.875],
      [141.875, -15.875], [141.875, -15.925],
      [141.925, -15.925], [141.975, -15.925],
      [142.025, -15.925], [142.075, -15.925],
      [142.075, -15.975], [142.125, -15.975],
      [142.125, -15.925], [142.175, -15.925],
      [142.225, -15.925], [142.275, -15.925],
      [142.275, -15.975], [142.325, -15.975],
      [142.325, -15.925], [142.375, -15.925],
      [142.375, -15.975], [142.425, -15.975],
      [142.475, -15.975], [142.525, -15.975],
      [142.525, -16.025], [142.575, -16.025],
      [142.625, -16.025], [142.625, -16.075],
      [142.675, -16.075], [142.725, -16.075],
      [142.775, -16.075], [142.825, -16.075],
      [142.875, -16.075], [142.925, -16.075],
      [142.975, -16.075], [143.025, -16.075],
      [143.075, -16.075], [143.075, -16.125],
      [143.125, -16.125], [143.175, -16.125],
      [143.225, -16.125], [143.225, -16.075],
      [143.275, -16.075], [143.325, -16.075],
      [143.325, -16.025], [143.375, -16.025],
      [143.425, -16.025], [143.425, -16.075],
      [143.475, -16.075], [143.525, -16.075],
      [143.575, -16.075], [143.625, -16.075],
      [143.625, -16.125], [143.675, -16.125],
      [143.725, -16.125], [143.725, -16.175],
      [143.775, -16.175], [143.825, -16.175],
      [143.825, -16.125], [143.875, -16.125],
      [143.925, -16.125], [143.975, -16.125],
      [144.025, -16.125], [144.075, -16.125],
      [144.075, -16.175], [144.075, -16.225],
      [144.075, -16.275], [144.125, -16.275],
      [144.125, -16.325], [144.125, -16.375],
      [144.125, -16.425], [144.125, -16.475],
      [144.175, -16.475], [144.175, -16.525],
      [144.125, -16.525], [144.075, -16.525],
      [144.075, -16.575], [144.075, -16.625],
      [144.075, -16.675], [144.075, -16.725],
      [144.075, -16.775], [144.025, -16.775],
      [144.025, -16.825], [144.025, -16.875],
      [144.025, -16.925], [144.025, -16.975],
      [143.975, -16.975], [143.975, -17.025],
      [143.925, -17.025], [143.925, -17.075],
      [143.875, -17.075], [143.875, -17.125],
      [143.825, -17.125], [143.825, -17.175],
      [143.825, -17.225], [143.825, -17.275],
      [143.825, -17.325], [143.825, -17.375],
      [143.825, -17.425], [143.775, -17.425],
      [143.775, -17.475], [143.775, -17.525],
      [143.775, -17.575], [143.725, -17.575],
      [143.725, -17.625], [143.675, -17.625],
      [143.675, -17.675], [143.725, -17.675],
      [143.725, -17.725], [143.775, -17.725],
      [143.775, -17.775], [143.775, -17.825],
      [143.775, -17.875], [143.725, -17.875],
      [143.725, -17.925], [143.675, -17.925],
      [143.675, -17.875], [143.625, -17.875],
      [143.575, -17.875], [143.575, -17.825],
      [143.575, -17.775], [143.525, -17.775],
      [143.475, -17.775], [143.475, -17.725],
      [143.425, -17.725], [143.375, -17.725],
      [143.325, -17.725], [143.275, -17.725],
      [143.275, -17.675], [143.225, -17.675],
      [143.225, -17.725]]]]
    },
    type: 'DEFAULT',
  },
  7: {
    id: '7',
    name: 'Staaten/Gilbert River Catchment',
    geometry: {
      "type": "MultiPolygon", "coordinates": [[[[142.025, -17.125], [142.075, -17.125],
      [142.125, -17.125], [142.175, -17.125],
      [142.225, -17.125], [142.275, -17.125],
      [142.325, -17.125], [142.375, -17.125],
      [142.425, -17.125], [142.475, -17.125],
      [142.525, -17.125], [142.525, -17.175],
      [142.575, -17.175], [142.625, -17.175],
      [142.675, -17.175], [142.675, -17.225],
      [142.725, -17.225], [142.725, -17.275],
      [142.775, -17.275], [142.825, -17.275],
      [142.825, -17.325], [142.875, -17.325],
      [142.875, -17.375], [142.925, -17.375],
      [142.925, -17.425], [142.925, -17.475],
      [142.975, -17.475], [142.975, -17.525],
      [143.025, -17.525], [143.025, -17.575],
      [143.075, -17.575], [143.075, -17.625],
      [143.075, -17.675], [143.125, -17.675],
      [143.125, -17.725], [143.175, -17.725],
      [143.225, -17.725], [143.225, -17.675],
      [143.275, -17.675], [143.275, -17.725],
      [143.325, -17.725], [143.375, -17.725],
      [143.425, -17.725], [143.475, -17.725],
      [143.475, -17.775], [143.525, -17.775],
      [143.575, -17.775], [143.575, -17.825],
      [143.575, -17.875], [143.625, -17.875],
      [143.675, -17.875], [143.675, -17.925],
      [143.675, -17.975], [143.625, -17.975],
      [143.625, -18.025], [143.625, -18.075],
      [143.625, -18.125], [143.625, -18.175],
      [143.625, -18.225], [143.625, -18.275],
      [143.675, -18.275], [143.675, -18.325],
      [143.625, -18.325], [143.625, -18.375],
      [143.575, -18.375], [143.575, -18.425],
      [143.575, -18.475], [143.525, -18.475],
      [143.475, -18.475], [143.475, -18.525],
      [143.425, -18.525], [143.425, -18.575],
      [143.475, -18.575], [143.475, -18.625],
      [143.475, -18.675], [143.475, -18.725],
      [143.475, -18.775], [143.475, -18.825],
      [143.425, -18.825], [143.375, -18.825],
      [143.325, -18.825], [143.325, -18.875],
      [143.325, -18.925], [143.275, -18.925],
      [143.225, -18.925], [143.175, -18.925],
      [143.125, -18.925], [143.125, -18.875],
      [143.075, -18.875], [143.075, -18.825],
      [143.025, -18.825], [143.025, -18.775],
      [143.025, -18.725], [142.975, -18.725],
      [142.925, -18.725], [142.875, -18.725],
      [142.875, -18.675], [142.825, -18.675],
      [142.775, -18.675], [142.775, -18.625],
      [142.725, -18.625], [142.675, -18.625],
      [142.625, -18.625], [142.625, -18.575],
      [142.575, -18.575], [142.525, -18.575],
      [142.475, -18.575], [142.475, -18.525],
      [142.425, -18.525], [142.375, -18.525],
      [142.325, -18.525], [142.325, -18.475],
      [142.275, -18.475], [142.225, -18.475],
      [142.175, -18.475], [142.175, -18.425],
      [142.125, -18.425], [142.125, -18.375],
      [142.075, -18.375], [142.025, -18.375],
      [142.025, -18.325], [141.975, -18.325],
      [141.925, -18.325], [141.875, -18.325],
      [141.825, -18.325], [141.775, -18.325],
      [141.725, -18.325], [141.675, -18.325],
      [141.625, -18.325], [141.575, -18.325],
      [141.525, -18.325], [141.475, -18.325],
      [141.425, -18.325], [141.375, -18.325],
      [141.375, -18.375], [141.325, -18.375],
      [141.275, -18.375], [141.225, -18.375],
      [141.175, -18.375], [141.125, -18.375],
      [141.075, -18.375], [141.025, -18.375],
      [140.975, -18.375], [140.925, -18.375],
      [140.925, -18.325], [140.925, -18.275],
      [140.925, -18.225], [140.925, -18.175],
      [140.875, -18.175], [140.875, -18.125],
      [140.875, -18.075], [140.925, -18.075],
      [140.925, -18.025], [140.925, -17.975],
      [140.925, -17.925], [140.975, -17.925],
      [140.975, -17.875], [140.975, -17.825],
      [140.925, -17.825], [140.925, -17.775],
      [140.925, -17.725], [140.875, -17.725],
      [140.875, -17.675], [140.825, -17.675],
      [140.825, -17.625], [140.775, -17.625],
      [140.775, -17.575], [140.775, -17.525],
      [140.775, -17.475], [140.775, -17.425],
      [140.825, -17.425], [140.825, -17.375],
      [140.875, -17.375], [140.875, -17.325],
      [140.875, -17.275], [140.875, -17.225],
      [140.925, -17.225], [140.975, -17.225],
      [141.025, -17.225], [141.075, -17.225],
      [141.125, -17.225], [141.125, -17.275],
      [141.175, -17.275], [141.225, -17.275],
      [141.225, -17.225], [141.275, -17.225],
      [141.325, -17.225], [141.375, -17.225],
      [141.425, -17.225], [141.425, -17.175],
      [141.475, -17.175], [141.525, -17.175],
      [141.575, -17.175], [141.625, -17.175],
      [141.625, -17.125], [141.675, -17.125],
      [141.725, -17.125], [141.775, -17.125],
      [141.825, -17.125], [141.875, -17.125],
      [141.925, -17.125], [141.975, -17.125],
      [142.025, -17.125]]]]
    },
    type: 'DEFAULT',
  },
  6: {
    id: '6',
    name: 'Norman River Catchment',
    geometry: {
      "type": "MultiPolygon", "coordinates": [[[[142.225, -19.925], [142.225, -19.975],
      [142.175, -19.975], [142.175, -19.925],
      [142.125, -19.925], [142.075, -19.925],
      [142.025, -19.925], [141.975, -19.925],
      [141.975, -19.875], [141.925, -19.875],
      [141.875, -19.875], [141.825, -19.875],
      [141.775, -19.875], [141.725, -19.875],
      [141.725, -19.825], [141.675, -19.825],
      [141.625, -19.825], [141.575, -19.825],
      [141.525, -19.825], [141.475, -19.825],
      [141.475, -19.775], [141.425, -19.775],
      [141.425, -19.725], [141.425, -19.675],
      [141.375, -19.675], [141.375, -19.625],
      [141.325, -19.625], [141.325, -19.575],
      [141.325, -19.525], [141.275, -19.525],
      [141.275, -19.475], [141.225, -19.475],
      [141.225, -19.425], [141.225, -19.375],
      [141.225, -19.325], [141.225, -19.275],
      [141.225, -19.225], [141.225, -19.175],
      [141.225, -19.125], [141.175, -19.125],
      [141.175, -19.075], [141.175, -19.025],
      [141.125, -19.025], [141.125, -18.975],
      [141.125, -18.925], [141.125, -18.875],
      [141.125, -18.825], [141.075, -18.825],
      [141.075, -18.775], [141.075, -18.725],
      [141.075, -18.675], [141.075, -18.625],
      [141.075, -18.575], [141.025, -18.575],
      [140.975, -18.575], [140.975, -18.525],
      [140.975, -18.475], [140.975, -18.425],
      [140.975, -18.375], [141.025, -18.375],
      [141.075, -18.375], [141.125, -18.375],
      [141.175, -18.375], [141.225, -18.375],
      [141.275, -18.375], [141.325, -18.375],
      [141.375, -18.375], [141.375, -18.325],
      [141.425, -18.325], [141.475, -18.325],
      [141.525, -18.325], [141.575, -18.325],
      [141.625, -18.325], [141.675, -18.325],
      [141.725, -18.325], [141.775, -18.325],
      [141.825, -18.325], [141.875, -18.325],
      [141.925, -18.325], [141.975, -18.325],
      [142.025, -18.325], [142.025, -18.375],
      [142.075, -18.375], [142.125, -18.375],
      [142.125, -18.425], [142.175, -18.425],
      [142.175, -18.475], [142.225, -18.475],
      [142.275, -18.475], [142.325, -18.475],
      [142.325, -18.525], [142.375, -18.525],
      [142.425, -18.525], [142.475, -18.525],
      [142.475, -18.575], [142.525, -18.575],
      [142.575, -18.575], [142.625, -18.575],
      [142.625, -18.625], [142.675, -18.625],
      [142.725, -18.625], [142.775, -18.625],
      [142.775, -18.675], [142.825, -18.675],
      [142.875, -18.675], [142.875, -18.725],
      [142.925, -18.725], [142.975, -18.725],
      [143.025, -18.725], [143.025, -18.775],
      [143.025, -18.825], [143.075, -18.825],
      [143.075, -18.875], [143.075, -18.925],
      [143.125, -18.925], [143.175, -18.925],
      [143.225, -18.925], [143.275, -18.925],
      [143.275, -18.975], [143.225, -18.975],
      [143.175, -18.975], [143.175, -19.025],
      [143.225, -19.025], [143.225, -19.075],
      [143.225, -19.125], [143.175, -19.125],
      [143.175, -19.175], [143.225, -19.175],
      [143.225, -19.225], [143.275, -19.225],
      [143.275, -19.275], [143.275, -19.325],
      [143.275, -19.375], [143.225, -19.375],
      [143.225, -19.425], [143.225, -19.475],
      [143.175, -19.475], [143.175, -19.525],
      [143.225, -19.525], [143.275, -19.525],
      [143.275, -19.575], [143.225, -19.575],
      [143.225, -19.625], [143.175, -19.625],
      [143.175, -19.675], [143.125, -19.675],
      [143.075, -19.675], [143.075, -19.625],
      [143.025, -19.625], [143.025, -19.575],
      [142.975, -19.575], [142.975, -19.625],
      [142.925, -19.625], [142.875, -19.625],
      [142.875, -19.675], [142.825, -19.675],
      [142.825, -19.725], [142.775, -19.725],
      [142.725, -19.725], [142.725, -19.775],
      [142.675, -19.775], [142.625, -19.775],
      [142.575, -19.775], [142.525, -19.775],
      [142.475, -19.775], [142.425, -19.775],
      [142.425, -19.825], [142.375, -19.825],
      [142.325, -19.825], [142.325, -19.875],
      [142.275, -19.875], [142.225, -19.875],
      [142.225, -19.925]]]]
    },
    type: 'DEFAULT',
  }*/
};

export default locations;
