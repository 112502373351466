import * as constants from '../constants/propertyIds';
import Property from './Property';

export default class MaxTemperatureProperty extends Property {
  generateStats = (forecast) => {
    const stats = [];
    if (forecast.properties[constants.HOT_DAYS]) {
      if (forecast.properties[constants.HOT_DAYS]['3days'] >= 75) {
        stats.push('There is a high chance of at least 3 days heat stress this week');
      }
    }
    return stats;
  };
}
