import { faMapMarked } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import MapLocationSelectorModal from './MapLocationSelectorModal';

export default function LocationSelector({
  className, locations, onChange, sort, value, id,
}) {
  const [isLocationSelectorModalOpen, setIsLocationSelectorModalOpen] = useState(false);
  const options = [];
  if (!value) {
    options.push(<option key="select-zone">Select location</option>);
  }
  if (value && !locations.find((item) => item.id === value)) {
    options.push(<option key="selected-zone" value={value}>{`${value} (not currently available)`}</option>);
  }
  [...locations].sort(sort).forEach(
    (item) => options.push(<option value={item.id} key={item.id}>{item.name}</option>),
  );
  return (
    <>
      <label className="sr-only" htmlFor={id}>Location</label>
      <div className="input-group">
        <button
          className="btn btn-sm btn-primary border"
          onClick={() => setIsLocationSelectorModalOpen(true)}
          type="button"
          title="Select location on map"
        >
          <FontAwesomeIcon icon={faMapMarked} />
        </button>
        <select
          id={id}
          className={`form-select ${className} cssip-tour-location-selector form-control`}
          onChange={onChange}
          value={value}
        >
          {options}
        </select>
      </div>
      <MapLocationSelectorModal
        isShowing={isLocationSelectorModalOpen}
        value={value}
        onChange={(locationId) => {
          onChange({ target: { value: locationId } });
          setIsLocationSelectorModalOpen(false);
        }}
        onClose={() => setIsLocationSelectorModalOpen(false)}
        options={locations.map((location) => location.id)}
      />
    </>
  );
}

LocationSelector.propTypes = {
  className: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func,
  sort: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string,
};

LocationSelector.defaultProps = {
  className: '',
  onChange: _.noop,
  sort: _.noop,
  value: false,
  id: 'location-selector',
};
