// @TODO: Feels like this should be a container or something.
/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';

const MapItemsContent = React.lazy(() => import(/* webpackChunkName: "MapItemsContent" */ './MapItemsContent'));

export default function MapItems(props) {
  return (
    <Suspense fallback={null}>
      <MapItemsContent {...props} />
    </Suspense>
  );
}
