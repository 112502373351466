import './HelpComponent.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function HelpComponent({ children, display, className }) {
  return (
    <div
      className={classNames(
        'mb-3 bg-primary p-2 border cssip-help-component overflow-hidden',
        display,
        className,
      )}
    >
      <div className="flex-fill cssip-help-component__inner">
        {children}
      </div>
    </div>
  );
}

HelpComponent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  display: PropTypes.string,
};

HelpComponent.defaultProps = {
  className: '',
  display: 'd-inline-flex',
};
