import _ from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as actions from '../actions';
import DateTimeOffsetSelector from '../components/DateTimeOffsetSelector';
import { PROPERTY_VIEW } from '../constants/pageViews';
import pages from '../data/dataPages';
import { useAction } from '../lib/hooks';

const DateTimeOffsetSelectorContainer = () => {
  const { pageId, propertyId } = useParams();
  const isPlaying = useSelector((state) => state.app.isPlaying);
  const { dateTimeRange, value } = useSelector((state) => {
    const page = new pages[pageId](state);
    return {
      dateTimeRange: page.getSelectedDateTimeRange(PROPERTY_VIEW, propertyId),
      value: page.getSelectedDateTimeOffset(),
    };
  }, _.isEqual);
  const setIsPlaying = useAction(actions.setIsPlaying);
  const dispatch = useDispatch();
  const setDateTime = useCallback((offset, event) => {
    dispatch(actions.setDataPageDateTimeOffset(pageId, offset, event));
  }, [dispatch, pageId]);
  return (
    <DateTimeOffsetSelector
      setDateTime={setDateTime}
      setIsPlaying={setIsPlaying}
      dateTimeRange={dateTimeRange}
      isPlaying={isPlaying}
      value={value}
    />
  );
};

export default DateTimeOffsetSelectorContainer;
