import _ from 'lodash';
import React from 'react';
import { FORECAST_GREATER_THAN } from '../constants/conditionIds';
import { NEXT_7_DAYS } from '../constants/dateTimeRangeIds';
import { MAX_TEMPERATURE } from '../constants/propertyIds';
import { DAY } from '../constants/timeIntervalIds';
import conditions from '../data/conditions';
import properties from '../data/properties';
import * as text from '../data/text';
import timeIntervals from '../data/timeIntervals';
import AlarmAddButton from './AlarmAddButton';
import AlarmContent from './AlarmContent';
import AlarmModalContent from './AlarmModalContent';
import HelpComponent from './HelpComponent';
import HelpSection from './HelpSection';
import HelpSubSection from './HelpSubSection';

export default function HelpAlarms() {
  return (
    <HelpSection title="Alarms">
      <p className="lead">
        Alarms are available to users who have an account.
        {` ${text.ALARMS_PAGE_DESCRIPTION} `}
        The conditions for these alarms to trigger are also configurable.
        <br />
        An example of an alarm could be when the temperature is forecast to be greater than 35
        degrees in a given region such as Giru/Barratta.
        <br />
        A phone number is require to be able to receive SMS notfications. This can be set through
        the account settings page.
      </p>
      <HelpSubSection
        title="Alarm add button"
        example={(
          <HelpComponent>
            <div className="bg-white p-2">
              <AlarmAddButton onClick={_.noop} />
            </div>
          </HelpComponent>
        )}
        text={(
          <p>
            Alarms can be added from any data page. When viewing data for an individual region
            there will be this button present which can be clicked to begin adding an alarm.
          </p>
        )}
      />
      <HelpSubSection
        title="Alarm form"
        example={(
          <HelpComponent>
            <div className="bg-white p-2 text-start">
              <AlarmModalContent
                conditions={[conditions[FORECAST_GREATER_THAN]]}
                property={properties[MAX_TEMPERATURE]}
                setFields={_.noop}
                alarm={{
                  title: 'High temperature',
                  conditionId: FORECAST_GREATER_THAN,
                  notifications: true,
                  value: 35,
                }}
                userHasPhone
                location={{ name: 'Giru/Barratta' }}
                timeInterval={{ prefix: 'Weekly' }}
              />
            </div>
          </HelpComponent>
        )}
        text={(
          <p>
            When adding an alarm it can be given a descriptive name. This name will be used when
            viewing all alarms on the alarms page and also when receiving SMS nofications.
            <br />
            The condition field will depend on what forecast property is currently selected.
            <br />
            Receiving SMS notifications can be set for each alarm individually.
          </p>
        )}
      />
      <HelpSubSection
        cols={8}
        title="Alarm in active state"
        example={(
          <HelpComponent>
            <AlarmContent
              {...{
                alarm: {
                  conditionId: FORECAST_GREATER_THAN,
                  dateTimeRangeId: NEXT_7_DAYS,
                  title: 'High temperature',
                  locationId: '1',
                  id: '69e29abe-c88a-441a-8539-5b8909b064e9',
                  propertyId: MAX_TEMPERATURE,
                  triggeredAt: '2021-01-29T22:00:49.670298+00:00',
                  value: 35,
                  notifications: false,
                },
                isActive: true,
                property: properties[MAX_TEMPERATURE],
                condition: conditions[FORECAST_GREATER_THAN],
                timeInterval: timeIntervals[DAY],
                location: {
                  id: '1',
                  name: 'Giru/Barratta',
                },
                remove: _.noop,
                setIsModalShowing: _.noop,
              }}
            />
          </HelpComponent>
        )}
        text={(
          <p>
            An example of an active alarm. Active alarms show coloured red.
            <br />
            The line through the bell icon shows that SMS notfications for this alarm are not
            enabled.
            <br />
            Alarms can be removed and edited through their menu, which is accessed by clicking
            on the dots in the top right corner.
          </p>
        )}
      />
      <HelpSubSection
        cols={8}
        title="Alarm in inactive state"
        example={(
          <HelpComponent>
            <AlarmContent
              {...{
                alarm: {
                  conditionId: FORECAST_GREATER_THAN,
                  dateTimeRangeId: NEXT_7_DAYS,
                  title: 'High temperature',
                  locationId: '1',
                  id: '69e29abe-c88a-441a-8539-5b8909b064e9',
                  propertyId: MAX_TEMPERATURE,
                  triggeredAt: '2021-01-29T22:00:49.670298+00:00',
                  value: 35,
                  notifications: true,
                },
                isActive: false,
                property: properties[MAX_TEMPERATURE],
                condition: conditions[FORECAST_GREATER_THAN],
                timeInterval: timeIntervals[DAY],
                location: {
                  id: '1',
                  name: 'Giru/Barratta',
                },
                remove: _.noop,
                setIsModalShowing: _.noop,
              }}
            />
          </HelpComponent>
        )}
        text={(
          <p>
            An example of an inactive alarm (coloured gray).
            <br />
            When an alarm is inactive but has been triggered in the past then the date it was last
            triggered will be shown.
          </p>
        )}
      />
    </HelpSection>
  );
}
