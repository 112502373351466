import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DEFAULT, REAL_TIME } from '../constants/locationTypeIds';
import * as paths from '../constants/paths';
import LocationSelector from '../containers/LocationSelector';
import turf from '../lib/turf';

export default function TaskNav({ locations, task }) {
  const history = useHistory();
  const updatePath = (path, overrides) => {
    const params = {
      ':realTimeLocationId?': locations.realTime.id,
      [paths.taskIdString]: task.id,
      ':weatherLocationId?': locations.weather.id,
      ...overrides,
    };
    let updatedPath = path;
    Object.keys(params).forEach((key) => {
      updatedPath = updatedPath.replace(key, params[key]);
    });
    history.replace(updatedPath);
  };
  const center = turf.center(locations.weather.geometry);
  return (
    <div className="my-2 d-flex">
      <div className="flex-fill mx-1">
        <LocationSelector
          className="form-select-sm"
          onChange={(e) => updatePath(
            paths.TASKS_PARAMS_NO_REAL_TIME_LOCATION,
            { ':weatherLocationId?': e.target.value },
          )}
          value={locations.weather.id}
          locationTypeId={DEFAULT}
          id="zone-location-selector"
        />
      </div>
      <div className="flex-fill mx-1">
        <LocationSelector
          className="form-select-sm"
          onChange={(e) => updatePath(paths.TASK_PARAMS_OPTIONAL, { ':realTimeLocationId?': e.target.value })}
          sort={(a, b) => {
            const aDistance = turf.distance(a.geometry, center);
            const bDistance = turf.distance(b.geometry, center);
            return Math.sign(aDistance - bDistance);
          }}
          value={locations.realTime.id}
          locationTypeId={REAL_TIME}
          id="station-location-selector"
        />
      </div>
    </div>
  );
}

TaskNav.propTypes = {
  locations: PropTypes.shape({
    realTime: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    weather: PropTypes.shape({
      geometry: PropTypes.shape({}).isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    statuses: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  }).isRequired,
};
