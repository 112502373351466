import './OverviewPropertyBox.scss';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

export default function OverviewPropertyBox({
  className, children, to, onClick, topRight,
}) {
  const Element = to ? Link : 'div';
  return (
    <div className="col-xl-3 col-lg-4 col-6 p-1 small position-relative">
      <Element
        className={classNames(
          className,
          'cssip-overview-property-box',
          'text-dark border p-1 h-100 d-flex flex-column justify-content-center text-decoration-none',
          {
            'cssip-overview-property-box--is-link': !!to,
          },
        )}
        to={to || undefined}
        onClick={onClick}
      >
        {children}
      </Element>
      {!!topRight && (
        <div className="position-absolute m-2" style={{ top: 0, right: 0 }}>
          {topRight}
        </div>
      )}
    </div>
  );
}

OverviewPropertyBox.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  topRight: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
};

OverviewPropertyBox.defaultProps = {
  to: false,
  onClick: _.noop,
  className: '',
  topRight: false,
};
