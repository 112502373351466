/* eslint-disable react/jsx-props-no-spreading */
import './ResponsiveLegend.scss';
import React from 'react';
import Responsive from '../containers/Responsive';
import Legend from './Legend';

export default function ResponsiveLegend(props) {
  return (
    <>
      <Responsive above="md">
        <Legend
          {...props}
          className="m-0 flex-grow-0 flex-shrink-1 col p-0 cssip-responsive-legend--sticky"
          orientation="vertical"
        />
      </Responsive>
      <Responsive below="md">
        <Legend {...props} orientation="horizontal" />
      </Responsive>
    </>
  );
}
