export const ADMIN_DATA_SOURCE_USERNAME_CHANGED = 'admin/dataSourceUsernameChanged';
export const ALARM_ADDED = 'alarm/added';
export const ALARM_EDITED = 'alarm/edited';
export const ALARM_REMOVED = 'alarm/removed';
export const APP_IRRIGATION_TYPE_CHANGED = 'app/irrigationTypeChanged';
export const OVERVIEW_PROPERTY_SELECTED = 'app/overviewPropertySelected';
export const IS_PLAYING_CHANGED = 'app/isPlayingChanged';
export const CURRENT_LOCATION_SET = 'currentLocation/set';
export const DATA_PAGE_META_FILTER_CHANGED = 'dataPage/metaFilterChanged';
export const DATA_PAGE_VIEW_DATE_TIME_RANGE_CHANGED = 'dataPage/viewDateTimeRangeChangedV2';
export const DATA_PAGE_FORECAST_ENDPOINT_CHANGED = 'dataPage/forecastEndpointChanged';
export const DATA_PAGE_DATE_TIME_OFFSET_CHANGED = 'dataPage/dateTimeOffsetChanged';
export const ENDPOINT_RECEIVED = 'endpoint/received';
export const ENDPOINT_REQUESTED = 'endpoint/requested';
export const MAP_DISPLAY_CHANGED = 'map/displayChanged';
export const MAP_IS_SHOWING_PARTICLES_CHANGED = 'map/isShowingParticlesChanged';
export const MAP_STYLE_CHANGED = 'map/styleChanged';
export const REAL_TIME_REMOVE_OLD_OBSERVATIONS = 'realTime/removeOldObservations';
export const REAL_TIME_ADD_NEW_OBSERVATIONS = 'realTime/addNewObservations';
export const USER_IS_LOADING = 'user/isLoading';
export const USER_SET = 'user/set';
export const USER_SIGNED_OUT = 'user/signedOut';
export const WIDGET_ADDED = 'widget/added';
export const WIDGET_EDITED = 'widget/edited';
export const WIDGET_MOVED = 'widget/moved';
export const WIDGET_REMOVED = 'widget/removed';
export const TIME_CHANGED = 'time/changed';
export const TOAST_ADDED = 'toast/added';
export const TOAST_REMOVED = 'toast/removed';
