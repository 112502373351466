export const JUNE = 'jun';
export const JULY = 'jul';
export const AUGUST = 'aug';
export const SEPTEMBER = 'sep';
export const OCTOBER = 'oct';
export const NOVEMBER = 'nov';
export const DECEMBER = 'dec';
export const MARCH = 'mar';
export const APRIL = 'apr';
export const MAY = 'may';
