import { INSTALLING } from '../constants/pageIds';
import * as text from '../data/text';

export default class InstallingPage {
  static id = INSTALLING;

  static label = 'Installing';

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: text.INSTALLING_PAGE_DESCRIPTION },
      ],
    };
  }
}
