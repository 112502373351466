import React from 'react';
import Alert from '../containers/Alert';

export default function DemoFarmAlert() {
  return (
    <Alert id="demo-farm">
      This is a
      <strong> demo page.</strong>
    </Alert>
  );
}
