import React from 'react';
import * as text from '../data/text';
import MyFarmPage from '../pages/MyFarmPage';
import HelpProperties from './HelpProperties';
import HelpSection from './HelpSection';

export default function HelpMyFarm() {
  return (
    <HelpSection title="My farm">
      <p className="lead">
        {text.FARM_PAGE_DESCRIPTION}
      </p>
      <div className="print-break-inside-avoid">
        <h4><u>Irrigation Forecast Workflow</u></h4>
        <p>
          The figure below outlines how Opticane goes from weather forecasts to My Farm forecasts
          of irrigation. Irrigation forecasts are uncertain because each forecast ensemble member
          predicts different weather conditions which may affect when the irrigation trigger
          (e.g. SWD level) occurs. We report the next irrigation date as the date most often
          forecast.
        </p>
        <div className="print-break-inside-avoid">
          <img
            src="/IrrigWorkflow.png"
            className="card-img-top h-100 w-100 cssip-contacts__image"
            alt="Irrigation Forecast Workflow"
          />
        </div>
      </div>
      <div className="print-break-inside-avoid">
        <h4><u>Overview view</u></h4>
        <p>
          This view contains a map for selecting what region to see information for. Also in this
          view is list of days/weeks and the forecasted values for each of the proeperties shown.
          Clicking on one of these properties will then take you to the property view explained
          below.
        </p>
      </div>
      <div className="print-break-inside-avoid">
        <h4><u>Property view</u></h4>
        <p>
          The property view goes into a more detailed look at a particular property. Charts and
          tables show the forecasts and observations over a selected time period.
          <br />
          Clicking on an area in the map will show the property data view specific for that
          area. The same can be achieved by clicking on the name of an area in the list if no area
          is selected.
          <br />
          If you want to go back to viewing all areas there is a few different ways you can do
          this. Using your browsers back button or clicking outside of the areas in the map.
          Clicking on the link in the menu for the page that you are currently on will take you to
          the overview.
        </p>
      </div>
      <HelpProperties propertyIds={MyFarmPage.propertyIds} />
    </HelpSection>
  );
}
