import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import * as paths from '../constants/paths';
import MapItems from '../containers/MapItems';
import Overview from '../containers/Overview';
import OverviewMap from '../containers/OverviewMap';
import ResponsiveLegend from '../containers/ResponsiveLegend';
import Layout from './Layout';
import Spinner from './Spinner';

const PropertyDetails = React.lazy(() => import(/* webpackChunkName: "PropertyDetails" */ '../containers/PropertyDetails'));
const RealTimeDetails = React.lazy(() => import(/* webpackChunkName: "RealTimeDetails" */ '../containers/RealTimeDetails'));

export default function DataPageLocation() {
  return (
    <Layout.Content className="container-fluid d-flex flex-column flex-grow-1">
      <Layout.Row>
        <Switch>
          <Route path={paths.PROPERTY_ITEM}>
            <Layout.Primary>
              <Route path={paths.PROPERTY_ITEM} component={MapItems} />
            </Layout.Primary>
          </Route>
          <Route path={paths.OVERVIEW_ITEM}>
            <Layout.Primary>
              <Route path={paths.OVERVIEW_ITEM} component={OverviewMap} />
            </Layout.Primary>
          </Route>
        </Switch>
        <Route path={paths.PROPERTY_ITEM} component={ResponsiveLegend} />
        <Route>
          <Layout.Secondary expanded>
            <Suspense fallback={<Spinner />}>
              <Switch>
                <Route path={paths.REAL_TIME_PROPERTY_ITEM} component={RealTimeDetails} />
                <Route path={paths.PROPERTY_ITEM} component={PropertyDetails} />
                <Route path={paths.OVERVIEW_ITEM} component={Overview} />
              </Switch>
            </Suspense>
          </Layout.Secondary>
        </Route>
      </Layout.Row>
    </Layout.Content>
  );
}
