import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import {
  Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import * as actions from './actions';
import App from './components/App';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPage from './components/ErrorPage';
import * as paths from './constants/paths';
import Head from './containers/Head';
import RequireAuth from './containers/RequireAuth';
import Tour from './containers/Tour';
import { onRequestError } from './lib/axios';
import './lib/customIcons';
import history from './lib/history';
import { getLocationIfPermissible } from './lib/location';
import store from './lib/store';
import { anonymousWebSocket } from './lib/websocket';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import './lib/notifications';

if (process.env.REACT_APP_SHOW_MOCK_FORECASTS === 'true') {
  import('./lib/mock').then((mock) => mock.setup(store));
}

store.dispatch(actions.fetchUser());

setInterval(() => store.dispatch(actions.removeOldRealTimeObservations()), 5 * 60 * 1000);
setInterval(() => store.dispatch(actions.updateTime()), 60 * 1000);

ReactGA.initialize('UA-166254134-1', {
  debug: process.env.NODE_ENV === 'development' && process.env.REACT_APP_GA_DEBUG === 'true',
});
ReactGA.pageview(history.location.pathname);
history.listen((location) => ReactGA.pageview(location.pathname));

getLocationIfPermissible(({ coords }) => {
  const action = actions.setCurrentLocation([coords.longitude, coords.latitude], { label: 'Set on load' });
  store.dispatch(action);
});

anonymousWebSocket.connect();

onRequestError(() => {
  store.dispatch(actions.addToast({
    message: 'Something went wrong with the network request. Try refreshing the page.', type: 'error', autoClose: false,
  }));
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <DndProvider backend={HTML5Backend}>
        <Provider store={store}>
          <Router history={history}>
            <Route path={paths.PAGE} component={Head} />
            <Tour>
              <Switch>
                <Route path={paths.ERROR} component={ErrorPage} />
                <Redirect exact from={paths.ROOT} to={paths.HOME} />
                <Route path={paths.MY_FARM}>
                  <RequireAuth redirectToLogin>
                    <App />
                  </RequireAuth>
                </Route>
                <Route component={App} />
              </Switch>
            </Tour>
          </Router>
        </Provider>
      </DndProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals(({ id, name, value }) => ReactGA.event({
  category: 'Web Vitals',
  action: name,
  value: Math.round(name === 'CLS' ? value * 1000 : value),
  label: id,
  nonInteraction: true,
}));
