import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as dateTimeRangeIds from '../constants/dateTimeRangeIds';
import * as endpointIds from '../constants/endpointIds';
import * as propertyIds from '../constants/propertyIds';
import * as taskIds from '../constants/taskIds';
import * as valueTypes from '../constants/valueTypes';
import * as widgetIds from '../constants/widgetIds';
import properties from '../data/properties';

const planting = {
  // https://pixabay.com/photos/hands-macro-plant-soil-grow-life-1838658/
  image: `${process.env.PUBLIC_URL}/tasks/planting.jpg`,
  getWidgetsForLocations: (locations) => {
    const widgets = [
      {
        key: uuidv4(),
        params: {
          dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
          forecastEndpointId: endpointIds.REAL_TIME,
          locationId: locations.realTime.id,
          observationEndpointId: endpointIds.REAL_TIME,
          propertyId: propertyIds.RAIN_24_HOUR_TOTAL,
          sources: [endpointIds.REAL_TIME],
        },
        title: `Rain real time (${locations.realTime.name})`,
        type: widgetIds.REAL_TIME_VALUES,
      },
      {
        key: uuidv4(),
        params: {
          dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
          forecastEndpointId: endpointIds.REAL_TIME,
          locationId: locations.realTime.id,
          observationEndpointId: endpointIds.REAL_TIME,
          propertyId: propertyIds.AIR_TEMPERATURE,
          sources: [endpointIds.REAL_TIME],
        },
        title: `Air temperature real time (${locations.realTime.name})`,
        type: widgetIds.REAL_TIME_VALUES,
      },
      {
        key: uuidv4(),
        params: {
          dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
          forecastEndpointId: endpointIds.REAL_TIME,
          locationId: locations.realTime.id,
          observationEndpointId: endpointIds.REAL_TIME,
          propertyId: propertyIds.SOIL_TEMPERATURE,
          sources: [endpointIds.REAL_TIME],
        },
        title: `Soil temperature real time (${locations.realTime.name})`,
        type: widgetIds.REAL_TIME_VALUES,
      },
      {
        key: uuidv4(),
        params: {
          dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
          forecastEndpointId: endpointIds.REAL_TIME,
          locationId: locations.realTime.id,
          observationEndpointId: endpointIds.REAL_TIME,
          propertyId: propertyIds.RAIN_24_HOUR_TOTAL,
          sources: [endpointIds.REAL_TIME],
        },
        title: `Rain real time (${locations.realTime.name})`,
        type: widgetIds.CHART,
      },
      {
        key: uuidv4(),
        params: {
          dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
          forecastEndpointId: endpointIds.REAL_TIME,
          locationId: locations.realTime.id,
          observationEndpointId: endpointIds.REAL_TIME,
          propertyId: propertyIds.AIR_TEMPERATURE,
          sources: [endpointIds.REAL_TIME],
          referenceLines: [15],
        },
        title: `Air temperature real time (${locations.realTime.name})`,
        type: widgetIds.CHART,
      },
      {
        key: uuidv4(),
        params: {
          dateTimeRangeId: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
          forecastEndpointId: endpointIds.REAL_TIME,
          locationId: locations.realTime.id,
          observationEndpointId: endpointIds.REAL_TIME,
          propertyId: propertyIds.SOIL_TEMPERATURE,
          sources: [endpointIds.REAL_TIME],
          referenceLines: [15],
        },
        title: `Soil temperature real time (${locations.realTime.name})`,
        type: widgetIds.CHART,
      },
      {
        key: uuidv4(),
        params: {
          dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
          forecastEndpointId: endpointIds.FORECAST_WEATHER,
          locationId: locations.weather.id,
          observationEndpointId: endpointIds.OBSERVATION_WEATHER,
          propertyId: propertyIds.RAIN_POP,
          sources: [
            endpointIds.FORECAST_WEATHER,
            endpointIds.LOCATIONS,
            endpointIds.OBSERVATION_WEATHER,
          ],
        },
        title: 'Rain probability forecast for next 3 days',
        type: widgetIds.CHART,
      },
      {
        key: uuidv4(),
        params: {
          dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
          forecastEndpointId: endpointIds.FORECAST_WEATHER,
          locationId: locations.weather.id,
          observationEndpointId: endpointIds.OBSERVATION_WEATHER,
          propertyId: propertyIds.RAIN,
          sources: [
            endpointIds.FORECAST_WEATHER,
            endpointIds.LOCATIONS,
            endpointIds.OBSERVATION_WEATHER,
          ],
        },
        title: 'Rain forecast for next 3 days',
        type: widgetIds.CHART,
      },
      {
        key: uuidv4(),
        params: {
          dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
          forecastEndpointId: endpointIds.FORECAST_WEATHER,
          locationId: locations.weather.id,
          observationEndpointId: endpointIds.OBSERVATION_WEATHER,
          propertyId: propertyIds.MIN_TEMPERATURE,
          sources: [
            endpointIds.FORECAST_WEATHER,
            endpointIds.LOCATIONS,
            endpointIds.OBSERVATION_WEATHER,
          ],
        },
        title: 'Minimum temperature forecast for next 3 days',
        type: widgetIds.CHART,
      },
      {
        key: uuidv4(),
        params: {
          dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
          forecastEndpointId: endpointIds.FORECAST_WEATHER,
          locationId: locations.weather.id,
          observationEndpointId: endpointIds.OBSERVATION_WEATHER,
          propertyId: propertyIds.RAIN_POP,
          sources: [
            endpointIds.FORECAST_WEATHER,
            endpointIds.LOCATIONS,
            endpointIds.OBSERVATION_WEATHER,
          ],
        },
        title: 'Rain probability forecast for next 3 days',
        type: widgetIds.TABLE,
      },
      {
        key: uuidv4(),
        params: {
          dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
          forecastEndpointId: endpointIds.FORECAST_WEATHER,
          locationId: locations.weather.id,
          observationEndpointId: endpointIds.OBSERVATION_WEATHER,
          propertyId: propertyIds.RAIN,
          sources: [
            endpointIds.FORECAST_WEATHER,
            endpointIds.LOCATIONS,
            endpointIds.OBSERVATION_WEATHER,
          ],
        },
        title: 'Rain forecast for next 3 days',
        type: widgetIds.TABLE,
      },
      {
        key: uuidv4(),
        params: {
          dateTimeRangeId: dateTimeRangeIds.NEXT_3_DAYS,
          forecastEndpointId: endpointIds.FORECAST_WEATHER,
          locationId: locations.weather.id,
          observationEndpointId: endpointIds.OBSERVATION_WEATHER,
          propertyId: propertyIds.MIN_TEMPERATURE,
          sources: [
            endpointIds.FORECAST_WEATHER,
            endpointIds.LOCATIONS,
            endpointIds.OBSERVATION_WEATHER,
          ],
        },
        title: 'Minimum temperature forecast for next 3 days',
        type: widgetIds.TABLE,
      },
    ];
    if (process.env.REACT_APP_SOIL_TEMP_ENABLED === 'true') {
      return widgets;
    }
    return widgets.filter((widget) => widget.params.propertyId !== propertyIds.SOIL_TEMPERATURE);
  },
  id: taskIds.PLANTING,
  name: 'Planting',
  statuses: [
    {
      getStatus: (current, weatherForecasts) => {
        const start = moment().startOf('day');
        const end = start.add(3, 'days').startOf('day');
        const forecasts = weatherForecasts
          .filter((item) => item.timeInterval === 'day')
          .filter((item) => moment(item.datetime).isBetween(start, end));
        const isBad = forecasts.some((item) => _.get(item.properties, `${propertyIds.RAIN_POP}.${valueTypes.RAIN_25MM}`) > 50);
        if (isBad) {
          return {
            icon: 'times',
            message: 'There is a medium chance of at least one above 25mm day in the next three days',
          };
        }
        const isOk = forecasts.some((item) => _.get(item.properties, `${propertyIds.RAIN_POP}.${valueTypes.RAIN_0MM}`) > 50);
        if (isOk) {
          return {
            icon: 'minus',
            message: 'There is a medium chance of at least one above 25mm day in the next three days',
          };
        }
        return {
          icon: 'check',
          message: 'There is a low to no chance of rain in the next three days',
        };
      },
      info: {
        long: 'A medium chance of rain is defined by any day in the next 3 days having 50% chance of any rain (above 0mm). Likewise a medium chance of above 25mm is any day having a 50% chance of at least 25mm occuring.',
        short: 'Low to no chance of rain is suitable for planting',
      },
      name: 'Rain probability',
    },
    {
      getStatus: (current, weatherForecasts) => {
        const start = moment().startOf('day');
        const end = start.add(3, 'days').startOf('day');
        const forecasts = weatherForecasts
          .filter((item) => item.timeInterval === 'day')
          .filter((item) => moment(item.datetime).isBetween(start, end));
        const cool = forecasts.filter(
          (item) => properties[propertyIds.MIN_TEMPERATURE].getValue(item.properties) < 15,
        );
        if (cool.length === 3) {
          return {
            icon: 'minus',
            message: 'Persistent cool nights are likely for the next 3 days',
          };
        }
        if (cool.length === 2) {
          return {
            icon: 'minus',
            message: 'There are at least two cool nights forecast in the next 3 days',
          };
        }
        return {
          icon: 'check',
          message: 'Air temperatures are acceptable for planting',
        };
      },
      info: {
        long: 'Time period used is the next three days. A cool night is defined by the minimum temperature going below 15.',
        short: 'Air temperature is acceptable for planting if it is above 15.',
      },
      name: 'Air temperature',
    },
  ],
};

export default planting;
