import './Home.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import tools from '../data/tools';
import ForecastPage from '../pages/ForecastPage';
import IrrigationPage from '../pages/IrrigationPage';
import RealTimePage from '../pages/RealTimePage';
import TasksPage from '../pages/TasksPage';
import Banner from './Banner';
import HomePartners from './HomePartners';
import Logo from './Logo';

export default function Home({ isAuthed }) {
  const pages = [IrrigationPage, RealTimePage, ForecastPage, TasksPage];
  const cards = pages.map((item) => {
    const subLinks = _.get(item, 'subLinks', []);
    return (
      <div key={item.label} className="col col-xl-3 col-12 col-md-6 mb-3 position-relative">
        <div className="cssip-home__card-box p-3 h-100 d-flex flex-column">
          <div className="d-flex align-items-center mb-3">
            <FontAwesomeIcon
              icon={item.icon}
              fixedWidth
              size="2x"
            />
            <h2 className="h3 text-dark text-center d-inline-block ms-3 mb-0">{item.label}</h2>
          </div>
          <div className="d-flex flex-column justify-content-between flex-grow-1">
            <div>
              <p className="border-top border-secondary pt-3 text-dark">{item.description}</p>
              <ul className="list-inline d-flex flex-wrap">
                {subLinks.map((link) => (
                  <li className="list-inline-item mb-2 flex-grow-1 mx-1" key={link.to}>
                    <Link to={link.to} className="btn btn-outline-primary btn-sm w-100">
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <Link
              to={item.getPath({ isAuthed })}
              className={classNames('btn w-100 btn-primary', {
                'stretched-link': subLinks.length === 0,
              })}
            >
              {`View ${item.label} page`}
            </Link>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="cssip-home text-white">
      <Banner
        image={`${process.env.PUBLIC_URL}/tractor.jpg`}
        main={<Logo />}
        sub={(
          <blockquote className="blockquote text-md-end">
            <p className="mb-0">
              Weather forecasts and irrigation outlooks with
              improved spatial resolution across the Burdekin catchment.
            </p>
          </blockquote>
        )}
      />
      <div className="container-fluid py-3 mt-3">
        <div className="row text-dark align-items-stretch">
          {cards}
        </div>
      </div>
      <div className="container-fluid pb-3 mb-5 mt-3">
        <h2 className="text-dark">Tools</h2>
        <div className="row py-3 bg-primary">
          {tools.map((tool) => (
            <div key={tool.path} className="col-md-6 col-lg-3 col-12">
              <Link
                to={tool.path}
                className="btn btn-light w-100 my-1"
              >
                {tool.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <HomePartners />
    </div>
  );
}

Home.propTypes = {
  isAuthed: PropTypes.bool.isRequired,
};
