import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import * as paths from '../constants/paths';

const RequireAuthContainer = ({ group, redirectToLogin, children }) => {
  const { pathname } = useLocation();
  const isLoading = useSelector((state) => state.auth.state === 'loading');
  const isAuthenticated = useSelector((state) => state.auth.state === 'signedIn');
  const isAuthorized = useSelector((state) => (
    !group || _.get(state.auth, 'user.groups', []).indexOf(group) >= 0
  ));
  const isAuthed = isAuthenticated && isAuthorized;
  if (isLoading) {
    return null;
  }
  if (isAuthed) {
    return children;
  }
  if (redirectToLogin) {
    return (
      <Redirect
        to={{
          pathname: paths.AUTH,
          state: { referrer: pathname },
        }}
      />
    );
  }
  return null;
};

RequireAuthContainer.propTypes = {
  group: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  redirectToLogin: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

RequireAuthContainer.defaultProps = {
  redirectToLogin: false,
  group: false,
};

export default RequireAuthContainer;
