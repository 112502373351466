import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import breadcrumbPages from '../data/breadcrumbPages';

const BreadcrumbsContainer = () => {
  const params = useParams();
  const location = useLocation();
  const breadcrumbs = useSelector((state) => {
    const page = new breadcrumbPages[params.pageId](state);
    return page.generateBreadcrumbs(location.pathname);
  }, _.isEqual);
  return (
    <Breadcrumbs breadcrumbs={breadcrumbs} />
  );
};

export default BreadcrumbsContainer;
