import { faUser, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, Route } from 'react-router-dom';
import * as paths from '../constants/paths';

export default function NavbarAccountDropdown({
  isAdmin, isShowing, setIsShowing, onClick, signOut,
}) {
  const userIcon = isAdmin ? faUserShield : faUser;
  return (
    <Route
      path={[
        paths.ACCOUNT,
        paths.ADMIN,
        paths.ALARMS,
        paths.DASHBOARD,
      ]}
      // eslint-disable-next-line react/no-children-prop
      children={({ match }) => (
        <NavDropdown
          id="account-navbar-dropdown"
          active={!!match}
          show={isShowing}
          onToggle={() => setIsShowing(!isShowing)}
          align="end"
          title={(
            <>
              <FontAwesomeIcon icon={userIcon} className="me-2" />
              <span className="d-md-none d-lg-inline">Account</span>
            </>
          )}
        >
          {isAdmin && (
            <NavLink
              onClick={onClick}
              to={paths.ADMIN}
              exact
              className="dropdown-item"
              activeClassName="active"
            >
              Admin
            </NavLink>
          )}
          <NavLink
            onClick={onClick}
            to={paths.DASHBOARD}
            exact
            className="dropdown-item"
            activeClassName="active"
          >
            Dashboard
          </NavLink>
          <NavLink
            onClick={onClick}
            to={paths.ALARMS}
            exact
            className="dropdown-item"
            activeClassName="active"
          >
            Alarms
          </NavLink>
          <NavLink
            onClick={onClick}
            to={paths.ACCOUNT}
            exact
            className="dropdown-item"
            activeClassName="active"
          >
            Settings
          </NavLink>
          <button
            type="button"
            className="dropdown-item"
            onClick={() => {
              signOut();
              onClick();
            }}
          >
            Sign out
          </button>
        </NavDropdown>
      )}
    />
  );
}

NavbarAccountDropdown.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  setIsShowing: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
