import PropTypes from 'prop-types';
import React from 'react';
import Toast from './Toast';

export default function Toasts({ toasts, remove }) {
  return (
    <div className="toast-container translate-middle-x bottom-0 start-50 position-fixed mb-3">
      {toasts.map((toast) => <Toast key={toast.id} toast={toast} remove={remove} />)}
    </div>
  );
}

Toasts.propTypes = {
  toasts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
  remove: PropTypes.func.isRequired,
};
