import * as types from '../constants/actionTypes';

export default function toastsReducer(state = [], action = {}) {
  switch (action.type) {
    case types.TOAST_ADDED: {
      return [
        ...state,
        action.toast,
      ];
    }
    case types.TOAST_REMOVED: {
      return state.filter((item) => item !== action.toast);
    }

    default: {
      return state;
    }
  }
}
