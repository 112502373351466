import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as actions from '../actions';
import ForecastOverviewNav from '../components/ForecastOverviewNav';
import { OVERVIEW_VIEW } from '../constants/pageViews';
import pages from '../data/dataPages';
import { useAction } from '../lib/hooks';

const ForecastOverviewNavContainer = () => {
  const { pageId } = useParams();
  const Page = pages[pageId];
  const dateTimeRangeId = useSelector((state) => {
    const page = new Page(state);
    return page.getSelectedDateTimeRange(OVERVIEW_VIEW).id;
  });
  const setPageViewDateTimeRange = useAction(actions.setPageViewDateTimeRange);
  return (
    <ForecastOverviewNav
      dateTimeRangeId={dateTimeRangeId}
      setPageViewDateTimeRange={setPageViewDateTimeRange}
      Page={Page}
    />
  );
};

export default ForecastOverviewNavContainer;
