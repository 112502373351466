import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import { WIND_DIRECTION, WIND_SPEED } from '../constants/propertyIds';
import { degreesToCompass } from '../lib/utils';
import Property from './Property';

export default class WindProperty extends Property {
  getAngle = (data) => {
    if (!_.isNumber(_.get(data, WIND_DIRECTION))) {
      return 0;
    }
    return data[WIND_DIRECTION] + 180;
  };

  getSpeed = (data) => {
    if (!_.isNumber(_.get(data, WIND_SPEED))) {
      return 0;
    }
    return data[WIND_SPEED];
  };

  getFormatted(data, options = {}) {
    if (!_.isNumber(_.get(data, this.id))) {
      return null;
    }
    return (
      <span>
        {degreesToCompass(data[WIND_DIRECTION])}
        <FontAwesomeIcon
          className="text-black-50"
          icon={faArrowUp}
          transform={{ rotate: this.getAngle(data) }}
        />
        {Math.round(data[WIND_SPEED])}
        {!!options.unit && this.unit}
      </span>
    );
  }

  getValue = (data) => {
    if (!_.isNumber(_.get(data, this.id))) {
      return undefined;
    }
    return parseFloat(data[WIND_SPEED].toFixed(this.decimalPoints));
  };

  getTableData = (data, timeInterval) => {
    const scale = this.getScale({ timeIntervalId: timeInterval.id });
    const dates = data.map((item) => ({
      datetime: item.datetime,
      value: timeInterval.formatLong(item.datetime),
    }));
    const tableData = {
      body: [],
      head: { items: dates, name: timeInterval.heading },
    };
    [{ key: 'observation', name: 'Observed' }, { key: 'forecast', name: 'Forecast' }].forEach((row) => {
      const hasRow = data.some((item) => !_.isUndefined(this.getValue(item[row.key])));
      if (hasRow) {
        const items = [
          {
            items: data.map((item) => {
              if (!_.isUndefined(item[row.key]) && WIND_DIRECTION in item[row.key]) {
                return {
                  datetime: item.datetime,
                  value: (
                    <FontAwesomeIcon
                      className="text-black-50"
                      size="2x"
                      icon={faArrowUp}
                      transform={{ rotate: this.getAngle(item[row.key]) }}
                    />
                  ),
                };
              }
              return { datetime: item.datetime };
            }),
            name: 'Angle',
          },
          {
            items: data.map((item) => {
              if (!_.isUndefined(item[row.key]) && WIND_DIRECTION in item[row.key]) {
                return {
                  datetime: item.datetime,
                  value: degreesToCompass(item[row.key][WIND_DIRECTION]),
                };
              }
              return { datetime: item.datetime };
            }),
            name: 'Direction',
          },
          {
            items: data.map((item) => {
              if (!_.isUndefined(item[row.key]) && this.id in item[row.key]) {
                return {
                  color: scale(this.getValue(item[row.key]))
                    .alpha(0.25)
                    .css(),
                  datetime: item.datetime,
                  value: `${item[row.key][WIND_SPEED].toFixed(this.decimalPoints)}${this.unit}`,
                };
              }
              return { datetime: item.datetime };
            }),
            name: 'Speed',
          },
        ];
        tableData.body.push({
          items,
          name: row.name,
        });
      }
    });
    return tableData;
  };

  particleDataFilterer = _.memoize((data) => data.filter((item) => (
    !_.isUndefined(item.properties.speed) && !_.isUndefined(item.properties.rotation)
  )));
}
