import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import * as text from '../data/text';
import HelpComponent from './HelpComponent';
import HelpSection from './HelpSection';
import HelpSubSection from './HelpSubSection';
import WidgetModalContent from './WidgetModalContent';

export default function HelpDashboard() {
  return (
    <HelpSection title="Dashboard">
      <p className="lead">
        {text.DASHBOARD_PAGE_DESCRIPTION}
        <br />
        Widgets on the dashboard can be reordered by dragging and dropping. An alternative is to
        use the widget menu as there are options to move up or down.
      </p>
      <HelpSubSection
        title="Dashboard widgets"
        example={(
          <>
            <HelpComponent>
              <button
                className="btn bg-white btn-link"
                type="button"
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </button>
            </HelpComponent>
            <br />
            <HelpComponent>
              <div className="bg-white">
                <WidgetModalContent
                  value=""
                  setValue={_.noop}
                  onChange={_.noop}
                  onClose={_.noop}
                  autoFocus={false}
                />
              </div>
            </HelpComponent>
          </>
        )}
        text={(
          <>
            <p>
              To add a widget to the dashboard, click on the dots and then add.
              <br />
              In the modal you can give a name to your widget and then press ok.
            </p>
            <p>
              From within the dashboard page you can rename and move up or down widgets.
              The menu to perform these actions is located by clicking on the same dots.
              New widgets are placed at the end.
              <br />
              There is also a menu button that removes a widget. This will remove from the
              dashboard, but it can be added again via the page it originated from.
            </p>
          </>
        )}
      />
    </HelpSection>
  );
}
