import * as constants from '../constants/propertyIds';
import Property from './Property';

export default class MinTemperatureProperty extends Property {
  generateStats = (forecast) => {
    const stats = [];
    if (forecast.properties[constants.COOL_DAYS]) {
      if (forecast.properties[constants.COOL_DAYS]['2days'] >= 75) {
        stats.push('There is a high chance of at least 2 cool nights this week');
      }
      if (forecast.properties[constants.FROST_DAYS]['1days'] >= 50) {
        stats.push('There is a medium chance of at least 1 frost night this week');
      }
    }
    return stats;
  };
}
