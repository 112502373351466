import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import chroma from 'chroma-js';
import _ from 'lodash';
import { DEFAULT_CHART } from '../constants/chartIds';
import { AVERAGE } from '../constants/combiningTypes';
import * as conditionIds from '../constants/conditionIds';
import {
  NEXT_7_DAYS, LAST_7_DAYS, NEXT_14_WEEKS, LAST_14_WEEKS, NEXT_24_WEEKS, LAST_24_WEEKS,
  LAST_30_DAYS as LAST_30_DAYS_DATE,
} from '../constants/dateTimeRangeIds';
import { LAST_30_DAYS as LAST_30_DAYS_ENDPOINT } from '../constants/endpointIds';
import { LOWER, MID, UPPER } from '../constants/valueTypes';
import { TABLE, CHART, SUMMARY } from '../constants/widgetIds';
import { probabilityLegend } from '../data/legends';
import { mapMarker } from '../data/mapIcons';
import { sortProperty } from '../lib/utils';

export default class Property {
  constructor(params) {
    this.id = params.id;
    this.chartId = params.chartId || DEFAULT_CHART;
    this.combining = params.combining || AVERAGE;
    this.domain = params.domain || ['auto', 'auto'];
    this.decimalPoints = params.decimalPoints || 0;
    this.hasParticles = params.hasparticles || false;
    this.icon = params.icon || faTimesCircle;
    this.info = params.info;
    this.legend = params.legend || probabilityLegend;
    this.mapIcon = params.mapIcon || mapMarker;
    this.name = params.name || 'Property';
    this.unit = params.unit || '';
    this.dateTimeRangeIds = params.dateTimeRangeIds || [
      NEXT_7_DAYS,
      NEXT_14_WEEKS,
      NEXT_24_WEEKS,
      LAST_7_DAYS,
      LAST_14_WEEKS,
      LAST_24_WEEKS,
    ];
    this.showMapIconOverPolygon = params.showMapIconOverPolygon || false;
    this.timeIntervalLegends = params.timeIntervalLegends;
  }

  particleDataFilterer = () => [];

  getAngle = () => 0;

  getSpeed = () => 0;

  getFormatted(data, options = {}) {
    if (_.isUndefined(data) || _.isUndefined(data[this.id])) {
      return undefined;
    }
    const item = data[this.id];
    const optionalUnit = options.unit ? this.unit : '';
    let value;
    if (_.every([LOWER, MID, UPPER], _.partial(_.has, item))) {
      value = {
        main: `${item[MID].toFixed(this.decimalPoints)}${optionalUnit}`,
        sub: `(${item[LOWER].toFixed(this.decimalPoints)}${optionalUnit} - ${item[UPPER].toFixed(this.decimalPoints)}${optionalUnit})`,
      };
    } else if (_.isNumber(item)) {
      value = `${item.toFixed(this.decimalPoints)}${optionalUnit}`;
    } else if (options.key && _.has(item, options.key)) {
      value = `${item[options.key].toFixed(this.decimalPoints)}${optionalUnit} ${options.key}`;
    }
    if (_.isUndefined(value)) {
      return undefined;
    }
    return value;
  }

  getValue = (data) => {
    if (!_.isObject(data)) {
      return undefined;
    }
    const item = data[this.id];
    if (_.isNumber(item)) {
      return parseFloat(item.toFixed(this.decimalPoints));
    }
    if (_.isObject(item)) {
      return _.mapValues(
        item,
        (value) => (_.isNumber(value) ? parseFloat(value.toFixed(this.decimalPoints)) : undefined),
      );
    }
    return undefined;
  };

  generateStats = () => [];

  getTableData = (data, timeInterval) => {
    const scale = this.getScale({ timeIntervalId: timeInterval.id });
    const dates = data.map((item) => ({
      datetime: item.datetime,
      value: timeInterval.formatLong(item.datetime),
    }));
    const tableData = {
      body: [],
      head: { items: dates, name: timeInterval.heading },
    };
    [{ key: 'observation', name: 'Observed' }, { key: 'forecast', name: 'Forecast' }].forEach((row) => {
      const hasRow = data.some((item) => !_.isUndefined(this.getValue(item[row.key])));
      if (hasRow) {
        const rangeItem = data.find((item) => _.isObject(this.getValue(item[row.key])));
        let items;
        if (rangeItem) {
          items = Object.keys(sortProperty(rangeItem[row.key][this.id])).map((key) => ({
            items: data.map((item) => {
              if (
                row.key in item
                && this.id in item[row.key]
                && _.isObject(item[row.key][this.id])
              ) {
                return {
                  color: scale(this.getValue(item[row.key])[key]).alpha(0.25).css(),
                  datetime: item.datetime,
                  value: this.getFormatted(
                    { [this.id]: item[row.key][this.id][key] },
                    { unit: true },
                  ),
                };
              }
              return { datetime: item.datetime };
            }),
            name: key,
          }));
        } else {
          items = data.map((item) => {
            if (!_.isUndefined(item[row.key])
              && this.id in item[row.key]
              && _.isNumber(item[row.key][this.id])
            ) {
              return {
                color: scale(this.getValue(item[row.key])).alpha(0.25).css(),
                datetime: item.datetime,
                value: this.getFormatted(item[row.key], { unit: true }),
              };
            }
            return { datetime: item.datetime };
          });
        }
        tableData.body.push({
          items,
          name: row.name,
        });
      }
    });
    return tableData;
  };

  hasInfo = () => !_.isUndefined(this.info);

  getConditionIds(data) {
    const isRange = data.some(
      (value) => _.every(
        [LOWER, MID, UPPER],
        _.partial(_.has, this.getValue(value.forecast)),
      ),
    );
    if (isRange) {
      return [
        conditionIds.UPPER_FORECAST_GREATER_THAN,
        conditionIds.UPPER_FORECAST_LESS_THAN,
        conditionIds.MID_FORECAST_GREATER_THAN,
        conditionIds.MID_FORECAST_LESS_THAN,
        conditionIds.LOWER_FORECAST_GREATER_THAN,
        conditionIds.LOWER_FORECAST_LESS_THAN,
      ];
    }
    return [
      conditionIds.FORECAST_GREATER_THAN,
      conditionIds.FORECAST_LESS_THAN,
    ];
  }

  getLegend = (params = {}) => {
    const { timeIntervalId } = params;
    if (timeIntervalId && _.has(this.timeIntervalLegends, timeIntervalId)) {
      return _.get(this.timeIntervalLegends, timeIntervalId);
    }
    return this.legend;
  };

  getScale = (params = {}) => chroma
    .scale(this.getLegend(params).map((item) => item.color))
    .domain(this.getLegend(params).map((item) => item.value));

  getColor = (properties, params = {}) => {
    const scale = this.getScale(params);
    let value = this.getValue(properties);
    if (_.isObject(value)) {
      value = value[Object.keys(value)[parseInt(Object.keys(value).length / 2, 10)]];
    }
    return scale(value);
  };

  getWidgets = (params, title, includeLast30Days = false) => {
    const widgets = [{
      key: 'SUMMARY',
      type: SUMMARY,
      params,
      title,
    }];
    if (includeLast30Days) {
      widgets.push({
        key: 'TABLE_24H',
        type: TABLE,
        params,
        title: 'Latest 24 hours',
      });
      widgets.push({
        key: 'CHART_24H',
        type: CHART,
        params,
        title: 'Latest 24 hours',
      });
      widgets.push({
        key: 'TABLE_30D',
        type: TABLE,
        params: {
          ...params,
          sources: [...params.sources, LAST_30_DAYS_ENDPOINT],
          observationEndpointId: LAST_30_DAYS_ENDPOINT,
          dateTimeRangeId: LAST_30_DAYS_DATE,
        },
        title: 'Last 30 days',
      });
      widgets.push({
        key: 'CHART_30D',
        type: CHART,
        params: {
          ...params,
          sources: [...params.sources, LAST_30_DAYS_ENDPOINT],
          observationEndpointId: LAST_30_DAYS_ENDPOINT,
          dateTimeRangeId: LAST_30_DAYS_DATE,
        },
        title: 'Last 30 days',
      });
    } else {
      widgets.push({ key: 'TABLE', type: TABLE, params });
      widgets.push({ key: 'CHART', type: CHART, params });
    }
    return widgets;
  };

  getLabelForPropertyKey = (key) => key;

  isInData = (data) => data.some(({ observation, forecast }) => (
    !_.isUndefined(_.get(observation, this.id)) || !_.isUndefined(_.get(forecast, this.id))
  ));
}
