import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import LocationSelector from '../components/LocationSelector';
import locationTypes from '../data/locationTypes';
import { useLoader } from '../lib/hooks';

const propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  sort: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string,
  locationTypeId: PropTypes.string.isRequired,
};

const defaultProps = {
  className: '',
  onChange: _.noop,
  sort: _.noop,
  value: false,
  id: 'location-selector',
};

const LocationSelectorContainer = ({
  className, onChange, sort, value, id, locationTypeId,
}) => {
  const locations = useSelector(
    (state) => Object.values(state.locations).filter((item) => item.type === locationTypeId),
    _.isEqual,
  );
  return (
    <LocationSelector
      locations={locations}
      className={className}
      onChange={onChange}
      sort={sort}
      value={value}
      id={id}
    />
  );
};

LocationSelectorContainer.propTypes = propTypes;
LocationSelectorContainer.defaultProps = defaultProps;

const LocationSelectorLoader = ({
  className, onChange, sort, value, id, locationTypeId,
}) => {
  const locationType = locationTypes[locationTypeId];
  const isLoading = useLoader([locationType.endpointId]);
  if (isLoading) {
    return null;
  }
  return (
    <LocationSelectorContainer
      locationTypeId={locationTypeId}
      className={className}
      onChange={onChange}
      sort={sort}
      value={value}
      id={id}
    />
  );
};

LocationSelectorLoader.propTypes = propTypes;
LocationSelectorLoader.defaultProps = defaultProps;

export default LocationSelectorLoader;
