import { ABOUT } from '../constants/pageIds';
import * as text from '../data/text';

export default class AboutPage {
  static id = ABOUT;

  static label = 'About';

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: text.ABOUT_PAGE_DESCRIPTION },
      ],
    };
  }
}
