import React from 'react';
import { useSelector } from 'react-redux';
import * as actions from '../actions';
import Toasts from '../components/Toasts';
import { useAction } from '../lib/hooks';

const ToastsContainer = () => {
  const toasts = useSelector((state) => state.toasts);
  const removeToast = useAction(actions.removeToast);
  return <Toasts toasts={toasts} remove={removeToast} />;
};

export default ToastsContainer;
