const listeners = [];

class OpticaneWebSocket {
  states = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
  };

  constructor() {
    this.token = null;
    this.interval = null;
    this.retries = 0;
    this.heartbeat = { action: 'message', type: 'heartbeat' };
    this.isWanted = false;
  }

  connect = () => {
    this.isWanted = true;
    let url = process.env.REACT_APP_WS_URL;
    if (this.token) {
      url += `?accessToken=${this.token}`;
    }
    this.webSocket = new WebSocket(url);
    this.webSocket.addEventListener('open', () => {
      this.interval = setInterval(
        () => {
          if (this.webSocket.readyState === this.states.OPEN) {
            this.webSocket.send(JSON.stringify(this.heartbeat));
          }
        },
        5 * 60 * 1000,
      );
    });
    this.webSocket.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      if (data.type !== this.heartbeat.type) {
        listeners.forEach((listener) => listener(data));
      }
    });
    this.webSocket.addEventListener('close', () => {
      clearInterval(this.interval);
      if (this.isWanted && this.retries < 5) {
        this.retries += 1;
        setTimeout(this.connect, this.retries * 1000);
      }
    });
    this.webSocket.addEventListener('error', () => {
      if (this.retries === 5) {
        // eslint-disable-next-line no-console
        console.error('Failed to connect to web socket after multiple attempts.');
      }
    });
  };

  disconnect = () => {
    this.isWanted = false;
    clearInterval(this.interval);
    if (this.webSocket) {
      this.webSocket.close();
    }
  };

  setSession = (token) => {
    this.disconnect();
    this.retries = 0;
    this.token = token;
    this.connect();
  };

  clearSession = () => {
    this.token = null;
    this.disconnect();
  };
}

export const onMessage = (listener) => listeners.push(listener);
export const anonymousWebSocket = new OpticaneWebSocket();
export const authWebSocket = new OpticaneWebSocket();
