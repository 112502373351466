import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AlarmModal from '../containers/AlarmModal';
import AlarmContent from './AlarmContent';

export default function Alarm({
  alarm, property, condition, isActive, timeInterval, remove, edit, location,
}) {
  const [isModalShowing, setIsModalShowing] = useState(false);
  return (
    <>
      <AlarmContent
        alarm={alarm}
        property={property}
        condition={condition}
        isActive={isActive}
        timeInterval={timeInterval}
        remove={remove}
        setIsModalShowing={setIsModalShowing}
        location={location}
      />
      <AlarmModal
        isShowing={isModalShowing}
        fields={alarm}
        onClose={() => setIsModalShowing(false)}
        onChange={(fields) => {
          edit(alarm.id, fields);
          setIsModalShowing(false);
        }}
      />
    </>
  );
}

Alarm.propTypes = {
  isActive: PropTypes.bool.isRequired,
  alarm: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    notifications: PropTypes.bool.isRequired,
    triggeredAt: PropTypes.string,
  }).isRequired,
  property: PropTypes.shape({
    name: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
  }).isRequired,
  condition: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  timeInterval: PropTypes.shape({
    prefix: PropTypes.string.isRequired,
  }).isRequired,
  remove: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};
