import PropTypes from 'prop-types';
import React from 'react';
import Alarm from '../containers/Alarm';
import AlarmsPage from '../pages/AlarmsPage';
import Box from './Box';
import PageTitle from './PageTitle';

export default function Alarms({ alarms }) {
  return (
    <>
      <PageTitle>{AlarmsPage.label}</PageTitle>
      <div className="m-3">
        {alarms.length === 0 && <h3 className="text-center">No alarms have been created yet</h3>}
        <Box.Row title="Active">
          {alarms.map((alarm) => (
            <Box.Column className="col-lg-4 col-md-6" key={alarm.id}>
              <Alarm alarm={alarm} />
            </Box.Column>
          ))}
        </Box.Row>
      </div>
    </>
  );
}

Alarms.propTypes = {
  alarms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
