import React from 'react';
import Alert from '../containers/Alert';

export default function CwuAlert() {
  return (
    <Alert id="cwu">
      This is
      <strong> generic crop water use.</strong>
    </Alert>
  );
}
