import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Route, Switch } from 'react-router-dom';
import * as paths from '../constants/paths';
import Box from './Box';
import CustomToggle from './CustomToggle';

export default function WidgetWrapper({
  title, setIsModalShowing, previousId, nextId, move, children, id, remove,
}) {
  return (
    <Box.Content title={title}>
      <div className="position-absolute" style={{ right: 0, top: 0 }}>
        <Dropdown align="end">
          <Dropdown.Toggle
            variant="success"
            as={CustomToggle}
            className="mt-2"
            aria-label="Widget menu"
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Switch>
              <Route path={paths.DASHBOARD}>
                <Dropdown.Item onClick={() => setIsModalShowing(true)}>
                  Rename
                </Dropdown.Item>
                {!!previousId && (
                  <Dropdown.Item onClick={() => move(id, previousId)}>
                    Move up
                  </Dropdown.Item>
                )}
                {!!nextId && (
                  <Dropdown.Item onClick={() => move(id, nextId)}>
                    Move down
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={() => remove(id)}>
                  Remove
                </Dropdown.Item>
              </Route>
              <Route path={paths.ROOT}>
                <Dropdown.Item onClick={() => setIsModalShowing(true)}>
                  Add to dashboard
                </Dropdown.Item>
              </Route>
            </Switch>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        className={classNames(
          'flex-grow-1 d-flex justify-content-center align-items-center text-start',
          {
            'mt-5': !title,
          },
        )}
      >
        {children}
      </div>
    </Box.Content>
  );
}

WidgetWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  previousId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  nextId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  move: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setIsModalShowing: PropTypes.func.isRequired,
  title: PropTypes.node,
};

WidgetWrapper.defaultProps = {
  id: '',
  previousId: false,
  nextId: false,
  title: '',
};
