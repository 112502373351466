import { FISHING } from '../constants/pageIds';
import * as paths from '../constants/paths';

export default class FishingPage {
  static id = FISHING;

  static label = 'Fishing 🐟';

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: 'Fishing conditions for the Burdekin region' },
      ],
    };
  }

  generateBreadcrumbs(pathname) {
    const breadcrumbs = [
      {
        to: paths.HOME,
        name: 'Home',
      },
      {
        to: '/tasks/',
        name: 'Tasks',
      },
      {
        to: pathname,
        name: this.constructor.label,
      },
    ];
    return breadcrumbs;
  }
}
