import { faBell } from '@fortawesome/free-regular-svg-icons';
import { ALARMS } from '../constants/pageIds';
import * as paths from '../constants/paths';
import * as text from '../data/text';

export default class AlarmsPage {
  static id = ALARMS;

  static label = 'Alarms';

  static path = paths.ALARMS;

  static icon = faBell;

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: text.ALARMS_PAGE_DESCRIPTION },
        { name: 'robots', content: 'noindex' },
      ],
    };
  }
}
