import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import AlarmModal from '../components/AlarmModal';
import conditions from '../data/conditions';
import dateTimeRanges from '../data/dateTimeRanges';
import properties from '../data/properties';
import timeIntervals from '../data/timeIntervals';
import { useLocationData } from '../lib/hooks';

const AlarmModalContainer = ({
  fields, onChange, onClose, isShowing,
}) => {
  const data = useLocationData({
    dateTimeRangeId: fields.dateTimeRangeId,
    forecastEndpointId: fields.forecastEndpointId,
    locationId: fields.locationId,
    observationEndpointId: fields.observationEndpointId,
    metaFilter: fields.metaFilter,
  });
  const property = properties[fields.propertyId];
  const { timeIntervalId } = dateTimeRanges[fields.dateTimeRangeId];
  const timeInterval = timeIntervals[timeIntervalId];
  const userHasPhone = useSelector((state) => !!state.auth.user.phoneNumber);
  const location = useSelector((state) => state.locations[fields.locationId]);
  return (
    <AlarmModal
      fields={fields}
      conditions={property.getConditionIds(data).map((id) => conditions[id])}
      property={property}
      userHasPhone={userHasPhone}
      timeInterval={timeInterval}
      location={location}
      onChange={onChange}
      onClose={onClose}
      isShowing={isShowing}
    />
  );
};

AlarmModalContainer.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    dateTimeRangeId: PropTypes.string.isRequired,
    forecastEndpointId: PropTypes.string.isRequired,
    locationId: PropTypes.string.isRequired,
    observationEndpointId: PropTypes.string.isRequired,
    propertyId: PropTypes.string.isRequired,
    metaFilter: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  }).isRequired,
};

export default AlarmModalContainer;
