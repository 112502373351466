import PropTypes from 'prop-types';
import React from 'react';
import TimeFromNow from './TimeFromNow';

export default function EndpointDescription({ sourceInfo, description, issuedDate }) {
  return (
    <div className="small">
      {!!sourceInfo && (
        <div className="mb-2">
          {sourceInfo}
        </div>
      )}
      <i>
        {description}
        {' '}
        {issuedDate && (
          <>
            Issued
            {' '}
            <TimeFromNow moment={issuedDate} updateFrequency={60 * 60} />
            .
          </>
        )}
      </i>
    </div>
  );
}

EndpointDescription.propTypes = {
  sourceInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  description: PropTypes.node.isRequired,
  issuedDate: PropTypes.oneOfType([
    PropTypes.shape({
      format: PropTypes.func.isRequired,
    }),
    PropTypes.bool,
  ]).isRequired,
};
