import * as irrigationTypeIds from '../constants/irrigationTypeIds';
import * as propertyIds from '../constants/propertyIds';

const irrigationTypes = {
  [irrigationTypeIds.IRRIGATE]: {
    id: irrigationTypeIds.IRRIGATE,
    name: 'Irrigate',
    toAdd: () => [{ id: propertyIds.FIRST_IRRIG_POP }],
  },
  [irrigationTypeIds.FURROW]: {
    id: irrigationTypeIds.FURROW,
    name: 'Single Irrigation',
    toAdd: () => [{ id: propertyIds.FIRST_IRRIG_POP }],
  },
  [irrigationTypeIds.DRIP]: {
    id: irrigationTypeIds.DRIP,
    name: 'Multiple Irrigations',
    toAdd: () => [{ id: propertyIds.FIRST_IRRIG_POP }],
  },
  [irrigationTypeIds.NO_IRRIGATION]: {
    id: irrigationTypeIds.NO_IRRIGATION,
    name: 'Soil Water Deficit',
    toAdd: () => [],
  },
};

export default irrigationTypes;
