import { faTasks } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { matchPath } from 'react-router-dom';
import { TASKS } from '../constants/pageIds';
import * as paths from '../constants/paths';
import tasks from '../data/tasks';
import * as text from '../data/text';

export default class TaskPage {
  static icon = faTasks;

  static id = TASKS;

  static label = 'Tasks';

  static path = paths.TASKS;

  static description = 'Recent conditions and short range forecasts for common managment decisions. Find recent outlooks on relevent conditions in one place.';

  static getPath = () => this.path;

  generateHead(pathname) {
    const taskPageMatch = matchPath(pathname, { path: paths.TASK_PARAMS_OPTIONAL });
    const head = {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: text.TASKS_PAGE_DESCRIPTION },
      ],
    };
    if (taskPageMatch) {
      const taskId = _.get(taskPageMatch, 'params.taskId');
      if (taskId) {
        head.title = [tasks[taskId].name, this.constructor.label];
      }
    }
    return head;
  }
}
