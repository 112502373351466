import { combineReducers } from 'redux';
import admin from './admin';
import alarms from './alarms';
import app from './app';
import auth from './auth';
import datetimes from './datetimes';
import endpoints from './endpoints';
import forecasts from './forecasts';
import forecastsEndpointsDates from './forecastsEndpointsDates';
import locations from './locations';
import map from './map';
import observations from './observations';
import pages from './pages';
import toasts from './toasts';
import widgets from './widgets';

export default combineReducers({
  admin,
  alarms,
  app,
  auth,
  datetimes,
  endpoints,
  forecasts,
  forecastsEndpointsDates,
  locations,
  map,
  observations,
  pages,
  toasts,
  widgets,
});
