import { Toast as BsToast } from 'bootstrap';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const classMap = {
  success: 'bg-success',
  error: 'bg-danger',
};

export default function Toast({ toast, remove }) {
  const element = useRef();
  const bsToast = useRef();
  useEffect(() => {
    bsToast.current = new BsToast(element.current, { autohide: toast.autoClose });
    bsToast.current.show();
    element.current.addEventListener('hidden.bs.toast', () => remove(toast));
    return bsToast.dispose;
  }, [toast, remove]);
  return (
    <div ref={element} className={`toast text-white ${classMap[toast.type]}`}>
      <div className="d-flex">
        <div className="toast-body">{toast.message}</div>
        <button
          type="button"
          className="btn-close btn-close-white me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        />
      </div>
    </div>
  );
}

Toast.propTypes = {
  toast: PropTypes.shape({
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    autoClose: PropTypes.bool.isRequired,
  }).isRequired,
  remove: PropTypes.func.isRequired,
};
