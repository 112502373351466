import _ from 'lodash';
import React from 'react';
import locations from '../data/locations';
import * as text from '../data/text';
import HelpComponent from './HelpComponent';
import HelpSection from './HelpSection';
import HelpSubSection from './HelpSubSection';
import LocationSelector from './LocationSelector';
import NavbarAccountDropdown from './NavbarAccountDropdown';
import NavbarSignIn from './NavbarSignIn';

export default function HelpAccount() {
  return (
    <HelpSection title="Account">
      <p className="lead">
        {`${text.SETTINGS_PAGE_DESCRIPTION} `}
        This will result in forecasts being generated specifically for your fields.
      </p>
      <HelpSubSection
        title="Sign up"
        example={(
          <HelpComponent>
            <NavbarSignIn onClick={_.noop} />
          </HelpComponent>
        )}
        text={(
          <p>
            To sign up for an account click on the sign in button. On the sign in page press
            &quot;Create account&quot; next to where it says &quot;No account?&quot;. Follow the
            steps and complete the form. Alternatively there is a &quot;Create an account&quot;
            button on the home page which will take you to the same place.
          </p>
        )}
      />
      <h4><u>Verify account</u></h4>
      <p>
        To confirm that your account email is accessible by you, a verification code will be
        sent to your email address. You will be required to enter this code as part of signing
        up.
      </p>
      <h4><u>IrrigWeb account</u></h4>
      <p>
        If you have an IrrigWeb account be sure to use the same email address that you have used
        for IrrigWeb when signing up for an Opticane account. This will allow us to link your
        accounts together.
      </p>
      <HelpSubSection
        title="Default zone"
        example={(
          <HelpComponent>
            <div className="bg-white p-2">
              <LocationSelector value="" locations={[]} id="zone-location-selector" />
            </div>
          </HelpComponent>
        )}
        text={(
          <>
            <p>
              After signing in with your account you will be prompted to select a default
              region. This will be used for generating forecasts for your fields if the fields
              do not have location data already associated with them. You will only have to set
              this once. Until it is set no forecasts can be generated for fields that have no
              location data.
            </p>
            <ul>
              {Object.values(locations).map((location) => (
                <li key={location.id}>
                  {location.name}
                </li>
              ))}
            </ul>
          </>
        )}
      />
      <HelpSubSection
        title="Default station"
        example={(
          <HelpComponent>
            <div className="bg-white p-2">
              <LocationSelector value="" locations={[]} id="station-location-selector" />
            </div>
          </HelpComponent>
        )}
        text={(
          <p>
            After signing in with your account you will be prompted to select a default
            station. This will be used within the tasks page and the current conditions page as
            the selected station by default.
          </p>
        )}
      />
      <HelpSubSection
        title="Account menu"
        example={(
          <HelpComponent>
            <div className="cssip-help__account-dropdown">
              <div className="bg-dark">
                <NavbarAccountDropdown
                  email="user@example.com"
                  isAdmin={false}
                  signOut={_.noop}
                  isShowing
                  setIsShowing={_.noop}
                  onClick={_.noop}
                />
              </div>
            </div>
          </HelpComponent>
        )}
        text={(
          <p>
            If you need to update your details, such as email address, phone number, password,
            default region and station then click on the account dropdown menu and select settings.
            From here you will be able to edit any of these details.
            <br />
            Sign out from the app using this menu.
            <br />
            There are menu links for going to the dashboard, alarms and my farm pages in this
            dropdown menu.
          </p>
        )}
      />
    </HelpSection>
  );
}
