export const USER_API = process.env.REACT_APP_USER_API_PATH;
export const PUBLIC_API = process.env.REACT_APP_PUBLIC_API_PATH;

export const ADMIN_USERS_URL = `${USER_API}admin/users`;
export const ALARMS_URL = `${USER_API}alarms`;
export const CROP_FORECAST_ENDPOINT_DATES_URL = `${USER_API}endpoints-dates?endpointId=FORECAST_CROP`;
export const CROP_FORECAST_PAST_ISSUED_URL = `${PUBLIC_API}forecasts/crop/:date.json`;
export const CROP_FORECAST_URL = `${PUBLIC_API}forecasts/crop.json`;
export const CROP_OBSERVATION_URL = `${PUBLIC_API}observations/crop.json`;
export const DEMO_FARM = `${process.env.PUBLIC_URL}/mock-demo-farm.json`;
export const ENSO_PHASE = `${PUBLIC_API}enso-phase.json`;
export const ENSO_RAINFALL_URL = `${PUBLIC_API}average-rainfall-enso-phase.json`;
export const MY_FARM_PAST_ISSUED_URL = `${USER_API}my-farm/forecasts?issued=:date`;
export const MY_FARM_FORECAST_ENDPOINT_DATES_URL = `${USER_API}my-farm/endpoints-dates?endpointId=FORECAST_MY_FARM`;
export const MY_FARM_FORECAST_URL = `${USER_API}my-farm/forecasts`;
export const MY_FARM_LOCATION_URL = `${USER_API}my-farm/locations`;
export const MY_FARM_OBSERVATION_URL = `${USER_API}my-farm/observations`;
export const REAL_TIME_URL = `${PUBLIC_API}24H-real-time.json`;
export const LAST_30_DAYS_URL = `${PUBLIC_API}30D-real-time.json`;
export const WEATHER_FORECAST_ENDPOINT_DATES_URL = `${USER_API}endpoints-dates?endpointId=FORECAST_WEATHER`;
export const WEATHER_FORECAST_PAST_ISSUED_URL = `${PUBLIC_API}forecasts/weather/:date.json`;
export const WEATHER_FORECAST_URL = `${PUBLIC_API}forecasts/weather.json`;
export const WEATHER_OBSERVATION_URL = `${PUBLIC_API}observations/weather.json`;
export const WIDGETS_MOVE_URL = `${USER_API}widgets/move`;
export const WIDGETS_URL = `${USER_API}widgets`;
export const ADD_IRRIGATION_URL = `${USER_API}irrigation`;
export const CONTACT = `${USER_API}contact`;
export const TIDES = `${USER_API}tides`;
export const IRRIGWEB_URL = 'https://www.irrigweb.com';
