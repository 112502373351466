import moment from 'moment';
import React from 'react';
import { Route, Link } from 'react-router-dom';
import * as paths from '../constants/paths';

export default function Footer() {
  return (
    <footer className="d-print-none small py-1 bg-dark text-white d-flex justify-content-between">
      <div className="px-1">
        <Route path={[paths.FORECASTS, paths.FARM, paths.TASKS, paths.DASHBOARD]}>
          <>
            Opticane uses data from a variety of sources. For full data attribution see
            {' '}
            <Link
              className="text-light"
              to={paths.TERMS_OF_USE_SECTION_OPTIONAL.replace(
                paths.termsOfUseSectionIdString,
                'data-attribution',
              )}
            >
              data disclaimer and attribution
            </Link>
            .
          </>
        </Route>
      </div>
      <div className="px-1 flex-shrink-0 align-self-end">
        <span>{`©${moment().format('YYYY')} JCU.`}</span>
      </div>
    </footer>
  );
}
