import * as types from '../constants/actionTypes';

const initialState = {};

export default function forecasts(state = initialState, action = {}) {
  switch (action.type) {
    case types.ENDPOINT_RECEIVED: {
      if (!action.forecasts) {
        return state;
      }
      return {
        ...state,
        [action.endpointId]: action.forecasts,
      };
    }

    default: {
      return state;
    }
  }
}
