import { REMOTE_SENSING } from '../constants/pageIds';
import * as text from '../data/text';

export default class RemoteSensingPage {
  static id = REMOTE_SENSING;

  static label = 'Remote Sensing';

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: text.REMOTE_SENSING_PAGE_DESCRIPTION },
      ],
    };
  }
}
