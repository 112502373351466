import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import Tour from '../components/Tour';
import tour from '../data/tour';
import { useLoader } from '../lib/hooks';
import DemoFarmPage from '../pages/DemoFarmPage';

const emptyList = [];

const propTypes = {
  children: PropTypes.node.isRequired,
};

const TourContainer = ({ children }) => {
  const location = useLocation();
  const key = Object.keys(tour).find(
    (path) => matchPath(location.pathname, { path, exact: true, strict: false }),
  );
  const steps = tour[key] || emptyList;
  return <Tour key={key} keyProp={key} steps={steps}>{children}</Tour>;
};

TourContainer.propTypes = propTypes;

const TourLoader = ({ children }) => {
  const sources = useSelector((state) => {
    const page = new DemoFarmPage(state);
    return page.getSources();
  });
  const isLoading = useLoader(sources);
  if (isLoading) {
    return children;
  }
  return <TourContainer>{children}</TourContainer>;
};

TourLoader.propTypes = propTypes;

export default TourLoader;
