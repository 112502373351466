import chroma from 'chroma-js';
import _ from 'lodash';
import variables from '../custom.module.scss';

// This is just so tests pass, they can't handle importing scss.
const colors = {};
['primary', 'secondary', 'tertiary'].forEach((key) => {
  try {
    colors[key] = chroma(_.get(variables, key));
  } catch {
    colors[key] = chroma('#FFFFFF');
  }
});

/* eslint-disable prefer-destructuring */
export const primary = colors.primary;
export const secondary = colors.secondary;
export const tertiary = colors.tertiary;
