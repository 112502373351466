import { faBug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Box from './Box';

export default function ErrorPage() {
  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <Box.Content className="h-auto p-5 text-center align-items-center">
        <FontAwesomeIcon icon={faBug} size="5x" />
        <h1>Something went wrong.</h1>
        <br />
        <a href="/">
          <h2>Return to home page</h2>
        </a>
      </Box.Content>
    </div>
  );
}
