import * as valueTypes from '../constants/valueTypes';

const info = {
  chance: {
    high: {
      long: 'Statistically this is calculated as the 25th percentile (often referred to as the upper quartile) and is equivalent to the lower bound of the forecast range.',
      short: '3-in-4 (75%) chance of reaching or exceeding this value',
    },
    low: {
      long: 'Statistically this is calculated as the 75th percentile (often referred to as the upper quartile) and is equivalent to the ‘high’ value of the forecast range.',
      short: '1 in 4 (25%) chance of reaching or exceeding this value',
    },
    medium: {
      long: 'Statistically this is calculated as the 50th percentile (often referred to as the median) and is equivalent to the ‘mid’ value of the forecast range.',
      short: '50:50 (50%) chance of reaching or exceeding this value',
    },
  },
  stats: {
    [valueTypes.LOWER]: {
      long: 'Statistically this is calculated as the 25th percentile (often referred to as the lower quartile). It suggests that 1 in 4 models forecast a value lower than the indicated value.',
      short: '1-in-4 (25%) chance of less than this value',
    },
    [valueTypes.MID]: {
      long: 'Statistically this is calculated as the 50th percentile (often referred to as the median). It suggests that half of all models were below and half were above the indicated value.',
      short: '50:50 chance of less than or greater than this value',
    },
    [valueTypes.UPPER]: {
      long: 'Statistically this is calculated as the 75th percentile (often referred to as the upper quartile). It suggests that 1 in 4 models forecast a value greater than the indicated value.',
      short: '1-in-4 (25%) chance of greater than this value',
    },
  },
};

export default info;
