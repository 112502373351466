import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AlarmModal from '../containers/AlarmModal';
import AlarmAddButton from './AlarmAddButton';
import AuthFeatureModal from './AuthFeatureModal';

export default function AlarmAdd({ add, fields, isAuthenticated }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <AlarmAddButton
        onClick={() => setIsModalOpen(!isModalOpen)}
      />
      {isAuthenticated ? (
        <AlarmModal
          isShowing={isModalOpen}
          fields={fields}
          onClose={() => setIsModalOpen(false)}
          onChange={(alarm) => {
            const id = uuidv4();
            add({ id, ...alarm });
            setIsModalOpen(false);
          }}
        />
      ) : (
        <AuthFeatureModal isShowing={isModalOpen} onClose={() => setIsModalOpen(false)} />
      )}
    </>
  );
}

AlarmAdd.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  add: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
};
