import * as constants from '../constants/dateTimeRangeIds';
import {
  MINUTE, HOUR, DAY, WEEK, FOUR_WEEK, LAST_30_DAYS_HOUR,
} from '../constants/timeIntervalIds';

const dateTimeRanges = {
  [constants.NEXT_24_HOURS]: {
    endOffset: 23,
    id: constants.NEXT_24_HOURS,
    startOffset: 0,
    timeIntervalId: HOUR,
    unit: HOUR,
    step: 1,
    alignTimeIntervalId: HOUR,
    label: constants.NEXT_24_HOURS,
  },
  [constants.NEXT_48_HOURS]: {
    endOffset: 47,
    id: constants.NEXT_48_HOURS,
    startOffset: 0,
    timeIntervalId: HOUR,
    unit: HOUR,
    step: 1,
    alignTimeIntervalId: HOUR,
    label: 'Hourly forecasts',
  },
  [constants.NEXT_3_DAYS]: {
    endOffset: 2,
    id: constants.NEXT_3_DAYS,
    startOffset: 0,
    timeIntervalId: DAY,
    unit: DAY,
    step: 1,
    alignTimeIntervalId: DAY,
    label: constants.NEXT_3_DAYS,
  },
  [constants.NEXT_7_DAYS]: {
    endOffset: 6,
    id: constants.NEXT_7_DAYS,
    startOffset: 0,
    timeIntervalId: DAY,
    unit: DAY,
    step: 1,
    alignTimeIntervalId: DAY,
    label: 'Daily forecasts',
  },
  [constants.NEXT_14_WEEKS]: {
    endOffset: 13,
    id: constants.NEXT_14_WEEKS,
    startOffset: 0,
    timeIntervalId: WEEK,
    unit: WEEK,
    step: 1,
    alignTimeIntervalId: DAY,
    label: 'Weekly outlook',
  },
  [constants.LAST_48_HOURS]: {
    endOffset: -1,
    id: constants.LAST_48_HOURS,
    startOffset: -48,
    timeIntervalId: HOUR,
    unit: HOUR,
    step: 1,
    alignTimeIntervalId: HOUR,
    label: constants.LAST_48_HOURS,
  },
  [constants.LAST_3_DAYS]: {
    endOffset: -1,
    id: constants.LAST_3_DAYS,
    startOffset: -3,
    timeIntervalId: DAY,
    unit: DAY,
    step: 1,
    alignTimeIntervalId: DAY,
    label: constants.LAST_3_DAYS,
  },
  [constants.LAST_7_DAYS]: {
    endOffset: -1,
    id: constants.LAST_7_DAYS,
    startOffset: -7,
    timeIntervalId: DAY,
    unit: DAY,
    step: 1,
    alignTimeIntervalId: DAY,
    label: 'Past daily',
  },
  [constants.LAST_14_WEEKS]: {
    endOffset: -1,
    id: constants.LAST_14_WEEKS,
    startOffset: -14,
    timeIntervalId: WEEK,
    unit: WEEK,
    step: 1,
    alignTimeIntervalId: DAY,
    label: 'Past weekly',
  },
  [constants.REAL_TIME_DATE_TIME_RANGE]: {
    endOffset: -1,
    id: constants.REAL_TIME_DATE_TIME_RANGE,
    startOffset: -24 * 60,
    timeIntervalId: MINUTE,
    unit: MINUTE,
    step: 4,
    alignTimeIntervalId: MINUTE,
    label: constants.REAL_TIME_DATE_TIME_RANGE,
  },
  [constants.LAST_24_WEEKS]: {
    endOffset: -1,
    id: constants.LAST_24_WEEKS,
    startOffset: -24,
    timeIntervalId: FOUR_WEEK,
    unit: WEEK,
    step: 4,
    alignTimeIntervalId: DAY,
    label: 'Past outlook',
  },
  [constants.NEXT_24_WEEKS]: {
    endOffset: 23,
    id: constants.NEXT_24_WEEKS,
    startOffset: 0,
    timeIntervalId: FOUR_WEEK,
    unit: WEEK,
    step: 4,
    alignTimeIntervalId: DAY,
    label: 'Outlook',
  },
  [constants.LAST_30_DAYS]: {
    endOffset: 0,
    id: constants.LAST_30_DAYS,
    startOffset: -30,
    timeIntervalId: LAST_30_DAYS_HOUR,
    unit: DAY,
    step: 1,
    alignTimeIntervalId: DAY,
    label: 'Last 30 days',
  },
};

export default dateTimeRanges;
