import './HelpSection.scss';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import PageTitle from './PageTitle';

export default function HelpSection({ title, children }) {
  useEffect(() => { window.scrollTo({ behavior: 'instant', top: 0 }); }, []);
  return (
    <>
      <PageTitle>{`${title} help`}</PageTitle>
      <div className="container bg-light py-3">
        <section className="mb-5 pb-3 cssip-help-section">
          {children}
        </section>
      </div>
    </>
  );
}

HelpSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
