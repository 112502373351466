import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export default function FormattedValue({ value, display }) {
  if (_.every(['main', 'sub'], _.partial(_.has, value))) {
    return (
      <>
        <div className={`d-${display}`}>
          {value.main}
        </div>
        {_.includes(['inline-block', 'inline'], display) && ' '}
        <div className={`d-${display} small mt-n1 text-muted`}>
          {value.sub}
        </div>
      </>
    );
  }
  return value;
}

FormattedValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  display: PropTypes.string,
};

FormattedValue.defaultProps = {
  value: '',
  display: 'block',
};
