import moment from 'moment';
import * as constants from '../constants/timeIntervalIds';

const timeIntervals = {
  [constants.MINUTE]: {
    alignment: constants.MINUTE,
    quantity: 1,
    formatLong: (date) => moment(date).format('h:mma'),
    formatShort: (date) => moment(date).format('h:mma'),
    heading: 'Time',
    id: constants.MINUTE,
    label: 'Minute',
    prefix: 'Minute',
    nextForecastLabel: 'Next minutes',
    showStats: false,
  },
  [constants.HOUR]: {
    alignment: constants.HOUR,
    quantity: 1,
    formatLong: (date) => moment(date).format('ha'),
    formatShort: (date) => moment(date).format('ha'),
    heading: 'Time',
    id: constants.HOUR,
    label: 'Hour',
    prefix: 'Hourly',
    nextForecastLabel: 'Next hours',
    generatePostfixForListItem: (diff) => {
      if (diff > 0) {
        return moment().to(moment().add(diff, 'hour'));
      }
      return 'for this hour';
    },
    showStats: false,
  },
  [constants.DAY]: {
    alignment: constants.DAY,
    quantity: 1,
    formatLong: (date) => moment(date).format('ddd Do MMM'),
    formatShort: (date) => moment(date).format('DD MMM'),
    heading: 'Date',
    id: constants.DAY,
    label: 'Day',
    prefix: 'Daily',
    nextForecastLabel: 'Todays',
    generatePostfixForListItem: (diff) => {
      if (diff > 0) {
        return moment().to(moment().add(diff, 'day'));
      }
      return 'for today';
    },
    showStats: false,
  },
  [constants.WEEK]: {
    alignment: constants.DAY,
    quantity: 7,
    formatLong: (date) => {
      const start = moment(date).format('DD MMM');
      const end = moment(date).add(6, 'days').format('DD MMM');
      return `${start} - ${end}`;
    },
    formatShort: (date) => {
      const start = moment(date).format('DD/MM');
      const end = moment(date).add(6, 'days').format('DD/MM');
      return `${start} - ${end}`;
    },
    heading: 'Date',
    id: constants.WEEK,
    label: 'Week',
    prefix: 'Weekly',
    nextForecastLabel: 'This weeks',
    generatePostfixForListItem: (diff) => {
      if (diff > 0) {
        return moment().to(moment().add(diff, 'week'));
      }
      return 'for this week';
    },
    showStats: true,
  },
  [constants.FOUR_WEEK]: {
    alignment: constants.DAY,
    quantity: 28,
    formatLong: (date) => {
      const start = moment(date).format('DD MMM');
      const end = moment(date).add(27, 'days').format('DD MMM');
      return `${start} - ${end}`;
    },
    formatShort: (date) => {
      const start = moment(date).format('DD/MM');
      const end = moment(date).add(27, 'days').format('DD/MM');
      return `${start} - ${end}`;
    },
    heading: 'Date',
    id: constants.FOUR_WEEK,
    label: 'Month',
    prefix: 'Monthly',
    nextForecastLabel: 'This next 4 weeks',
    generatePostfixForListItem: (diff) => {
      if (diff > 0) {
        return moment().to(moment().add(diff * 4, 'weeks'));
      }
      return 'for this 4 weeks';
    },
    showStats: false,
  },
  [constants.LAST_30_DAYS_HOUR]: {
    alignment: constants.HOUR,
    quantity: 1,
    formatLong: (date) => moment(date).format('ha Do MMM'),
    formatShort: (date) => moment(date).format('ha Do MMM'),
    heading: 'Time',
    id: constants.LAST_30_DAYS_HOUR,
    label: 'Hour',
    prefix: 'Hourly',
    nextForecastLabel: 'Next hours',
    generatePostfixForListItem: (diff) => {
      if (diff > 0) {
        return moment().to(moment().add(diff, 'hour'));
      }
      return 'for this hour';
    },
    showStats: false,
  },
};

export default timeIntervals;
