import * as types from '../constants/actionTypes';

const initialState = [];

export default function alarms(state = initialState, action = {}) {
  switch (action.type) {
    case types.ENDPOINT_RECEIVED: {
      if (action.alarms) {
        return action.alarms;
      }
      return state;
    }

    case types.ALARM_ADDED: {
      return [...state, action.alarm];
    }

    case types.ALARM_EDITED: {
      return state.map((item) => {
        if (item.id !== action.id) {
          return item;
        }
        return { ...item, ...action.fields };
      });
    }

    case types.ALARM_REMOVED: {
      return state.filter((item) => item.id !== action.id);
    }

    default: {
      return state;
    }
  }
}
