import { faBroadcastTower, faRuler, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment';
import { matchPath } from 'react-router-dom';
import * as dateTimeRangeIds from '../constants/dateTimeRangeIds';
import * as endpointIds from '../constants/endpointIds';
import { REAL_TIME } from '../constants/locationTypeIds';
import * as constants from '../constants/pageIds';
import { PROPERTY_VIEW, OVERVIEW_VIEW } from '../constants/pageViews';
import * as paths from '../constants/paths';
import * as propertyIds from '../constants/propertyIds';
import { MINUTE } from '../constants/timeIntervalIds';
import properties from '../data/properties';
import * as text from '../data/text';
import { primary, secondary } from '../data/theme';
import DataPage from './DataPage';

const realTimePropertyIds = [
  propertyIds.AIR_TEMPERATURE,
  propertyIds.RELATIVE_HUMIDITY,
  propertyIds.WIND_SPEED,
  propertyIds.RAIN_24_HOUR_TOTAL,
  propertyIds.DELTA_T,
  propertyIds.REAL_TIME_RADIATION,
];

if (process.env.REACT_APP_SOIL_TEMP_ENABLED === 'true') {
  realTimePropertyIds.push(propertyIds.SOIL_TEMPERATURE);
}

export default class RealTimePage extends DataPage {
  static icon = faBroadcastTower;

  static id = constants.REAL_TIME;

  static showMapDisplaySelector = false;

  static locationTypeId = REAL_TIME;

  static initialState = {
    dateTimeRangeIds: {
      [PROPERTY_VIEW]: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
      [OVERVIEW_VIEW]: dateTimeRangeIds.REAL_TIME_DATE_TIME_RANGE,
    },
    // @TODO: Real time isn't forecast. Required here for forecast description.
    forecastEndpointId: endpointIds.REAL_TIME,
    forecastEndpointIds: [endpointIds.REAL_TIME],
    dateTimeOffset: 0,
  };

  static observationEndpointId = endpointIds.REAL_TIME;

  static locationsEndpointId = endpointIds.REAL_TIME;

  static label = 'Current conditions';

  static propertyIds = realTimePropertyIds;

  static sources = [endpointIds.REAL_TIME];

  static outdatedInMinutes = 120;

  static description = text.CURRENT_CONDITIONS_PAGE_DESCRIPTION;

  getOutdatedInMinutes() {
    return this.constructor.outdatedInMinutes;
  }

  static getMapValue = (location) => {
    const { observations } = location;
    if (!observations.length) {
      return undefined;
    }
    const latestObservation = _.last(observations);
    if (moment().subtract(this.outdatedInMinutes, 'minutes').isAfter(latestObservation.datetime)) {
      return undefined;
    }
    return latestObservation.properties;
  };

  getSelectedEndpointIssuedDate() {
    const state = this.getPageState();
    const all = [];
    if (this.state.observations[state.observationEndpointId]) {
      Object.values(this.state.observations[state.observationEndpointId])
        .forEach((location) => all.push(_.last(location)));
    }
    const filtered = all.filter((item) => item).map((item) => moment(item.datetime));
    return filtered.length ? moment.max(filtered) : false;
  }

  getOverviewItems = (state, props) => {
    const { observations } = state;
    const location = state.locations[props.locationId];
    const locationObservations = (observations[endpointIds.REAL_TIME][location.id] || []);
    if (locationObservations.length === 0) {
      return [];
    }
    const current = _.last(locationObservations);
    const title = moment(current.datetime).format('h:mmA dddd Do MMMM');
    return [{
      ...current,
      title,
      timeInterval: MINUTE, // @TODO: Not used anywhere, just added to keep console warning away.
      propertyIds: this.constructor.propertyIds,
    }];
  };

  generateOverviewLocationListItems() {
    const locations = _.orderBy(this.constructor.filterLocations(Object.values(this.state.locations)), ['distance'], ['asc']);
    const observations = this.state.observations[this.constructor.getObservationEndpoint().id];
    return locations.map((location) => {
      const items = [];
      const locationObservations = observations[location.id];
      const current = _.last(locationObservations);
      let hasRecent = false;
      this.constructor.propertyIds.forEach((propertyId) => {
        const property = properties[propertyId];
        if (current) {
          const hasValue = !_.isUndefined(property.getValue(current.properties));
          const isRecent = moment().subtract(this.getOutdatedInMinutes(), 'minutes').isBefore(current.datetime);
          hasRecent = hasRecent || isRecent;
          if (hasValue && isRecent) {
            const color = property.getColor(current.properties);
            items.push({
              icon: {
                color: color.css(),
                icon: property.icon,
              },
              name: property.name,
              value: property.getFormatted(current.properties, { unit: true }),
              to: this.constructor.getPropertyLocationPath(propertyId, location.id),
            });
          }
        }
      });
      if (!hasRecent) {
        items.push({
          icon: {
            icon: faTimes,
            color: secondary.css(),
          },
          name: 'No current data available',
        });
      }
      if (location.distance) {
        items.push({
          icon: {
            color: primary.css(),
            icon: faRuler,
          },
          name: 'Distance from current location',
          value: `${parseInt(location.distance, 10)} km`,
        });
      }
      return {
        geoJson: {
          features: locations.map((item) => ({
            geometry: item.geometry,
            properties: {
              selected: item === location,
            },
            type: 'Feature',
          })),
          type: 'FeatureCollection',
        },
        geometry: location.geometry,
        name: location.name,
        properties: items,
        id: location.id,
        datetime: hasRecent ? current.datetime : undefined,
      };
    });
  }

  generateBreadcrumbs(pathname) {
    const breadcrumbs = [
      {
        to: paths.HOME,
        name: 'Home',
      },
      {
        to: this.constructor.getOverviewPath(),
        name: this.constructor.label,
      },
    ];
    const propertyPageMatch = matchPath(pathname, { path: paths.PROPERTY_ITEM_OPTIONAL });
    if (propertyPageMatch) {
      const propertyId = _.get(propertyPageMatch, 'params.propertyId');
      const locationId = _.get(propertyPageMatch, 'params.locationId');
      breadcrumbs.push({
        name: `${_.get(this.state, `locations[${locationId}].name`, 'location')} - ${properties[propertyId].name}`,
      });
    }
    return breadcrumbs;
  }
}
