import './Table.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import info from '../data/info';
import Info from './Info';
import TableCell from './TableCell';
import TableDownload from './TableDownload';

export default function Table({ data, active, getLabelForPropertyKey }) {
  const isActive = (datetime) => (active && datetime.startsWith(active));
  const isRange = data.body.find((outer) => outer.items.find((inner) => inner.items));
  const body = data.body.map((row) => {
    const isMulti = row.items.find((item) => item.items);
    if (isMulti) {
      const keys = row.items.map((item) => item.name);
      return keys.map((key, index) => (
        <tr key={`${row.name}-${key}`}>
          {index === 0 && (
            <td
              className="align-middle bg-white cssip-table__fixed-cell cssip-table__vertical-word"
              rowSpan={keys.length}
            >
              {row.name.toUpperCase().split('').reduce((sum, character) => {
                if (sum === null) {
                  return character;
                }
                return (
                  <>
                    {sum}
                    <br />
                    {character}
                  </>
                );
              }, null)}
            </td>
          )}
          <td
            className="text-nowrap text-end cssip-table__fixed-cell bg-white cssip-table__fixed-cell--offset"
          >
            {/* @TODO: Do this in the property.getTableData function. */}
            {getLabelForPropertyKey(key)}
            <Info info={info.stats[key]} />
          </td>
          {row.items.find((sub) => sub.name === key).items.map((item) => (
            <TableCell
              key={item.datetime}
              value={item.value}
              color={item.color}
              className={isActive(item.datetime) ? 'fw-bold' : ''}
            />
          ))}
        </tr>
      ));
    }
    return (
      <tr key={row.name}>
        <td colSpan={isRange ? 2 : 1} className="bg-white cssip-table__fixed-cell">{row.name}</td>
        {row.items.map((item) => (
          <TableCell
            key={item.datetime}
            value={item.value}
            color={item.color}
            className={isActive(item.datetime) ? 'fw-bold' : ''}
          />
        ))}
      </tr>
    );
  });
  const head = (
    <tr>
      <td
        className="bg-white cssip-table__fixed-cell"
        colSpan={isRange ? 2 : 1}
      >
        {data.head.name}
      </td>
      {data.head.items.map((item) => (
        <td
          className={classNames('text-nowrap', {
            'bg-white': isActive(item.datetime),
          })}
          key={item.datetime}
        >
          {item.value}
        </td>
      ))}
    </tr>
  );
  return (
    <div className="mb-3 w-100">
      <div className="table-responsive border border-bottom-0">
        <table className="table mb-0">
          <thead>
            {head}
          </thead>
          <tbody>
            {body}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end">
        <TableDownload data={data} getLabelForPropertyKey={getLabelForPropertyKey} />
      </div>
    </div>
  );
}

Table.propTypes = {
  data: PropTypes.shape({
    body: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    head: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  getLabelForPropertyKey: PropTypes.func,
};

Table.defaultProps = {
  active: false,
  getLabelForPropertyKey: (key) => key,
};
