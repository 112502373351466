import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import * as paths from '../constants/paths';

export default function AuthFeatureModal({ isShowing, onClose }) {
  return (
    <Modal show={isShowing} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Sign in required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          To use this feature you must sign in.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Link
          className="btn btn-outline-primary"
          to={paths.AUTH}
        >
          Sign in
        </Link>
        <Button
          variant="outline-secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AuthFeatureModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
