import './ListItem.scss';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import ConditionalWrapper from '../containers/ConditionalWrapper';
import { scrollToElement } from '../lib/utils';
import Box from './Box';
import FormattedValue from './FormattedValue';
import Icon from './Icon';
import Shape from './Shape';
import TimeFromNow from './TimeFromNow';

export default function ListItem({ item, selected, children }) {
  const properties = item.properties.map((property) => (
    <ConditionalWrapper
      key={property.name}
      condition={property.to}
      // eslint-disable-next-line react/no-unstable-nested-components
      wrapper={(kids) => (<Link to={property.to} className="text-decoration-none">{kids}</Link>)}
    >
      <div>
        {!!property.icon && (
          <Icon
            shadow
            icon={property.icon.icon}
            style={{ color: property.icon.color }}
            className="me-1"
            fixedWidth
          />
        )}
        <strong>
          {property.name}
        </strong>
        {' '}
        <FormattedValue display="inline-block" value={property.value} />
      </div>
    </ConditionalWrapper>
  ));
  const elementRef = useRef();
  useEffect(() => {
    if (selected) {
      scrollToElement(elementRef.current, { position: 'center' });
    }
  }, [selected]);
  return (
    <li ref={elementRef}>
      <Box.Content
        className={classNames('cssip-list-item', {
          'border-secondary bg-white': selected,
          'cssip-list-item--clickable': !!item.to,
        })}
      >
        <div className="p-2 d-flex align-items-center">
          <div className="flex-grow-1">
            <ConditionalWrapper
              condition={item.to}
              // eslint-disable-next-line react/no-unstable-nested-components
              wrapper={(kids) => (
                <Link
                  className="stretched-link text-decoration-none cursor-pointer"
                  to={item.to}
                  onClick={() => ReactGA.event({ action: 'List item clicked', category: 'Navigation' })}
                >
                  {kids}
                </Link>
              )}
            >
              {!!item.color && (
                <FontAwesomeIcon icon={faCircle} style={{ color: item.color }} className="me-2" />
              )}
              <h1 className="h5 d-inline-block">{item.name}</h1>
            </ConditionalWrapper>
            {children}
            {properties.length > 0 && (
              <>
                <hr className="my-1" />
                <div className="d-flex justify-content-between">
                  <div className="small">
                    {properties}
                  </div>
                </div>
              </>
            )}
            {!!item.datetime && (
              <div className="small">
                <hr className="my-1" />
                Updated
                {' '}
                <TimeFromNow
                  moment={moment(item.datetime)}
                  updateFrequency={60 * 1000}
                />
              </div>
            )}
          </div>
          {!!item.geoJson && (
            <Shape geoJson={item.geoJson} />
          )}
        </div>
      </Box.Content>
    </li>
  );
}

ListItem.propTypes = {
  children: PropTypes.node,
  item: PropTypes.shape({
    geoJson: PropTypes.shape({}),
    name: PropTypes.string.isRequired,
    properties: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.node,
      value: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
      icon: PropTypes.shape({
        color: PropTypes.string.isRequired,
        icon: PropTypes.shape({}).isRequired,
      }),
      to: PropTypes.string,
    })).isRequired,
    to: PropTypes.string,
    color: PropTypes.string,
    datetime: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool,
};

ListItem.defaultProps = {
  children: null,
  selected: false,
};
