import { Auth } from '@aws-amplify/auth';

Auth.configure({
  region: process.env.REACT_APP_AMPLIFY_AUTH_REGION,
  userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
});

const getUser = async () => {
  let user;
  try {
    user = await Auth.currentAuthenticatedUser({ bypassCache: true });
  } catch (err) {
    user = false;
  }
  return user;
};

const updateUserAttributes = async (attributes) => {
  const userObject = await Auth.currentAuthenticatedUser();
  const response = await Auth.updateUserAttributes(userObject, attributes);
  return response;
};

const signOut = async () => {
  await Auth.signOut();
};

const changePassword = async (oldPassword, newPassword) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(user, oldPassword, newPassword);
};

const verifyUserAttributeSubmit = async (attribute, code) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.verifyUserAttributeSubmit(user, attribute, code);
};

const verifyUserAttribute = async (attribute) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.verifyUserAttribute(user, attribute);
};

export default {
  changePassword,
  verifyUserAttributeSubmit,
  verifyUserAttribute,
  getUser,
  updateUserAttributes,
  signOut,
};
