import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import NotFoundBox from '../components/NotFoundBox';
import Overview from '../components/Overview';
import Spinner from '../components/Spinner';
import { OVERVIEW_VIEW } from '../constants/pageViews';
import pages from '../data/dataPages';
import { useLoader } from '../lib/hooks';

const OverviewContainer = () => {
  const { locationId, pageId } = useParams();
  const location = useSelector((state) => state.locations[locationId]);
  const rest = useSelector((state) => {
    const Page = pages[pageId];
    const page = new Page(state);
    const dateTimeRange = page.getSelectedDateTimeRange(OVERVIEW_VIEW);
    const items = page.getOverviewItems(state, {
      dateTimeRangeId: dateTimeRange.id,
      forecastEndpointId: page.getSelectedForecastEndpoint().id,
      locationId: location.id,
      now: moment(),
      page,
    });
    return {
      ItemComponent: page.getOverviewItemComponent(),
      items,
      dateTimeRange,
      properties: page.getOverviewProperties(),
    };
  }, _.isEqual);
  return (
    <Overview
      location={location}
      ItemComponent={rest.ItemComponent}
      items={rest.items}
      dateTimeRange={rest.dateTimeRange}
      properties={rest.properties}
    />
  );
};

const OverviewLoader = () => {
  const { locationId, pageId } = useParams();
  const Page = pages[pageId];
  const { sources, isWaitingForUserLoad } = useSelector((state) => {
    const page = new Page(state);
    return {
      sources: page.getSources(),
      isWaitingForUserLoad: page.requiresAuth && !state.auth.user,
    };
  }, _.isEqual);
  const isLoading = useLoader(sources) || isWaitingForUserLoad;
  const locations = useSelector((state) => state.locations);
  if (isLoading) {
    return <Spinner />;
  }
  if (!(locationId in locations)) {
    return <NotFoundBox message={`Could not find ${Page.label} details for "${locationId}"`} />;
  }
  return <OverviewContainer />;
};

export default OverviewLoader;
