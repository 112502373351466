import { faColumns } from '@fortawesome/free-solid-svg-icons';
import { DASHBOARD } from '../constants/pageIds';
import * as paths from '../constants/paths';
import * as text from '../data/text';

export default class DashboardPage {
  static id = DASHBOARD;

  static label = 'Dashboard';

  static path = paths.DASHBOARD;

  static icon = faColumns;

  generateHead() {
    return {
      title: [this.constructor.label],
      meta: [
        { name: 'description', content: text.DASHBOARD_PAGE_DESCRIPTION },
        { name: 'robots', content: 'noindex' },
      ],
    };
  }
}
