import _ from 'lodash';
import * as types from '../constants/actionTypes';
import locationsData from '../data/locations';
import turf from '../lib/turf';

const addDistanceAndAreaToLocations = (items, currentLocation) => _.mapValues(items, (item) => {
  const location = { ...item };
  if (location.geometry) {
    if ((_.isNumber(_.get(currentLocation, '0')))) {
      location.distance = turf.distance(
        turf.center(location.geometry),
        currentLocation,
      );
    }
  }
  return location;
});

const initialState = addDistanceAndAreaToLocations(locationsData);

export default function locations(state = initialState, action = {}) {
  switch (action.type) {
    case types.ENDPOINT_RECEIVED: {
      if (!action.locations) {
        return state;
      }
      const newLocations = addDistanceAndAreaToLocations(action.locations, action.currentLocation);
      return {
        ...state,
        ...newLocations,
      };
    }

    case types.ADMIN_DATA_SOURCE_USERNAME_CHANGED:
    case types.USER_SIGNED_OUT: {
      return _.pickBy(state, (value) => value.type !== 'MY_FARM');
    }

    case types.CURRENT_LOCATION_SET: {
      return addDistanceAndAreaToLocations(state, action.location);
    }

    case types.REAL_TIME_ADD_NEW_OBSERVATIONS: {
      const newLocations = addDistanceAndAreaToLocations(
        action.data.locations,
        action.currentLocation,
      );
      return {
        ...state,
        ...newLocations,
      };
    }

    default: {
      return state;
    }
  }
}
