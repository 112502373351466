export const ALARMS = 'ALARMS';
export const CROP_FORECAST_ENDPOINT_DATES = 'CROP_FORECAST_ENDPOINT_DATES';
export const ENSO_PHASE = 'ENSO_PHASE';
export const FORECAST_CROP = 'FORECAST_CROP';
export const FORECAST_MY_FARM = 'FORECAST_MY_FARM';
export const DEMO_FARM = 'DEMO_FARM';
export const FORECAST_WEATHER = 'FORECAST_WEATHER';
export const LOCATIONS = 'LOCATIONS';
export const LOCATIONS_MY_FARM = 'LOCATIONS_MY_FARM';
export const MY_FARM_FORECAST_ENDPOINT_DATES = 'MY_FARM_FORECAST_ENDPOINT_DATES';
export const DEMO_FARM_FORECAST_ENDPOINT_DATES = 'DEMO_FARM_FORECAST_ENDPOINT_DATES';
export const OBSERVATION_CROP = 'OBSERVATION_CROP';
export const OBSERVATION_MY_FARM = 'OBSERVATION_MY_FARM';
export const OBSERVATION_WEATHER = 'OBSERVATION_WEATHER';
export const REAL_TIME = 'REAL_TIME';
export const LAST_30_DAYS = 'LAST_30_DAYS';
export const USERS = 'USERS';
export const WEATHER_FORECAST_ENDPOINT_DATES = 'WEATHER_FORECAST_ENDPOINT_DATES';
export const WIDGETS = 'WIDGETS';
