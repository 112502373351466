import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

export default function Spinner({ className }) {
  return (
    <div className={`text-center text-black-50 ${className} m-3`}>
      <FontAwesomeIcon icon={faSpinner} spin size="2x" />
    </div>
  );
}

Spinner.propTypes = {
  className: PropTypes.string,
};

Spinner.defaultProps = {
  className: '',
};
