import React from 'react';
import { useSelector } from 'react-redux';
import * as actions from '../actions';
import AccountLocationsModal from '../components/AccountLocationsModal';
import { useAction } from '../lib/hooks';

const AccountLocationsModalContainer = () => {
  const user = useSelector((state) => state.auth.user);
  const updateUserAttributes = useAction(actions.updateUserAttributes);
  if (!user) {
    return null;
  }
  const { station, zone } = user;
  return (
    <AccountLocationsModal
      station={station}
      zone={zone}
      onChange={updateUserAttributes}
    />
  );
};

export default AccountLocationsModalContainer;
