import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MapLocationSelector from '../containers/MapLocationSelector';

export default function MapLocationSelectorModal({
  isShowing, onClose, onChange, value, options,
}) {
  const [localValue, setLocalValue] = useState(value);
  const [modalState, setModalState] = useState('');
  return (
    <Modal
      show={isShowing}
      onHide={onClose}
      onEnter={() => setModalState('onEnter')}
      onEntered={() => setModalState('onEntered')}
      onExit={() => setModalState('onExit')}
    >
      {isShowing && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Select location</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: 400 }} className="p-0 overflow-hidden">
            {modalState === 'onEntered' && (
              <MapLocationSelector
                value={localValue}
                options={options}
                onChange={(item) => {
                  if (item) {
                    setLocalValue(item.id);
                  }
                }}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={() => onChange(localValue)}
            >
              Ok
            </Button>
            <Button
              variant="outline-secondary"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}

MapLocationSelectorModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

MapLocationSelectorModal.defaultProps = {
  value: false,
};
