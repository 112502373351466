import * as paths from '../constants/paths';
import CwuPage from '../pages/CwuPage';

const tools = [];

if (process.env.REACT_APP_SHOW_ENSO === 'true') {
  tools.push({
    name: 'ENSO',
    path: paths.ENSO,
    includeInUnauthed: true,
  });
}

tools.push({
  name: 'Remote sensing',
  path: paths.REMOTE_SENSING,
  includeInUnauthed: true,
});

tools.push({
  name: 'Generic crop water use',
  path: CwuPage.getOverviewPath(),
  includeInUnauthed: false,
});

if (process.env.REACT_APP_SHOW_SEASONAL === 'true') {
  tools.push({
    name: 'Climate explorer',
    path: paths.SEASONAL,
    includeInUnauthed: true,
  });
}

export default tools;
