import axios from 'axios';

const instances = {
  authed: axios.create(),
  default: axios.create(),
};

const callbacks = [];

Object.values(instances).forEach((instance) => {
  // eslint-disable-next-line no-param-reassign
  instance.defaults.timeout = 60 * 1000;
  instance.interceptors.response.use((response) => response, (error) => {
    callbacks.forEach((cb) => cb(error));
    return Promise.reject(error);
  });
});

export default instances.default;
export const axiosAuthed = instances.authed;

export const onRequestError = (cb) => {
  callbacks.push(cb);
};
